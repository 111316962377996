import * as PIXI from 'pixi.js';
import BattleActions from '~/flux/actions/BattleActions';
import { BattleStore } from '~/flux/stores';
import AbilityIcon from '~/components/common/AbilityIcon';
import BattleCalc from 'dt-common/battle_engine/BattleCalc';

const MoveBtns = function(hero) {
  if (hero.dead || !hero.inPlay) {
    return;
  }

  PIXI.Container.call(this);

  const ABILITY_ICON_SCALE = 1.5;
  const ARROW_TINT = 0x999999;

  var _moveBtns = [];
  for (var i = 0; i < 6; ++i) {
    var btn = new PIXI.Sprite();
    btn.texture = PIXI.utils.TextureCache['move_btn_part.png'];
    var arrow = new PIXI.Sprite();
    arrow.texture = PIXI.utils.TextureCache['up_arrowhead.png'];
    arrow.width = btn.width * 0.2;
    arrow.scale.y = arrow.scale.x;
    arrow.anchor.x = arrow.anchor.y = 0.5;
    arrow.y = -107;
    arrow.tint = ARROW_TINT;
    btn.addChild(arrow);

    btn.hitArea = new PIXI.Circle(0, -90, 50);

    btn.anchor.x = btn.anchor.y = 0.5;
    btn.rotation = 1.047*i; // 60 degrees
    // btn.alpha = 0.65;
    this.addChild(btn);

    btn.dirIndex = i; // mirrors the constant Game.MOVES.<direction>

    btn.interactive = btn.buttonMode = true;
    btn.mousedown = btn.touchstart = function(event) {
      var btn = event.target;
      BattleActions.moveBtnTouch(btn.dirIndex);
      scaleBtnOnTouch(btn);
    };

    _moveBtns.push(btn);
  }

  var _waitBtn = new PIXI.Sprite();
  _waitBtn.texture = PIXI.utils.TextureCache['wait.png'];
  _waitBtn.anchor.x = _waitBtn.anchor.y = 0.5;
  _waitBtn.scale.x = _waitBtn.scale.y = ABILITY_ICON_SCALE;
  _waitBtn.interactive = true;
  _waitBtn.buttonMode = true;
  _waitBtn.mousedown = _waitBtn.touchstart = (event) => {
    scaleBtnOnTouch(event.target);

    BattleActions.heroWait();
  };
  this.addChild(_waitBtn);

  var _executeBtn;
  const hideExecuteBtn = () => {
    TweenMax.to(_executeBtn.scale, 0.1, {
      x: 0,
      y: 0,
      onComplete: this.removeChild.bind(this),
      onCompleteParams: [_executeBtn],
    });
  };

  const onAbilitySelected = () => {
    if (_executeBtn) {
      hideExecuteBtn();
    }

    var ability = BattleStore.getAll().selectedAbility;
    if (ability && !ability.passive) {
      TweenMax.to(_waitBtn.scale, 0.1, { x: 0, y: 0 });

      _executeBtn = new AbilityIcon(ability);
      _executeBtn.anchor.x = _executeBtn.anchor.y = 0.5;
      _executeBtn.scale.x = _executeBtn.scale.y = ABILITY_ICON_SCALE;
      _executeBtn.interactive = true;
      _executeBtn.buttonMode = true;
      _executeBtn.mousedown = _executeBtn.touchstart = (event) => {
        scaleBtnOnTouch(event.target);

        BattleActions.executeBtnTouch();
      };
      TweenMax.from(_executeBtn.scale, 0.2, { x: 0,y: 0 });
      this.addChild(_executeBtn);
    } else {
      TweenMax.to(_waitBtn.scale, 0.2, { x: ABILITY_ICON_SCALE, y: ABILITY_ICON_SCALE });
    }
  };
  BattleStore.on(BattleStore.ABILITY_SELECTED, onAbilitySelected);

  const onStatsChange = () => {
    hero = BattleStore.getAll().battleState.allPieces[ BattleStore.getAll().unitToOrder ];
    if (!hero) {
      return;
    }

    const moveCost = BattleCalc.getEquippedAbility(hero, 'move').apCost;

    // grey out move buttons if we don't have the AP to move
    for (var i = 0; i < _moveBtns.length; ++i) {
      var btn = _moveBtns[i];
      if (hero.ap < moveCost) {
        btn.tint = 0x555555;
        btn.getChildAt(0).tint = 0x222222;
        btn.buttonMode = btn.interactive = false;
      } else {
        btn.tint = 0xffffff;
        btn.getChildAt(0).tint = ARROW_TINT;
        btn.buttonMode = btn.interactive = true;
      }
    }
  };
  onStatsChange();
  BattleStore.on(BattleStore.STAT_COSTS_EXACTED, onStatsChange);
  BattleStore.on(BattleStore.ABILITY_EXECUTED, onStatsChange); // in case of mystic focus or adrenaline

  function scaleBtnOnTouch(btn) {
    // ensure we are starting from full scale
    var tweens = TweenMax.getTweensOf(btn.scale);
    for (var i = 0; i < tweens.length; ++i) {
      tweens[i].seek(tweens[i].endTime());
    }

    TweenMax.from(btn.scale, 0.2, { x: btn.scale.x*0.7,y: btn.scale.y*0.7 });
  }

  this.dispose = function() {
    BattleStore.removeListener(BattleStore.ABILITY_SELECTED, onAbilitySelected);
    BattleStore.removeListener(BattleStore.STAT_COSTS_EXACTED, onStatsChange);
    BattleStore.removeListener(BattleStore.ABILITY_EXECUTED, onStatsChange);

    _waitBtn = null;
    _executeBtn = null;
    _moveBtns = null;

    this.removeChildren();
  };
};
MoveBtns.prototype = Object.create(PIXI.Container.prototype);
MoveBtns.prototype.constructor = MoveBtns;
export default MoveBtns;