import Audio from '~/Audio';

const SlashRendering = {
	render: function( data, gameBoard, isCaverns ) {
		const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
		const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

		render_movement && actorSprite.puppet.getMovements().slash.movePuppet({ transitTime });
	},

	renderResult: function( data, gameBoard ) {
		Audio.play( 'slash' );
	}
}
export default SlashRendering;