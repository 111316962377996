import PuppetMovement from './PuppetMovement.js';

const TauntMovement = (puppet) => {
  const poses = [ 
    { pose:WalkCycle1() },
    { pose:WalkCycle2() },
    { pose:WalkCycle3() },
    { pose:WalkCycle4() },
    { pose:WalkCycle5() },
    { pose:WalkCycle6() },
    { pose:WalkCycle7() },
    { pose:WalkCycle8() },
    { pose:Pose1() },
    { pose:Pose1() },
    { pose:Pose3() },
    { pose:Pose1() },
    { pose:Pose1() },
    { pose:Pose3() },
    { pose:Pose3() }
  ];
     
  return PuppetMovement({
    poses,
    puppet,
    setPoseDurations: (actorCooldown) => {
      const transTime = actorCooldown / 20;
      for (const pose of poses) {
        pose.duration = transTime;
      }
      poses[8].duration = transTime * 3;            
      poses[11].duration = transTime * 3;            
      poses[14].duration = transTime * 2;
    }
  });
};
export default TauntMovement;

const WalkCycle1 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -155,
      z: 8
    },        
    shoulders: {
      x: 0,
      y: -140,
      z: 4
    },        
    torsoe: {
      x: 0,
      y: -105,
      z: 0
    },        
    leftHand: {
      x: -24,
      y: -72,
      z: 20
    },     
    bowPitch: 70,
    bowYaw: 10,   
    rightHand: {
      x: 24,
      y: -66,
      z: 6
    },        
    weaponPitch: 70,
    weaponYaw: -10,        
    leftFoot: {
      x: -6,
      y: 0,
      z: -5
    },        
    rightFoot: {
      x: 6,
      y: -18,
      z: -26
    }
  };
  return pose;       
};

const WalkCycle2 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -162,
      z: 8
    },        
    shoulders: {
      x: 0,
      y: -147,
      z: 4
    },        
    torsoe: {
      x: 0,
      y: -94,
      z: 0
    },        
    leftHand: {
      x: -24,
      y: -72,
      z: 36
    },     
    bowPitch: 60,
    bowYaw: 10,   
    rightHand: {
      x: 24,
      y: -78,
      z: 6
    },        
    weaponPitch: 80,
    weaponYaw: -10,        
    leftFoot: {
      x: -6,
      y: -6,
      z: -18
    },        
    rightFoot: {
      x: 6,
      y: -18,
      z: -22
    }
  };

  //forward travel
  for(var part in pose) {
    if(pose[part].z) {
      pose[part].z += 10;
    }       
  }

  return pose;       
};

const WalkCycle3 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -147,
      z: 8
    },        
    shoulders: {
      x: 0,
      y: -137,
      z: 4
    },        
    torsoe: {
      x: 0,
      y: -90,
      z: 0
    },        
    leftHand: {
      x: -24,
      y: -66,
      z: 42
    },        
    bowPitch: 70,
    bowYaw: 10,  
    rightHand: {
      x: 24,
      y: -78,
      z: -24
    },        
    weaponPitch: 110,
    weaponYaw: -10,        
    leftFoot: {
      x: -6,
      y: -12,
      z: -48
    },        
    rightFoot: {
      x: 6,
      y: -6,
      z: 57
    }
  };

  //forward travel
  for(var part in pose) {
    if(pose[part].z) {
      pose[part].z += 20;
    }       
  }

  return pose;       
};

const WalkCycle4 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -137,
      z: 8
    },        
    shoulders: {
      x: 0,
      y: -122,
      z: 4
    },        
    torsoe: {
      x: 0,
      y: -87,
      z: 0
    },        
    leftHand: {
      x: -24,
      y: -72,
      z: 48
    },        
    bowPitch: 40,
    bowYaw: 10,  
    rightHand: {
      x: 24,
      y: -72,
      z: -33
    },        
    weaponPitch: 110,
    weaponYaw: -10,        
    leftFoot: {
      x: -6,
      y: -18,
      z: -54
    },        
    rightFoot: {
      x: 6,
      y: 0,
      z: 30
    }
  };

  //forward travel
  for(var part in pose) {
    if(pose[part].z) {
      pose[part].z += 30;
    }       
  }

  return pose;       
};

const WalkCycle5 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -155,
      z: 8
    },        
    shoulders: {
      x: 0,
      y: -140,
      z: 4
    },        
    torsoe: {
      x: 0,
      y: -105,
      z: 0
    },        
    leftHand: {
      x: -24,
      y: -66,
      z: 6
    },        
    bowPitch: 70,
    bowYaw: -10,  
    rightHand: {
      x: 24,
      y: -72,
      z: -20
    },        
    weaponPitch: 70,
    weaponYaw: 10,        
    leftFoot: {
      x: -6,
      y: -18,
      z: -26
    },        
    rightFoot: {
      x: 6,
      y: 6,
      z: -5
    }
  };

  //forward travel
  for(var part in pose) {
    if(pose[part].z) {
      pose[part].z += 40;
    }       
  }

  return pose;       
};

const WalkCycle6 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -162,
      z: 8
    },        
    shoulders: {
      x: 0,
      y: -147,
      z: 4
    },        
    torsoe: {
      x: 0,
      y: -94,
      z: 0
    },        
    leftHand: {
      x: -24,
      y: -78,
      z: 6
    },        
    bowPitch: 80,
    bowYaw: -10,  
    rightHand: {
      x: 24,
      y: -72,
      z: 36
    },        
    weaponPitch: 60,
    weaponYaw: 10,        
    leftFoot: {
      x: -6,
      y: -18,
      z: 22
    },        
    rightFoot: {
      x: 6,
      y: -6,
      z: -18
    }
  };

  //forward travel
  for(var part in pose) {
    if(pose[part].z) {
      pose[part].z += 50;
    }       
  }

  return pose;       
};

const WalkCycle7 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -147,
      z: 8
    },        
    shoulders: {
      x: 0,
      y: -137,
      z: 4
    },        
    torsoe: {
      x: 0,
      y: -90,
      z: 0
    },        
    leftHand: {
      x: -24,
      y: -78,
      z: -24
    },        
    bowPitch: 110,
    bowYaw: -10,  
    rightHand: {
      x: 24,
      y: -66,
      z: 42
    },        
    weaponPitch: 70,
    weaponYaw: 10,        
    leftFoot: {
      x: -6,
      y: -6,
      z: 57
    },        
    rightFoot: {
      x: 6,
      y: -12,
      z: -48
    }
  };

  //forward travel
  for(var part in pose) {
    if(pose[part].z) {
      pose[part].z += 60;
    }       
  }

  return pose;       
};

const WalkCycle8 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -137,
      z: 8
    },        
    shoulders: {
      x: 0,
      y: -122,
      z: 4
    },        
    torsoe: {
      x: 0,
      y: -87,
      z: 0
    },        
    leftHand: {
      x: -24,
      y: -72,
      z: -33
    },        
    bowPitch: 110,
    bowYaw: -10,  
    rightHand: {
      x: 24,
      y: -72,
      z: 48
    },        
    weaponPitch: 40,
    weaponYaw: 10,        
    leftFoot: {
      x: -6,
      y: 0,
      z: 30
    },        
    rightFoot: {
      x: 6,
      y: -18,
      z: -54
    }
  };

  //forward travel
  for(var part in pose) {
    if(pose[part].z) {
      pose[part].z += 70;
    }       
  }

  return pose;       
};

const Pose1 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -160,
      z: 8
    },        
    shoulders: {
      x: 0,
      y: -144,
      z: 4
    },        
    torsoe: {
      x: 0,
      y: -105,
      z: 0
    },        
    leftHand: {
      x: -35,
      y: -90,
      z: 0
    },        
    bowPitch: 110,
    bowYaw: -10,  
    rightHand: {
      x: 12,
      y: -150,
      z: 72
    },        
    weaponPitch: 40,
    weaponYaw: 10,        
    leftFoot: {
      x: -26,
      y: 0,
      z: 0
    },        
    rightFoot: {
      x: 26,
      y: 0,
      z: 0
    }
  };

  //forward travel
  for(var part in pose) {
    if(pose[part].z) {
      pose[part].z += 70;
    }       
  }

  return pose;       
};

const Pose2 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -160,
      z: 8
    },        
    shoulders: {
      x: 0,
      y: -144,
      z: 4
    },        
    torsoe: {
      x: 0,
      y: -105,
      z: 0
    },        
    leftHand: {
      x: -35,
      y: -90,
      z: 0
    },        
    bowPitch: 110,
    bowYaw: -10,  
    rightHand: {
      x: -7,
      y: -177,
      z: 42
    },        
    weaponPitch: 40,
    weaponYaw: 10,        
    leftFoot: {
      x: -26,
      y: 0,
      z: 0
    },        
    rightFoot: {
      x: 26,
      y: 0,
      z: 0
    }
  };

  //forward travel
  for(var part in pose) {
    if(pose[part].z) {
      pose[part].z += 70;
    }       
  }

  return pose;       
};

const Pose3 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -160,
      z: 8
    },        
    shoulders: {
      x: 0,
      y: -144,
      z: 4
    },        
    torsoe: {
      x: 0,
      y: -105,
      z: 0
    },        
    leftHand: {
      x: -35,
      y: -90,
      z: 0
    },        
    bowPitch: 110,
    bowYaw: -10,  
    rightHand: {
      x: 17,
      y: -177,
      z: 42
    },        
    weaponPitch: 40,
    weaponYaw: 10,        
    leftFoot: {
      x: -26,
      y: 0,
      z: 0
    },        
    rightFoot: {
      x: 26,
      y: 0,
      z: 0
    }
  };

  //forward travel
  for(var part in pose) {
    if(pose[part].z) {
      pose[part].z += 70;
    }       
  }

  return pose;       
};