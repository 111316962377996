import * as PIXI from 'pixi.js';
import BattleConfig from 'dt-common/constants/BattleConfig';
import BattleCalc from 'dt-common/battle_engine/BattleCalc';
import Audio from '~/Audio';
import CommonEffects from '../../../effects/CommonEffects';

const RoadToNowhereRendering = {
	render: function( data, gameBoard, isCaverns ) {
		const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];		
		const victimSprite = gameBoard.getAllPieceSprites()[data.victimId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
		const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

		const projectileTime = BattleCalc.projectileTime( BattleConfig.THROWN_SPEED, actorSprite.gamePiece, victimSprite.gamePiece );

		render_movement && actorSprite.puppet.getMovements().throw.movePuppet({ transitTime });

		var _timeline = new TimelineMax();

		_timeline.call( Audio.play, ['throw_01'], transitTime * 0.4);
		_timeline.call( Audio.play, ['glass_shatter_01'], transitTime * 0.4 + projectileTime / 1000);

		_timeline.call( ()=> {
			var _bottle = new PIXI.Sprite();
      _bottle.texture = PIXI.utils.TextureCache['effects/ice_nine_bottle.png'];
			_bottle.anchor.x = _bottle.anchor.y = 0.5;
			_bottle.x = actorSprite.x;
			_bottle.y = actorSprite.y;
			gameBoard.addChild( _bottle );
			
			_timeline.add( 
				TweenMax.to( _bottle, projectileTime/1000, {rotation:(45+Math.random()*360)*(Math.PI/180), ease:Linear.easeNone} ),
				_timeline._time
			);

			_timeline.add( 
				TweenMax.to( _bottle, projectileTime/1000, {
					x: victimSprite.x, 
					y: victimSprite.y,
					onComplete: ()=> {
						if( _bottle.parent ) {
							_bottle.parent.removeChild( _bottle );
						}
						
						for( var i = 0; i < 7; ++i ) {
							TweenMax.delayedCall( i*0.03, CommonEffects.makeCloudPuff, [victimSprite] );
						}

						Audio.play( 'fumigate' );
					}
				}),
				_timeline._time
			);			

		}, [], transitTime*0.5);
	}
};
export default RoadToNowhereRendering;