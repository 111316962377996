import CommonEffects from '../../../effects/CommonEffects';
import Audio from '~/Audio';

const SummonElfRendering = {
  render: function (data, gameBoard, isCaverns) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
    const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

    render_movement &&
      actorSprite.puppet.getMovements().magicSummon.movePuppet({ transitTime });

    TweenMax.delayedCall(transitTime - 0.5, () => {
      for (var i = 0; i < 7; ++i) {
        TweenMax.delayedCall(i * 0.03, CommonEffects.makeCloudPuff, [
          actorSprite,
        ]);
      }

      Audio.play('summon_04');
    });
  },

  renderResult: function (data, gameBoard, isCaverns) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const pSprite1 = gameBoard.getAllPieceSprites()[data.poppets[0].uid];
    const pSprite2 = gameBoard.getAllPieceSprites()[data.poppets[1].uid];

    pSprite1.x = pSprite2.x = actorSprite.x;
    pSprite1.y = pSprite2.y = actorSprite.y;

    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
    if (!render_movement) {
      actorSprite.snapToBoardPosition(
        data.newActorCoords.x,
        data.newActorCoords.y
      );
      pSprite1.snapToBoardPosition(pSprite1.gamePiece.x, pSprite1.gamePiece.y);
      pSprite2.snapToBoardPosition(pSprite2.gamePiece.x, pSprite2.gamePiece.y);
    } else {
      actorSprite.tweenToBoardPosition(
        data.newActorCoords.x,
        data.newActorCoords.y,
        0.25
      );
      pSprite1.tweenToBoardPosition(
        pSprite1.gamePiece.x,
        pSprite1.gamePiece.y,
        0.25
      );
      pSprite2.tweenToBoardPosition(
        pSprite2.gamePiece.x,
        pSprite2.gamePiece.y,
        0.25
      );
    }
  },
};
export default SummonElfRendering;
