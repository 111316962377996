import { Dispatcher } from 'flux';

const LeaderboardDispatcher = Object.assign(new Dispatcher(), {
  NAME: 'LEADERBOARD_DISPATCHER',

  GET_LEADERBOARD: 'GET_LEADERBOARD',
  USE_LOCATION: 'USE_LOCATION',

  handleLeaderboardAction: function(action) {
    this.dispatch({
      source: LeaderboardDispatcher.NAME,
      action
    });
  }
});
export default LeaderboardDispatcher;
