import { Dispatcher } from 'flux';

const ArenaLobbyDispatcher = Object.assign(new Dispatcher(), {
  NAME: 'ARENA_LOBBY_DISPATCHER',

  ENTER_STANDARD_MATCHMAKING_QUEUE: 'ENTER_STANDARD_MATCHMAKING_QUEUE',
  SELECT_HERO_FOR_SLOT: 'SELECT_HERO_FOR_SLOT',
  GET_QUICK_MATCHUP: 'GET_QUICK_MATCHUP',
  SHOW_CHALLENGE_BY_NAME_DIALOG: 'SHOW_CHALLENGE_BY_NAME_DIALOG',
  CHALLENGE_PLAYER_BY_NAME: 'CHALLENGE_PLAYER_BY_NAME',
  SET_LAST_MATCH_TYPE: 'SET_LAST_MATCH_TYPE',
  INIT_CHALLENGE: 'INIT_CHALLENGE',
  ACCEPT_LIVE_CHALLENGE: 'ACCEPT_LIVE_CHALLENGE',

  FETCH_LEADERBOARD_RANK: 'FETCH_LEADERBOARD_RANK',

  handleArenaLobbyAction: function(action) {
    this.dispatch({
      source: ArenaLobbyDispatcher.NAME,
      action
    });
  }
});
export default ArenaLobbyDispatcher;
