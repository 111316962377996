import * as PIXI from 'pixi.js';
import CurrencyStore from '~/flux/stores/CurrencyStore';
import { UIActions } from '~/flux/actions';
import CanvasTools from '~/view/CanvasTools';
import Audio from '~/Audio';
import Tools from '~/Tools';

const CurrenciesView = function() {
  PIXI.Container.call(this);

  this.dispose = () => {
    this.destroy();
    
    if (_pdBtn) {
      _pdBtn.mousedown = _pdBtn.touchstart = null;
      _pdBtn.valueField.destroy();
      _pdBtn = null;
    }

    if (_goldBtn) {
      _goldBtn.mousedown = _goldBtn.touchstart = null;
      _goldBtn.valueField.destroy();
      _goldBtn = null;
    }

    CurrencyStore.removeListener(CurrencyStore.CURRENCY_CHANGE, onCurrencyChange);
    CurrencyStore.removeListener(CurrencyStore.INSUFFICIENT_PIXIE_DUST, onInsufficientPixieDust);
  };

  const NUMBER_STYLE =  {
    fontFamily: 'Courier New',
    fontSize: CanvasTools.dynamicFontSizeString(13),
    fontWeight: 'bold',
    fill: 0xffffff,
  };

  // make pixie dust button and value field
  var _pdBtn = new PIXI.Sprite();
  _pdBtn.texture = PIXI.utils.TextureCache['header/pixie_dust_btn.png'];
  _pdBtn.scale.x = _pdBtn.scale.y = Math.min(1, window.innerWidth / 1000);
  _pdBtn.x = Math.round(window.innerWidth - _pdBtn.width * 2 - DT_CANVAS_GLOBALS.spacing * 10);
  _pdBtn.y = Math.round(DT_CANVAS_GLOBALS.spacing);
  this.addChild(_pdBtn);

  const pd_val = CurrencyStore.getAll().pixieDust;
  _pdBtn.valueField = new PIXI.Text(Tools.formatGameCurrency(pd_val), NUMBER_STYLE);
  _pdBtn.valueField.x = Math.round((_pdBtn.width/2)/_pdBtn.scale.x - _pdBtn.valueField.width / 2);
  _pdBtn.valueField.y = Math.round(_pdBtn.height/_pdBtn.scale.y + DT_CANVAS_GLOBALS.spacing / 2);
  _pdBtn.addChild(_pdBtn.valueField);

  // make gold button and value field
  var _goldBtn = new PIXI.Sprite();
  _goldBtn.texture = PIXI.utils.TextureCache['header/gold_coin_btn.png'];
  _goldBtn.scale.x = _goldBtn.scale.y = _pdBtn.scale.x;
  _goldBtn.x = Math.round(_pdBtn.x + _pdBtn.width + DT_CANVAS_GLOBALS.spacing * 5);
  _goldBtn.y = Math.round(_pdBtn.y);
  this.addChild(_goldBtn);

  const gold_val = CurrencyStore.getAll().gold;
  _goldBtn.valueField = new PIXI.Text(Tools.formatGameCurrency(gold_val), NUMBER_STYLE);
  _goldBtn.valueField.x = Math.round((_goldBtn.width/2)/_goldBtn.scale.x - _goldBtn.valueField.width/2);
  _goldBtn.valueField.y = Math.round(_goldBtn.height/_goldBtn.scale.y + DT_CANVAS_GLOBALS.spacing/2);
  _goldBtn.addChild(_goldBtn.valueField);

  _pdBtn.interactive = _pdBtn.buttonMode = true;
  _pdBtn.tap = _pdBtn.click = () => {
    UIActions.showModal({ modal_key: 'PixieDustModal' });
  };

  _goldBtn.interactive = _goldBtn.buttonMode = true;
  _goldBtn.tap = _goldBtn.click = () => {
    UIActions.showModal({ modal_key: 'MakeGoldModal' });
  };

  var _lastPixieDust = CurrencyStore.getAll().pixieDust;
  var _lastGold = CurrencyStore.getAll().gold;

  const onCurrencyChange = () => {
    if(_lastPixieDust !== CurrencyStore.getAll().pixieDust) {
      _pdBtn.valueField.text = Tools.formatGameCurrency(CurrencyStore.getAll().pixieDust);
      _pdBtn.valueField.x = Math.round((_pdBtn.width/2)/_pdBtn.scale.x - _pdBtn.valueField.width/2);
      _pdBtn.valueField.y = Math.round(_pdBtn.height/_pdBtn.scale.y + DT_CANVAS_GLOBALS.spacing/2);
            
      _lastPixieDust = CurrencyStore.getAll().pixieDust;
      Audio.play('crystal_twinkle');
    }

    if(_lastGold !== CurrencyStore.getAll().gold) {
      _goldBtn.valueField.text = Tools.formatGameCurrency(CurrencyStore.getAll().gold);
      _goldBtn.valueField.x = Math.round((_goldBtn.width/2)/_goldBtn.scale.x - _goldBtn.valueField.width/2);
      _goldBtn.valueField.y = Math.round(_goldBtn.height/_goldBtn.scale.y + DT_CANVAS_GLOBALS.spacing/2);

      _lastGold = CurrencyStore.getAll().gold;
      Audio.play('coin_rattle');
    }
  };
  CurrencyStore.on(CurrencyStore.CURRENCY_CHANGE, onCurrencyChange);

  const onInsufficientPixieDust = () => {
    setTimeout(() => {
      UIActions.showModal({ modal_key: 'PixieDustModal' });
    }, 1500);
  };
  CurrencyStore.on(CurrencyStore.INSUFFICIENT_PIXIE_DUST, onInsufficientPixieDust);
};
CurrenciesView.prototype = Object.create(PIXI.Container.prototype);
CurrenciesView.prototype.constructor = CurrenciesView;
export default CurrenciesView;
