<template>
  <div
    id='chat_body_scroll'
    ref="chat_body_scroll"
    class="grow text-grey-700 max-h-[100%] overflow-y-auto text-xs"
  >
    <div v-for="message, i of message_cache" :key="i">
      <!-- case: line break -->
      <br v-if="message.line_break" />

      <!-- case: emote/command/notif -->
      <div
        v-else-if="message.emote || message.command || message.notification"
        :style="{ color: message.color || '#fff', fontWeight: message.notification ? 'bold' : 'normal' }"
      >
        <div :class="{ 'italic ml-2': !!message.emote }">
          <span v-if="!!message.timestamp" class="mr-1" style="font-size: 10px;">{{ parseTimestamp(message.timestamp) }}</span>
          <span v-html="linkify(message.text)"></span>
        </div>
      </div>

      <!-- case: player chat message -->
      <div
        v-else
        class="flex"
        :style="{ color: message.color || '#fff' }"
      >
        <!-- timestamp | username | colon -->
        <span class="mr-1" style="font-size: 10px;">{{ parseTimestamp(message.timestamp) }}</span>
        <UserNameplate :username="message.actor" />
        <span v-if="!!message.actor" class="font-bold">:</span>
        <!-- message body -->
        <div
          v-html="linkify(message.text)"
          :class="{ 'ml-2': !!message.actor }">
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onUpdated } from 'vue';
import ChatStore from '~/flux/stores/ChatStore';
import { UserNameplate } from '~/view/components/common/DOM';

const chat_body_scroll = ref(null);
const message_cache = ref(null)

const normalizeMessageCache = raw => {
  const MAX_TO_RENDER = 2000;
  return raw.slice(0, MAX_TO_RENDER);
};

const onGotMessage = () => {
  const { messageCache, currentChannel } = ChatStore.getAll();
  message_cache.value = normalizeMessageCache(messageCache[currentChannel]);
};
onGotMessage(); // initialize message_cache
ChatStore.on(ChatStore.GOT_MESSAGE_EVENT, onGotMessage);

onUpdated(() => {
  // keep scroll cinched to bottom when new message lines are added
  if (chat_body_scroll.value.scrollHeight - chat_body_scroll.value.scrollTop < window.innerHeight * 0.82) {
    chat_body_scroll.value.scrollTop = chat_body_scroll.value.scrollHeight;
  }
});

function linkify(text) {
  return text.replace(LINK_REGEX, "<a href='$1' target='_blank'>$1</a>");
}
const LINK_REGEX = /(\b(https?|ftp|file):\/\/([-A-Z0-9+&@#%?=~_|!:,.;]*)([-A-Z0-9+&@#%?\/=~_|!:,.;]*)[-A-Z0-9+&@#\/%=~_|])/ig;

function parseTimestamp(timestamp) {
  if (!timestamp) {
    return '';
  }
  const date = new Date(timestamp);
  return `[${date.toLocaleTimeString()}]`.replace(' AM', '').replace(' PM', '');
}
</script>
