import PuppetMovement from './PuppetMovement';

const StunnedMovement = (puppet) => {
  const poses = [
    {pose:Pose1},
    {pose:Pose2}
  ];
     
  return PuppetMovement({
    poses,
    puppet,
    setPoseDurations: (actorCooldown) => {
      poses[0].duration = poses[1].duration = actorCooldown * 0.8;
      poses[0].ease = poses[1].ease = 'quad.inOut'; // Quad.easeInOut;
    }
  });
};
export default StunnedMovement;

const Pose1 =  {
  head: {
    	x: -24,
    y: -142,
    z: 38
  },
  shoulders: {
    x: -10,
    y: -132,
    z: 24
  },
  torsoe: {
    x: -5,
    y: -99,
    z: 0
  },    
  leftHand: {
    x: -44,
    y: -60,
    z: 18
  },      
  bowPitch: 40,
  bowYaw: 40,
  rightHand: {
    x: -13,
    y: -60,
    z: 36
  },
  weaponPitch: 40,
  weaponYaw: -40,
  leftFoot: {
    x: -21,
    y: 0,
    z: 12
  },
  rightFoot: {
    x: 16,
    y: 0,
    z: 12
  }
};	
	
const Pose2 = {
  head: {
    x: 24,
    y: -142,
    z: 38
  },
  shoulders: {
    x: 10,
    y: -132,
    z: 24
  },
  torsoe: {
    x: 5,
    y: -99,
    z: 0
  },
  leftHand: {
    x: 13,
    y: -60,
    z: 36
  },
  bowPitch: 40,
  bowYaw: 40,
  rightHand: {
    x: 44,
    y: -60,
    z: 18
  },
  weaponPitch: 40,
  weaponYaw: -40,
  leftFoot: {
    x: -21,
    y: 0,
    z: 12
  },
  rightFoot: {
    x: 16,
    y: 0,
    z: 12	
  }
};
