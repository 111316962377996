import StripAnimation from './StripAnimation';

const FlameEffect = function( duration ) {
	return new StripAnimation( 
		'effects/flame',
		duration,
		10,
		false
	);	
}
export default FlameEffect;