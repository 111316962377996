const EXCLUDED_PARTS = ['off_hand', 'shield', 'weapon'];

const PuppetMovement = ({ poses, puppet, setPoseDurations, skill_type }) => {
  const _timelines = poses.map(() => gsap.timeline());

  const tweenToPose = (pose_index, duration) => {
    puppet.timeline.getChildren().forEach(tween => tween.kill());
    puppet.timeline.clear();
    puppet.timeline.add(
      makeMovementTimeline({
        pose_index,
        duration,
      }),
      0.001,
    );
    puppet.timeline.restart();
  };

  const makeMovementTimeline = ({ pose_index, duration }) => {
    const pose = poses[pose_index];
    const timeline = _timelines[pose_index];
    timeline.getChildren().forEach(tween => tween.kill());
    timeline.clear();

    const puppet_parts = puppet.getParts();
    const puppet_parts_entries =  Object.entries(puppet_parts)
      .filter(([key]) => !EXCLUDED_PARTS.includes(key));

    // world coords for body parts
    for (let i = 0; i < puppet_parts_entries.length; ++i) {
      const [key, part] = puppet_parts_entries[i];
      const pose_part = pose.pose[key];
      if (pose_part) {
        timeline.add(
          gsap.to(part, {
            duration,
            world_x: pose_part.x,
            world_y: pose_part.y,
            world_z: pose_part.z,
            ease: pose.ease,
          }),
          0.001,
        );
      }
    }
    // held items pitch & yaw
    timeline.add(
      gsap.to(puppet_parts.weapon, {
        duration,
        pitch: pose.pose.weaponPitch,
        yaw: pose.pose.weaponYaw,
        ease: pose.ease
      }),
      0.001
    );
    timeline.add(
      gsap.to(puppet_parts.off_hand, {
        duration,
        pitch: pose.pose.bowPitch,
        yaw: pose.pose.bowYaw,
        ease: pose.ease
      }),
      0.001
    );

    return timeline;
  };

  return {
    dispose: () => {
      for (const timeline of _timelines) {
        timeline.getChildren().forEach(tween => tween.kill());
        timeline.kill();
      }
    },

    movePuppet: ({ transitTime, timeVar = NaN }) => {
      if (!puppet) {
        return;
      }

      // show/hide weapons/bows based on skill type
      const pParts = puppet.getParts();
      if (skill_type === MELEE_SKILL) {
        pParts.weapon.visible = true;

        if (puppet.actor.equipment.off_hand?.type === 'bow') {
          pParts.off_hand.visible = false;
        } else {
          pParts.off_hand.visible = true;
        }
      } else if (skill_type === BOW_SKILL) {
        pParts.weapon.visible = false;
        pParts.off_hand.visible = true;
      }

      puppet.timeline.getChildren().forEach(tween => tween.kill());
      puppet.timeline.clear();
      setPoseDurations(transitTime, timeVar);
      for (let i = 0; i < poses.length; ++i) {
        puppet.timeline.add(
          makeMovementTimeline({
            pose_index: i,
            duration: poses[i].duration,
          }),
          '>',
        );
      }
    },

    pause: () => {
      for (const timeline of _timelines) {
        timeline.pause();
      }
    },

    resume: () => {
      for (const timeline of _timelines) {
        timeline.resume();
      }
    },

    setPose: (pose_index) => {
      tweenToPose(pose_index, 0.001);
    },

    tweenToPose,
  }
};
export default PuppetMovement;
export const MELEE_SKILL = 'MELEE_SKILL';
export const BOW_SKILL = 'BOW_SKILL';
