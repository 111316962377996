import * as PIXI from 'pixi.js';
import Colors from '~/constants/Colors';
import Tools from '~/Tools';
import AltarActions from '~/flux/actions/AltarActions';

const PrayerSlotView = function(slotData, locked=false) {
  PIXI.Container.call(this);

  this.slotData = slotData;

  this.transitionIn = () => {};
  this.transitionOut = () => {};

  this.dispose = () => {
    this.removeChildren();

    if (this.ppb) {
      this.ppb.removeChildren();
      this.ppb = null;
    }

    this.mouseover = this.pointerover = null;
    this.mouseout = this.pointerout = null;
    this.mousedown = this.touchstart = null;
    this.mouseup = this.touchend = null;
  };

  const makePrayerProgressBar = () => {
    try {
      const ppb = this.ppb = new PIXI.Container();

      // backdrop
      ppb.bd = new PIXI.Graphics();
      ppb.bd.beginFill(0xf1ee1b, 0.3);
      ppb.bd.drawRect(0, 0, _backdrop.width, _backdrop.height);
      ppb.bd.endFill();
      TweenMax.from(ppb.bd, 0.5, { alpha: 0 });
      ppb.addChild(ppb.bd);

      // time ticker
      var timeColor;
      switch (slotData.godTag) {
        case 'chaos': timeColor = 0xff0000; break;
        case 'balance': timeColor = 0x00ff00; break;
        case 'order': timeColor = 0x0000ff; break;
      }

      const now = new Date().getTime();

      ppb.time = new PIXI.Text(Tools.millisecondsToHHMMSS(slotData.endTime - now), {
        fontFamily: 'Courier New',
        fontStyle: 'bold',
        fontSize: '24px',
        fill: timeColor,
        dropShadow: true,
        dropShadowDistance: 2,
      });
      ppb.time.anchor.x = ppb.time.anchor.y = 0.5;

      ppb.height = -_backdrop.height;
      ppb.width = _backdrop.width;
      ppb.x = 0;
      ppb.y = _backdrop.height;
      this.addChild(ppb);

      ppb.time.scale.y /= ppb.scale.y;
      ppb.time.scale.x /= ppb.scale.x;

      if (ppb.time.width > (ppb.width - 10)) {
        ppb.time.width = ppb.width - 10;
        ppb.time.scale.y = -ppb.time.scale.x;
      }

      ppb.time.x = (ppb.width / 2) / ppb.scale.x;
      ppb.time.y = (ppb.height / 2) / ppb.scale.y;

      ppb.addChild(ppb.time);

      const updatePrayerProgressBar = () => {
        if (this.ppb) {
          const now = new Date().getTime();

          var timeRatio = (now - slotData.startTime) / (slotData.endTime - slotData.startTime);
          ppb.bd.scale.y = Math.min(1, timeRatio);

          ppb.time.text = Tools.millisecondsToHHMMSS(Math.max(0, slotData.endTime - now));
          ppb.time.x = (ppb.width / 2) / ppb.scale.x;

          if (timeRatio < 1) {
            requestAnimationFrame(updatePrayerProgressBar);
          } else {
            // should come back with the prayer slot in a "complete" state
            AltarActions.getAltarState();
          }
        }
      };
      updatePrayerProgressBar();
    } catch (err) {
      logError(err, Object.assign(
        {
          module: 'components/ui_screens/altar/PrayerSlotView',
          func: 'makePrayerProgressBar',
          locked,
        }, slotData),
      );
    }
  };

  var _backdrop = this.backdrop = new PIXI.Sprite();
  _backdrop.texture = PIXI.utils.TextureCache['altar/prayer_slot.png'];
  _backdrop.height = Math.min(_backdrop.height, window.innerHeight*0.2);
  _backdrop.scale.x = _backdrop.scale.y;
  this.addChild(_backdrop);

  const DARK_GREY = 0x444444;
  this.tintBuffer = 0xffffff;

  this.mouseover = this.pointerover = () => {
    _backdrop.tint = 0x6effbe;
  };
  this.mouseout = this.pointerout = () => {
    _backdrop.tint = this.tintBuffer;

    if (_scale_buffer) {
      this.scale = _scale_buffer;
    }
  };

  let _scale_buffer;
  this.mousedown = this.touchstart = () => {
    _scale_buffer = { x: this.scale.x, y: this.scale.y };
    this.scale = {
      x: this.scale.x * 0.965,
      y: this.scale.y * 0.965,
    };
  };
  this.mouseup = this.touchend = () => {
    if (_scale_buffer) {
      this.scale = _scale_buffer;
    }
  };

  this.pivot = { x: _backdrop.width / 2, y: _backdrop.width / 2 };

  if (locked) {
    // locked slot
    _backdrop.tint = this.tintBuffer = DARK_GREY;

    var plus = new PIXI.Sprite();
    plus.texture = PIXI.utils.TextureCache['plus.png'];
    plus.x = _backdrop.width/2 - plus.width/2;
    plus.y = _backdrop.height/2 - plus.height/2;
    this.addChild(plus);
  } else {
    // backwards compatibility with old var name
    if (slotData && !slotData.godTag) {
      slotData.godTag = slotData.targetGod;
    }

    var prayerHands = new PIXI.Sprite();
    prayerHands.texture = PIXI.utils.TextureCache['altar/prayer_hands_white.png'];
    prayerHands.width = _backdrop.width*0.3;
    prayerHands.scale.y = prayerHands.scale.x;
    prayerHands.x = _backdrop.width/2 - prayerHands.width/2;
    prayerHands.y = _backdrop.height/2 - prayerHands.height/2;
    this.addChild(prayerHands);

    if (!slotData.active) {
      // inactive slot
      _backdrop.tint = this.tintBuffer = DARK_GREY;
      prayerHands.tint = DARK_GREY;
    } else {
      if (new Date().getTime() >= slotData.endTime) {
        // complete prayer
        prayerHands.tint = Colors.BRIGHT_YELLOW;
      } else {
        // active prayer
        _backdrop.tint = 0xffffff;
        prayerHands.tint = 0xffffff;
        makePrayerProgressBar();
      }
    }
  }
};
PrayerSlotView.prototype = Object.create(PIXI.Container.prototype);
PrayerSlotView.prototype.constructor = PrayerSlotView;
export default PrayerSlotView;
