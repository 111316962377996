/**
 * ...
 * @author Robert Lombardo, Wakefield Studios ( rob@wakefield-studios.com )
 */	
import StripAnimation from './StripAnimation';

const BleedingEffect = function( duration ) {
	return new StripAnimation( 
		'effects/bleeding',
		duration,
		13,
		false
	);	
}
export default BleedingEffect;