import * as PIXI from 'pixi.js';
import { EventEmitter } from 'events';
import Economy from 'dt-common/constants/Economy';
import HeroBase from 'dt-common/models/game_state/heroes';
import {  CurrencyStore, HeroBuildStore } from '~/flux/stores';
import CanvasTools from '~/view/CanvasTools';
import AbilityTreePanel from './AbilityTreePanel';

const AbilityTreePanelCarriage = function({ hero_handle, hidePopupsFunc, doTweening, foregroundIndex = 1 }) {
  PIXI.Container.call(this);

  this.eventEmitter = new EventEmitter();

  const BACKGROUND_PANEL_Y_OFFSET = 20;
  const BACKGROUND_PANEL_SCALE = 0.6;

  const treePanels = [];
  this.getTreePanels = function() { return treePanels; };

  var fullPanelWidth;
  this.foregroundPanelIndex = foregroundIndex;
  var leftPanelIndex = 0;
  var rightPanelIndex = 2;

  const setForegroundPanelIndex = (n) => {
    this.foregroundPanelIndex = n;
    switch (n) {
      case 0: leftPanelIndex = 2; rightPanelIndex = 1; break;
      case 1: leftPanelIndex = 0; rightPanelIndex = 2; break;
      case 2: leftPanelIndex = 1; rightPanelIndex = 0; break;
      default: throw new Error('Invalid foregroundPanelIndex.');
    }

    var leftPanel = treePanels[ leftPanelIndex ];
    var rightPanel = treePanels[ rightPanelIndex ];
    var foregroundPanel = treePanels[ this.foregroundPanelIndex ];

    leftPanel.buttonMode = true;
    rightPanel.buttonMode = true;
    foregroundPanel.buttonMode = false;

    // prevent AbilityElments on background panels from triggering mouse events
    for (let i = 0; i < leftPanel.abilityElements.length; ++i) {
      const leftPanelElement = leftPanel.abilityElements[i];
      const rightPanelElement = rightPanel.abilityElements[i];
      const foregroundPanelElement = foregroundPanel.abilityElements[i];

      // the AbiltyElement itself is interactive for tooltips
      leftPanelElement.interactive = leftPanelElement.buttonMode = false;
      rightPanelElement.interactive = rightPanelElement.buttonMode = false;

      // each AbilityElement's "icon" is clickable for equipping
      const icon_l = leftPanelElement.getIcon();
      const icon_r = rightPanelElement.getIcon();
      icon_l.interactive = icon_l.buttonMode = false;
      icon_r.interactive = icon_r.buttonMode = false;

      TweenMax.delayedCall(0.2, (ele) => {
        if (ele) {
          const icon = ele.getIcon();
          ele.interactive = ele.buttonMode = true;
          icon.interactive = icon.buttonMode = true;
        }
      }, [foregroundPanelElement]);

      if (leftPanelElement.getUpgradeBtn && leftPanelElement.getUpgradeBtn()) {
        leftPanelElement.getUpgradeBtn().interactive = leftPanelElement.getUpgradeBtn().buttonMode = false;
      }
      if (rightPanelElement.getUpgradeBtn && rightPanelElement.getUpgradeBtn()) {
        rightPanelElement.getUpgradeBtn().interactive = rightPanelElement.getUpgradeBtn().buttonMode = false;
      }
      if (foregroundPanelElement.getUpgradeBtn && foregroundPanelElement.getUpgradeBtn()) {
        foregroundPanelElement.getUpgradeBtn().interactive = foregroundPanelElement.getUpgradeBtn().buttonMode = true;
      }
    }
  };

  var treePanelTweens;

  const destroyTreePanelTweens = () => {
    if (treePanelTweens) {
      for (var i = 0; i < treePanelTweens.length; ++i) {
        treePanelTweens[i].kill();
      }
      treePanelTweens = null;
    }
  };

  const tweenTreePanels = () => {
    if (treePanelTweens) {
      destroyTreePanelTweens();
    }

    var tweens = treePanelTweens = [];

    var tweenTime = 0.5+Math.random()*0.3;
    tweens.push(TweenMax.to(treePanels[this.foregroundPanelIndex], tweenTime, {
      x: 0,
      y: 0,
      alpha: 1,
    }));
    tweens.push(TweenMax.to(treePanels[this.foregroundPanelIndex].scale, tweenTime, {
      x: 1,
      y: 1,
      ease: Quad.easeOut,
    }));

    tweenTime = 0.5+Math.random()*0.3;
    tweens.push(TweenMax.to(treePanels[leftPanelIndex], tweenTime, {
      x: -fullPanelWidth,
      y: BACKGROUND_PANEL_Y_OFFSET,
      alpha: 0.5,
    }));
    tweens.push(TweenMax.to(treePanels[leftPanelIndex].scale, tweenTime, {
      x: BACKGROUND_PANEL_SCALE,
      y: BACKGROUND_PANEL_SCALE,
      ease: Quad.easeOut,
    }));

    tweenTime = 0.5+Math.random()*0.3;
    tweens.push(TweenMax.to(treePanels[rightPanelIndex], tweenTime, {
      x: fullPanelWidth,
      y: BACKGROUND_PANEL_Y_OFFSET,
      alpha: 0.5,
    }));
    tweens.push(TweenMax.to(treePanels[rightPanelIndex].scale, tweenTime, {
      x: BACKGROUND_PANEL_SCALE,
      y: BACKGROUND_PANEL_SCALE,
      ease: Quad.easeOut,
    }));

    // keep the center panel on top
    this.addChild(treePanels[this.foregroundPanelIndex]);

    this.centerPanel = treePanels[this.foregroundPanelIndex];
    this.rightPanel = treePanels[rightPanelIndex];
    this.eventEmitter.emit(AbilityTreePanelCarriage.TREE_PANELS_TWEEENED);
  };

  const onTreePanelClick = (event) => {
    hidePopupsFunc();
    destroyTreePanelTweens();

    // move the clicked panel to front & center
    for (var i = 0; i < 3; ++i) {
      if (i!==this.foregroundPanelIndex && treePanels[i]===event.target) {
        setForegroundPanelIndex(i);
        tweenTreePanels();
        break;
      }
    }
  };

  // make our ability tree panels
  const abilityTrees = HeroBase[hero_handle].getAbilityTrees();
  for (var i = 0; i < 3; ++i) {
    var panel = new AbilityTreePanel({
      abilityTree: abilityTrees[i],
      hero_handle,
    });
    panel.pivot.x = panel.pivot.y = 0.5;
    panel.makeTitle(Math.round(-panel.height/2-36));
    this.addChild(panel);
    treePanels.push(panel);

    panel.interactive = true;
    panel.tap = panel.click = onTreePanelClick;

    fullPanelWidth = Math.round(panel.width);

    if (!doTweening) {
      panel.initTween.kill();
      panel.progressMeter = 1000;
    }
  }

  setForegroundPanelIndex(this.foregroundPanelIndex);

  if (doTweening) {
    tweenTreePanels();
  } else {
    var leftPanel = treePanels[leftPanelIndex];
    leftPanel.x = -fullPanelWidth;
    leftPanel.y = BACKGROUND_PANEL_Y_OFFSET;
    leftPanel.scale.x = leftPanel.scale.y = BACKGROUND_PANEL_SCALE;
    leftPanel.alpha = 0.5;

    var rightPanel = treePanels[rightPanelIndex];
    rightPanel.x = fullPanelWidth;
    rightPanel.y = BACKGROUND_PANEL_Y_OFFSET;
    rightPanel.scale.x = rightPanel.scale.y = BACKGROUND_PANEL_SCALE;
    rightPanel.alpha = 0.5;
  }

  const onResetAbilitiesRequested = () => {
    // make spinners for all the abilities above level 0, that are getting reset
    if (CurrencyStore.getAll().pixieDust >= Economy.ABILITY_RESET_COST && treePanels) {
      for (const tp of treePanels) {
        if (tp) {
          for (const ae of tp.abilityElements) {
            if (ae && ae.getAbility().level > 0) {
              CanvasTools.makeFaerieSpinners(ae, 3);
            }
          }
        }
      }
    }
  };
  HeroBuildStore.on(HeroBuildStore.RESET_ABILITIES_REQUESTED, onResetAbilitiesRequested);

  this.dispose = () => {
    TweenMax.killTweensOf(this);
    HeroBuildStore.removeListener(HeroBuildStore.RESET_ABILITIES_REQUESTED, onResetAbilitiesRequested);
    destroyTreePanelTweens();
    for (const tree_panel of treePanels) {
      tree_panel?.dispose();
    }
    this.destroy();
  };
};
AbilityTreePanelCarriage.prototype = Object.create(PIXI.Container.prototype);
AbilityTreePanelCarriage.prototype.constructor = AbilityTreePanelCarriage;
export default AbilityTreePanelCarriage;
export const TREE_PANELS_TWEEENED = 'TREE_PANELS_TWEEENED';
