const _shopkeeperMessages = [
  'Заходи еще, поторгуем.',
  // "Говорят, хаотические предметы способны менять форму...",
  'Против целителей поможет Ловкость рук.',
  'Знаешь ли, кровотечение и отравление суммируются.',
  'Для отряда с магами Поделиться маной — мощное комбо.',
  'Говорят, против испуга и ярости помогут обучение и воля.',
  'Говорят, против оглушения и замедления помогут сила и ловкость.',
  'Говорят, мощь оружия зависит от твоего уровня.',
  'От Шипов бездны невозможно ни защититься, ни уклониться.',
  'А ты знаешь, что поле боя можно масштабировать?',
  'Каждые 30 минут у меня новое поступление.',
  'Чем лучше щит, тем чаще можно ставить блоки.',
  'Против атак Молнией броня не поможет...',
  'Яд действует раз в секунду, кровотечение — раз в три секунды. Теперь все тайны раскрыты.',
  'Заменить атакующих в Таверне можно и не трогая защитников.',
];

export default {
  randomShopkeeperMessage: () => {
    return _shopkeeperMessages[
      Math.floor(Math.random() * _shopkeeperMessages.length)
    ];
  },
};
