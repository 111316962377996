import { CavernsDispatcher } from '~/flux/dispatchers';
import { GameStateStore, HeroBuildStore, PlayerStore, UIStore } from '~/flux/stores';

const CavernsActions = {
  addHeroToCavernsTeamSlot: function(heroHandle, slotIndex) {
    CavernsDispatcher.handleCavernsAction({
      actionType: CavernsDispatcher.ADD_HERO_TO_CAVERNS_TEAM_SLOT,
      heroHandle,
      slotIndex,
    });
  },

  claimPassiveYields: function() {
    CavernsDispatcher.handleCavernsAction({
      actionType: CavernsDispatcher.CLAIM_PASSIVE_YIELDS,
      game_submode: UIStore.getAll().current_game_submode,
    });
  },

  doorSpriteClick: function(direction) {
    CavernsDispatcher.handleCavernsAction({
      actionType: CavernsDispatcher.DOOR_SPRITE_CLICK,
      direction,
      game_submode: UIStore.getAll().current_game_submode,
    });
  },

  initNewCavernsMap: function(startingLevel) {
    CavernsDispatcher.handleCavernsAction({
      actionType: CavernsDispatcher.INIT_NEW_CAVERNS_MAP,
      startingLevel,
      game_submode: UIStore.getAll().current_game_submode,
    });
  },

  lootTile: function(tileId) {
    CavernsDispatcher.handleCavernsAction({
      actionType: CavernsDispatcher.LOOT_TILE,
      tileId,
    });
  },

  returnToSurface: () => {
    CavernsDispatcher.handleCavernsAction({
      actionType: CavernsDispatcher.RETURN_TO_SURFACE,
    });
  },

  setPortalLevelToCurrentDepth: () => {
    CavernsDispatcher.handleCavernsAction({
      actionType: CavernsDispatcher.SET_PORTAL_LEVEL_TO_CURRENT_DEPTH,
      game_submode: UIStore.getAll().current_game_submode,
    });
  },

  startCavernsBattleEngine: ({ new_caverns_data }) => {
    const { current_game_mode, current_game_submode } = UIStore.getAll();
    const active_caverns_loadout = GameStateStore.getAll()
      .gameState.loadouts[current_game_mode][current_game_submode]
      .filter(({ engagement }) => !!engagement);

    CavernsDispatcher.handleCavernsAction({
      actionType: CavernsDispatcher.START_CAVERNS_BATTLE_ENGINE,
      active_caverns_loadout,
      new_caverns_data,
      game_submode: current_game_submode,
      white_team: {
        player: PlayerStore.getAll().loggedInPlayer,
        loadout: active_caverns_loadout,
        roster: GameStateStore.getAll().gameState.hero_roster,
        builds: active_caverns_loadout.reduce((result, loadout_member) => {
          const unit_handle = loadout_member.hero_handle || loadout_member.handle;
          result[unit_handle] = HeroBuildStore.getAll().hero_builds[unit_handle].find(({ _id }) => {
            const { hero_build_id } = active_caverns_loadout.find(({ handle }) => handle === unit_handle);
            return hero_build_id === _id;
          });
          return result;
        }, {}),
      },
    });
  },
};
export default CavernsActions;
