import PuppetMovement from './PuppetMovement';

const LeapMovement = (puppet) => {
  const poses = [ 
    { pose:Pose1() },
    { pose:Pose2() },
    { pose:Pose3() },
    { pose:Pose4() },
    { pose:Pose5() },
    { pose:Pose6() },
    { pose:Pose1() },
    { pose:Pose1() }
  ];
     
  return PuppetMovement({
		poses,
    puppet,
    setPoseDurations: (actor_cooldown, timeVar) => {
      poses[0].duration = 0.4;            
      poses[1].duration = 0.18;            
      poses[2].duration = 0.14;            
      poses[3].duration = 0.14;            
      poses[4].duration = 0.14;            
      poses[5].duration = 0.14;            
      poses[6].duration = timeVar / 3.5;            
      poses[7].duration = 0.25;
    }
  });
};
export default LeapMovement;

const Pose1 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -102,
      z: 48
    },        
    shoulders: {
      x: 0,
      y: -90,
      z: 18
    },        
    torsoe: {
      x: 0,
      y: -66,
      z: 0
    },        
    leftHand: {
      x: -5,
      y: -54,
      z: 66
    },     
    bowPitch: 0,
    bowYaw: 0,   
    rightHand: {
      x: 5,
      y: -54,
      z: 66
    },        
    weaponPitch: 0,
    weaponYaw: 0,        
    leftFoot: {
      x: -6,
      y: 0,
      z: -54
    },        
    rightFoot: {
      x: 10,
      y: 0,
      z: 48
    }
  };
  return pose;       
};

const Pose2 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -150,
      z: 36
    },        
    shoulders: {
      x: 0,
      y: -134,
      z: 15
    },        
    torsoe: {
      x: 0,
      y: -111,
      z: 0
    },        
    leftHand: {
      x: -18,
      y: -174,
      z: 72
    },     
    bowPitch: -45,
    bowYaw: 10,   
    rightHand: {
      x: 18,
      y: -174,
      z: 72
    },        
    weaponPitch: -45,
    weaponYaw: -10,        
    leftFoot: {
      x: -6,
      y: -6,
      z: -66
    },        
    rightFoot: {
      x: 12,
      y: 0,
      z: -66
    }
  };

  //forward travel
  for(var part in pose) {
    if(pose[part].y) {
      pose[part].y -= 150;
    }       
  }

  return pose;       
};

const Pose3 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -72,
      z: 42
    },        
    shoulders: {
      x: 0,
      y: -72,
      z: 28
    },        
    torsoe: {
      x: 0,
      y: -78,
      z: 0
    },        
    leftHand: {
      x: -18,
      y: -54,
      z: 0
    },     
    bowPitch: 90,
    bowYaw: 10,   
    rightHand: {
      x: 18,
      y: -54,
      z: 0
    },        
    weaponPitch: 90,
    weaponYaw: -10,        
    leftFoot: {
      x: -6,
      y: -6,
      z: -60
    },        
    rightFoot: {
      x: 12,
      y: 0,
      z: -60
    }
  };

  //forward travel
  for(var part in pose) {
    if(pose[part].y) {
      pose[part].y -= 170;
    }       
  }

  return pose;       
};

const Pose4 = function() {
  const pose =  {
    head: {
      x: 0,
      y: 0,
      z: -12
    },        
    shoulders: {
      x: 0,
      y: -30,
      z: -6
    },        
    torsoe: {
      x: 0,
      y: -60,
      z: 0
    },        
    leftHand: {
      x: -18,
      y: -90,
      z: -30
    },     
    bowPitch: 190,
    bowYaw: 10,   
    rightHand: {
      x: 18,
      y: -94,
      z: -30
    },        
    weaponPitch: 190,
    weaponYaw: -10,        
    leftFoot: {
      x: -6,
      y: -106,
      z: -30
    },        
    rightFoot: {
      x: 12,
      y: -106,
      z: -30
    }
  };

  //forward travel
  for(var part in pose) {
    if(pose[part].y) {
      pose[part].y -= 180;
    }       
  }

  return pose;       
};

const Pose5 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -48,
      z: -24
    },        
    shoulders: {
      x: 0,
      y: -36,
      z: -20
    },        
    torsoe: {
      x: 0,
      y: 0,
      z: 0
    },        
    leftHand: {
      x: -18,
      y: -30,
      z: 18
    },     
    bowPitch: 290,
    bowYaw: 10,   
    rightHand: {
      x: 18,
      y: -34,
      z: 18
    },        
    weaponPitch: 290,
    weaponYaw: -10,        
    leftFoot: {
      x: -6,
      y: -22,
      z: 30
    },        
    rightFoot: {
      x: 12,
      y: -22,
      z: 30
    }
  };

  //forward travel
  for(var part in pose) {
    if(pose[part].y) {
      pose[part].y -= 160;
    }       
  }

  return pose;       
};

const Pose6 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -120,
      z: -10
    },        
    shoulders: {
      x: 0,
      y: -102,
      z: -6
    },        
    torsoe: {
      x: 0,
      y: 72,
      z: 0
    },        
    leftHand: {
      x: -18,
      y: -50,
      z: 18
    },     
    bowPitch: 380,
    bowYaw: 10,   
    rightHand: {
      x: 18,
      y: -50,
      z: 18
    },        
    weaponPitch: 380,
    weaponYaw: -10,        
    leftFoot: {
      x: -6,
      y: 0,
      z: 60
    },        
    rightFoot: {
      x: 12,
      y: 0,
      z: 60
    }
  };

  //forward travel
  for(var part in pose) {
    if(pose[part].y) {
      pose[part].y -= 80;
    }       
  }

  return pose;       
};