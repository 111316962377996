import { InnDispatcher } from '~/flux/dispatchers';
import { InnStore, UIStore } from '~/flux/stores';

var InnActions = {
  fetchRandomInnOpponents({ game_submode, num_opponents = 5 } = {}) {
    InnDispatcher.handleInnAction({
      actionType: InnDispatcher.FETCH_RANDOM_INN_OPPONENTS,
      game_submode,
      num_opponents,
    });
  },

  fetchPlayerInnSubmodeState({ game_submode }) {
    InnDispatcher.handleInnAction({
      actionType: InnDispatcher.FETCH_PLAYER_INN_SUBMODE_STATE,
      game_submode,
    });
  },

  startAttackBattle({ game_submode }) {
    InnDispatcher.handleInnAction({
      actionType: InnDispatcher.START_ATTACK_BATTLE,
      game_submode,
    });
  },

  startAttackPrep({ attack_loadout, opponent }) {
    InnDispatcher.handleInnAction({
      actionType: InnDispatcher.START_ATTACK_PREP,
      attack_loadout,
      opponent,
    });
  },

  swapAttackHero({
    hero_handle_in,
    hero_build_id_to_swap_in,
    hero_build_id_to_swap_out,
  }) {
    const {current_game_mode, current_game_submode } = UIStore.getAll();
    InnDispatcher.handleInnAction({
      actionType: InnDispatcher.SWAP_ATTACK_HERO,
      hero_handle_in,
      hero_build_id_to_swap_in,
      hero_build_id_to_swap_out,
    });
  },
};
export default InnActions;

InnStore.on(InnStore.PASSIVE_TOURNEY_RESOLVED, () => {
  InnActions.fetchPlayerInnSubmodeState({ game_submode: UIStore.getAll().current_game_submode });
})