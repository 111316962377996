import * as PIXI from 'pixi.js';
import Game from 'dt-common/constants/Game';
import Tooltip from '~/components/tooltips/Tooltip';
import Colors from '~/constants/Colors';
import EquipmentDisplay from './EquipmentDisplay';
import EquipmentImgFactory from './EquipmentImgFactory';

const GameItemIcon = function(
  gameItem,
  {
    animate = true,
    frame = true,
    showBlackwash = true,
    show_inner_img = true,
    disable_mouseover_scaling = false,
  } = {}
) {
  try {
    PIXI.Container.call(this);

    let _tooltip;
    this.getTooltip = () => _tooltip;

    this.getItem = () => gameItem;

    var blackwash = this.blackwash = new PIXI.Graphics();
    blackwash.beginFill(Colors.ALMOST_BLACK);
    blackwash.drawRect(-26, -26, 52, 52);
    this.addChild(blackwash);
    if (!showBlackwash) {
      blackwash.alpha = 0;
    }

    var frameImg;
    frameImg = this.frameImg = new PIXI.Sprite()
    frameImg.texture = PIXI.utils.TextureCache['armory/inventory_grid.png'];
    frameImg.width = frameImg.height = blackwash.width;
    frameImg.anchor.x = frameImg.anchor.y = 0.5;
    this.addChild(frameImg);

    frameImg.tintBuffer = 0xffffff;

    this.hitArea = new PIXI.Rectangle(0, 0, frameImg.width, frameImg.height);

    this.mouseover = this.pointerover = (event) => {
      if (frameImg) {
        frameImg.tint = Colors.BRIGHT_YELLOW;
      }

      if (img && !disable_mouseover_scaling) {
        if (!img.scale_buffer) {
          img.scale_buffer = { x: img.scale.x, y: img.scale.y };
        }
        img.scale.x *= 1.2;
        img.scale.y *= 1.2;

        if (shadow) {
          shadow.scale = img.scale;
        }
      }

      if (gameItem && !_tooltip) {
        _tooltip = new Tooltip('item', gameItem, this);
        if (this.stage_rect) {
          _tooltip.x += this.stage_rect.x;
          _tooltip.y += this.stage_rect.y;
        }
      }
    };
    this.mouseout = this.pointerout = () => {
      frameImg.tint = frameImg.tintBuffer;

      if (img && img.scale_buffer) {
        img.scale = img.scale_buffer;

        if (shadow) {
          shadow.scale = img.scale;
        }
      }

      if (_tooltip) {
        _tooltip.dispose();
        _tooltip = null;
      }
    };

    if (!frame) {
      frameImg.alpha = 0.01;
    }

    var img;
    var shadow;

    if (gameItem) {
      img = this.img = makeImg.call(this, gameItem, { animate, show_inner_img });

      // make a shadow
      if (gameItem.type !== 'prayer_candle') {
        shadow = makeImg.call(this, gameItem, { show_inner_img });
        shadow.tint = 0xffffff;
        if (shadow.shoulders) {
          shadow.shoulders.tint = 0x777777;
        }
        shadow.x -= 1;
        shadow.y -= 1;
        this.addChild(shadow);
      }

      this.addChild(img);
    }

    this.pivot.x = this.pivot.y = 0.5;

    this.interactive = true;
    this.buttonMode = true;
    this.hitArea = new PIXI.Rectangle(-26, -26, 52, 52);

    this.setScale = function(size) {
      this.hitArea = new PIXI.Rectangle(-size / 2, -size / 2, size, size);

      blackwash.width = size;
      blackwash.scale.y = blackwash.scale.x;

      if (frameImg) {
        frameImg.width = size;
        frameImg.scale.y = frameImg.scale.x;
      }

      if (img) {
        img.height = blackwash.height * 1.1;

        const quality_index = EquipmentDisplay.getQualityIndex(gameItem);

        switch (gameItem.slot) {
          case 'helmet':
            if (gameItem.type === 'light') {
              img.scale.y *= 0.67;
            } else if (gameItem.type === 'med') {
              switch (quality_index) {
                case 3:
                  // coif
                  img.scale.y *= 0.8;
                  break;

                case 4:
                  // dragonscale helm
                  img.scale.y *= 0.89;
                  break;

                default:
                  img.scale.y *= 0.68;
              }
            } else {
              img.scale.y *= 0.92;
            }
            break;

          case 'torsoe':
            if (gameItem.type === 'light') {
              switch (quality_index) {
                case 0:
                  img.scale.y *= 1.3;
                  break;

                default:
                  img.scale.y *= 1.01;
              }
            } else if (gameItem.type === 'heavy' && quality_index === 0) {
            // copper plate
              img.scale.y *= 1.1;
            } else if (gameItem.type === 'heavy' && quality_index === 1) {
            // bronze plate
              img.scale.y *= 1.03;
            } else {
              img.scale.y *= 1.25;
            }
            break;

          case 'boots':
            if (false) {
            } else {
              img.scale.y *= 0.86;
            }
            break;

          case 'weapon':
            switch (gameItem.type) {
              case 'dagger':
                if (quality_index === 1) {
                  // dagger
                  img.scale.y *= 0.76;
                } else if (quality_index === 2) {
                  // slicer
                  img.scale.y *= 0.76;
                } else if (quality_index === 3) {
                  // stiletto
                  img.scale.y *= 0.83;
                } else {
                  img.scale.y *= 0.9;
                }
                break;

              case 'sword':
                img.scale.y *= 1.55;
                break;

              case 'hammer':
                switch (quality_index) {
                  case 0:
                    // club
                    img.scale.y *= 0.87;
                    break;

                  case 1:
                    img.scale.y *= 1.08;
                    break;

                  default:
                    img.scale.y *= 1.4;
                }
                break;
            }
            break;

          case 'off_hand':
            switch (gameItem.type) {
              case 'bow':
                switch (quality_index) {
                  case 3:
                    // mechanical bow
                    img.scale.y *= 1.27;
                    break;

                  default:
                    img.scale.y *= 1.15;
                }
                break;

              case 'shield':
                switch (quality_index) {
                  case 0:
                    // wooden shield
                    img.scale.y *= 0.67;
                    break;

                  case 4:
                    // tower shield
                    img.scale.y *= 0.74;
                    break;

                  default:
                    img.scale.y *= 0.8;
                }
                break;

              case 'book':
                switch (quality_index) {
                  case 0:
                    // folio
                    img.scale.y *= 0.77;
                    break;

                  case 3:
                    // tome
                    img.scale.y *= 0.92;
                    break;

                  case 4:
                    // compendium
                    img.scale.y *= 0.82;
                    break;
                }
                break;

              case 'trinket':
                if (false) {
                } else {
                  img.scale.y *= 0.8;
                }
                break;
            }
            break;

          case 'ring':
            img.scale.y *= 0.5;
            break;

          default:
            switch (gameItem.type) {
              case 'equipment_dye':
              case 'miracle_dye':
                img.scale.y = 0.2;
                break;

              case 'hero_xp_boost':
                img.scale.y = 0.23;
                break;
            }
        }

        img.scale.x = img.scale.y;

        if (shadow) {
          shadow.scale = img.scale;
        }
      }
    };

    this.dispose = () => {
      cancelAnimationFrame(this.animationFrame);

      if (this.effectInterval) {
        this.effectInterval.kill();
      }

      if (this.tintInterval) {
        this.tintInterval.kill();
      }

      if (frameImg) {
        frameImg.mouseover = frameImg.pointerover = null;
        frameImg.mouseout = frameImg.pointerout = null;
      }

      if (_tooltip) {
        _tooltip.dispose();
      }

      this.removeChildren();
      this.parent?.removeChild(this);
    };
  } catch (err) {
    logError(err, Object.assign(
      {
        module: 'GameItemIcon',
        func: 'constructor'
      },
      gameItem,
      { frame, showBlackwash }
    ));
  }
};
GameItemIcon.prototype = Object.create(PIXI.Container.prototype);
GameItemIcon.prototype.constructor = GameItemIcon;
export default GameItemIcon;

const makeImg = function(gameItem, { animate, show_inner_img }) {
  try {
    var img;
    var innerImg;
    var imgAnchorX = 0.5;
    var imgAnchorY = 0.5;

    const DYE_ANCHOR_Y = 0.33;

    switch (gameItem.type) {
      case 'equipment_dye':
        img = new PIXI.Sprite();
        img.texture = PIXI.utils.TextureCache['potion.png'];
        innerImg = new PIXI.Sprite();
        innerImg.texture = PIXI.utils.TextureCache['potion_inner.png'];
        innerImg.anchor.x = 0.5;
        innerImg.anchor.y = DYE_ANCHOR_Y;
        img.addChild(innerImg);
        innerImg.tint = gameItem.tint;

        imgAnchorY = DYE_ANCHOR_Y;
        break;

      case 'miracle_dye': {
        img = new PIXI.Sprite();
        img.texture = PIXI.utils.TextureCache['potion.png'];
        innerImg = new PIXI.Sprite();
        innerImg.texture = PIXI.utils.TextureCache['potion_inner.png'];
        innerImg.anchor.x = 0.5;
        innerImg.anchor.y = DYE_ANCHOR_Y;
        img.addChild(innerImg);
        innerImg.tint = Math.random() * 0xffffff;

        imgAnchorY = DYE_ANCHOR_Y;

        const changeTint = () => {
          var oldInnerImg = innerImg;

          innerImg = new PIXI.Sprite();
          innerImg.texture = PIXI.utils.TextureCache['potion_inner.png'];
          innerImg.tint = Math.random()*0xffffff;
          innerImg.anchor.x = 0.5;
          innerImg.anchor.y = DYE_ANCHOR_Y;

          var duration = 0.6 + Math.random() * 0.7;
          TweenMax.from(innerImg, duration, {alpha:0});
          TweenMax.delayedCall(
            duration,
            (deadImg)=> deadImg?.parent?.removeChild(deadImg),
            [oldInnerImg]
          );

          img.addChild(innerImg);

          this.tintInterval = TweenMax.delayedCall(duration, changeTint);
        };
        this.tintInterval = TweenMax.delayedCall(0.6+Math.random()*0.7, changeTint);

        const makeEffect = () => {
          var effect = new PIXI.Graphics();
          effect.beginFill(Math.random()*0xffffff);
          effect.drawRect(-2.5, -5, 5, 10);
          effect.endFill();

          effect.x = -img.width*0.1 + Math.random()*img.width*0.2;
          effect.y = -img.height * 1.62;
          img.addChild(effect);

          TweenMax.to(effect, 0.876, {
            y: effect.y - 20 - Math.random() * 45,
            ease: Back.easeOut,
            onComplete: (twinkle) => twinkle?.parent?.removeChild(twinkle),
            onCompleteParams: [ effect ]
          });

          TweenMax.to(effect, 0.876, {
            x: -65+Math.random()*130,
            rotation: -Math.PI*4 + Math.random()*Math.PI*8,
            alpha: 0,
            ease: Quad.easeIn
          });

          this.effectInterval = TweenMax.delayedCall(0.3+Math.random()*0.6, makeEffect);
        };
        if (animate) {
          this.effectInterval = TweenMax.delayedCall(Math.random()*0.1, makeEffect);
        }
      }
      break;

      case 'hero_xp_boost': {
        const XP_POT_ANCHOR_Y = 0.42;

        img = new PIXI.Sprite();
        img.texture = PIXI.utils.TextureCache['xp_potion.png'];
        if (show_inner_img) {
          innerImg = this.innerImg = new PIXI.Sprite();
          innerImg.texture = PIXI.utils.TextureCache['xp_potion_inner.png'];
          innerImg.anchor.x = 0.5;
          innerImg.anchor.y = XP_POT_ANCHOR_Y;
          img.addChild(innerImg);
          innerImg.tint = Colors.BRIGHT_YELLOW;
        }

        imgAnchorY = XP_POT_ANCHOR_Y;

        const makeEffect = () => {
          var effect = new PIXI.Graphics();
          effect.beginFill(0x00ff00);
          effect.drawRect(-1.5, -3, 3, 6);
          effect.endFill();

          effect.x = -img.width/2 + Math.random()*img.width;
          effect.y = -Math.random()*img.height/2;
          img.addChildAt(effect, Math.floor(Math.random()*img.children.length));

          TweenMax.to(effect, 0.876, {
            y: effect.y - 25 + Math.random()*10,
            alpha: 0,
            ease: Linear.easeNone,
            onComplete: (twinkle) => twinkle?.parent?.removeChild(twinkle),
            onCompleteParams: [ effect ]
          });

          this.effectInterval = TweenMax.delayedCall(0.3+Math.random()*1.0, makeEffect);
        };
        if (animate) {
          this.effectInterval = TweenMax.delayedCall(Math.random()*0.1, makeEffect);
        }
      }
      break;

      case 'prayer_candle': {
        img = new PIXI.Sprite();
        img.texture = PIXI.utils.TextureCache['prayer_candle_base.png'];
        innerImg = new PIXI.Sprite();
        innerImg.texture = PIXI.utils.TextureCache['prayer_candle_shaft.png'];
        innerImg.tint = Colors[ gameItem.alignment ];
        innerImg.anchor.x = innerImg.anchor.y = 0.5;
        img.addChild(innerImg);

        img.scale = { x: 0.6, y: 0.6 };
        innerImg.scale = { x: 0.8, y: 0.8 };
        img.y -= 8;
        innerImg.y += 8;

        const killParticle = (deadParticle) => {
          TweenMax.killTweensOf(deadParticle);
          deadParticle?.parent?.removeChild(deadParticle);
        };

        const backToCenter = (particle) => particle && TweenMax.to(particle, 0.1, { x: 7 });

        const animateCandle = () => {
          var particle = new PIXI.Container();

          var orange = new PIXI.Graphics();
          orange.beginFill(0xde7611);
          orange.drawRect(-3, -3, 6, 6);
          orange.endFill();
          particle.addChild(orange);

          var yellow = new PIXI.Graphics();
          yellow.beginFill(0xfff600);
          yellow.drawRect(-3, -3, 6, 6);
          yellow.endFill();
          particle.addChild(yellow);
          yellow.alpha = 0;

          particle.x = 7;
          particle.y = -innerImg.height * 0.12;

          // upward travel
          TweenMax.to(particle, 0.25, { y: particle.y - 18 - Math.random()*12 });

          // horizontal travel (left-then-right or right-then-left)
          var startLeft = Math.random() < 0.5;
          TweenMax.to(particle, 0.1, {
            x: startLeft? particle.x-Math.random()*10 : particle.x+Math.random()*10,
            onComplete: backToCenter,
            onCompleteParams: [particle]
          });

          // fade in yellow
          TweenMax.to(yellow, 0.25, { alpha: 1, ease: Quad.easeOut });

          // fade out
          TweenMax.to(orange, 0.25, {
            alpha: 0,
            ease: Expo.easeOut,
            onComplete: killParticle,
            onCompleteParams: [particle]

          });
          img.addChild(particle);

          this.animationFrame = requestAnimationFrame(animateCandle);
        };
        this.animationFrame = requestAnimationFrame(animateCandle);
      }
      break;

      default: {
        if (!gameItem.slot || !gameItem.type) {
          throw new Error('invalid game item');
        }

        var appendSouth = gameItem.slot!=='weapon' && gameItem.type!=='book' && gameItem.type!=='trinket' && gameItem.type!=='bow';
        var texture = gameItem.slot === 'item_container'
          ? EquipmentImgFactory.getItemContainer(gameItem)
          : EquipmentImgFactory.getStandard(gameItem, gameItem.type==='shield'?Game.EAST : appendSouth?Game.SOUTH:null);
        
        
        img = new PIXI.Sprite(texture);
        img.tint = EquipmentDisplay.PuppetTint.equipment(gameItem);

        if (gameItem.slot === 'torsoe') {
          texture = EquipmentImgFactory.getShoulders(gameItem, Game.SOUTH);
          var shoulderImg = img.shoulders = new PIXI.Sprite(texture);
          shoulderImg.tint = gameItem.tint;
          shoulderImg.anchor.x = 0.5;
          shoulderImg.anchor.y = 0.67;
          img.addChild(shoulderImg);
        }

        const quality_index = EquipmentDisplay.getQualityIndex(gameItem);

        switch (gameItem.slot) {
          case 'helmet':
            switch (gameItem.type) {
              case 'light':
                switch (quality_index) {
                  case 1:
                    // headdress
                    imgAnchorY = 0.42;
                    break;

                  case 3:
                    // diadem
                    imgAnchorY = 0.35;
                    break;

                  case 4:
                    // crown
                    imgAnchorY = 0.35;
                    break;
                }
                break;

              case 'med':
                switch (quality_index) {
                  case 0:
                    // skullcap
                    imgAnchorY = 0.42;
                    break;

                  case 3:
                    // coif
                    imgAnchorY = 0.65;
                    break;

                  case 4:
                    // dragonscale helm
                    imgAnchorY = 0.45;
                    break;
                }
                break;
            }
            break;

          case 'torsoe':
            if (gameItem.type === 'light') {
              switch (quality_index) {
                case 0:
                  // rags
                  imgAnchorY = 0.6;
                  break;

                case 1:
                  // linen cloak
                  imgAnchorY = 0.65;
                  break;

                case 3:
                  // spidersilk cloak
                  imgAnchorY = 0.67;
                  break;

                case 4:
                  imgAnchorY = 0.55;
                  break;
              }
            } else if (gameItem.type === 'med') {
              switch (quality_index) {
                case 0:
                  // hide vest
                  imgAnchorY = 0.63;
                  break;

                case 1:
                  // vest
                  imgAnchorY = 0.65;
                  break;

                case 2:
                  // studded vest
                  imgAnchorY = 0.66;
                  break;

                case 3:
                  // chain-mail vest
                  imgAnchorY = 0.65;
                  break;

                case 4:
                  // dragonscale vest
                  imgAnchorY = 0.7;
                  break;

                default:
                  imgAnchorY = 0.6;
              }
            } else if (gameItem.type === 'heavy') {
              switch (quality_index) {
                case 0:
                  // copper plate
                  imgAnchorY = 0.65;
                  break;

                case 1:
                  // bronze plate
                  imgAnchorY = 0.7;
                  break;

                case 2:
                  // iron plate
                  imgAnchorY = 0.65;
                  break;

                case 3:
                  // steel plate
                  imgAnchorY = 0.7;
                  break;

                case 4:
                  // mithril plate
                  imgAnchorY = 0.7;
                  break;

                default:
                  imgAnchorY = 0.6;
              }
            }
            break;

          case 'boots':
            imgAnchorY = 0.65;
            img.rotation = -Math.PI / 4;

            if (gameItem.type === 'light') {
              switch (quality_index) {
                case 1:
                  // linen shoes
                  imgAnchorY = 0.6;
                  break;

                case 2:
                  // brocade slippers
                  imgAnchorY = 0.6;
                  break;
              }
            } else if (gameItem.type === 'heavy' && EquipmentDisplay.getQualityIndex(gameItem) === 1) {
              // bronze boots
              imgAnchorY = 0.55;
            }
            break;

          case 'weapon':
            img.rotation = Math.PI / 4;

            switch (gameItem.type) {
              case 'dagger':
                if (EquipmentDisplay.getQualityIndex(gameItem) === 3) {
                  // stiletto
                  imgAnchorY = 0.35;
                } else if (EquipmentDisplay.getQualityIndex(gameItem) === 2) {
                  // slicer
                  imgAnchorY = 0.35;
                } else {
                  imgAnchorY = 0.4;
                }
                break;

              case 'sword':
                switch (EquipmentDisplay.getQualityIndex(gameItem)) {
                  case 0:
                    // shortsword
                    imgAnchorY = 0.35;
                    break;

                  case 1:
                    // broadsword
                    imgAnchorY = 0.33;
                    break;

                  default:
                    imgAnchorY = 0.3;
                }
                break;

              case 'hammer':
                switch (quality_index) {
                  case 1:
                    // hammer
                    imgAnchorY = 0.23;
                    break;

                  case 2:
                    // greathammer
                    imgAnchorY = 0.27;
                    break;

                  case 3:
                    // grandhammer
                    imgAnchorY = 0.25;
                    break;

                  case 4:
                    // thor's hammer
                    imgAnchorY = 0.3;
                    break;

                  default:
                    imgAnchorY = 0.35;
                }
                break;

              case 'staff':
                if (EquipmentDisplay.getQualityIndex(gameItem) === 2) {
                  // oaken staff
                  imgAnchorY = 0.46;
                }
                break;
            }
            break;

          case 'off_hand':
            switch (gameItem.type) {
              case 'bow':
                img.rotation = Math.PI / 4;

                switch (quality_index) {
                  case 3:
                    imgAnchorX = 0.22;
                    break;

                  default:
                    imgAnchorX = 0.3;
                }
                break;

              case 'book':
                switch (quality_index) {
                  case 0:
                    // folio
                    imgAnchorY = 0.34;
                    break;

                  case 2:
                    // folio
                    imgAnchorY = 0.3;
                    break;

                  case 3:
                    // tome
                    imgAnchorY = 0.33;
                    break;

                  case 4:
                    // compendium
                    imgAnchorY = 0.3;
                    break;

                  default:
                    imgAnchorY = 0.4;
                }
                break;

              case 'trinket':
                imgAnchorY = 0.32;
                break;
            }
            break;
        }
      }
    }

    img.height = this.blackwash.height * 1.1;
    img.scale.x = img.scale.y;
    img.anchor.x = imgAnchorX;
    img.anchor.y = imgAnchorY;

    return img;
  } catch (err) {
    logError(err, Object.assign(
      {
        module: 'GameItemIcon',
        func: 'makeImg'
      },
      gameItem
    ));
    return null;
  }
};
