import { Dispatcher } from 'flux';

const AltarDispatcher = Object.assign(new Dispatcher(), {
  NAME: 'ALTAR_DISPATCHER',

  GET_ALTAR_STATE: 'GET_ALTAR_STATE',
  START_NEW_PRAYER: 'START_NEW_PRAYER',
  UPDATE_PRAYER_GOLD_VALUE: 'UPDATE_PRAYER_GOLD_VALUE',
  UPDATE_ITEM_DATA: 'UPDATE_ITEM_DATA',
  UPDATE_PRAYER_GOD: 'UPDATE_PRAYER_GOD',
  SET_NEW_PRAYER_PARAMETER: 'SET_NEW_PRAYER_PARAMETER',
  SUBMIT_PRAYER: 'SUBMIT_PRAYER',
  COMPLETE_PRAYER: 'COMPLETE_PRAYER',
  SHOW_UNLOCK_PRAYER_SLOT_DIALOG: 'SHOW_UNLOCK_PRAYER_SLOT_DIALOG',
  UNLOCK_PRAYER_SLOT: 'UNLOCK_PRAYER_SLOT',
  USE_PRAYER_CANDLE: 'USE_PRAYER_CANDLE',

  handleAltarAction: function(action) {
    this.dispatch({
      source: AltarDispatcher.NAME,
      action
    });
  }
});
export default AltarDispatcher;
