
import Screens from '~/constants/Screens';
import ArenaLobby from './arena-lobby/DOM/ArenaLobby.vue';
import Battle from './battle/DOM/Battle.vue';
import Caverns from './caverns/DOM/Caverns.vue';
import EditHero from './edit-hero/DOM/EditHero.vue';
import HeroBuilds from './hero-builds/DOM/HeroBuilds.vue';
import Inn from './inn/DOM/Inn.vue';
import OnboardFirstHeroUnlock from './onboard-first-hero-unlock/DOM/OnboardFirstHeroUnlock.vue';
import Shop from './shop/DOM/Shop.vue';
import Temple from './temple/DOM/Temple.vue';

const game_screens = {
  [Screens.ARENA_LOBBY]: ArenaLobby,
  [Screens.BATTLE]: Battle,
  [Screens.CAVERNS]: Caverns,
  [Screens.EDIT_HERO]: EditHero,
  [Screens.HERO_BUILDS]: HeroBuilds,
  [Screens.INN]: Inn,
  [Screens.ONBOARD_FIRST_HERO_UNLOCK]: OnboardFirstHeroUnlock,
  [Screens.SHOP]: Shop,
  [Screens.TEMPLE]: Temple,
};
export default game_screens;
