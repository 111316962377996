import BattleDebriefDispatcher from '~/flux/dispatchers/BattleDebriefDispatcher';

const BattleDebriefActions = {
  claimAllRewards(battle_id) {
    BattleDebriefDispatcher.handleBattleDebriefAction({
      actionType: BattleDebriefDispatcher.CLAIM_ALL_REWARDS,
      battle_id,
    });
  },

  claimAllRewards_Inn(inn_log_id) {
    BattleDebriefDispatcher.handleBattleDebriefAction({
      actionType: BattleDebriefDispatcher.CLAIM_ALL_REWARDS_INN,
      inn_log_id,
    });
  },

  claimItemReward(battle_id, itemId) {
    BattleDebriefDispatcher.handleBattleDebriefAction({
      actionType: BattleDebriefDispatcher.CLAIM_ITEM_REWARD,
      battle_id,
      itemId,
    });
  },

  claimItemReward_Inn(inn_log_id, itemId) {
    BattleDebriefDispatcher.handleBattleDebriefAction({
      actionType: BattleDebriefDispatcher.CLAIM_ITEM_REWARD_INN,
      inn_log_id,
      itemId,
    });
  },

  claimGoldReward(battle_id) {
    BattleDebriefDispatcher.handleBattleDebriefAction({
      actionType: BattleDebriefDispatcher.CLAIM_GOLD_REWARD,
      battle_id,
    });
  },

  claimGoldReward_Inn(inn_log_id) {
    BattleDebriefDispatcher.handleBattleDebriefAction({
      actionType: BattleDebriefDispatcher.CLAIM_GOLD_REWARD_INN,
      inn_log_id,
    });
  },

  claimPixieDustReward(battle_id) {
    BattleDebriefDispatcher.handleBattleDebriefAction({
      actionType: BattleDebriefDispatcher.CLAIM_PD_REWARD,
      battle_id,
    });
  },

  claimPixieDustReward_Inn(inn_log_id) {
    BattleDebriefDispatcher.handleBattleDebriefAction({
      actionType: BattleDebriefDispatcher.CLAIM_PD_REWARD_INN,
      inn_log_id,
    });
  },

  deleteBattleLogEntry(battle_id) {
    BattleDebriefDispatcher.handleBattleDebriefAction({
      actionType: BattleDebriefDispatcher.DELETE_BATTLE_LOG_ENTRY,
      battle_id,
    });
  },

  deleteInnLogEntry(log_id) {
    BattleDebriefDispatcher.handleBattleDebriefAction({
      actionType: BattleDebriefDispatcher.DELETE_INN_LOG_ENTRY,
      log_id,
    });
  },
};
export default BattleDebriefActions;
