export default {
  base: 'Базовый',
  from_equipment: 'Снаряжение',
  from_upgrades: 'Повышение уровня',
  next_upgrade_cost: 'Стоимость нового уровня',

  armorClass: {
    name: 'Класс брони',
    short: 'КБ',
    blurb:
      'Общий уровень брони персонажа. Уменьшает входящий урон на процент: 0.9 * tanh(0.0044 * КБ)',
  },

  dexterity: {
    name: 'Ловкость',
    short: 'Лв',
    blurb:
      'Показатель ловкости определяет гибкость и точность движений. Он заметно влияет на эффективность атак дальнего и ближнего боя, скорость и вероятность уклонения.',
  },

  dodgeChance: {
    name: 'Уклонение',
    short: 'Ук',
    blurb: 'Вероятность избежать физического урона.',
    calc: 'Вс/4 + Лв/7 + Ма/16 + Фокусы:Расплывчатость',
  },

  physicalResil: {
    name: 'Стойкость тела',
    short: 'СТ',
    blurb: 'Вероятность сопротивления состояниям замедления и оглушения.',
    calc: 'Си/5 + Лв/5 + Движение:Свобода*3 + Фокусы:Расплывчатость',
  },

  mentalResil: {
    name: 'Стойкость духа',
    short: 'СД',
    blurb: 'Вероятность сопротивления состояниям испуга и ярости.',
    calc: 'Об/5 + Вл/5 + Духовность:Железная воля*3 + Фокусы:Расплывчатость',
  },

  learning: {
    name: 'Обучение',
    short: 'Об',
    blurb:
      'Показатель обучения определяет способность персонажа учиться на опыте. Он связан с развитием многих магических способностей, но полезен и воинам, сделавшим ставку на силу: им пригодится знание, куда направить удар!',
  },

  magicalGravity: {
    name: 'Притяжение магии',
    short: 'ПМ',
    blurb: 'Скорость восстановления магической силы в секунду.',
    calc: 'Ма/5 + Вл/14 + Вс/23',
  },

  mana: {
    name: 'Мана',
    short: 'Ма',
    blurb:
      'Мана определяет способность настроиться на невидимые материальные и нематериальные силы Вселенной (но не обязательно ее сознание). Она влияет на мощь многих заклинаний и является основным фактором достижения максимума магической силы и притяжения магии. Мана влияет на многие расчеты, но незначительно, а еще ее часто путают с удачей.',
  },

  meleeDamage: {
    name: 'Урон (ближний бой)',
    short: 'УБ',
    blurb:
      'Количество урона при обычной атаке ближнего боя (атаки ближнего боя могут проводить все персонажи, независимо от способностей и настроек ИИ).',
    calc: 'Си*3 + Лв*2 + Об/2 + Вл/3',
  },

  perception: {
    name: 'Восприятие',
    short: 'Вс',
    blurb:
      'Способность оценивать обстановку, основной фактор вероятности уклонения. Высокие уровни восприятия необходимы мастерам стрельбы из лука.',
  },

  regeneration: {
    name: 'Регенерация',
    short: 'Ре',
    blurb: 'Скорость восстановления очков здоровья в секунду.',
    calc: 'Си/8 + Вл/10 + Вн/15 + Ма/20',
  },

  speed: {
    name: 'Скорость',
    short: 'Ск',
    blurb: 'Скорость восстановления очков действия в секунду.',
    calc: '10 + Лв/8 + Си/12 + Вл/20 + Ма/50 + Движение:Стремительность/3',
  },

  strength: {
    name: 'Сила',
    short: 'Си',
    blurb:
      'Показатель силы определяет массу тела и переносимый вес. Сила особенно полезна для навыков ближнего боя и заметно влияет на регенерацию и скорость.',
  },

  toughness: {
    name: 'Выносливость',
    short: 'Вн',
    blurb:
      'Показатель выносливости определяет устойчивость персонажа к урону. Его главное преимущество — много очков здоровья.',
  },

  willpower: {
    name: 'Воля',
    short: 'Вл',
    blurb:
      'Персонаж с высокими показателями воли харизматичен и способен влиять на других. Показатель задействован и при проверке некоторых навыков владения магией, особенно целительства, и дает много ОЗ. Кроме того, воля заметно влияет на восстановление следующих трех параметров: очки здоровья, очки действия и магическая сила.',
  },

  max_hp: {
    name: 'Максимум очков здоровья',
    calc: 'Вн*25 + Си*15 + Вл*10 + Ма*5',
  },

  max_ap: {
    name: 'Максимум очков действия',
    calc: '100 + Движение:Подготовка*2',
  },

  max_mp: {
    name: 'Максимум магической силы',
    calc: 'Ма*10 + Об*7 + Вл*4 + Вн*2',
  },
};
