import { render } from 'vue';
import Audio from '~/Audio';

const PsycheOutRendering = {
  render: function (data, gameBoard, isCaverns) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
    const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

    if (!data.reflection && render_movement) {
      actorSprite.puppet
        .getMovements()
        .magicProjectile.movePuppet({ transitTime });
    }
  },

  renderResult: function (data, gameBoard, isCaverns) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
    const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

    TweenMax.delayedCall(transitTime * 0.5, Audio.play, ['magic_wave']);
  },
};
export default PsycheOutRendering;
