import * as PIXI from 'pixi.js';
import getUnitState from 'dt-common/isomorphic-helpers/getUnitState';
import CavernsStore from '~/flux/stores/CavernsStore';
import CavernsUnitDetailsView from './CavernsUnitDetailsView';
import Tools from '~/Tools';

const UnitDetailsPanel = function ({
  hero_builds,
  loadout_squad_list,
  roster_heroes,
}) {
  PIXI.Container.call(this);

  let _unitDetailsViews = [];

  this.dispose = () => {
    clearInterval(_update_interval);

    CavernsStore.removeListener(CavernsStore.BATTLE_EVENT, update);
    CavernsStore.removeListener(CavernsStore.GOT_UNIT_STATE, update);

    destroyUnitDetailsViews();
    this.removeChildren();
    this.parent?.removeChild(this);
  };

  const destroyUnitDetailsViews = () => {
    if (_unitDetailsViews) {
      for (let i = 0; i < _unitDetailsViews.length; ++i) {
        _unitDetailsViews[i].dispose();
      }
      _unitDetailsViews = null;
    }
  };

  const makeUnitDetailsViews = () => {
    const { battleState } = CavernsStore.getAll();
    if (!battleState) {
      return;
    }

    destroyUnitDetailsViews();
    _unitDetailsViews = [];

    for (let i = 0; i < loadout_squad_list.length; ++i) {
      const loadout_member = loadout_squad_list[i];
      const hero_handle = loadout_member.hero_handle || loadout_member.handle;
      const unit_state = Object.values(battleState.allUnits).find(
        ({ hero_handle: hh }) => hh === hero_handle
      );

      const udv = new CavernsUnitDetailsView(unit_state);
      const prev_udv = _unitDetailsViews[i - 1];
      if (prev_udv) {
        udv.y = Math.round(
          prev_udv.y + prev_udv.height + DT_CANVAS_GLOBALS.spacing * 2
        );
      }
      this.addChild(udv);
      _unitDetailsViews.push(udv);
    }

    update();
  };

  const update = (data) => {
    const { battleState } = CavernsStore.getAll();
    if (!battleState) {
      return;
    }

    if (data?.event?.eventHandle === 'death') {
      for (const view of _unitDetailsViews) {
        if (data?.event?.victimId === view.unit_state.uid) {
          view.onUnitDeath();
          return;
        }
      }
    }
  };
  makeUnitDetailsViews();

  CavernsStore.on(CavernsStore.BATTLE_EVENT, update);
  CavernsStore.on(CavernsStore.GOT_UNIT_STATE, update);

  const _update_interval = setInterval(() => {
    for (const view of _unitDetailsViews || []) {
      view.updateStats();
    }
  }, 33);
};
UnitDetailsPanel.prototype = Object.create(PIXI.Container.prototype);
UnitDetailsPanel.prototype.constructor = UnitDetailsPanel;
export default UnitDetailsPanel;
