import CommonEffects from '../../../effects/CommonEffects';
import DevilMaskEffect from '../../../effects/DevilMaskEffect';
import Audio from '~/Audio';

const DevilMaskRendering = {
  render: function (data, gameBoard, isCaverns) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
    const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

    if (!data.reflection && render_movement) {
      actorSprite.puppet.getMovements().magicSummon.movePuppet({ transitTime });
    }
  },

  renderResult: function (data, gameBoard, isCaverns) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
    const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

    var _timeline = new TimelineMax();

    TweenMax.delayedCall(transitTime * 0.7, () => {
      // make smoke
      for (var i = 0; i < 7; ++i) {
        _timeline.call(
          CommonEffects.makeCloudPuff,
          [actorSprite, 0x777777],
          _timeline._time + i * 0.03
        );
      }

      Audio.play('devil_mask_01');
    });

    TweenMax.delayedCall(transitTime, () => {
      var image = new DevilMaskEffect(1500);
      image.x = actorSprite.x;
      image.y = actorSprite.y - 18;
      image.alpha = 0.75;
      gameBoard.addChild(image);

      _timeline.add(
        TweenMax.from(image, 0.4, {
          alpha: 0,
          'scale.x': 0,
          'scale.y': 0,
          onComplete: fadeOutMask,
          onCompleteParams: [image],
        }),
        _timeline._time
      );
      _timeline.add(
        TweenMax.to(image, 1.5, { y: image.y - 18 }),
        _timeline._time
      );

      TweenMax.delayedCall(0.1, Audio.play, ['evil_laugh_01']);
    });

    function fadeOutMask(image) {
      _timeline.add(
        TweenMax.to(image, 1.1, {
          alpha: 0.0,
          ease: Expo.easeIn,
        }),
        _timeline._time
      );
    }
  },
};
export default DevilMaskRendering;
