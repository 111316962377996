export default {
	ability_trees: "Ability Trees",
	alchemy: {
		name: "Alchemy"
	},
	armor_use: {
		name: "Armor Use"
	},
	bow_use: { 
		name: "Bow Use"
	},
	conjuration: { 
		 name: "Conjuration"
	},
	illusion: { 
		 name: "Illusion"
	},
	melee_combat: { 
		 name: "Melee Combat"
	},
	movement: { 
		 name: "Movement"
	},
	spirit: { 
		 name: "Spirit"
	}
};