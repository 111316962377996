import * as PIXI from 'pixi.js';
import CanvasTools from '~/view/CanvasTools';
import Colors from '~/constants/Colors';

const Modal = function( myWidth, myHeight ) {
    PIXI.Container.call( this );

    this.width = myWidth || window.innerWidth * 0.5;
    this.height = myHeight || window.innerHeight * 0.7;
    this.PADDING = this._width * 0.03;

    this.setTitle = ( text ) => {
      const fontSize = (22*(window.innerWidth/1000)).toString()+'px';

      this.title = new PIXI.Text( text, {
        fontFamily: 'Courier New',
        fontStyle: 'bold',
        fontSize,
        fill: 0xffffff
      });
      this.title.x = Math.round( this.PADDING );
      this.title.y = Math.round( this.PADDING );
      this.addChild( this.title );
    };

    this.transitionIn = () => {
      // $('#chat_root').css( 'visibility', 'hidden' );
      TweenMax.from( this, 0.6, {y:-window.innerHeight,alpha:0} );
      TweenMax.from( _backScreen, 0.8, {alpha:0} );
    };

    this.transitionOut = ( onComplete ) => {
      // $('#chat_root').css( 'visibility', 'visible' );
      TweenMax.to( this, 0.4, {y:-window.innerHeight,alpha:0,onComplete} );
      TweenMax.to( _backScreen, 0.4, {alpha:0} );
    };

    this.dispose = () => {
      this.removeChildren();

      if( this.parent ) {
        this.parent.removeChild( this );
      }

      if( _backScreen ) {
        _backScreen.mousedown = _backScreen.touchstart = null;
        DT_CANVAS_GLOBALS.stage.removeChild( _backScreen );
        _backScreen = null;
      }

      if( this.disposeChildClass ) {
        this.disposeChildClass();
      }
    };

    this.bg = CanvasTools.addBackFill( this, Colors.ALMOST_BLACK );
    CanvasTools.addBorder( 
      this,
      'window_border_horiz.png', 
      'window_border_vert.png',
      'window_border_horiz.png', 
      'window_border_vert.png',
      0
    );

    var _backScreen = CanvasTools.addBackFill(DT_CANVAS_GLOBALS.stage, 0x555555, 0.7, false)
    _backScreen.interactive = true;
    _backScreen.mousedown = _backScreen.touchstart = () => {
      this.transitionOut( this.dispose );
    }
};
Modal.prototype = Object.create(PIXI.Container.prototype)
Modal.prototype.constructor = Modal
export default Modal
