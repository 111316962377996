import { EditHeroDispatcher } from '~/flux/dispatchers';

const  EditHeroActions = {
  abilityUpgradeConfirm: function({ hero_handle, ability_handle, game_mode, game_submode }) {
    EditHeroDispatcher.handleEditHeroAction({
      actionType: EditHeroDispatcher.ABILITY_UPGRADE_CONFIRM,
      hero_handle,
      ability_handle,
      game_mode,
      game_submode,
    });
  },

  equipAbility: function({
    ability_handle, 
    hero_handle,
    hero_build_id,
  }) {
    EditHeroDispatcher.handleEditHeroAction({
      actionType: EditHeroDispatcher.EQUIP_ABILITY,
      ability_handle, 
      hero_handle,
      hero_build_id,
    });
  },

  equipItem: function(hero, item) {
    EditHeroDispatcher.handleEditHeroAction({
      actionType: EditHeroDispatcher.EQUIP_ITEM,
      hero,
      item,
    });
  },

  heroUnlockConfirm(hero) {
    EditHeroDispatcher.handleEditHeroAction({
      actionType: EditHeroDispatcher.HERO_UNLOCK_CONFIRM,
      hero,
    });
  },

  moveAIRoleToIndex: function({
    ai_tree_tag,
    hero_build_id,
    hero_handle,
    target_index,
  }) {
    EditHeroDispatcher.handleEditHeroAction({
      actionType: EditHeroDispatcher.MOVE_AI_ROLE_TO_INDEX,
      ai_tree_tag,
      hero_build_id,
      hero_handle,
      target_index,
    });
  },

  moveItem: function(item, targetPage, targetIndex) {
    EditHeroDispatcher.handleEditHeroAction({
      actionType: EditHeroDispatcher.MOVE_ITEM,
      item,
      targetPage,
      targetIndex,
    });
  },

  resetAbilitiesConfirm: function({
    hero_handle,
    hero_build_id,
  }) {
    EditHeroDispatcher.handleEditHeroAction({
      actionType: EditHeroDispatcher.RESET_ABILITIES_CONFIRM,
      hero_handle,
      hero_build_id
    });
  },

  resetAttributesConfirm: function({
    hero_build_id,
    hero_handle,
  }) {
    EditHeroDispatcher.handleEditHeroAction({
      actionType: EditHeroDispatcher.RESET_ATTRIBUTES_CONFIRM,
      hero_build_id,
      hero_handle,
    });
  },

  showMiracleDyeDialog: function(dyeId) {
    EditHeroDispatcher.handleEditHeroAction({
      actionType: EditHeroDispatcher.SHOW_MIRACLE_DYE_DIALOG,
      dyeId,
    });
  },

  unequipAbility: function({
    ability_handle, 
    hero_handle,
    hero_build_id,
  }) {
    EditHeroDispatcher.handleEditHeroAction({
      actionType: EditHeroDispatcher.UNEQUIP_ABILITY,
      ability_handle,
      hero_handle,
      hero_build_id
    });
  },

  unequipItem: function(hero, item) {
    EditHeroDispatcher.handleEditHeroAction({
      actionType: EditHeroDispatcher.UNEQUIP_ITEM,
      hero,
      item,
    });
  },

  upgradeAbility: function({ hero_handle, ability_handle, hero_build_id }) {
    EditHeroDispatcher.handleEditHeroAction({
      actionType: EditHeroDispatcher.UPGRADE_ABILITY,
      hero_handle,
      ability_handle,
      hero_build_id,
    });
  },

  upgradeAttribute: function({
    hero_handle,
    attribute_handle,
    hero_build_id
  }) {
    EditHeroDispatcher.handleEditHeroAction({
      actionType: EditHeroDispatcher.UPGRADE_ATTRIBUTE,
      hero_handle,
      attribute_handle,
      hero_build_id,
    });
  },

  useXPBoost: function({ potionId, heroHandle }) {
    EditHeroDispatcher.handleEditHeroAction({
      actionType: EditHeroDispatcher.USE_XP_BOOST,
      potionId,
      heroHandle,
    });
  },
};
export default EditHeroActions;