import FloatingTextEffect from '../../effects/FloatingTextEffect';
import text from '~/text';
import Audio from '~/Audio';

const BlockRendering = {
  render: function(data, gameBoard, isCaverns) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    if (!actorSprite) {
      return;
    }

    var effect = new FloatingTextEffect(text('battle.block'), 15, 0x999999);

    if (actorSprite.textQueue) {
      actorSprite.textQueue.push(effect);
    }

    Audio.play('block');
  },
};
export default BlockRendering;