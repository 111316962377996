const heroes = {
	assassin: {
		name: "Assassin",
		short_name: "As",
		description: "Skilled in the arts of subterfuge, poisons, and quick death..."
	},
	battle_mage: {
		name: "Battle Mage",
		short_name: "BM",
		description: "Both well equipped and a master of potent spells..."
	},
	dreamsmith: {
		name: "Dreamsmith",
		short_name: "DS",
		description: "A spiritual craftsman from worlds beyond ours..."
	},
	invoker: {
		name: "Invoker",
		short_name: "In",
		description: "Mystical powers earned through a lifetime of arcane studies..."
	},
	knight: {
		name: "Knight",
		short_name: "Kn",
		description: "Heavily armed and armored, no one is better prepared for close combat..."
	},
	mercenary: {
		name: "Mercenary",
		short_name: "Me",
		description: "Fast and deadly at close range as well as from afar..."
	},
	monk: {
		name: "Monk",
		short_name: "Mo",
		description: "Like water, this one's strength assumes many forms..."
	},
	paladin: {
		name: "Paladin",
		short_name: "Pa",
		description: "Member of a holy order who succor their brethren and smite their enemies..."
	},
	ranger: {
		name: "Ranger",
		short_name: "Ra",
		description: "Mysterious denizen of the wilderness, in tune with the natural gods and with a bow..."
	},
	shaman: {
		name: "Shaman",
		short_name: "Sh",
		description: "A strange mystic from an undocumented tribe of savages..."
	},
	warlock: {
		name: "Warlock",
		short_name: "Wa",
		description: "Dances across the line between darkness and light..."
	},

	dwarf: {
		name: "Dwarf",
		description: "A dwarf."
	},
	elf: {
		name: "Elf",
		description: "An elf."
	},
	faerie: {
		name: "Faerie",
		description: "A faerie."
	},
	poppet: {
		name: "Poppet",
		description: "A magical clone."
	},

  // Hero Builds  
  basic: 'Basic', // default hero build name
  yourHeroBuilds: hero_handle => `Your ${heroes[hero_handle]?.name} Builds`,
  delete_hero_build_title: 'Delete Hero Build',
  delete_hero_build_prompt: ({
    hero_handle,
    build_name,
  }) => `Are you sure you want to delete the ${heroes[hero_handle].name} build named "${build_name}"?`
};

export default heroes;
