import * as PIXI from 'pixi.js';
const instances = {};
	
const PoisonerRendering = {
	render: function( condition, gameBoard, isCaverns ) {
		if( isCaverns || instances[condition.uid] ) {
			return;
		}

		instances[ condition.uid ] = {};
		instances[ condition.uid ].ownerSprite = gameBoard.getAllPieceSprites()[condition.ownerId];
				
		const makePoisonDrip = ( conditionId ) => {
			var poisonDrip = new PIXI.Graphics();
			poisonDrip.beginFill( 0x49700d );
			poisonDrip.drawRect( -1.5, -6, 3, 12 );
			poisonDrip.endFill();
			
			const puppet = instances[conditionId].ownerSprite.puppet;
			poisonDrip.x = puppet.getParts().rightHand.x;
			poisonDrip.y = puppet.getParts().rightHand.y;			
			puppet.addChildAt( poisonDrip, 0 );

			TweenMax.to( poisonDrip, 0.876, {
				y: 25,
				alpha: 0,
				ease: Quad.easeIn,
				onComplete: (droplet) => { if(droplet.parent) droplet.parent.removeChild(droplet); },
				onCompleteParams: [ poisonDrip ]
			});

			instances[ conditionId ].interval = TweenMax.delayedCall( 0.3+Math.random()*1.0, makePoisonDrip, [conditionId] );
		};

		instances[ condition.uid ].interval = TweenMax.delayedCall( 0.3+Math.random()*1.0, makePoisonDrip, [condition.uid] );
	},

	stopRendering: function( conditionId ) {
		if( instances[conditionId] ) {
			instances[conditionId].interval.kill();		
			delete instances[ conditionId ];
		}
	},

	dispose: function() {
		for( var prop in instances ) {
			PoisonerRendering.stopRendering( prop );
		}
	}
};
export default PoisonerRendering;