import * as PIXI from 'pixi.js';
import Colors from '~/constants/Colors';
import text from '~/text';
import CanvasTools from '~/view/CanvasTools';

const wordWrapWidth = 420;

const PrimaryAttributeTooltip = function(attribute) {
  try {
    PIXI.Container.call(this);

    const { handle, base, fromUpgrades, fromEquipment, cost, has_upgrade_cost } = attribute;

    // generate title text, e.g. "Dexterity - 10"
    const level_total = base + fromUpgrades + fromEquipment;
    const title_text = text(`attributes.${handle}.name`) + ' - ' + level_total;

    // add the title sprite
    const _title = new PIXI.Text(title_text, {
      fontFamily: 'Courier New',
      fontSize: CanvasTools.dynamicFontSizeString(18),
      fontWeight: 'bold',
      fill: Colors.BRIGHT_YELLOW,
      wordWrap: true,
      wordWrapWidth,
    });
    this.addChild(_title);

    // add the blurb sprite
    const _blurb = new PIXI.Text(text(`attributes.${handle}.blurb`), {
      fontFamily: 'Courier New',
      fontSize: CanvasTools.dynamicFontSizeString(14),
      fontStyle: 'italic',
      fill: 0xbababa,
      wordWrap: true,
      wordWrapWidth,
    });
    _blurb.y = Math.round(_title.y + _title.height + DT_CANVAS_GLOBALS.spacing / 2);
    this.addChild(_blurb);

    // horizontal line under the blurb
    const hl = new PIXI.Graphics();
    hl.beginFill(Colors.BRIGHT_YELLOW, 0.2);
    hl.drawRect(0, 0, this.width, 2);
    hl.y = Math.round(_blurb.y + _blurb.height + 12);
    this.addChild(hl);

    // attribute base
    const _base_text = getAttributePartText(`${text('attributes.base')}: ${base}`);
    _base_text.y = Math.round(hl.y + 14);
    this.addChild(_base_text);

    // from equipment
    const _fromEquipment_text = getAttributePartText(`${text('attributes.from_equipment')}: ${fromEquipment}`);
    _fromEquipment_text.y = Math.round(_base_text.y + _base_text.height + 3);
    this.addChild(_fromEquipment_text);

    // from upgrades
    const _fromUpgrades_text = getAttributePartText(`${text('attributes.from_upgrades')}: ${fromUpgrades}`);
    _fromUpgrades_text.y = Math.round(_fromEquipment_text.y + _fromEquipment_text.height + 3);
    this.addChild(_fromUpgrades_text);

    // next upgrade cost
    const _next_upgrade_text = getAttributePartText(`${text('attributes.next_upgrade_cost')}: ${cost}`);
    _next_upgrade_text.style.fill = has_upgrade_cost ? Colors.GREEN : Colors.RED;
    _next_upgrade_text.y = Math.round(_fromUpgrades_text.y + _fromUpgrades_text.height + 3);
    this.addChild(_next_upgrade_text);

    this.dispose = () => {
      this.removeChildren();
    };
  } catch (err) {
    logError(err, {
      module: 'components/tooltips/PrimaryAttributeTooltip',
      func: 'constructor',
      attribute,
    });
  }
};
PrimaryAttributeTooltip.prototype = Object.create(PIXI.Container.prototype);
PrimaryAttributeTooltip.prototype.constructor = PrimaryAttributeTooltip;
export default PrimaryAttributeTooltip;

const getAttributePartText = (text) => {
  return new PIXI.Text(text, {
    fontFamily: 'Courier New',
    fontSize: CanvasTools.dynamicFontSizeString(14),
    lineHeight: window.innerWidth > 800 ? 13 : 9,
    fill: 0xffffff,
    padding: 2,
  });
};
