export default {
  // root level screens
  ARENA_LOBBY: 'ARENA_LOBBY',
  BATTLE: 'BATTLE',
  BATTLE_DEBRIEF: 'BATTLE_DEBRIEF',
  CAVERNS: 'CAVERNS',
  EDIT_HERO: 'EDIT_HERO',
  HERO_BUILDS: 'HERO_BUILDS',
  INN: 'INN',
  ONBOARD_FIRST_HERO_UNLOCK: 'ONBOARD_FIRST_HERO_UNLOCK',
  SHOP: 'SHOP',
  TEMPLE: 'TEMPLE',

  // edit loadout views
  EQUIPMENT: 'EQUIPMENT',
  ABILITIES: 'ABILITIES',
  ATTRIBUTES: 'ATTRIBUTES',
  AI_SETTINGS: 'AI_SETTINGS',
};
