<template>
  <div>
    <div
      id="battle_screen_root"
      class="w-full h-full"
    />
  </div>
</template>

<script setup>
import { nextTick, onBeforeUnmount, onMounted } from 'vue';
import Tools from '~/Tools';
import CanvasTools from '~/view/CanvasTools';
import BattleScreen_canvas from '../canvas/BattleScreen';

let _canvas_root;

onMounted(() => {
  nextTick(renderCanvas);

  window.addEventListener('resize', handleResize);
  window.addEventListener('orientationchange', handleResize);
});

onBeforeUnmount(() => {
  _canvas_root?.dispose();
  _canvas_root = null;
  window.removeEventListener('resize', handleResize);
  window.removeEventListener('orientationchange', handleResize);
});

function renderCanvas() {
  _canvas_root?.dispose();
  _canvas_root = new BattleScreen_canvas();
  const { x, y } = CanvasTools.getDomAnchor('battle_screen_root');
  _canvas_root.x = x;
  _canvas_root.y = y;
  _canvas_root.transitionIn();
  DT_CANVAS_GLOBALS.stage.addChildAt(_canvas_root, 0);
}

const handleResize = Tools.debounce(renderCanvas, 300);
</script>
