<template>
  <Dialog
    :open="true"
    @close="props.onClose"
  >
    <div
      class="fixed inset-0 flex items-center justify-center p-4 z-20 bg-zinc-700/90"
    >
      <DialogPanel
        class="border border-white w-[800px] max-h-[90vh] flex flex-col bg-black"
      >
        <DialogTitle class="border-b border-white p-3 text-yellow-300">
          {{ text('ui.purchase_pixie_dust') }}
        </DialogTitle>

        <div class="p-3 overflow-y-auto">
          <!-- purchase options -->
          <div class="w-full flex flex-col items-center">
            <div
              v-for="(purchase_option, i) of purchase_options"
              :key="i"
              :class="{
                'mx-3 my-1 flex items-center text-sm pointer-events-auto cursor-pointer hover:text-yellow-300': true,
                'text-green-500':
                  state.purchase_selection.id === purchase_option.id,
              }"
              @click="onPurchaseSelection(purchase_option)"
            >
              <div class="w-24 text-right">
                {{ purchase_option.name }}
              </div>
              <input
                type="radio"
                :id="purchase_option.id"
                name="purchase_selection"
                :value="purchase_option.id"
                :checked="state.purchase_selection.id === purchase_option.id"
                readOnly
                class="mx-3"
              />
              <div class="w-[100px] flex items-center">
                <span>{{ purchase_option.price }}</span>
                <img
                  v-if="Config.PLATFORM === 'yandex'"
                  :src="`${Config.DOM_IMG_PATH}/yandex_yan_200x200.png`"
                  class="w-[24px] h-[24px] ml-1"
                />
              </div>
            </div>
          </div>

          <!-- stripe card element -->
          <div
            v-if="['home', 'itch_io'].includes(Config.PLATFORM)"
            class="w-full flex justify-center"
          >
            <div
              class="my-6 w-[500px] p-3 rounded-md border border border-zinc-500/30 bg-zinc-700/30"
            >
              <div id="stripe_card_element"></div>
              <div>{{ state.card_error_msg }}</div>
            </div>
          </div>

          <!-- pay button row -->
          <div
            v-if="Config.PLATFORM === 'steam'"
            class="mt-4 w-full flex justify-center"
          >
            <DTButton
              type="success"
              id="pay_btn"
              :disabled="false"
              class="px-4 py-2"
              @click="onPayWithSteamClick"
            >
              {{ `Pay ${state.purchase_selection.price} via Steam` }}
            </DTButton>
          </div>
          <div
            v-else-if="Config.PLATFORM === 'yandex'"
            class="mt-4 w-full flex justify-center"
          >
            <DTButton
              type="success"
              id="pay_btn"
              :disabled="false"
              class="px-4 py-2"
              @click="onPayWithYandexClick"
            >
              {{ `${text('ui.pay')} ${state.purchase_selection.price}` }}
            </DTButton>
          </div>
          <div v-else>
            <div
              v-if="state.is_processing_payment"
              class="w-full flex justify-center"
            >
              <div class="dt-spinner"></div>
            </div>
            <div v-else-if="state.payment_succeeded">
              <div class="w-full flex justify-center text-green-500">
                Success!
              </div>
              <div class="w-full flex justify-center text-green-500">
                <span>Your card was charged </span>
                <span class="ml-3 text-yellow-300">
                  USD ${{ state.payment_succeeded_data.charge_amount / 100 }}
                </span>
                <span>, and</span>
              </div>
              <div class="w-full flex justify-center text-green-500">
                <span>you've been credited</span>
                <span class="ml-3 text-cyan-300 font-bold">
                  {{
                    state.payment_succeeded_data.purchase_selection.id.split(
                      '_'
                    )[1]
                  }}
                  Pixie Dust
                </span>
                <span>!</span>
              </div>
              <div class="w-full flex justify-center">
                <a
                  class="underline cursor-pointer"
                  @click="startOver"
                >
                  Make another purchase
                </a>
              </div>
            </div>
            <div
              v-else-if="state.card_declined"
              class="flex col justify-center items-center"
            >
              <div class="text-red-500">
                Card declined. Message: {{ state.card_declined_data?.message }}
              </div>
              <a
                class="cursor-pointer"
                @click="startOver"
              >
                Start Over
              </a>
            </div>
            <div
              v-else-if="state.payment_requires_action"
              class="flex col justify-center items-center"
            >
              <div class="flex col justify-center items-center text-yellow-300">
                <div>
                  This card has a security feature we don't support yet.
                </div>
                <div>You were not charged.</div>
              </div>
              <a
                class="cursor-point"
                @click="startOver"
              >
                Start Over
              </a>
            </div>
            <div
              v-else-if="state.payment_error"
              class="flex col justify-center items-center"
            >
              <div class="flex col justify-center items-center text-red-500">
                <div>There was a server error processing your payment.</div>
                <div>
                  <span
                    >If you were charged & didn't get your PD, please email
                  </span>
                  <a href="mailto:accounts@wakefield-studios.com"
                    >accounts@wakefield-studios.com</a
                  >
                </div>
              </div>
              <a
                class="cursor-point"
                @click="startOver"
              >
                Start Over
              </a>
            </div>
            <!-- the actual button -->
            <div
              v-else
              class="w-full flex justify-center"
            >
              <DTButton
                type="success"
                id="pay_btn"
                :disabled="state.disable_pay_button"
                class="px-4 py-2"
                @click="onPayNowClick"
              >
                {{ `Pay ${state.purchase_selection.price} Now` }}
              </DTButton>
            </div>
          </div>

          <!-- payment security message -->
          <div
            v-if="!['kongregate', 'steam', 'yandex'].includes(Config.PLATFORM)"
            class="mt-6 w-full flex justify-center"
          >
            <div class="text-xs text-zinc-200">
              Payment information is entered & processed securely by
              <a
                href="https://stripe.com/"
                target="_blank"
                >Stripe</a
              >
            </div>
          </div>

          <hr class="my-6" />

          <p class="w-full text-center text-yellow-300">
            {{ text('ui.get_free_pixie_dust') }}
          </p>
          <div class="pt-6 flex justify-center items-center">
            <div class="w-[50%] text-center no-select">
              <EmailSignupForm />
            </div>
            <div class="w-[50%] text-center">
              <div>
                <span>{{ text('ui.steam_review_hook') }}</span>
                <a
                  href="https://store.steampowered.com/app/1509060/Dungeon_Team/"
                  target="_blank"
                  @click="PlayerActions.onSocialClick('steam')"
                  >Steam</a
                >!
              </div>
              <div class="text-xs italic">
                {{ text('ui.steam_review_appeal') }}
              </div>
              <div
                class="text-cyan-300 mt-3"
                :style="{
                  marginLeft: '10px',
                  color: 'cyan',
                }"
              >
                <span
                  :style="{
                    textDecoration: state.social.steam_wishlisted
                      ? 'line-through'
                      : 'normal',
                  }"
                >
                  {{ text('ui.plus_100_pd') }}
                </span>
                <span v-if="state.social.steam_wishlisted"> Thank you! </span>
              </div>
            </div>
          </div>
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup>
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/vue';
import firebase from 'firebase/compat/app';
import { onBeforeUnmount, onMounted, nextTick, reactive } from 'vue';
import { Config } from '~/constants';
import { PaymentsActions, PlayerActions } from '~/flux/actions';
import { PaymentsStore, PlayerStore, ShopStore } from '~/flux/stores';
import text from '~/text';
import { DTButton, EmailSignupForm } from '~/view/components/common/DOM';

const purchase_options = [
  {
    name: '1,000 ' + text('ui.pixie_dust_short'),
    price: 'USD $4.99',
    id: 'pd_1000',
  },
  {
    name: '2,500 ' + text('ui.pixie_dust_short'),
    price: 'USD $9.99',
    id: 'pd_2500',
  },
  {
    name: '7,000 ' + text('ui.pixie_dust_short'),
    price: 'USD $24.99',
    id: 'pd_7000',
  },
  {
    name: '15,500 ' + text('ui.pixie_dust_short'),
    price: 'USD $49.99',
    id: 'pd_15500',
  },
  {
    name: '35,000 ' + text('ui.pixie_dust_short'),
    price: 'USD $99.99',
    id: 'pd_35000',
  },
];
if (Config.PLATFORM === 'yandex') {
  purchase_options[0].price = '150';
  purchase_options[1].price = '300';
  purchase_options[2].price = '750';
  purchase_options[3].price = '1,500';
  purchase_options[4].price = '3,000';
}

const stripe = Stripe(process.env.STRIPE_API_KEY);
let _card;

const props = defineProps({
  onClose: {
    type: Function,
    required: true,
  },
});

const state = reactive({
  purchase_selection: purchase_options[1],
  premiumItemList: ShopStore.getAll().premiumItemList,
  card_error_msg: '',
  disable_pay_button: true,
  is_processing_payment: false,
  payment_succeeded: false,
  payment_succeeded_data: null,
  card_declined: false,
  card_declined_data: null,
  payment_requires_action: false,
  payment_requires_action_data: null,
  payment_error: false,
  social: PlayerStore.getAll().social || {},
});

onMounted(() => {
  _card = stripe.elements().create('card', {
    style: {
      base: {
        color: '#fff',
        fontFamily: 'Courier New, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '14px',
        '::placeholder': {
          color: '#888',
        },
      },
      invalid: {
        fontFamily: 'Courier New, sans-serif',
        color: '#ad4408',
        iconColor: '#ad4408',
      },
    },
  });
  _card.on('change', (event) => {
    state.disable_pay_button = event.empty || event.error || !event.complete;
    state.card_error_msg = event.error ? event.error.message : '';
  });
  // Stripe injects an iframe into the DOM
  nextTick(() => {
    _card.mount('#stripe_card_element');
  });

  PaymentsStore.on(PaymentsStore.PAYMENT_SUCCEEDED, onPaymentSucceeded);
  PaymentsStore.on(PaymentsStore.CARD_DECLINED, onCardDeclined);
  PaymentsStore.on(
    PaymentsStore.PAYMENT_REQUIRES_ACTION,
    onPaymentRequiresAction
  );
  PaymentsStore.on(PaymentsStore.PAYMENT_ERROR, onPaymentError);
  PlayerStore.on(PlayerStore.SOCIAL_CLICK_REGISTERED, onSocialClickRegistered);
});

onBeforeUnmount(() => {
  PaymentsStore.removeListener(
    PaymentsStore.PAYMENT_SUCCEEDED,
    onPaymentSucceeded
  );
  PaymentsStore.removeListener(PaymentsStore.CARD_DECLINED, onCardDeclined);
  PaymentsStore.removeListener(
    PaymentsStore.PAYMENT_REQUIRES_ACTION,
    onPaymentRequiresAction
  );
  PaymentsStore.removeListener(PaymentsStore.PAYMENT_ERROR, onPaymentError);
  PlayerStore.removeListener(
    PlayerStore.SOCIAL_CLICK_REGISTERED,
    onSocialClickRegistered
  );
});

function onPurchaseSelection(purchase_option) {
  if (Config.PLATFORM === 'yandex') {
  }

  state.purchase_selection = purchase_option;
}

async function onPayNowClick() {
  state.is_processing_payment = true;

  const { error, paymentMethod } = await stripe.createPaymentMethod({
    type: 'card',
    card: _card,
  });

  if (error) {
    // TODO
  } else {
    PaymentsActions.createPaymentIntent({
      purchase_selection: state.purchase_selection,
      payment_method_id: paymentMethod.id,
    });
  }
}

async function onPayWithSteamClick() {
  state.is_processing_payment = true;
  PaymentsActions.initSteamPurchase({
    purchase_selection: state.purchase_selection,
  });
}

function onPaymentSucceeded(data) {
  firebase.analytics().logEvent('purchase', {
    value: data.charge_amount / 100,
    currency: 'USD',
    items: [data.purchase_selection.id],
  });

  state.payment_succeeded = true;
  state.payment_succeeded_data = data;
  state.is_processing_payment = false;
}

function onCardDeclined(data) {
  state.card_declined = true;
  state.card_declined_data = data;
  state.is_processing_payment = false;
}

function onPaymentRequiresAction(data) {
  state.payment_requires_action = true;
  state.payment_requires_action_data = data;
  state.is_processing_payment = false;
}

function onPaymentError() {
  state.payment_error = true;
  state.is_processing_payment = false;
}

function startOver() {
  state.is_processing_payment = false;
  state.payment_succeeded = false;
  state.payment_succeeded_data = null;
  state.card_declined = false;
  state.card_declined_data = null;
  state.payment_requires_action = false;
  state.payment_requires_action_data = null;
  state.payment_error = false;
}

// onKongItemNameClick: function(identifier) {
//   let priceInCents
//   switch (identifier) {
//     case 'pd_250': priceInCents = 500; break
//     case 'pd_600': priceInCents = 1000; break
//     case 'pd_1500': priceInCents = 2500; break
//     case 'pd_3500': priceInCents = 5000; break
//     case 'pd_8000': priceInCents = 10000; break
//   }

//   kongregate.mtx.purchaseItems([identifier], (data) => {
//     if (data.success) {
//       ShopActions.claimPremiumPurchases(data.purchase_id)

//       firebase.analytics().logEvent('purchase', {
//         transaction_id: data.purchase_id,
//         value: priceInCents / 100,
//         currency: 'USD',
//         items: [identifier]
//       })
//     }
//   })
// },

// onFBClick() {
//   if (!this.clickedFB) {
//     window.open('https://www.facebook.com/wakefieldgamestudio/', '_blank')
//     PlayerActions.onSocialClick('facebook')
//     this.clickedFB = true
//   }
// },

// onTwitterClick() {
//   if (!this.clickedTwitter) {
//     window.open('https://www.twitter.com/wakefieldstudio', '_blank')
//     PlayerActions.onSocialClick('twitter')
//     this.clickedTwitter = true
//   }
// },

// onKongregateClick: function() {
//   if (!this.clickedKong) {
//     PlayerActions.onSocialClick('kongregate')
//     this.clickedKong = true
//   }
// },

function onSocialClickRegistered() {
  state.social = PlayerStore.getAll().social || {};
}

function onPayWithYandexClick() {
  state.is_processing_payment = true;
  PaymentsActions.initYandexPurchase({
    purchase_selection: state.purchase_selection,
  });
}
</script>
