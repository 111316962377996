import * as PIXI from 'pixi.js';
import Colors from '~/constants/Colors';
import CanvasTools from '~/view/CanvasTools';
import NotificationsStore from '~/flux/stores/NotificationsStore';

const BattleNotifications = function() {
    PIXI.Container.call( this );

    this.dispose = () => {
      _valueField?.destroy();
        _valueField = null;

        this.removeChildren();
        NotificationsStore.removeListener( NotificationsStore.GOT_BATTLE_LOG, onGotBattleLog );
    };

    this._width = this._height = 20;
    CanvasTools.addBackFill( this, Colors.RED );

    var _valueField = new PIXI.Text( '?', {
        fontFamily: 'Courier New',
        fontStyle: 'bold',
        fontSize: CanvasTools.dynamicFontSizeString(12),
        fill: 0xffffff,
        dropShadow: true,
        dropShadowDistance: 1,
        dropShadowColor: 0x000000
    });
    _valueField.x = Math.round(this._width / 2 - _valueField.width / 2);
    _valueField.y = Math.round(this._height / 2 - _valueField.height / 2);
    this.addChild( _valueField );

    const onGotBattleLog = () => {
      const battleLog = NotificationsStore.getAll().battleLog;
      const num = battleLog ? Object.values(battleLog).length : 0

      if (num === 0) {
        this.visible = false
        return
      } else {
        this.visible = true
        _valueField.text = num
        _valueField.x = this._width / 2 - _valueField.width / 2
      }
    }
    onGotBattleLog()
    NotificationsStore.on(NotificationsStore.GOT_BATTLE_LOG, onGotBattleLog)
}
BattleNotifications.prototype = Object.create(PIXI.Container.prototype)
BattleNotifications.prototype.constructor = BattleNotifications
export default BattleNotifications