<template>
  <div class="px-3 flex">
    <!-- global inventory -->
    <div
      id="edit_equipment_global_inventory"
      class="w-[30%] h-full pt-[2%] px-[1%] flex flex-col"
    >
      <div
        id="global_inventory_title"
        class="text-yellow-300 no-select"
      >
        {{ text('ui.global_inventory') }}
      </div>
      <div
        id="global_bags_icons"
        class="mt-3 flex"
      >
        <div
          v-for="n in Balance.MAX_GLOBAL_BAG_SLOTS"
          :key="n"
          :class="{
            'w-8 h-8 mr-2 border border-zinc-500 flex justify-center items-center no-select': true,
            'border border-yellow-300':
              state.selected_global_bag_index === n - 1,
          }"
        >
          <div
            v-if="!!state.global_bags[n - 1]"
            :id="`global_bag_${n - 1}`"
            class="h-[90%] w-auto pointer-events-none"
          ></div>
          <div
            v-else-if="state.global_bags[n - 1] === null"
            class="w-full h-full pointer-events-auto cursor-pointer"
            @mouseup="onGlobalBagSlotMouseup({ bag_slot_index: n - 1 })"
          ></div>
          <img
            v-else
            :src="`${Config.DOM_IMG_PATH}/lock.png`"
            class="pointer-events-auto cursor-pointer"
            @click="onLockedGlobalBagSlotClick"
          />
        </div>
      </div>
      <div
        id="global_item_icons_container"
        class="mt-3 flex flex-wrap"
      >
        <div
          v-for="(bag_item, i) in state.selected_global_bag_items"
          :id="`selected_global_bag_item_${i}`"
          :key="i"
          :style="`width: ${state.game_item_icon_size_px}px; height: ${state.game_item_icon_size_px}px;`"
        />
      </div>
    </div>

    <!-- hero inventory -->
    <div
      id="edit_equipment_hero_inventory"
      class="w-[30%] h-full pt-[2%] pl-[1%] flex flex-col"
    >
      <div
        id="hero_inventory_title"
        class="text-yellow-300 no-select"
      >
        {{ text(`heroes.${UIStore.getAll().focused_hero_handle}.name`) }}
        {{ text('ui.inventory') }}
      </div>
      <div
        id="hero_bags_icons"
        class="mt-3 flex"
        :key="state.bag_slots_key"
      >
        <div
          v-for="n in Balance.MAX_HERO_BAG_SLOTS"
          :key="n"
          :class="{
            'w-8 h-8 mr-2 border border-zinc-500 flex justify-center items-center': true,
            'border border-yellow-300': state.selected_hero_bag_index === n - 1,
          }"
        >
          <div
            v-if="!!state.focused_hero_bags[n - 1]"
            :id="`focused_hero_bag_${n - 1}`"
            class="h-[90%] w-auto pointer-events-none"
          ></div>
          <div
            v-else-if="state.focused_hero_bags[n - 1] === null"
            class="w-full h-full pointer-events-auto cursor-pointer"
            @mouseup="onHeroBagSlotMouseup({ bag_slot_index: n - 1 })"
          ></div>
          <img
            v-else
            :src="`${Config.DOM_IMG_PATH}/lock.png`"
            class="pointer-events-auto cursor-pointer"
            @click="onLockedHeroBagSlotClick"
          />
        </div>
      </div>
      <div
        id="hero_item_icons_container"
        class="mt-3 flex flex-wrap"
      >
        <div
          v-for="(bag_item, i) in state.selected_hero_bag_items"
          :id="`selected_hero_bag_item_${i}`"
          :key="i"
          :style="`width: ${state.game_item_icon_size_px}px; height: ${state.game_item_icon_size_px}px;`"
        ></div>
      </div>
    </div>

    <!-- hero equipped items figure -->
    <div
      id="edit_equipment_equipment_figure"
      class="w-[20%] h-full"
    ></div>
  </div>
</template>

<script setup>
import * as PIXI from 'pixi.js';
import { nextTick, onBeforeUnmount, onMounted, reactive, ref } from 'vue';
import Balance from 'dt-common/constants/Balance';
import Economy from 'dt-common/constants/Economy';
import Game from 'dt-common/constants/Game';
import describeVacantInventorySpace from 'dt-common/isomorphic-helpers/describeVacantInventorySpace';
import getEquipmentObjFromAssignments from 'dt-common/isomorphic-helpers/getEquipmentObjFromAssignments';
import Audio from '~/Audio';
import EquipmentFigure from '~/components/common/EquipmentFigure'; // TODO move to ~/view
import GameItemIcon from '~/components/common/GameItemIcon';
import { Colors, Config } from '~/constants';
import { GameItemActions, UIActions } from '~/flux/actions';
import {
  FluxGetters,
  GameStateStore,
  HeroBuildStore,
  UIStore,
} from '~/flux/stores';
import text from '~/text';
import Tools from '~/Tools';
import CanvasTools from '~/view/CanvasTools';

const {
  ItemLocations: {
    GLOBAL_BAG_SLOT,
    GLOBAL_INVENTORY,
    HERO_BAG_SLOT,
    HERO_INVENTORY,
    EQUIPMENT_FIGURE,
  },
} = Game;

const hero_carousel_anchor = ref(null);

const emit = defineEmits(['item-drag-start']);
defineExpose({
  hero_carousel_anchor,
});

let _currently_dragging_icon_data;
let _equipment_figure;
let _equipped_global_bag_icons;
let _focused_hero_bag_icons;
let _global_game_item_icons;
let _hero_game_item_icons;

const state = reactive({
  bag_slots_key: 0,
  focused_hero_bags: [],
  game_item_icon_size_px: 64,
  global_bags: [],
  selected_global_bag_index: 0,
  selected_global_bag_items: [],
  selected_hero_bag_index: 0,
  selected_hero_bag_items: [],
});

function getSelectedGlobalBagItems() {
  const { bags } = GameStateStore.getAll().gameState.inventory;
  return bags ? bags[state.selected_global_bag_index]?.items : [];
}

function getFocusedHeroBags() {
  const { hero_roster } = GameStateStore.getAll().gameState;
  const { focused_hero_handle } = UIStore.getAll();
  let bags = hero_roster[focused_hero_handle].bags;
  if (typeof bags === 'object') {
    bags = Object.values(bags);
  }
  return bags;
}

function getSelectedHeroBagItems() {
  return state.focused_hero_bags[state.selected_hero_bag_index]?.items;
}

onMounted(() => {
  state.focused_hero_bags = Array.from(getFocusedHeroBags()); // was throwing on missing findIndex fn
  state.global_bags = GameStateStore.getAll().gameState.inventory.bags;
  state.selected_global_bag_index =
    state.global_bags?.findIndex((x) => !!x) || 0;
  state.selected_hero_bag_index =
    state.focused_hero_bags?.findIndex((x) => !!x) || 0;
  state.selected_global_bag_items = getSelectedGlobalBagItems();
  state.selected_hero_bag_items = getSelectedHeroBagItems();
  nextTick(renderCanvas);

  window.addEventListener('resize', handleResize);
  window.addEventListener('orientationchange', handleResize);
  window.addEventListener('mousemove', onMouseMove);
  window.addEventListener('mouseup', onMouseUp);
  GameStateStore.on(GameStateStore.EQUIPMENT_CHANGE, onEquipmentChange);
  HeroBuildStore.on(HeroBuildStore.ITEM_EQUIPPED, onEquipmentAssignmentChange);
  HeroBuildStore.on(
    HeroBuildStore.ITEM_UNEQUIPPED,
    onEquipmentAssignmentChange
  );
});

onBeforeUnmount(() => {
  destroyEquipmentFigure();
  destroyEquippedGlobalBagIcons();
  destroyFocusedHeroBagIcons();
  destroyGlobalInventoryIcons();
  destroyHeroInventoryIcons();

  window.removeEventListener('resize', handleResize);
  window.removeEventListener('orientationchange', handleResize);
  window.removeEventListener('mousemove', onMouseMove);
  window.removeEventListener('mouseup', onMouseUp);
  GameStateStore.removeListener(
    GameStateStore.EQUIPMENT_CHANGE,
    onEquipmentChange
  );
  HeroBuildStore.removeListener(
    HeroBuildStore.ITEM_EQUIPPED,
    onEquipmentAssignmentChange
  );
  HeroBuildStore.removeListener(
    HeroBuildStore.ITEM_UNEQUIPPED,
    onEquipmentAssignmentChange
  );
});

function renderCanvas() {
  state.game_item_icon_size_px = Math.min(64, DT_CANVAS_GLOBALS.spacing * 6.5);
  nextTick(() => {
    makeEquipmentFigure();
    makeEquippedGlobalBagIcons();
    makeFocusedHeroBagIcons();
    makeGlobalInventoryIcons();
    makeHeroInventoryIcons();
  });
}

const handleResize = Tools.debounce(renderCanvas, 300);

function destroyEquipmentFigure() {
  _equipment_figure?.dispose();
  _equipment_figure = null;
}

function destroyEquippedGlobalBagIcons() {
  for (const game_item_icon of _equipped_global_bag_icons || []) {
    game_item_icon?.dispose();
  }
  _equipped_global_bag_icons = null;
}

function destroyFocusedHeroBagIcons() {
  for (const game_item_icon of _focused_hero_bag_icons || []) {
    game_item_icon?.dispose();
  }
  _focused_hero_bag_icons = null;
}

function destroyGlobalInventoryIcons() {
  for (const game_item_icon of _global_game_item_icons || []) {
    game_item_icon?.dispose();
  }
  _global_game_item_icons = null;
}

function destroyHeroInventoryIcons() {
  for (const game_item_icon of _hero_game_item_icons || []) {
    game_item_icon?.dispose();
  }
  _hero_game_item_icons = null;
}

function makeEquippedGlobalBagIcons() {
  destroyEquippedGlobalBagIcons();
  _equipped_global_bag_icons = [];

  for (let i = 0; i < state.global_bags.length; ++i) {
    const bag = state.global_bags[i];
    if (!bag) {
      continue;
    }

    // make game item icon & add to stage
    const { x, y, width, height } = CanvasTools.getDomAnchor(`global_bag_${i}`);
    const game_item_icon = new GameItemIcon(bag, {
      frame: false,
    });
    game_item_icon.setScale(24);
    game_item_icon.x = x + width / 2;
    game_item_icon.y = y + height / 2;
    DT_CANVAS_GLOBALS.stage.addChildAt(game_item_icon, 0); // add at 0 so as not to mask faerie spinners on equipment change

    // store icon data locally
    game_item_icon.bag_slot_index = i;
    _equipped_global_bag_icons.push(game_item_icon);

    // add interaction listeners
    game_item_icon.click = ((icon, bag_slot_index) => {
      icon.should_start_drag = false;
      state.selected_global_bag_index = bag_slot_index;
      state.selected_global_bag_items = state.global_bags[bag_slot_index].items;
      nextTick(() => {
        makeEquippedGlobalBagIcons();
        makeFocusedHeroBagIcons();
        makeGlobalInventoryIcons();
        makeHeroInventoryIcons();
      });
    }).bind(null, game_item_icon, i);
    game_item_icon.mousedown = ((icon) => (icon.should_start_drag = true)).bind(
      null,
      game_item_icon
    );
    game_item_icon.mousemove = ((game_item_icon, bag_slot_index, event) => {
      game_item_icon.should_start_drag &&
        onItemDragStart(
          {
            from: {
              bag_slot_index,
              location: GLOBAL_BAG_SLOT,
            },
            game_item_icon,
          },
          event
        );
      game_item_icon.should_start_drag = false;
    }).bind(null, game_item_icon, i);
    game_item_icon.mouseup = onItemDragEnd.bind(null, {
      game_item_icon,
      to: {
        bag_slot_index: i,
        location: GLOBAL_BAG_SLOT,
      },
    });
  }
}

function makeFocusedHeroBagIcons() {
  destroyFocusedHeroBagIcons();
  _focused_hero_bag_icons = [];

  for (let i = 0; i < state.focused_hero_bags.length; ++i) {
    const bag = state.focused_hero_bags[i];
    if (!bag) {
      continue;
    }

    // make game item icon & add to stage
    const { x, y, width, height } = CanvasTools.getDomAnchor(
      `focused_hero_bag_${i}`
    );
    const game_item_icon = new GameItemIcon(bag, {
      frame: false,
    });
    game_item_icon.setScale(24);
    game_item_icon.x = x + width / 2;
    game_item_icon.y = y + height / 2;
    DT_CANVAS_GLOBALS.stage.addChildAt(game_item_icon, 0); // add at 0 so as not to mask faerie spinners on equipment change

    // store icon data locally
    game_item_icon.bag_slot_index = i;
    _focused_hero_bag_icons.push(game_item_icon);

    // add interaction listeners
    game_item_icon.click = ((icon, bag_slot_index) => {
      icon.should_start_drag = false;
      state.selected_hero_bag_index = bag_slot_index;
      state.selected_hero_bag_items =
        state.focused_hero_bags[bag_slot_index].items;
      nextTick(makeHeroInventoryIcons);
    }).bind(null, game_item_icon, i);
    game_item_icon.mousedown = ((icon) => (icon.should_start_drag = true)).bind(
      null,
      game_item_icon
    );
    game_item_icon.mousemove = ((game_item_icon, bag_slot_index, event) => {
      game_item_icon.should_start_drag &&
        onItemDragStart(
          {
            from: {
              bag_slot_index,
              hero_handle: UIStore.getAll().focused_hero_handle,
              location: HERO_BAG_SLOT,
            },
            game_item_icon,
          },
          event
        );
      game_item_icon.should_start_drag = false;
    }).bind(null, game_item_icon, i);
    game_item_icon.mouseup = onItemDragEnd.bind(null, {
      game_item_icon,
      to: {
        bag_slot_index: i,
        hero_handle: UIStore.getAll().focused_hero_handle,
        location: HERO_BAG_SLOT,
      },
    });
  }
}

function makeGlobalInventoryIcons() {
  destroyGlobalInventoryIcons();
  _global_game_item_icons = [];

  for (let i = 0; i < (state.selected_global_bag_items || []).length; ++i) {
    const bag_item = state.selected_global_bag_items[i];

    // make game item icon & add to stage
    const game_item_icon = new GameItemIcon(bag_item);
    game_item_icon.setScale(state.game_item_icon_size_px);
    const { x, y, width, height } = CanvasTools.getDomAnchor(
      `selected_global_bag_item_${i}`
    );
    game_item_icon.x = x + width / 2;
    game_item_icon.y = y + height / 2;
    DT_CANVAS_GLOBALS.stage.addChildAt(game_item_icon, 0); // add at 0 so as not to mask faerie spinners on equipment change

    // store icon data locally
    game_item_icon.bag_item_index = i;
    _global_game_item_icons.push(game_item_icon);

    // add interaction listeners
    game_item_icon.mousedown = ((icon) => (icon.should_start_drag = true)).bind(
      null,
      game_item_icon
    );
    game_item_icon.click = ((icon) => (icon.should_start_drag = false)).bind(
      null,
      game_item_icon
    );
    game_item_icon.mousemove = ((game_item_icon, bag_item_index, event) => {
      game_item_icon.should_start_drag &&
        onItemDragStart(
          {
            from: {
              bag_index: state.selected_global_bag_index,
              bag_item_index,
              location: GLOBAL_INVENTORY,
            },
            game_item_icon,
          },
          event
        );
      game_item_icon.should_start_drag = false;
    }).bind(null, game_item_icon, i);
    game_item_icon.mouseup = onItemDragEnd.bind(null, {
      game_item_icon,
      to: {
        bag_index: state.selected_global_bag_index,
        bag_item_index: i,
        location: GLOBAL_INVENTORY,
      },
    });
  }
}

function makeHeroInventoryIcons() {
  destroyHeroInventoryIcons();
  _hero_game_item_icons = [];

  for (let i = 0; i < state.selected_hero_bag_items.length; ++i) {
    const bag_item = state.selected_hero_bag_items[i];

    // make game item icon & add to stage
    const game_item_icon = new GameItemIcon(bag_item);
    game_item_icon.setScale(state.game_item_icon_size_px);
    const { x, y, width, height } = CanvasTools.getDomAnchor(
      `selected_hero_bag_item_${i}`
    );
    game_item_icon.x = x + width / 2;
    game_item_icon.y = y + height / 2;
    DT_CANVAS_GLOBALS.stage.addChildAt(game_item_icon, 0); // add at 0 so as not to mask faerie spinners on equipment change

    // store icon data locally
    game_item_icon.bag_item_index = i;
    _hero_game_item_icons.push(game_item_icon);

    // add interaction listeners
    game_item_icon.mousedown = ((icon) => (icon.should_start_drag = true)).bind(
      null,
      game_item_icon
    );
    game_item_icon.click = ((icon) => (icon.should_start_drag = false)).bind(
      null,
      game_item_icon
    );
    game_item_icon.mousemove = ((game_item_icon, bag_item_index, event) => {
      game_item_icon.should_start_drag &&
        onItemDragStart(
          {
            from: {
              bag_index: state.selected_hero_bag_index,
              bag_item_index,
              hero_handle: UIStore.getAll().focused_hero_handle,
              location: HERO_INVENTORY,
            },
            game_item_icon,
          },
          event
        );
      game_item_icon.should_start_drag = false;
    }).bind(null, game_item_icon, i);
    game_item_icon.mouseup = onItemDragEnd.bind(null, {
      game_item_icon,
      to: {
        bag_index: state.selected_hero_bag_index,
        bag_item_index: i,
        hero_handle: UIStore.getAll().focused_hero_handle,
        location: HERO_INVENTORY,
      },
    });
  }
}

function makeEquipmentFigure() {
  destroyEquipmentFigure();

  const { built_hero, roster_hero } = FluxGetters.getFocusedHeroBuild();
  const { hero_handle } = built_hero;

  _equipment_figure = new EquipmentFigure(
    getEquipmentObjFromAssignments({
      unit_bags: roster_hero.bags,
      unit_build: built_hero,
    }),
    state.game_item_icon_size_px
  );
  _equipment_figure.init();

  const { x, y, width, height } = CanvasTools.getDomAnchor(
    'edit_equipment_equipment_figure'
  );
  _equipment_figure.scale.x = _equipment_figure.scale.y = Math.min(
    1,
    window.innerWidth / 1200
  );
  _equipment_figure.x = Math.round(x + width / 2);
  _equipment_figure.y = Math.round(y + height / 2);
  DT_CANVAS_GLOBALS.stage.addChildAt(_equipment_figure, 0); // add at 0 so as not to mask faerie spinners on equipment change

  for (const game_item_icon of _equipment_figure.getIcons()) {
    game_item_icon.mousedown = ((icon) => (icon.should_start_drag = true)).bind(
      null,
      game_item_icon
    );
    game_item_icon.click = ((icon) => (icon.should_start_drag = false)).bind(
      null,
      game_item_icon
    );
    game_item_icon.mousemove = ((game_item_icon, event) => {
      game_item_icon.should_start_drag &&
        onItemDragStart(
          {
            from: {
              hero_handle,
              hero_build_id: UIStore.getAll().focused_hero_build.build_id,
              location: EQUIPMENT_FIGURE,
              slot: game_item_icon.slot,
              slot_hand: game_item_icon.slot_hand,
            },
            game_item_icon,
          },
          event
        );
      game_item_icon.should_start_drag = false;
    }).bind(null, game_item_icon);
    game_item_icon.mouseup = onItemDragEnd.bind(null, {
      game_item_icon,
      to: {
        hero_handle,
        hero_build_id: UIStore.getAll().focused_hero_build.build_id,
        location: EQUIPMENT_FIGURE,
        slot: game_item_icon.slot,
        slot_hand: game_item_icon.slot_hand,
      },
    });
  }
}

function onEquipmentChange() {
  state.focused_hero_bags = getFocusedHeroBags();
  state.global_bags = GameStateStore.getAll().gameState.inventory.bags;

  // handle the case of unequipping the currently selected global/hero bag index
  if (!state.global_bags[state.selected_global_bag_index]) {
    state.selected_global_bag_index = state.global_bags.findIndex((x) => !!x);
  }
  if (!state.focused_hero_bags[state.selected_hero_bag_index]) {
    state.selected_hero_bag_index = state.focused_hero_bags.findIndex(
      (x) => !!x
    );
  }

  state.selected_global_bag_items = getSelectedGlobalBagItems();
  state.selected_hero_bag_items = getSelectedHeroBagItems();

  nextTick(() => {
    makeEquippedGlobalBagIcons();
    makeFocusedHeroBagIcons();
    makeGlobalInventoryIcons();
    makeHeroInventoryIcons();
    makeEquipmentFigure();
  });
}

function onEquipmentAssignmentChange() {
  nextTick(() => {
    makeEquipmentFigure();
  });
  Audio.play('equipment_storage');
}

function onGlobalBagSlotMouseup({ bag_slot_index }) {
  onItemDragEnd({
    to: {
      bag_slot_index,
      location: GLOBAL_BAG_SLOT,
    },
  });
}

function onHeroBagSlotMouseup({ bag_slot_index }) {
  onItemDragEnd({
    to: {
      bag_slot_index,
      hero_handle: UIStore.getAll().focused_hero_handle,
      location: HERO_BAG_SLOT,
    },
  });
}

function onItemDragStart(data, event) {
  if (!data.game_item_icon.getItem()) {
    return;
  }
  _currently_dragging_icon_data = data;
  emit('item-drag-start', data);

  const { img } = data.game_item_icon;
  DT_CANVAS_GLOBALS.stage.addChild(img);
  img.x = event.data?.global?.x ?? event.x;
  img.y = event.data?.global?.y ?? event.y;
}

function onItemDragEnd({ game_item_icon: to_icon, to }) {
  if (!_currently_dragging_icon_data) {
    return;
  }

  const { from, game_item_icon: from_icon } = _currently_dragging_icon_data;
  const from_game_item = from_icon.getItem();
  const to_game_item = to_icon?.getItem();

  if (from_game_item?.type === 'miracle_dye' && !!to_game_item?.slot) {
    const from_icon_clone = new PIXI.Sprite();
    from_icon_clone.x = from_icon.x;
    from_icon_clone.y = from_icon.y;
    from_icon_clone.width = from_icon.width;
    from_icon_clone.height = from_icon.height;

    const to_icon_clone = new PIXI.Sprite();
    to_icon_clone.x = to_icon.x;
    to_icon_clone.y = to_icon.y;
    to_icon_clone.width = to_icon.width;
    to_icon_clone.height = to_icon.height;

    UIActions.showModal({
      modal_key: 'MiracleDyeModal',
      modal_props: {
        item_to_dye: to_game_item,
        onColorSelected: ({ base_16_color }) => {
          CanvasTools.makeFaerieSpinners(from_icon_clone);
          CanvasTools.makeFaerieSpinners(to_icon_clone);
          GameItemActions.activateMiracleDye({
            dyeId: from_game_item.uid,
            itemId: to_game_item.uid,
            from,
            to,
            tint: base_16_color,
          });
          Audio.play('airy_magic_whoosh');
          Audio.play('bless');
        },
      },
    });
  } else if (from_game_item?.type === 'equipment_dye' && !!to_game_item?.slot) {
    GameItemActions.dyeItem({
      dyeId: from_game_item.uid,
      itemId: to_game_item.uid,
      from,
      to,
    });
    CanvasTools.makeFaerieSpinners(from_icon);
    CanvasTools.makeFaerieSpinners(to_icon);
  } else {
    onMoveItemConfirmed({ from, to });
  }
}

function onLockedGlobalBagSlotClick() {
  const unlock_cost =
    Economy.GLOBAL_BAG_SLOT_UNLOCK_COST[state.global_bags.length];
  UIActions.showModal({
    modal_key: 'ConfirmDecisionModal',
    modal_props: {
      title_text: text('ui.bag_slot_unlock_title'),
      prompt_text: text('ui.bag_slot_unlock_prompt')(unlock_cost),
      onConfirmed: GameItemActions.globalBagSlotUnlockConfirm,
    },
  });
}

function onLockedHeroBagSlotClick({ bag_slot_index }) {
  const unlock_cost =
    Economy.HERO_BAG_SLOT_UNLOCK_COST[state.focused_hero_bags.length];
  UIActions.showModal({
    modal_key: 'ConfirmDecisionModal',
    modal_props: {
      title_text: text('ui.bag_slot_unlock_title'),
      prompt_text: text('ui.bag_slot_unlock_prompt')(unlock_cost),
      onConfirmed: GameItemActions.heroBagSlotUnlockConfirm,
    },
  });
}

function onMoveItemConfirmed({ from, to }) {
  const from_item_icon =
    from.location === GLOBAL_BAG_SLOT
      ? _equipped_global_bag_icons.find(
          (icon) => icon.bag_slot_index === from.bag_slot_index
        )
      : from.location === HERO_BAG_SLOT
        ? _focused_hero_bag_icons.find(
            (icon) => icon.bag_slot_index === from.bag_slot_index
          )
        : from.location === GLOBAL_INVENTORY
          ? _global_game_item_icons.find(
              (icon) => icon.bag_item_index === from.bag_item_index
            )
          : from.location === HERO_INVENTORY
            ? _hero_game_item_icons.find(
                (icon) => icon.bag_item_index === from.bag_item_index
              )
            : _equipment_figure.getIcons().find((icon) => {
                if (from.slot_hand) {
                  return icon.slot_hand === from.slot_hand;
                }
                return icon.slot === from.slot;
              });
  const to_item_icon =
    to.location === GLOBAL_BAG_SLOT
      ? _equipped_global_bag_icons.find(
          (icon) => icon.bag_slot_index === to.bag_slot_index
        )
      : to.location === HERO_BAG_SLOT
        ? _focused_hero_bag_icons.find(
            (icon) => icon.bag_slot_index === to.bag_slot_index
          )
        : to.location === GLOBAL_INVENTORY
          ? _global_game_item_icons.find(
              (icon) => icon.bag_item_index === to.bag_item_index
            )
          : to.location === HERO_INVENTORY
            ? _hero_game_item_icons.find(
                (icon) => icon.bag_item_index === to.bag_item_index
              )
            : _equipment_figure.getIcons().find((icon) => {
                if (to.slot_hand) {
                  return icon.slot_hand === to.slot_hand;
                }
                return icon.slot === to.slot;
              });

  const game_item = from_item_icon.getItem();
  const action_payload = {
    from,
    game_item,
    to,
  };

  switch (to.location) {
    case GLOBAL_BAG_SLOT:
      if (game_item.type === 'bag') {
        if (
          from.location !== GLOBAL_BAG_SLOT ||
          from.bag_slot_index !== to.bag_slot_index
        ) {
          GameItemActions.equipBagToGlobalBagSlot(action_payload);
        }
      } else {
        const { is_room, index_with_room } = describeVacantInventorySpace(
          { bags: state.global_bags },
          { bag_index: to.bag_slot_index }
        );
        if (is_room) {
          action_payload.to.location = GLOBAL_INVENTORY;
          action_payload.to.bag_index = to.bag_slot_index;
          action_payload.to.bag_item_index = index_with_room;
          GameItemActions.placeItemInGlobalInventory(action_payload);
        }
      }
      break;

    case GLOBAL_INVENTORY:
      if (from.location === EQUIPMENT_FIGURE) {
        $addMessageLogMessage(
          text('ui.warnings.no_unequip_item_to_global'),
          Colors.RED
        );
        return;
      } else {
        GameItemActions.placeItemInGlobalInventory(action_payload);
      }
      break;

    case HERO_BAG_SLOT:
      if (game_item.type === 'bag') {
        if (
          from.location !== HERO_BAG_SLOT ||
          from.bag_slot_index !== to.bag_slot_index
        ) {
          GameItemActions.equipBagToHeroBagSlot(action_payload);
        }
      } else {
        const { is_room, index_with_room } = describeVacantInventorySpace(
          { bags: state.focused_hero_bags },
          { bag_index: to.bag_slot_index }
        );
        if (is_room) {
          action_payload.to.location = HERO_INVENTORY;
          action_payload.to.bag_index = to.bag_slot_index;
          action_payload.to.bag_item_index = index_with_room;
          GameItemActions.placeItemInHeroInventory(action_payload);
        }
      }
      break;

    case HERO_INVENTORY:
      if (from.location !== EQUIPMENT_FIGURE) {
        GameItemActions.placeItemInHeroInventory(action_payload);
      }
      break;

    case EQUIPMENT_FIGURE:
      switch (from.location) {
        case GLOBAL_INVENTORY:
          $addMessageLogMessage(
            text('ui.warnings.no_assign_equipment_from_global'),
            Colors.RED
          );
          return;

        case HERO_INVENTORY:
        case EQUIPMENT_FIGURE:
          // if (not_equippable) $addMessageLogMessage('fuck you'
          // if (wrong_slot) $addMessageLogMessage('fuck you'
          GameItemActions.equipItem(action_payload);
          break;
      }
      break;
  }

  // spinners
  CanvasTools.makeFaerieSpinners(from_item_icon);
  const make_to_icon_spinner =
    to_item_icon &&
    (from.location !== EQUIPMENT_FIGURE || to.location === EQUIPMENT_FIGURE);
  make_to_icon_spinner && CanvasTools.makeFaerieSpinners(to_item_icon);
}

function onMouseMove(event) {
  if (_currently_dragging_icon_data) {
    // track the mouse
    _currently_dragging_icon_data.game_item_icon.img.x = event.x;
    _currently_dragging_icon_data.game_item_icon.img.y = event.y;
  }
}

function onMouseUp() {
  if (!_currently_dragging_icon_data) {
    return;
  }
  // kill the dragging icon & refresh its container
  const { from, game_item_icon } = _currently_dragging_icon_data;
  DT_CANVAS_GLOBALS.stage.removeChild(game_item_icon.img);
  switch (from.location) {
    case GLOBAL_INVENTORY:
      makeGlobalInventoryIcons();
      break;
    case HERO_INVENTORY:
      makeHeroInventoryIcons();
      break;
    case EQUIPMENT_FIGURE: {
      CanvasTools.makeFaerieSpinners(game_item_icon);
      makeEquipmentFigure();
      GameItemActions.unequipItem({
        game_item: game_item_icon.getItem(),
        from,
      });
      break;
    }
  }
  _currently_dragging_icon_data = null;
}
</script>
