import VampStandardMovement from './VampStandardMovement';
import RunMovement from './RunMovement';
import MeleeAttackMovement from './MeleeAttackMovement';
import TinkerMovement from './TinkerMovement';
import GetHurtMovement from './GetHurtMovement';
import SlashMovement from './SlashMovement';
import FireBowMovement from './FireBowMovement';
import MagicProjectileMovement from './MagicProjectileMovement';
import MagicUpGentleMovement from './MagicUpGentleMovement';
import SlamMovement from './SlamMovement';
import KnockbackMovement from './KnockbackMovement';
import TheQuickeningMovement from './TheQuickeningMovement';
import ShieldBlockMovement from './ShieldBlockMovement';
import MagicSummonMovement from './MagicSummonMovement';
import TauntMovement from './TauntMovement';
import LeapMovement from './LeapMovement';
import ThrowMovement from './ThrowMovement';
import StunnedMovement from './StunnedMovement';
import BashMovement from './BashMovement';
import ArmorBreakMovement from './ArmorBreakMovement';
import OffensiveStanceMovement from './OffensiveStanceMovement';
import DefensiveStanceMovement from './DefensiveStanceMovement';
import MortalStrikeMovement from './MortalStrikeMovement';
import SkyHammerMovement from './SkyHammerMovement';

const PuppetMovements = (puppet) => {
  const movements = {
    vampStandard: VampStandardMovement(puppet),
    run: RunMovement(puppet),
    meleeAttack: MeleeAttackMovement(puppet),
    tinker: TinkerMovement(puppet),
    getHurt: GetHurtMovement(puppet),
    slash: SlashMovement(puppet),
    fireBow: FireBowMovement(puppet),
    magicProjectile: MagicProjectileMovement(puppet),
    magicUpGentle: MagicUpGentleMovement(puppet),
    slam: SlamMovement(puppet),
    knockback: KnockbackMovement(puppet),
    theQuickening: TheQuickeningMovement(puppet),
    shieldBlock: ShieldBlockMovement(puppet),
    magicSummon: MagicSummonMovement(puppet),
    taunt: TauntMovement(puppet),
    leap: LeapMovement(puppet),
    throw: ThrowMovement(puppet),
    stunned: StunnedMovement(puppet),
    bash: BashMovement(puppet),
    armorBreak: ArmorBreakMovement(puppet),
    offensiveStance: OffensiveStanceMovement(puppet),
    defensiveStance: DefensiveStanceMovement(puppet),
    mortalStrike: MortalStrikeMovement(puppet),
    skyHammer: SkyHammerMovement(puppet),
  };

  return {
    ...movements,
    dispose: () => {
      for (const movement of Object.values(movements)) {
        movement.dispose();
      }
    },
  };
};
export default PuppetMovements;
