const heroes = {
	assassin: {
		name: "Ассасин",
		short_name: "Ас",
		description: "Владеет искусством уловок, приготовления ядов и быстрых убийств..."
	},
	battle_mage: {
		name: "Боевой маг",
		short_name: "Бм",
		description: "Знаток мощных заклинаний с превосходным снаряжением..."
	},
	dreamsmith: {
		name: "Чародей",
		short_name: "Чр",
		description: "Мастер духовных сил из потусторонних миров..."
	},
	invoker: {
		name: "Волшебник",
		short_name: "Вл",
		description: "Получил способности к магии, посвятив жизнь изучению потаенных знаний..."
	},
	knight: {
		name: "Рыцарь",
		short_name: "Рц",
		description: "Что лучше всего в ближнем бою, как не крепкая броня да острый меч..."
	},
	mercenary: {
		name: "Наемник",
		short_name: "На",
		description: "Стремительный и смертоносный — как в ближнем, так и в дальнем бою..."
	},
	monk: {
		name: "Монах",
		short_name: "Мо",
		description: "Подобно воде, сила его обретает много форм..."
	},
	paladin: {
		name: "Паладин",
		short_name: "Па",
		description: "Член священного ордена, что братьев своих охраняет, а врагов — сокрушает..."
	},
	ranger: {
		name: "Следопыт",
		short_name: "Сл",
		description: "Загадочный сын диких земель, в гармонии с божествами природы и собственным луком..."
	},
	shaman: {
		name: "Шаман",
		short_name: "Шм",
		description: "Чудак-тайновидец из неизвестного племени дикарей..."
	},
	warlock: {
		name: "Колдун",
		short_name: "Кл",
		description: "Танцует на границе между светом и тьмой..."
	},

	dwarf: {
		name: "Дворф",
		description: "Дворф."
	},
	elf: {
		name: "Эльф",
		description: "Эльф."
	},
	faerie: {
		name: "Фея",
		description: "Фея."
	},
	poppet: {
		name: "Двойник",
		description: "Магический клон."
	},

  // Hero Builds  
  basic: 'Базовый', // default hero build name
  yourHeroBuilds: hero_handle => `Билды персонажей ${heroes[hero_handle]?.name}`,
  delete_hero_build_title: 'Удалить билд',
  delete_hero_build_prompt: ({
    hero_handle,
    build_name,
  }) => `Удалить билд персонажа ${heroes[hero_handle].name} под названием "${build_name}"?`
};

export default heroes;
