import Game from 'dt-common/constants/Game';
import Audio from '~/Audio';

const BashRendering = {
  render: function(data, gameBoard, isCaverns) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const puppet = actorSprite.puppet;
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
    const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

    if (render_movement) {
      TweenMax.delayedCall(transitTime-0.55, () => {
        switch (puppet.getFacingDirection()){
          case Game.NORTH:
            puppet.face(Game.EAST);
            break;

          case Game.SOUTH:
            puppet.face(Game.WEST);
            break;

          case Game.EAST:
            puppet.face(Game.SOUTH);
            break;

          case Game.WEST:
            puppet.face(Game.NORTH);
            break;
        }
      });

      TweenMax.delayedCall(transitTime-0.45, () => {
        actorSprite.puppet.getMovements().bash.movePuppet({ transitTime });
      });
    }
  },

  renderResult: function(data, gameBoard) {
    if (data.success) {
      Audio.play('thump_02');
    }
  },
};
export default BashRendering;