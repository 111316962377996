import * as PIXI from 'pixi.js';
import CavernsStore from '~/flux/stores/CavernsStore';
import BattleTilesView from './battle_tiles_view';

const Caverns = function ({ dom_bounding_rect, is_resize = false } = {}) {
  PIXI.Container.call(this);
  this.interactive = this.interactiveChildren = true;

  this.dispose = async (doTransition = true) => {
    CavernsStore.removeListener(
      CavernsStore.BATTLE_INITIALIZED,
      makeCavernsBattleView
    );
    CavernsStore.removeListener(
      CavernsStore.RETURNED_TO_SURFACE,
      destroyCavernsBattleView
    );
    document
      .getElementById('game_canvas')
      .removeEventListener('wheel', onMouseWheel);
    window.removeEventListener('visibilitychange', onVisibilityChange);

    await destroyCavernsBattleView(doTransition);
    this.removeChildren();
    this.parent?.removeChild(this);
  };

  const destroyCavernsBattleView = (doTransition = true) =>
    new Promise((resolve) => {
      if (_battle_tiles_view && doTransition) {
        _battle_tiles_view.can_update = false;
        typeof _battle_tiles_view?.stopUpdating === 'function' &&
          _battle_tiles_view.stopUpdating();
        gsap.to(_battle_tiles_view.scale, {
          duration: 0.15,
          x: 0,
          y: 0,
          onComplete,
        });
      } else {
        onComplete();
      }

      function onComplete() {
        if (typeof _battle_tiles_view?.dispose === 'function') {
          _battle_tiles_view?.dispose();
        }
        _battle_tiles_view = null;
        resolve();
      }
    });

  const makeCavernsBattleView = async (options = {}) => {
    let scaleBuf = { x: 1, y: 1 };
    if (_battle_tiles_view) {
      scaleBuf = {
        x: _battle_tiles_view.scale_buf.x,
        y: _battle_tiles_view.scale_buf.y,
      };
    }

    await destroyCavernsBattleView(!options.prevent_transition_in);

    _battle_tiles_view = new BattleTilesView(
      CavernsStore.getAll().battleState,
      options
    );
    _battle_tiles_view.x = dom_bounding_rect.width / 2;
    _battle_tiles_view.y = dom_bounding_rect.height / 2;
    _battle_tiles_view.scale.x = scaleBuf.x;
    _battle_tiles_view.scale.y = scaleBuf.y;
    if (!options.prevent_transition_in) {
      gsap.from(_battle_tiles_view.scale, {
        duration: 0.3,
        x: 0,
        y: 0,
      });
    }
    this.addChild(_battle_tiles_view);

    // game board panning
    _battle_tiles_view.interactive = true;
    this.pointerdown = (event) => {
      _battle_tiles_view.panning = true;
      _battle_tiles_view.last_pan_point = { ...event.data.global };
    };

    _battle_tiles_view.pointermove = (event) => {
      if (_battle_tiles_view.panning) {
        const { global } = event.data;
        const { last_pan_point, scale } = _battle_tiles_view;
        _battle_tiles_view.pivot.x -= (global.x - last_pan_point.x) / scale.x;
        _battle_tiles_view.pivot.y -= (global.y - last_pan_point.y) / scale.y;
        _battle_tiles_view.last_pan_point = { ...global };
      }
    };
    _battle_tiles_view.pointerup = (event) => {
      _battle_tiles_view.panning = false;
    };
  };

  var _battle_tiles_view;
  if (CavernsStore.getAll().battleState) {
    makeCavernsBattleView({ prevent_transition_in: is_resize });
  }

  CavernsStore.on(CavernsStore.BATTLE_INITIALIZED, makeCavernsBattleView);
  CavernsStore.on(CavernsStore.RETURNED_TO_SURFACE, destroyCavernsBattleView);
  window.addEventListener('visibilitychange', onVisibilityChange);

  function onVisibilityChange(event) {
    if (document.hidden || document.webkitHidden) {
      destroyCavernsBattleView(false);
    } else {
      makeCavernsBattleView({ prevent_transition_in: true });
    }
  }

  // mousewheel handling
  var zoomLevel = 1;
  var zoomTween;
  const onMouseWheel = function (event) {
    if (!_battle_tiles_view) {
      return;
    }

    if (zoomTween) {
      zoomTween.kill();
    }

    const deltaNormal = event.deltaY / Math.abs(event.deltaY);
    if (isNaN(deltaNormal)) {
      return;
    }

    zoomTween = TweenMax.to(_battle_tiles_view.scale, 0.5, {
      x: Math.max(0.2, _battle_tiles_view.scale.x - deltaNormal * 0.2),
      y: Math.max(0.2, _battle_tiles_view.scale.y - deltaNormal * 0.2),
    });
  };
  document
    .getElementById('game_canvas')
    .removeEventListener('wheel', onMouseWheel);
  document
    .getElementById('game_canvas')
    .addEventListener('wheel', onMouseWheel);
};
Caverns.prototype = Object.create(PIXI.Container.prototype);
Caverns.prototype.constructor = Caverns;
export default Caverns;
