export default {
	block: "Block!",
	dodge: "Dodge!",
	resist: "Resist!",

	resign_battle_question: "Do you really want to resign from the battle?",

	// message log
	not_enough_ap: "Not enough Action Points!",
	not_enough_mp: "Not enough Magic Power!",
	insufficient_stats: "Insufficient stats!",
	try_wait_button: "Try the 'Wait' button...",
	shaped_like_hourglass: "...it looks like an hourglass...",
	illegal_action: "Illegal action!",

	// battle HUD
	active_unit: "Active Unit",
	target: "Target"
};
