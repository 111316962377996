import MysticFocusEffect from '../../../effects/MysticFocusEffect';
import Audio from '~/Audio';

const HealingPrayerRendering = {
  render: function(data, gameBoard, isCaverns) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
		const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

    render_movement && actorSprite.puppet.getMovements().magicUpGentle.movePuppet({ transitTime });

    const timeline = new TimelineMax();

    const image = new MysticFocusEffect(2400);
    image.alpha = 0.45;
    image.rotation = -50 + Math.random()*100;
    actorSprite.addChildAt(image, 1);

    timeline.add(TweenMax.from(image, 0.73, { alpha: 0,ease: Expo.easeIn }));
    timeline.add(TweenMax.from(image.scale,0.73,{ x: 3.6,y: 3.6,ease: Expo.easeIn }), 0);
    timeline.add(TweenMax.to(image,0.9,{ alpha: 0.0,y: image.y-25 }));
    timeline.add(TweenMax.to(image.scale, 0.9, { y: 0,x: 1.74 }), 0.73);

    Audio.play('mystic_focus');
  },
};
export default HealingPrayerRendering;