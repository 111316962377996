import ShopDispatcher from '~/flux/dispatchers/ShopDispatcher';

const ShopActions = {
  confirmItemSale: function() {
    ShopDispatcher.handleShopAction({
      actionType: ShopDispatcher.CONFIRM_ITEM_SALE,
    });
  },

  confirmItemPurchase: function(itemToBuy) {
    ShopDispatcher.handleShopAction({
      actionType: ShopDispatcher.CONFIRM_ITEM_PURCHASE,
      itemToBuy,
    });
  },

  claimPremiumPurchases: function(purchase_id) {
    ShopDispatcher.handleShopAction({
      actionType: ShopDispatcher.CLAIM_PREMIUM_PURCHASES,
      purchase_id,
    });
  },

  fetchShopkeeperOffer: function({ item_uids_to_sell }) {
    ShopDispatcher.handleShopAction({
      actionType: ShopDispatcher.FETCH_SHOPKEEPER_OFFER,
      item_uids_to_sell,
    });
  },
};
export default ShopActions;
