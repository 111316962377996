import * as PIXI from 'pixi.js';
import Colors from '~/constants/Colors';

const APMeter = function (unit) {
  PIXI.Container.call(this);

  const drawNub = (x, y) => {
    _gfx.beginFill(TINT);
    _gfx.drawCircle(x, y, 0.2);
    _gfx.endFill();
  };

  this.update = (newUnit) => {
    if (newUnit) {
      unit = newUnit;
    }

    _gfx.clear();
    _gfx.lineStyle(1, TINT);

    drawNub(17, 5);

    var progress = Math.max(0, Math.min(1, (unit.ap - 0) / (unit.max_ap / 6)));
    _gfx.moveTo(17, 5);
    _gfx.lineTo(17 + (41 - 17) * progress, 5 + (5 - 5) * progress);

    if (progress >= 1) {
      drawNub(41, 5);
    }

    progress = Math.max(
      0,
      Math.min(1, (unit.ap - (1 * unit.max_ap) / 6) / (unit.max_ap / 6))
    );
    _gfx.moveTo(41, 5);
    _gfx.lineTo(41 + (53 - 41) * progress, 5 + (26 - 5) * progress);

    if (progress >= 1) {
      drawNub(53, 26);
    }

    progress = Math.max(
      0,
      Math.min(1, (unit.ap - (2 * unit.max_ap) / 6) / (unit.max_ap / 6))
    );
    _gfx.moveTo(53, 26);
    _gfx.lineTo(53 + (41 - 53) * progress, 26 + (47 - 26) * progress);

    if (progress >= 1) {
      drawNub(41, 47);
    }

    progress = Math.max(
      0,
      Math.min(1, (unit.ap - (3 * unit.max_ap) / 6) / (unit.max_ap / 6))
    );
    _gfx.moveTo(41, 47);
    _gfx.lineTo(41 + (17 - 41) * progress, 47 + (47 - 47) * progress);

    if (progress >= 1) {
      drawNub(17, 47);
    }

    progress = Math.max(
      0,
      Math.min(1, (unit.ap - (4 * unit.max_ap) / 6) / (unit.max_ap / 6))
    );
    _gfx.moveTo(17, 47);
    _gfx.lineTo(17 + (5 - 17) * progress, 47 + (26 - 47) * progress);

    if (progress >= 1) {
      drawNub(5, 26);
    }

    progress = Math.max(
      0,
      Math.min(1, (unit.ap - (5 * unit.max_ap) / 6) / (unit.max_ap / 6))
    );
    _gfx.moveTo(5, 26);
    _gfx.lineTo(5 + (17 - 5) * progress, 26 + (5 - 26) * progress);

    _gfx.endFill();
  };

  this.dispose = () => {
    this.removeChildren();
  };

  this.pivot.x = 29;
  this.pivot.y = 26;
  const TINT = Colors.AP_ORANGE; // unit.team==='white'? Colors.WHITE_TEAM:Colors.BLACK_TEAM;

  var _gfx = new PIXI.Graphics();
  this.addChild(_gfx);
};
APMeter.prototype = Object.create(PIXI.Container.prototype);
APMeter.prototype.constructor = APMeter;
export default APMeter;
