import Audio from '~/Audio';

const SkyHammerRendering = {
  render: function (data, gameBoard, isCaverns) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
    const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;
    const victim = gameBoard.getAllPieceSprites()[data.victimId].gamePiece;

    if (!render_movement) {
      actorSprite.snapToBoardPosition(victim.x, victim.y);
    } else {
      actorSprite.puppet.getMovements().skyHammer.movePuppet({ transitTime });

      TweenMax.delayedCall(transitTime * 0.4, () => {
        actorSprite.puppet.getMovements().skyHammer.pause();
        actorSprite.tweenToBoardPosition(victim.x, victim.y, transitTime * 0.4);
      });
    }
    Audio.play('leap');
  },

  renderResult: function (data, gameBoard, isCaverns) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
    const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

    render_movement && actorSprite.puppet.getMovements().skyHammer.resume();
    TweenMax.delayedCall(transitTime * 0.2, Audio.play, ['thump_02']);
  },
};
export default SkyHammerRendering;
