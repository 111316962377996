import Audio from '~/Audio';

const BlessRendering = {
	render: function( data, gameBoard, isCaverns ) {
		const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
		const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

	  render_movement && actorSprite.puppet.getMovements().magicUpGentle.movePuppet({ transitTime });
		Audio.play( 'bless' );
	}
};
export default BlessRendering;