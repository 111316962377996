import * as PIXI from 'pixi.js';
import Colors from '~/constants/Colors';
import CanvasTools from '~/view/CanvasTools';

const LINE_HEIGHT = 17.6; // ???

const InfoPanel = function( title, description, body, childDisposeFunc ) {
    PIXI.Container.call( this );

    this.padding_x = Math.round( window.innerHeight*0.02 );
    this.padding_y = Math.round( this.padding_x/2 );

	this._width = window.innerWidth * 0.462;
	this._height = window.innerHeight * 0.24;
	CanvasTools.addBackFill( this, Colors.ALMOST_BLACK );
    
	this.title = new PIXI.Text( title, {
		fontFamily: 'Courier New',
		fontSize: CanvasTools.dynamicFontSizeString(15),
		fill: Colors.BRIGHT_YELLOW,
		align: 'center',
		wordWrap: true,
		wordWrapWidth: this._width
	});
	this.title.x = Math.round( this._width/2 - this.title.width/2 );
	this.title.y = this.padding_y;
	this.addChild( this.title );

	this.description = new PIXI.Text( description, {
		fontFamily: 'Courier New',
		fontSize: CanvasTools.dynamicFontSizeString(12),
		fill: 0x999999,
		wordWrap: true,
		wordWrapWidth: this._width
	});
	this.addChild( this.description );

	this.body = new PIXI.Text( body, {
		fontFamily: 'Courier New',
		fontSize: CanvasTools.dynamicFontSizeString(12),
		fill: 0xffffff,
		wordWrap: true,
		wordWrapWidth: this._width
	});

	this.addChild( this.body );

	this.dispose = () => {
		this.title = null;
		this.description = null;
		this.body = null;

    if (childDisposeFunc) {
      childDisposeFunc()
    }

		this.removeChildren()
	}
}
InfoPanel.prototype = Object.create(PIXI.Container.prototype)
InfoPanel.prototype.constructor = InfoPanel
export default InfoPanel
