import PuppetMovement from './PuppetMovement';

const GetHurtMovement = (puppet) => {   
  const poses = [ 
    { pose: Pose1 }
  ];  
  return PuppetMovement({
    poses,
    puppet,
    setPoseDurations: () => {
      poses[0].duration = 0.1;
    }
  });
};
export default GetHurtMovement;

const Pose1 = {
  head: {
    x: 0,
    y: -144,
    z: 48
  },        
  shoulders: {
    x: 0,
    y: -120,
    z: 24
  },        
  torsoe: {
    x: 0,
    y: -90,
    z: 0
  },        
  leftHand: {
    x: -46,
    y: -84,
    z: 60
  },    
  bowPitch: 0,
  bowYaw: -45,    
  rightHand: {
    x: 46,
    y: -89,
    z: 52
  },        
  weaponPitch: 0,
  weaponYaw: 0,        
  leftFoot: {
    x: -30,
    y: 0,
    z: 39
  },        
  rightFoot: {
    x: 30,
    y: 0,
    z: 47
  },      
};
