import * as PIXI from 'pixi.js';
import Colors from '~/constants/Colors';

const UnitStatBar = function UnitStatBar(
  unit_state,
  { is_caverns = false } = {}
) {
  PIXI.Container.call(this);

  let _last_hp = !isNaN(unit_state.hp) || unit_state.max_hp;
  let _last_mp = !isNaN(unit_state.mp) || unit_state.max_mp;
  let statBarWidth, statBarHeight;
  let _hp_bar_tween;
  let _mp_bar_tween;

  const hp_bar = new PIXI.Graphics();
  const mp_bar = is_caverns ? undefined : new PIXI.Graphics();

  const drawStatBarBackGround = () => {
    if (is_caverns) {
      return;
    }

    this.background.clear();

    this.background.beginFill(
      unit_state.team === 'white'
        ? Colors.WHITE_TEAM_DARK
        : Colors.BLACK_TEAM_DARK
    );
    this.background.drawRect(1, 1, statBarWidth, statBarHeight);
    this.background.endFill();

    this.background.beginFill(Colors.MP_PURPLE_DARK);
    this.background.drawRect(1, 3 + statBarHeight, statBarWidth, statBarHeight);
    this.background.endFill();
  };

  this.updateStatBarSize = () => {
    if (!this.parent) {
      return;
    }
    statBarWidth = is_caverns
      ? 12
      : Math.max(30, this.parent.getChildAt(0).width * 0.3);
    statBarHeight = is_caverns
      ? 1
      : Math.max(4, this.parent.getChildAt(0).height * 0.015);
    drawStatBarBackGround(NaN);
  };

  this.init = () => {
    this.background = new PIXI.Graphics();
    this.addChild(this.background);
    this.updateStatBarSize();

    this.addChild(hp_bar);
    !is_caverns && this.addChild(mp_bar);
  };

  this.update = (new_unit_state) => {
    hp_bar.clear();
    hp_bar.beginFill(
      new_unit_state.team === 'white'
        ? is_caverns
          ? Colors.HP_RED
          : Colors.WHITE_TEAM
        : Colors.BLACK_TEAM
    );
    hp_bar.drawRect(
      1,
      1,
      statBarWidth * (new_unit_state.hp / new_unit_state.max_hp),
      statBarHeight
    );
    hp_bar.endFill();

    if (!is_caverns) {
      mp_bar.clear();
      mp_bar.beginFill(Colors.MP_PURPLE);
      mp_bar.drawRect(
        1,
        3 + statBarHeight,
        statBarWidth * (new_unit_state.mp / new_unit_state.max_mp),
        statBarHeight
      );
      mp_bar.endFill();
    }

    if (new_unit_state.hp < _last_hp && !_hp_bar_tween) {
      // hp have been spent or lost
      const lossBar = new PIXI.Graphics();
      lossBar.beginFill(0xffffff);
      lossBar.drawRect(
        1 + (statBarWidth * new_unit_state.hp) / new_unit_state.max_hp,
        1,
        statBarWidth * ((_last_hp - new_unit_state.hp) / new_unit_state.max_hp),
        statBarHeight
      );
      lossBar.endFill();
      this.addChildAt(lossBar, 1);
      _hp_bar_tween = TweenMax.to(lossBar, 0.8, {
        x: 1 + (statBarWidth * new_unit_state.hp) / new_unit_state.max_hp,
        width: 0,
        onComplete: (dead_bar) => {
          dead_bar?.parent?.removeChild(dead_bar);
          _hp_bar_tween = null;
        },
        onCompleteParams: [lossBar],
      });
    }

    if (new_unit_state.mp < _last_mp && !_mp_bar_tween && !is_caverns) {
      // mp have been spent or lost
      const lossBar = new PIXI.Graphics();
      lossBar.beginFill(0xffffff); // 0x3a3a7e );
      lossBar.drawRect(
        1 + (statBarWidth * unit_state.mp) / unit_state.max_mp,
        3 + statBarHeight,
        statBarWidth * ((_last_mp - unit_state.mp) / unit_state.max_mp),
        statBarHeight
      );
      lossBar.endFill();
      this.addChildAt(lossBar, 1);
      _mp_bar_tween = TweenMax.to(lossBar, 0.8, {
        x: 1 + statBarWidth * (unit_state.mp / unit_state.max_mp),
        width: 0,
        onComplete: (dead_bar) => {
          dead_bar?.parent?.removeChild(dead_bar);
          _mp_bar_tween = null;
        },
        onCompleteParams: [lossBar],
      });
    }

    _last_hp = new_unit_state.hp;
    _last_mp = new_unit_state.mp;
  };

  this.dispose = () => {
    _hp_bar_tween?.kill();
    _mp_bar_tween?.kill();
    _hp_bar_tween = null;
    _mp_bar_tween = null;
    this.unit = null;
    this.removeChildren();
    this.parent?.removeChild(this);
  };
};
UnitStatBar.prototype = Object.create(PIXI.Container.prototype);
UnitStatBar.prototype.constructor = UnitStatBar;
export default UnitStatBar;
