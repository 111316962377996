import * as PIXI from 'pixi.js';
import Colors from '~/constants/Colors';

const instances = {};
	
const SlowedRendering = {
	render: function( condition, gameBoard ) {
		if( instances[condition.uid] ) {
			return;
		}

		instances[ condition.uid ] = {};
		instances[ condition.uid ].ownerSprite = gameBoard.getAllPieceSprites()[condition.ownerId];
				
		const makeEffect = ( conditionId ) => {
			var effect = new PIXI.Graphics();
			effect.beginFill( Colors.AP_ORANGE_DARK );
			effect.drawCircle( 0, 0, 26 );
			effect.endFill();
			effect.alpha = 0;
			instances[ condition.uid ].ownerSprite.addChildAt( effect, 0 );

			TweenMax.to( effect.scale, 1.5, {x:0,y:0,ease:Quad.easeIn} );
			TweenMax.to( effect, 1.51, {
				alpha: 0.3,
				ease: Linear.easeNone,
				onComplete: e => e.parent?.removeChild(e),
				onCompleteParams: [ effect ]
			});

			instances[ conditionId ].interval = TweenMax.delayedCall( 0.4+Math.random()*0.2, makeEffect, [conditionId] );
		};

		instances[ condition.uid ].interval = TweenMax.delayedCall( 0.4+Math.random()*0.2, makeEffect, [condition.uid] );
	},

	stopRendering: function( conditionId ) {
		if( instances[conditionId] ) {
			instances[conditionId].interval.kill();
			delete instances[ conditionId ];
		}
	},

	dispose: function() {
		for( var prop in instances ) {
			SlowedRendering.stopRendering( prop );
		}
	}
};
export default SlowedRendering;