import { Dispatcher } from 'flux';

const ShopDispatcher = Object.assign(new Dispatcher(), {
  NAME: 'SHOP_DISPATCHER',

  FETCH_SHOPKEEPER_OFFER: 'FETCH_SHOPKEEPER_OFFER',
  CONFIRM_ITEM_SALE: 'CONFIRM_ITEM_SALE',
  CONFIRM_ITEM_PURCHASE: 'CONFIRM_ITEM_PURCHASE',
  CLAIM_PREMIUM_PURCHASES: 'CLAIM_PREMIUM_PURCHASES',

  handleShopAction: function(action) {
    this.dispatch({
      source: ShopDispatcher.NAME,
      action
    });
  }
});
export default ShopDispatcher;