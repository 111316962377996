import * as PIXI from 'pixi.js';
import BattleCalc from 'dt-common/battle_engine/BattleCalc';
import Colors from '~/constants/Colors';
import Audio from '~/Audio';

const FumigateRendering = {
  render: function(data, gameBoard, isCaverns) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
    const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

    render_movement && actorSprite.puppet.getMovements().tinker.movePuppet({ transitTime });

    var _timeline = new TimelineMax();

    TweenMax.delayedCall(transitTime, ()=>{
      var range = BattleCalc.getEquippedAbility(actorSprite.gamePiece, 'fumigate').effectRange;		
      var numClouds = range*10;
      var radius = range*30;			
				
      for(var i = 0; i < numClouds; ++i) {
        _timeline.call(makeCloud, [radius], _timeline._time + i * 0.03);
      }
			
      Audio.play('send_mana');
      TweenMax.delayedCall(0.1, Audio.play, ['fumigate']);			
    });

    function makeCloud(radius) {
      var duration = 1500 + Math.random()*1201;
      var image = new PIXI.Sprite();
      image.texture = PIXI.utils.TextureCache['effects/cloud.png'];
      image.tint = Colors.GREEN;
			
      image.x = actorSprite.x + Math.random()* 32 * Math.cos(Math.random()*361);
      image.y = actorSprite.y + Math.random()* 32 * Math.sin(Math.random()*361);
      image.alpha = 0;
      gameBoard.addChild(image);			
				
      var fadeInTime = 0.6 + Math.random() * 0.4;
			
      _timeline.add(
        TweenMax.to(image, fadeInTime,	{
          alpha:0.2,
          ease:Quad.easeIn, 
          onComplete:fadeOut,
          onCompleteParams:[image, (duration/1000 - fadeInTime)]
        }), 
        _timeline._time
      );
			
      var xDest = actorSprite.x + Math.random()* radius * Math.cos(Math.random()*361);
      var yDest = actorSprite.y + Math.random()* radius * Math.sin(Math.random()*361);
      _timeline.add( 
        TweenMax.to(image, duration/1000.0, {
          x:xDest,
          y:yDest,
          rotation:(-100+Math.random()*200)*(Math.PI/180), 
          ease:Linear.easeNone
        }), 
        _timeline._time
      );
			
      _timeline.add( 
        TweenMax.from(image.scale, 1.5, {
          x: 0,
          y: 0,
          ease:Quad.easeIn
        }),
			 _timeline._time
      );
    }
		
    function fadeOut(image, remainingDuration) {
      _timeline.add( 
        TweenMax.to(image, remainingDuration, {
          alpha:0,
          ease:Expo.easeIn,
          onComplete: i => i.parent?.removeChild(i),
          onCompleteParams: [image],
        }),
        _timeline._time
      );
    }	
  }
};
export default FumigateRendering;