import PuppetMovement from './PuppetMovement.js';

const SkyHammerMovement = (puppet) => {
  const poses = [ 
    { pose:Pose1() },
    { pose:Pose2() },
    { pose:Pose3() },
    { pose:Pose4() }
  ];
     
  return PuppetMovement({
		poses,
    puppet,
    setPoseDurations: (actorCooldown) => {
      const transTime = actorCooldown / 7;

      poses[0].duration = transTime*2;
      poses[1].duration = transTime*3;
      poses[2].duration = transTime*0.1;
      poses[3].duration = transTime*1.9;
    }
  });
};
export default SkyHammerMovement;

// crouched, about to jump
const Pose1 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -102,
      z: 48
    },        
    shoulders: {
      x: 0,
      y: -90,
      z: 18
    },        
    torsoe: {
      x: 0,
      y: -66,
      z: 0
    },        
    leftHand: {
      x: -5,
      y: -54,
      z: 66
    },       
    bowPitch: 0,
    bowYaw: 0, 
    rightHand: {
      x: 5,
      y: -54,
      z: 66
    },        
    weaponPitch: 0,
    weaponYaw: 0,        
    leftFoot: {
      x: -6,
      y: 0,
      z: -54
    },        
    rightFoot: {
      x: 10,
      y: 0,
      z: 48
    }
  };
  return pose;       
};
    
// cutting upward thru the air with weapon
const Pose2 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -108,
      z: -12
    },        
    shoulders: {
      x: 0,
      y: -84,
      z: 0
    },        
    torsoe: {
      x: 0,
      y: -60,
      z: 0
    },        
    leftHand: {
      x: -24,
      y: -48,
      z: 0
    },       
    bowPitch: 45,
    bowYaw: 0, 
    rightHand: {
      x: 12,
      y: -140,
      z: 24
    },        
    weaponPitch: 0,
    weaponYaw: 0,        
    leftFoot: {
      x: -6,
      y: 0,
      z: 0
    },        
    rightFoot: {
      x: 12,
      y: 0,
      z: 24
    }
  };
    // upward forward travel
  for(var part in pose) {
    if(pose[part].y !== undefined) {
      pose[part].y -= 3000;
    }
  }

  return pose;
};
    
// bearing down with weapon and right knee, still in the air
const Pose3 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -70,
      z: 54
    },        
    shoulders: {
      x: 0,
      y: -60,
      z: 34
    },        
    torsoe: {
      x: 0,
      y: -45,
      z: 0
    },        
    leftHand: {
      x: -24,
      y: -45,
      z: 0
    },       
    bowPitch: 70,
    bowYaw: 0, 
    rightHand: {
      x: 12,
      y: 0,
      z: 60
    },        
    weaponPitch: 90,
    weaponYaw: 90,        
    leftFoot: {
      x: -6,
      y: 0,
      z: 0
    },        
    rightFoot: {
      x: 12,
      y: 0,
      z: -30
    }
  };
    // upward forward travel
  for(var part in pose) {
    if(pose[part].y !== undefined) {
      pose[part].y -= 3000;
    }
  }

  return pose;
};

// bearing down with weapon and right knee, on the ground
const Pose4 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -70,
      z: 54
    },        
    shoulders: {
      x: 0,
      y: -60,
      z: 34
    },        
    torsoe: {
      x: 0,
      y: -45,
      z: 0
    },        
    leftHand: {
      x: -24,
      y: -45,
      z: 0
    },       
    bowPitch: 70,
    bowYaw: 0, 
    rightHand: {
      x: 12,
      y: 0,
      z: 60
    },        
    weaponPitch: 90,
    weaponYaw: 90,        
    leftFoot: {
      x: -6,
      y: 0,
      z: 0
    },        
    rightFoot: {
      x: 12,
      y: 0,
      z: -30
    }
  };

  return pose;
};