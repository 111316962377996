export default {
	assassin: "Ассасин",
	battle_mage: "Боевой маг",
	dreamsmith: "Чародей",
	invoker: "Волшебник",
	knight: "Рыцарь",
	mercenary: "Наемник",
	monk: "Монах",
	paladin: "Паладин",
	ranger: "Следопыт",
	shaman: "Шаман",
	warlock: "Колдун",

	dwarf: "Дворф",
	elf: "Эльф",
	faerie: "Фея",

	giant_rat: "Огромная крыса",
	kobold: "Кобольд",
	skeleton: "Скелет",
	skeletal_archer: "Скелет-лучник",
	cave_lizard: "Пещерная ящерица",
	ratling_warrior: "Ретлинг-воин",
	slime: "Слизь",
	ratling_priest: "Ретлинг-священник",
	huge_bat: "Большая летучая мышь",
	bandit: "Бандит",
	goblin: "Гоблин",
	kobold_cleric: "Кобольд-жрец",
	goblin_elementalist: "Гоблин-заклинатель",
	snake: "Змея"
};