import * as PIXI from 'pixi.js';
import Economy from 'dt-common/constants/Economy';
import { AltarStore, CurrencyStore } from '~/flux/stores';
import { AltarActions, ChatActions, UIActions } from '~/flux/actions';
import text from '~/text';
import MessageLog from '~/view/components/MessageLog_canvas';
import PrayerSlotView from './PrayerSlotView';
import ItemTypeSelector from './ItemTypeSelector';
import GoldAmountSlider from './GoldAmountSlider';

const PrayerMenu = function() {
  PIXI.Container.call(this);

  this.transitionIn = () => {};
  this.transitionOut = () => {};

  this.dispose = () => {
    AltarStore.removeListener(AltarStore.PRAYER_DATA, onNewPrayerData);
    AltarStore.removeListener(AltarStore.GOT_ALTAR_STATE, onAltarState);  
      
    destroyPrayerSlotViews();
    destroyGoldAmountSlider();
    destroyGodButtons();
    destroyItemTypeSelector();

    this.removeChildren();
  };

  const destroyPrayerSlotViews = () => {
    if (_prayerSlotViews) {
      for (const psv of _prayerSlotViews) {
        if (psv) {
          psv.dispose();
          if (psv.parent) {
            psv.parent.removeChild(psv);
          }
        }
      }

      if (_prayerSlotViewContainer) {
        this.removeChild(_prayerSlotViewContainer);
      }

      _prayerSlotViews = null;
    }
  };

  const destroyGoldAmountSlider = () => {
    if (_goldAmountSlider) {
      _goldAmountSlider.dispose();
      this.removeChild(_goldAmountSlider);
      _goldAmountSlider = null;
    }
  };

  const destroyGodButtons = () => {
    if (_chaosButton) {
      _chaosButton.tap = _chaosButton.click = null;
      this.removeChild(_chaosButton);
      _chaosButton = null;
    }

    if (_balanceButton) {
      _balanceButton.tap = _balanceButton.click = null;
      this.removeChild(_balanceButton);
      _balanceButton = null;
    }

    if (_orderButton) {
      _orderButton.tap = _orderButton.click = null;
      this.removeChild(_orderButton);
      _orderButton = null;
    }
  };

  const destroyItemTypeSelector = () => {
    if (_itemTypeSelector) {
      _itemTypeSelector.dispose();
      this.removeChild(_itemTypeSelector);
      _itemTypeSelector = null;
    }
  };

  const onUnlockedSlotClick = (event) => {
    var slotData = event.target.slotData;

    if(slotData.active) {
      if(new Date().getTime() >= slotData.endTime) {
        AltarActions.completePrayer(event.target.slotIndex);
      }
    } else if(CurrencyStore.getAll().gold >= 100) {
      AltarActions.startNewPrayer(event.target.slotIndex);
    } else {
      MessageLog.addMessage(text('ui.not_enough_gold'), 0xff0000);
      return;
    }

    for(var i = 0; i < _prayerSlotViews.length; ++i) {
      _prayerSlotViews[i].backdrop.tint = _prayerSlotViews[i].tintBuffer;
    }

    event.target.backdrop.tint = event.target.tintBuffer = 0x00ff00;
  };

  var _unlockingPrayerSlot = false;
  const onLockedSlotClick = () => {
    const unlock_cost = Economy.PRAYER_SLOT_UNLOCK_COSTS[AltarStore.getAll().prayerSlots.length];
    UIActions.showModal({
      modal_key: 'ConfirmDecisionModal',
      modal_props: {
        title_text: text('ui.prayer_slot_unlock_title'),
        prompt_text: `${text('ui.prayer_slot_unlock_question.p1')} ${unlock_cost} ${text('ui.prayer_slot_unlock_question.p2')}`,
        onConfirmed: AltarActions.prayerSlotUnlockConfirm,
      },
    });
    _unlockingPrayerSlot = true;
  };

  const makePrayerSlotViews = () => {
    destroyPrayerSlotViews();
    _prayerSlotViews = [];
    _prayerSlotViewContainer = new PIXI.Container();

    for (let i = 0; i < _altarState.prayerSlots.length; ++i) {
      const slotView = new PrayerSlotView(_altarState.prayerSlots[i]);
      slotView.slotIndex = i;
      slotView.x = i * slotView.width * 1.15 + slotView.width / 2;
      slotView.y = slotView.height / 2 + DT_CANVAS_GLOBALS.spacing * 2;
      _prayerSlotViewContainer.addChild(slotView);
      _prayerSlotViews.push(slotView);

      slotView.interactive = slotView.buttonMode = true;
      slotView.tap = slotView.click = onUnlockedSlotClick;
    }

    if (_altarState.prayerSlots.length < Economy.MAX_PRAYER_SLOTS) {
      const lockedSlotView = new PrayerSlotView(null, true);
      lockedSlotView.x = _prayerSlotViews.length*lockedSlotView.width * 1.15 + lockedSlotView.width / 2;
      lockedSlotView.y = lockedSlotView.height / 2 + DT_CANVAS_GLOBALS.spacing * 2;
      _prayerSlotViewContainer.addChild(lockedSlotView);
      _prayerSlotViews.push(lockedSlotView);

      lockedSlotView.interactive = lockedSlotView.buttonMode = true;
      lockedSlotView.tap = lockedSlotView.click = onLockedSlotClick;
    }

    _prayerSlotViewContainer.x = -_prayerSlotViewContainer.width/2;
    this.addChild(_prayerSlotViewContainer);
  };

  const makeGoldAmountSlider = () => {
    destroyGoldAmountSlider();

    _goldAmountSlider = new GoldAmountSlider(this, window.innerWidth*0.4);
    _goldAmountSlider.y = Math.round(_prayerSlotViewContainer.y - DT_CANVAS_GLOBALS.spacing * 8 - _goldAmountSlider.height/2);
    this.addChild(_goldAmountSlider);
  };

  const makeGodButtons = () => {
    destroyGodButtons();

    const LABEL_STYLE = {
      fontFamily: 'Courier New',
      fontSize: 18,
      fontSytle: 'bold',
      fill: 0xffffff
    };
    var label;

    _chaosButton = new PIXI.Sprite();
    _chaosButton.texture = PIXI.utils.TextureCache['button_unselected.png'];
    label = _chaosButton.label = new PIXI.Text(text('ui.gods.chaos'), LABEL_STYLE);
    label.anchor.set(0.5, 0.57);
    _chaosButton.addChild(label);
    _chaosButton.height = Math.min(_chaosButton.height, window.innerHeight * 0.04);
    _chaosButton.scale.x = _chaosButton.scale.y;
    _chaosButton.anchor.set(0.5, 0.5);
    _chaosButton.x = Math.round(-window.innerWidth * 0.2);
    _chaosButton.y = Math.round(_prayerSlotViewContainer.y - DT_CANVAS_GLOBALS.spacing * 11);
    this.addChild(_chaosButton);
    _chaosButton.interactive = _chaosButton.buttonMode = true;
    _chaosButton.tap = _chaosButton.click = () => {
      AltarActions.updatePrayerGod('chaos');
      _chaosButton.is_selection = true;

      _orderButton.is_selection = false;
      _orderButton.label.style.fill = 0xffffff;

      _balanceButton.is_selection = false;
      _balanceButton.label.style.fill = 0xffffff;
    };
    _chaosButton.mouseover = _chaosButton.pointerover = () => {
      _chaosButton.tint = 0xff0000;
      _chaosButton.label.style.fill = 0x6d1da4;
    };
    _chaosButton.mouseout = _chaosButton.pointerout = std_mouseout.bind(null, _chaosButton);
    _chaosButton.mousedown = _chaosButton.pointerdown = std_mousedown.bind(null, _chaosButton);
    _chaosButton.mouseup = _chaosButton.pointerup = std_mouseup.bind(null, _chaosButton);

    _balanceButton = new PIXI.Sprite();
    _balanceButton.texture = PIXI.utils.TextureCache['button_unselected.png'];
    label = _balanceButton.label = new PIXI.Text(text('ui.gods.balance'), LABEL_STYLE);
    label.anchor.set(0.5, 0.57);
    _balanceButton.addChild(label);
    _balanceButton.height = Math.min(_balanceButton.height, window.innerHeight*0.04);
    _balanceButton.scale.x = _balanceButton.scale.y;
    _balanceButton.anchor.set(0.5, 0.5);
    _balanceButton.y = _chaosButton.y;
    this.addChild(_balanceButton);
    _balanceButton.interactive = _balanceButton.buttonMode = true;
    _balanceButton.tap = _balanceButton.click = () => {
      AltarActions.updatePrayerGod('balance');
      _balanceButton.is_selection = true;

      _orderButton.is_selection = false;
      _orderButton.label.style.fill = 0xffffff;

      _chaosButton.is_selection = false;
      _chaosButton.label.style.fill = 0xffffff;
    };
    _balanceButton.mouseover = _balanceButton.pointerover = () => {
      _balanceButton.tint = 0x00ff00;
      _balanceButton.label.style.fill = 0x998d1d;
    };
    _balanceButton.mouseout = _balanceButton.pointerout = std_mouseout.bind(null, _balanceButton);
    _balanceButton.mousedown = _balanceButton.pointerdown = std_mousedown.bind(null, _balanceButton);
    _balanceButton.mouseup = _balanceButton.pointerup = std_mouseup.bind(null, _balanceButton);

    _orderButton = new PIXI.Sprite();
    _orderButton.texture = PIXI.utils.TextureCache['button_unselected.png'];
    label = _orderButton.label = new PIXI.Text(text('ui.gods.order'), LABEL_STYLE);
    label.anchor.set(0.5, 0.57);
    _orderButton.addChild(label);
    _orderButton.height = Math.min(_orderButton.height, window.innerHeight*0.04);
    _orderButton.scale.x = _orderButton.scale.y;
    _orderButton.anchor.set(0.5, 0.5);
    _orderButton.x = Math.round(window.innerWidth * 0.2);
    _orderButton.y = _chaosButton.y;
    this.addChild(_orderButton); 
    _orderButton.interactive = _orderButton.buttonMode = true;
    _orderButton.tap = _orderButton.click = () => {
      AltarActions.updatePrayerGod('order');
      _orderButton.is_selection = true;

      _balanceButton.is_selection = false;
      _balanceButton.label.style.fill = 0xffffff;

      _chaosButton.is_selection = false;
      _chaosButton.label.style.fill = 0xffffff;
    };
    _orderButton.mouseover = _orderButton.pointerover = () => {
      _orderButton.tint = 0x0000ff;
      _orderButton.label.style.fill = 0x00c6ae;
    };
    _orderButton.mouseout = _orderButton.pointerout = std_mouseout.bind(null, _orderButton);
    _orderButton.mousedown = _orderButton.pointerdown = std_mousedown.bind(null, _orderButton);
    _orderButton.mouseup = _orderButton.pointerup = std_mouseup.bind(null, _orderButton);
  };

  const makeItemTypeSelector = () => {
    destroyItemTypeSelector();

    _itemTypeSelector = new ItemTypeSelector();
    _itemTypeSelector.y = DT_CANVAS_GLOBALS.spacing * -11 - _itemTypeSelector.height / 2;
    this.addChild(_itemTypeSelector);
  };

  var _altarState = AltarStore.getAll();
  var _lastNumPrayerSlots = _altarState.prayerSlots.length;
  var _prayerSlotViews;
  var _prayerSlotViewContainer;
  var _goldAmountSlider;
  var _chaosButton;
  var _balanceButton;
  var _orderButton;
  var _itemTypeSelector;
  makePrayerSlotViews();

  const onNewPrayerData = (newPrayerData) => {
    if(!newPrayerData.goldValueIsSet) {
      if(!_goldAmountSlider) {
        destroyGodButtons();
        destroyItemTypeSelector();
        makeGoldAmountSlider();
      }
    } else {
      destroyGoldAmountSlider();
        
      if(!newPrayerData.godHandleIsSet) {
        if(!_chaosButton) {
          makeGodButtons();
        }
      } else {
        destroyGodButtons();
          
        if(!newPrayerData.itemDataIsSet) {
          if(!_itemTypeSelector) {
            makeItemTypeSelector();
          }
        } else {
          // all data is set, i.e. prayer has been submitted
          destroyItemTypeSelector();
        }
      }       
    }
  };
  AltarStore.on(AltarStore.PRAYER_DATA, onNewPrayerData);

  const onAltarState = () => {
    _altarState = AltarStore.getAll();
    makePrayerSlotViews();

    var numPrayerSlots = _altarState.prayerSlots.length;

    if(_unlockingPrayerSlot && numPrayerSlots > _lastNumPrayerSlots) {
      ChatActions.gameNotification('prayer_slot_unlocked', {numPrayerSlots});
      _lastNumPrayerSlots = numPrayerSlots;
      _unlockingPrayerSlot = false;
    }
  };
  AltarStore.on(AltarStore.GOT_ALTAR_STATE, onAltarState);    
};
PrayerMenu.prototype = Object.create(PIXI.Container.prototype);
PrayerMenu.prototype.constructor = PrayerMenu;
export default PrayerMenu;

const std_mouseout = (btn) => {
  btn.tint = 0xffffff;

  if (!btn.is_selection) {
    btn.label.style.fill = 0xffffff;
  }

  if (btn.scale_buffer) {
    btn.scale = btn.scale_buffer;
  }
};
const std_mousedown = (btn) => {
  btn.scale_buffer = { x: btn.scale.x, y: btn.scale.y };
  btn.scale = {
    x: btn.scale.x * 0.95,
    y: btn.scale.y * 0.95
  };
};
const std_mouseup = (btn) => {
  if (btn.scale_buffer) {
    btn.scale = btn.scale_buffer;
  }
};
