import PuppetMovement from './PuppetMovement';

const KnockbackMovement = (puppet) => {
  const poses = [ 
    { pose:Pose1() },
    { pose:Pose2() }
  ];
     
  return PuppetMovement({
		poses,
    puppet,
    setPoseDurations: () => {
      poses[0].duration = 0.34;
      poses[1].duration = 0.38;
    }
  });
};
export default KnockbackMovement;

const Pose1 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -138,
      z: -66
    },        
    shoulders: {
      x: 0,
      y: -129,
      z: -42
    },        
    torsoe: {
      x: 0,
      y: -123,
      z: -20
    },        
    leftHand: {
      x: -24,
      y: -174,
      z: -20
    },    
    bowPitch: -100,
    bowYaw: -30,    
    rightHand: {
      x: 42,
      y: -144,
      z: -96
    },        
    weaponPitch: -170,
    weaponYaw: 15,        
    leftFoot: {
      x: -30,
      y: -114,
      z: 72
    },        
    rightFoot: {
      x: 20,
      y: -90,
      z: 90
    }
  };
  return pose;       
};

const Pose2 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -138,
      z: -66
    },        
    shoulders: {
      x: 0,
      y: -129,
      z: -29
    },        
    torsoe: {
      x: 0,
      y: -123,
      z: -20
    },        
    leftHand: {
      x: -24,
      y: -174,
      z: -20
    },    
    bowPitch: -100,
    bowYaw: -30,    
    rightHand: {
      x: 42,
      y: -144,
      z: -96
    },        
    weaponPitch: -170,
    weaponYaw: -15,        
    leftFoot: {
      x: -30,
      y: -114,
      z: 72
    },        
    rightFoot: {
      x: 20,
      y: -90,
      z: -90
    }
  };

  // downward travel
  for(var prop in pose) {
    if(pose[prop].y) {
      pose[prop].y += 84;
    }
  }

  return pose;       
};