import CanvasTools from '~/view/CanvasTools';
import LightningEffect from '../../../effects/LightningEffect';
import Colors from '~/constants/Colors';
import Audio from '~/Audio';

const SendManaRendering = {
  render: function(data, gameBoard, isCaverns) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const victimSprite = gameBoard.getAllPieceSprites()[data.victimId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
		const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

    render_movement && actorSprite.puppet.getMovements().magicProjectile.movePuppet({ transitTime });

    setTimeout(()=> {
      var dist = CanvasTools.distanceFromSpriteToSprite(actorSprite, victimSprite);

      for (var i = 16; i < dist; i +=32)	{
        var image = new LightningEffect(700, true);
        image.tint = Colors.BLUE;

        image.rotation = CanvasTools.angleFromSpriteToSprite(actorSprite, victimSprite);
        image.x = actorSprite.x + (victimSprite.x-actorSprite.x)*(i/dist);
        image.y = actorSprite.y + (victimSprite.y-actorSprite.y)*(i/dist);
        gameBoard.addChild(image);
      }

      Audio.play('send_mana');
      TweenMax.delayedCall(0.05, Audio.play, ['lightning_01']);
      TweenMax.delayedCall(0.15, Audio.play, ['lightning_01']);
      TweenMax.delayedCall(0.4, Audio.play, ['lightning_01']);
    }, transitTime*1000*0.5);
  },
};
export default SendManaRendering;