import CommonEffects from '../../../effects/CommonEffects';
import Audio from '~/Audio';

const SummonFaerieRendering = {
	render: function( data, gameBoard, isCaverns ) {
		const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];		
		const victimSprite = gameBoard.getAllPieceSprites()[data.victimId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
		const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

		render_movement && actorSprite.puppet.getMovements().magicSummon.movePuppet({ transitTime });

		TweenMax.delayedCall( transitTime/3, CommonEffects.makePortalEffect, [victimSprite] );

		TweenMax.delayedCall( transitTime-0.5, ()=> {
			for( var i = 0; i < 7; ++i ) {
				TweenMax.delayedCall( i*0.03, CommonEffects.makeCloudPuff, [victimSprite] );
			}

			Audio.play( 'summon_04' );
		});
	}
};
export default SummonFaerieRendering;