<template>
  <div class="no-select ml-2 pb-1 text-xs">
    {{ text('ui.players_online') }}: {{ players_online }}
  </div>
</template>

<script setup>
import { ref } from 'vue';
import ApplicationStore from '~/flux/stores/ApplicationStore';
import text from '~/text';

const players_online = ref('???');
ApplicationStore.on(ApplicationStore.NUM_PLAYERS_ONLINE, num => {
  players_online.value = num;
});
</script>

<style>
</style>
