import * as PIXI from 'pixi.js';
import HeroBase from 'dt-common/models/game_state/heroes';
import AbilityIcon from '~/components/common/AbilityIcon';
import Tooltip from '~/components/tooltips/Tooltip';

const EquippedAbilityPanel = function(built_hero) {
  PIXI.Container.call(this);

  const abilityTrees = HeroBase[built_hero.hero_handle].getAbilityTrees();

  let _tooltip;
  let icons = [];
  this.getIcons = () => icons;

  for (let i = 0; i < built_hero.equipped_abilities.length; ++i) {
    if (built_hero.equipped_abilities[i] === null) {
      icons.push(new AbilityIcon(null));
      continue;
    }

    for (let j = 0; j < abilityTrees.length; ++j) {
      if (abilityTrees[j].handle === 'standard') {
        continue;
      }

      for (let prop in abilityTrees[j].abilities) {
        const ability = abilityTrees[j].abilities[prop];
        if (ability.handle === built_hero.equipped_abilities[i]) {
          icons.push(new AbilityIcon(ability));
          continue;
        }
      }
    }
  }

  let dummy = new AbilityIcon(null);
  const ICON_SIZE = dummy.width;

  for (var i = 0; i < icons.length; ++i) {
    var icon = icons[i];
    icon.anchor.x = icon.anchor.y = 0.5;
    icon.x = ICON_SIZE/2 + i*ICON_SIZE*1.1;
    icon.y = ICON_SIZE/2;

    const ability = icon.getAbility();

    if (ability) {
      icon.interactive = true;
      icon.buttonMode = true;

      icon.mouseover = icon.pointerover = (e) => {
        const icon = e.target;

        if (_tooltip) {
          _tooltip.dispose();
        }
        _tooltip = new Tooltip(
          'ability',
          {
            ability_handle: ability.handle,
          },
          icon,
        );
      };
      icon.mouseout = icon.pointerout = () => {
        if (_tooltip) {
          _tooltip.dispose();
        }
      };
    }

    // making sure they are all the same size
    dummy = new AbilityIcon(null);
    dummy.alpha = 0.01;
    dummy.anchor.x = dummy.anchor.y = 0.5;
    icon.addChildAt(dummy, 0);

    this.addChild(icon);
  }

  this.dispose = () => {
    TweenMax.killTweensOf(this);
    if (icons) {
      for (const icon of icons) {
        icon.mouseover = icon.pointerover = null;
        icon.mouseout = icon.pointerout = null;
        icon.dispose();
      }
    }

    if (_tooltip) {
      _tooltip.dispose();
    }

    this.destroy();
  };
};
EquippedAbilityPanel.prototype = Object.create(PIXI.Container.prototype);
EquippedAbilityPanel.prototype.constructor = EquippedAbilityPanel;
export default EquippedAbilityPanel;
