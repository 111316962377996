import * as PIXI from 'pixi.js';
import Audio from '~/Audio';
import Colors from '~/constants/Colors';
import LightFromAbove from '../../LightFromAbove';

const SanctuaryRendering = {
  render: function(data, gameBoard, isCaverns) {

    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
		const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

    render_movement && actorSprite.puppet.getMovements().magicSummon.movePuppet({ transitTime });

    TweenMax.delayedCall(transitTime/2, LightFromAbove, [actorSprite]);
  },

  renderResult: function(data, gameBoard) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];

    var numBeams = 70 + Math.round(Math.random()*30);
    for (var i = 0; i < numBeams; ++i) {
      var beam = new PIXI.Graphics();
      beam.beginFill(Colors.BRIGHT_YELLOW, 0.8+Math.random()*0.2);
      beam.drawRect(-0.5, -0.5, 1, 1);
      beam.endFill();
      TweenMax.to(beam.scale, 0.2+Math.random()*0.5, { y: 25+Math.random()*45/*,yoyo:true,repeat:1*/ }).delay(i*0.003);
      beam.x = -actorSprite.tileImg.width + Math.random()*actorSprite.tileImg.width*2;
      beam.y = -actorSprite.tileImg.height + Math.random()*actorSprite.tileImg.height*2;
      TweenMax.to(beam, 0.2+Math.random()*0.5, { y: beam.y-30-Math.random()*30,alpha: 0 }).delay(i*0.003);
      gsap.delayedCall(0.8, (b) => {
        b.parent?.removeChild(b);
      }, [beam]);
      actorSprite.tileImg.addChild(beam);
    }

    Audio.play('burning_hands');
  },
};
export default SanctuaryRendering;