import * as PIXI from 'pixi.js';
import Colors from '~/constants/Colors';
import Audio from '~/Audio';
import { render } from 'vue';

const InfectMindRendering = {
  render: function (data, gameBoard, isCaverns) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const victimSprite = gameBoard.getAllPieceSprites()[data.victimId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
    const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

    if (!data.reflection && render_movement) {
      actorSprite.puppet
        .getMovements()
        .magicProjectile.movePuppet({ transitTime });
    }
  },

  renderResult: function (data, gameBoard, isCaverns) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const victimSprite = gameBoard.getAllPieceSprites()[data.victimId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
    const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

    TweenMax.delayedCall(transitTime * 0.2, Audio.play, ['magic_wave']);
    TweenMax.delayedCall(transitTime * 0.3, Audio.play, ['antidote_02']);

    const makeEffect = () => {
      var cloud = new PIXI.Sprite();
      cloud.texture = PIXI.utils.TextureCache['effects/cloud.png'];
      cloud.anchor.x = cloud.anchor.y = 0.5;
      cloud.tint = Colors.HP_RED;
      cloud.y = isCaverns ? -5 : -50;
      cloud.scale = { x: 0.3, y: 0.3 };

      const ownerSprite = victimSprite;
      var partToAddEffectTo =
        ownerSprite.puppet?.getParts()?.head ?? ownerSprite.tileImg;
      partToAddEffectTo.addChild(cloud);

      TweenMax.to(cloud.scale, 0.8, {
        x: isCaverns ? 1 : 8,
        y: isCaverns ? 1 : 8,
      });
      TweenMax.to(cloud, 0.876, {
        y: isCaverns ? -25 + Math.random() * 15 : -250 + Math.random() * 150,
        rotation: (-120 + Math.random() * 240) * (Math.PI / 180),
        alpha: 0,
        ease: Quad.easeOut,
        onComplete: (c) => c.parent?.removeChild(c),
        onCompleteParams: [cloud],
      });
    };

    TweenMax.delayedCall(transitTime / 2, () => {
      for (var i = 0; i < 15; ++i) {
        TweenMax.delayedCall(0.09 * i, makeEffect);
      }
    });
  },
};
export default InfectMindRendering;
