import * as PIXI from 'pixi.js';
import PinwheelPanel from '~/components/common/PinwheelPanel';
import text from '~/text';
import CanvasTools from '~/view/CanvasTools';
import AbilityElement from './AbilityElement';

const AbilityTreePanel = function({ abilityTree, hero_handle }) {
  const pinwheel = new PinwheelPanel();

  pinwheel.abilityElements = [];
  pinwheel.abilityTree = abilityTree;
  pinwheel.pinwheelDelay = 0.15;
  pinwheel.radius = 95;

  var frame = new PIXI.Sprite();
  frame.texture = PIXI.utils.TextureCache['armory/ability_ring.png'];
  frame.anchor.x = frame.anchor.y = 0.5;
  pinwheel.addChild(frame);

  // make the level-1 AbilityIcon in the center, not a pinwheel object
  var l1Ability;
  Object.keys(abilityTree.abilities).forEach((prop) => {
    if (abilityTree.abilities[prop].unlockLevel === 1) {
      l1Ability = abilityTree.abilities[prop];
    }
  });

  var l1ae = pinwheel.level1AbilityElement = new AbilityElement({
    ability: l1Ability,
    hero_handle,
  });
  // TweenMax.from( l1ae.scale, 2.5+Math.random()*1.0, {x:0, y:0, ease:Elastic.easeOut} );
  l1ae.pivot.x = l1ae.pivot.y = 0.5;
  pinwheel.addChild(l1ae);
  pinwheel.abilityElements.push(l1ae);

  // populate our array of pinwheel objects
  Object.keys(abilityTree.abilities).forEach((prop) => {
    // skipping the level-1 ability, since we made a special AbilityElement for that
    if (abilityTree.abilities[prop].unlockLevel > 1) {
      var ae = new AbilityElement({
        ability: abilityTree.abilities[prop],
        hero_handle,
      });
      ae.pivot.x = ae.pivot.y = 0.5;
      pinwheel.addChild(ae);

      pinwheel.pinwheelObjects.push(ae);
      pinwheel.abilityElements.push(ae);
    }
  });

  pinwheel.init();

  pinwheel.makeTitle = function(yPos) {
    var title = new PIXI.Text(text('ability_trees.'+pinwheel.abilityTree.handle+'.name'), {
      fontFamily: 'Courier New',
      fontSize: CanvasTools.dynamicFontSizeString(16),
      fill: 0xffffff,
      align: 'center',
      dropShadow: true,
    });

    title.x = Math.round(pinwheel.width*0.47);
    title.y = Math.round(yPos + title.height*3.2);
    pinwheel.addChild(title);
  };

  pinwheel.disposeCallback = () => {
    for (const ability_element of pinwheel.abilityElements) {
      ability_element.dispose();
    }
    pinwheel.abilityElements = null;
    pinwheel.level1AbilityElement.dispose();
    pinwheel.level1AbilityElement = null;
  };

  return pinwheel;
};
export default AbilityTreePanel;
