import ChatDispatcher from '~/flux/dispatchers/ChatDispatcher';

var ChatActions = {
  enterMessage: function(text) {
    ChatDispatcher.handleChatAction({
      actionType: ChatDispatcher.ENTER_MESSAGE,
      text
    });
  },

  setChatTextColor: function(color) {
    ChatDispatcher.handleChatAction({
      actionType: ChatDispatcher.SET_CHAT_TEXT_COLOR,
      color
    });
  },

  gameNotification: function(type, data) {
    ChatDispatcher.handleChatAction({
      actionType: ChatDispatcher.GAME_NOTIFICATION,
      type,
      data
    });
  }
};
export default ChatActions;
