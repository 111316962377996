import PuppetMovement from './PuppetMovement';

var MagicUpGentleMovement = (puppet) => {
  const poses = [ 
    { pose:Pose1() },
    { pose:Pose3() },
    { pose:Pose4() },
    { pose:Pose4a() },
    { pose:Pose5() },
    { pose:Pose5() }
  ];

  return PuppetMovement({
		poses,
    puppet,
    setPoseDurations: (actorCooldown) => {
      const transTime = actorCooldown/9;

      poses[0].duration = transTime*3;
      poses[1].duration = transTime;
      poses[2].duration = transTime;
      poses[3].duration = transTime;
      poses[4].duration = transTime;
      poses[5].duration = transTime*2;
    }
  });
};
export default MagicUpGentleMovement;

const Pose1 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -160,
      z: 0
    },        
    shoulders: {
      x: 0,
      y: -144,
      z: 0
    },        
    torsoe: {
      x: 0,
      y: -105,
      z: 0
    },        
    leftHand: {
      x: -47,
      y: -86,
      z: 42
    },    
    bowPitch: 15,
    bowYaw: -60,    
    rightHand: {
      x: 47,
      y: -86,
      z: 42
    },        
    weaponPitch: 15,
    weaponYaw: 60,        
    leftFoot: {
      x: -22,
      y: 0,
      z: 10
    },        
    rightFoot: {
      x: 22,
      y: 0,
      z: -5
    }
  };

  return pose;       
};

const Pose3 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -160,
      z: 0
    },        
    shoulders: {
      x: 0,
      y: -144,
      z: 0
    },        
    torsoe: {
      x: 0,
      y: -105,
      z: 0
    },        
    leftHand: {
      x: -12,
      y: -96,
      z: 28
    },    
    bowPitch: 0,
    bowYaw: 0,    
    rightHand: {
      x: 12,
      y: -96,
      z: 28
    },        
    weaponPitch: 0,
    weaponYaw: 0,        
    leftFoot: {
      x: -22,
      y: 0,
      z: 10
    },        
    rightFoot: {
      x: 22,
      y: 0,
      z: -5
    }
  };

  return pose;       
};

const Pose4 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -160,
      z: 0
    },        
    shoulders: {
      x: 0,
      y: -144,
      z: 0
    },        
    torsoe: {
      x: 0,
      y: -105,
      z: 0
    },        
    leftHand: {
      x: -21,
      y: -115,
      z: 28
    },    
    bowPitch: 0,
    bowYaw: 0,    
    rightHand: {
      x: 21,
      y: -115,
      z: 28
    },        
    weaponPitch: 0,
    weaponYaw: 0,        
    leftFoot: {
      x: -22,
      y: 0,
      z: 10
    },        
    rightFoot: {
      x: 22,
      y: 0,
      z: -5
    }
  };

  return pose;       
};

const Pose4a = function() {
  const pose =  {
    head: {
      x: 0,
      y: -160,
      z: 0
    },        
    shoulders: {
      x: 0,
      y: -144,
      z: 0
    },        
    torsoe: {
      x: 0,
      y: -105,
      z: 0
    },        
    leftHand: {
      x: -31,
      y: -127,
      z: 28
    },    
    bowPitch: 0,
    bowYaw: -26,    
    rightHand: {
      x: 31,
      y: -127,
      z: 28
    },        
    weaponPitch: 0,
    weaponYaw: 26,        
    leftFoot: {
      x: -22,
      y: 0,
      z: 10
    },        
    rightFoot: {
      x: 22,
      y: 0,
      z: -5
    }
  };

  return pose;       
};

const Pose5 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -160,
      z: 0
    },        
    shoulders: {
      x: 0,
      y: -144,
      z: 0
    },        
    torsoe: {
      x: 0,
      y: -105,
      z: 0
    },        
    leftHand: {
      x: -48,
      y: -138,
      z: 52
    },    
    bowPitch: -15,
    bowYaw: -52,    
    rightHand: {
      x: 48,
      y: -138,
      z: 52
    },        
    weaponPitch: -15,
    weaponYaw: 52,        
    leftFoot: {
      x: -22,
      y: 0,
      z: 10
    },        
    rightFoot: {
      x: 22,
      y: 0,
      z: -5
    }
  };

  return pose;       
};