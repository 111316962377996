import { EventEmitter } from 'events';

import Chat from 'dt-common/constants/Chat';
import { awaitSocket, registerDispatchHandlers } from '~/Tools';
import PlayerDispatcher from '~/flux/dispatchers/PlayerDispatcher';
import ChatDispatcher from '~/flux/dispatchers/ChatDispatcher';
import text, { getChatCommandEmote } from '~/text';
import Colors from '~/constants/Colors';

const { GENERAL_COMMANDS, FREE_EMOTES } = Chat;
const NOTIFICATION_COLOR = '#d7d00e';
const DEFAULT_GENDER = { subject: 'they', object: 'them', possessive: 'their' };

let _socket;
let playerId;

// the stuff we serve
const userObject = {
  displayName: null,
  gender: DEFAULT_GENDER,
  chatTextColor: '#ffffff',
};
const messageCache = {
  global: [
    {
      actor: 'Wakefield Studios',
      text: text('ui.welcome_msg'),
      color: NOTIFICATION_COLOR,
    },
    { text: text('ui.join_discord_msg'), color: NOTIFICATION_COLOR },
    { text: text('chat.help_msg'), color: NOTIFICATION_COLOR },
    { line_break: true },
  ],
};
let currentChannel = 'global';

const ChatStore = Object.assign({}, EventEmitter.prototype, {
  SUBSCRIBED_TO_CHANNEL: 'SUBSCRIBED_TO_CHANNEL',
  UNSUBSCRIBED_FROM_CHANNEL: 'UNSUBSCRIBED_FROM_CHANNEL',
  GOT_MESSAGE_EVENT: 'GOT_MESSAGE_EVENT',
  SHOW_CHAT_TEXT_COLOR_PICKER: 'SHOW_CHAT_TEXT_COLOR_PICKER',

  getAll() {
    return {
      userObject,
      messageCache,
      currentChannel,
    };
  },
});
export default ChatStore;

PlayerDispatcher.register(
  registerDispatchHandlers({
    [PlayerDispatcher.PLAYER_LOGGED_IN]: onPlayerLoggedIn,
  })
);
ChatDispatcher.register(
  registerDispatchHandlers({
    [ChatDispatcher.ENTER_MESSAGE]: onMessageEntered,
    [ChatDispatcher.SET_CHAT_TEXT_COLOR]: requestChatTextColorChange,
    [ChatDispatcher.GAME_NOTIFICATION]: onGameNotification,
  })
);

awaitSocket(onSocketConnected);
// awaitSocket().then(onSocketConnected);
function onSocketConnected(socket) {
  _socket = socket;

  if (!_socket.has_ChatStore_listeners) {
    _socket.on('chat_text', onChatText);
    _socket.on('chat_emote', onChatEmote);
    _socket.on('invalid_emote', onInvalidEmote);
    _socket.on('newPlayerName', onNewPlayerName);
    _socket.on('invalid_gender_pronoun', onInvalidGenderPronoun);
    _socket.on('updated_gender', onGenderUpdated);
    _socket.on('chatTextColorChanged', onChatTextColorChanged);
    _socket.on('online_player_names', onOnlinePlayerNames);
    _socket.on(
      'player_entered_standard_arena_matchmaking_queue',
      onMatchmakingNotif
    );
    _socket.on('player_silenced', onPlayerSilenced);
    _socket.on('player_unsilenced', onPlayerUnsilenced);

    _socket.has_ChatStore_listeners = true;
  }
}

function onPlayerLoggedIn(action) {
  try {
    const { player } = action;
    const {
      scriptData: { displayName, chat },
    } = player;

    playerId = player._id;
    userObject.displayName = displayName || 'New Player';
    userObject.gender = chat ? chat.gender || DEFAULT_GENDER : DEFAULT_GENDER;
    userObject.chatTextColor = chat ? chat.textColor || '#ffffff' : '#ffffff';

    // join the global channel automatically
    _socket.emit('subscribe_chat_channel', {
      playerId,
      channel: 'global',
    });
  } catch (err) {
    logError(err, {
      module: 'ChatStore',
      func: 'onPlayerLoggedIn',
      action,
    });
  }
}

function onMessageEntered(action) {
  try {
    const player_has_display_name =
      userObject.displayName && userObject.displayName !== 'New Player';

    if (action.text[0] === '/') {
      const command = action.text.split(' ')[0];
      const target = action.text.slice(command.length + 1);

      switch (command) {
        case '/challenge':
          // this is handled by the ArenaLobbyStore - return out here so we don't default to sending an emote
          return;

        case '/color':
          ChatStore.emit(ChatStore.SHOW_CHAT_TEXT_COLOR_PICKER);
          return;

        case '/emotes':
          addLineBreak();
          pushToLog(currentChannel, {
            text: text('chat.your_emotes'),
            notification: true,
            color: NOTIFICATION_COLOR,
          });
          for (const command of FREE_EMOTES) {
            pushToLog(currentChannel, {
              text: command,
              color: NOTIFICATION_COLOR,
            });
          }
          addLineBreak();
          return;

        case '/gender':
          let parsed_target =
            target === 'male'
              ? 'he/him/his'
              : target === 'female'
                ? 'she/her/her'
                : target;

          const [subject, object, possessive] = parsed_target.split('/');
          if (
            typeof subject !== 'string' ||
            subject.length > 7 ||
            typeof object !== 'string' ||
            object.length > 7 ||
            typeof possessive !== 'string' ||
            possessive.length > 7
          ) {
            for (const msg of text('ui.invalid_gender_input')) {
              $addMessageLogMessage(msg, Colors.RED);
            }
            return;
          }

          _socket.emit('set_gender_pronouns', {
            playerId,
            subject,
            object,
            possessive,
          });
          return;

        case '/help':
          addLineBreak();
          const info_text = text('chat.help_info');
          for (let i = 0; i < info_text.length; ++i) {
            pushToLog(currentChannel, {
              text: info_text[i],
              notification: i === 0,
              color: NOTIFICATION_COLOR,
            });
          }
          addLineBreak();
          return;

        case '/online':
          _socket.emit('get_online_player_names', { playerId });
          return;

        case '/silence':
          _socket.emit('silence_player', { playerId, target });
          return;

        case '/unsilence':
          _socket.emit('unsilence_player', { playerId, target });
          return;

        default:
          if (!player_has_display_name) {
            pushToLog(currentChannel, {
              text: text('chat.make_a_username'),
              notification: true,
              color: NOTIFICATION_COLOR,
            });
            return;
          }

          if (FREE_EMOTES.includes(command)) {
            if (target.length > 20) {
              onInvalidEmote({ message: 'Emote target too long.' });
              return;
            }

            _socket.emit('publish_chat_emote', {
              playerId,
              channel: currentChannel,
              command,
              target_displayName: target,
            });
            return;
          }

          _socket.emit('chatCommand', {
            playerId,
            command: action.text,
          });
          return;
      }
    } else if (player_has_display_name) {
      _socket.emit('publish_chat_text', {
        playerId,
        channel: currentChannel,
        text: action.text,
      });
    } else {
      pushToLog(currentChannel, {
        text: text('chat.make_a_username'),
        notification: true,
        color: NOTIFICATION_COLOR,
      });
    }
  } catch (err) {
    logError(err, {
      module: 'ChatStore',
      func: 'onMessageEntered',
      action,
    });
  }
}

function onChatText(data) {
  try {
    const { channel, actor, text, timestamp } = data;

    pushToLog(channel, {
      actor: actor.displayName,
      color: actor.textColor,
      text,
      timestamp,
    });
  } catch (err) {
    logError(err, {
      module: 'ChatStore',
      func: onChatMessage,
      data,
    });
  }
}

function onChatEmote(data) {
  try {
    const { channel, command, actor, target_displayName } = data;

    const emote_text = getChatCommandEmote(command, actor, target_displayName);

    pushToLog(channel, {
      color: actor.textColor,
      emote: true,
      text: emote_text,
    });
  } catch (err) {
    logError(err, {
      module: 'ChatStore',
      func: 'onChatEmote',
      data,
    });
  }
}

function onInvalidEmote(data) {
  $addMessageLogMessage(
    `${text('ui.invalid_emote_command')}: ${data.message}`,
    Colors.RED
  );
}

function onInvalidGenderPronoun(data) {
  $addMessageLogMessage(
    `${text('ui.invalid_gender_input')[0]}: ${data.message}`,
    Colors.RED
  );
}

function onGenderUpdated(data) {
  try {
    userObject.gender = data.gender;

    pushToLog(currentChannel, {
      text: text('chat.gender_changed')(data.gender),
      color: NOTIFICATION_COLOR,
    });
  } catch (err) {
    logError(err, {
      module: 'ChatStore',
      func: 'onGenderUpdated',
      data,
    });
  }
}

function pushToLog(channel, message) {
  messageCache[channel].push(message);
  ChatStore.emit(ChatStore.GOT_MESSAGE_EVENT);
}

function onNewPlayerName({ newPlayerName }) {
  userObject.displayName = newPlayerName || 'New Player';
}

function requestChatTextColorChange(action) {
  _socket.emit('setChatTextColor', {
    playerId,
    color: action.color,
  });
}

function onChatTextColorChanged(data) {
  userObject.chatTextColor = data.newColor;
  pushToLog(currentChannel, {
    text: text('ui.chat_color_change_success'),
    notification: true,
    color: data.newColor,
  });
}

function onOnlinePlayerNames(data) {
  try {
    let log_text = '';
    for (const name of data.online_player_names) {
      log_text += `${name}, `;
    }
    log_text = log_text.slice(0, log_text.length - 2); // snip the trailing comma

    const anonymous_text = data.num_anonymous
      ? ` ... and ${data.num_anonymous} Anonymous ...`
      : '';

    pushToLog(currentChannel, {
      text: `${text('chat.players_online')}: ${log_text}${anonymous_text}`,
      color: NOTIFICATION_COLOR,
    });
  } catch (err) {
    logError(err, {
      module: 'ChatStore',
      func: 'onOnlinePlayerNames',
      data,
    });
  }
}

function onGameNotification(action) {
  // publish({
  //     notification: true,
  //     text: text.getGameNotificationText( userObject.displayName, action.type, action.data ),
  //     color: NOTIFICATION_COLOR
  // });
}

function addLineBreak(num = 1, color = NOTIFICATION_COLOR) {
  for (let i = 0; i < num; ++i) {
    pushToLog(currentChannel, { line_break: true });
  }
}

function onMatchmakingNotif({ game_submode, player_display_name, timestamp }) {
  try {
    pushToLog('global', {
      color: NOTIFICATION_COLOR,
      notification: true,
      text: text('chat.notifications.matchmaking_queue')({
        game_submode,
        player_display_name,
      }),
      timestamp,
    });
  } catch (err) {
    logError(err, {
      module: 'ChatStore',
      func: 'onMatchmakingNotif',
      game_submode,
      player_display_name,
    });
  }
}

function onPlayerSilenced({ target }) {
  try {
    $addMessageLogMessage(`${target} has been silenced.`, Colors.GREEN);
  } catch (err) {
    logError(err, {
      module: 'ChatStore',
      func: 'onPlayerSilenced',
      target,
    });
  }
}

function onPlayerUnsilenced({ target }) {
  try {
    $addMessageLogMessage(`${target} has been unsilenced.`, Colors.GREEN);
  } catch (err) {
    logError(err, {
      module: 'ChatStore',
      func: 'onPlayerSilenced',
      target,
    });
  }
}
