import * as PIXI from 'pixi.js';
import Game from 'dt-common/constants/Game';
import getNumHeroesInSubmode from 'dt-common/isomorphic-helpers/getNumHeroesInSubmode';
import Tooltip from '~/components/tooltips/Tooltip';
import { UIActions } from '~/flux/actions';
import { GameStateStore, UIStore } from '~/flux/stores';
import text from '~/text';
import CanvasTools from '~/view/CanvasTools';

const { GAME_MODES, GAME_SUBMODES } = Game;

const GameSubmodeSelector = function() {
  PIXI.Container.call(this);

  let _buttons;
  let _tooltip;

  this.transitionIn = () => {
    TweenMax.from(this, 0.5, { alpha: 0 });
  };

  this.transitionOut = () => {
    TweenMax.to(this, 0.2, { y: -this.height, alpha: 0 });
  };

  this.muteButtons = () => {
    for (const b of _buttons) {
      b.buttonMode = false;
      b.tap = b.click = null;
    }
  }

  this.unmuteButtons = () => {
    makeButtons();
  }

  const destroyButtons = () => {
    if (_buttons) {
      for (const btn of _buttons) {
        btn.txt.destroy();
        btn.click = btn.tap = null;
        btn.mouseover = btn.pointerover = null;
        btn.mouseout = btn.pointerout = null;
        this.removeChild(btn);
      }
      _buttons = null;
    }
  };

  this.dispose = () => {
    destroyButtons();
    _tooltip?.dispose();
    _tooltip = null;
    this.removeChildren();
    this.parent?.removeChild(this);
    UIStore.removeListener(UIStore.GAME_MODE_SELECTION, makeButtons);
    UIStore.removeListener(UIStore.GAME_SUBMODE_SELECTION, makeButtons);
    GameStateStore.removeListener(GameStateStore.HERO_UNLOCKED, makeButtons);
  };

  const makeButton = (game_submode_key) => {
    destroyButtons();
    const { loadouts } = GameStateStore.getAll().gameState;
    const { current_game_mode, current_game_submode } = UIStore.getAll();

    const num_engaged_heroes = loadouts[current_game_mode][game_submode_key].filter(({ engagement }) => !!engagement).length;
    const submode_locked = num_engaged_heroes < getNumHeroesInSubmode(game_submode_key);
    const is_selected = current_game_submode === game_submode_key;

    const style = {
      fontFamily: 'Courier New',
      fontSize: CanvasTools.dynamicFontSizeString(12),
      fontStyle: 'bold',
      fill: submode_locked
        ? 0x000000
        : is_selected
          ? 0x000000
          : 0xffffff,
    };

    let btn = new PIXI.Container();
    btn.gameSubmode = game_submode_key;
    let txt = btn.txt = new PIXI.Text(text(`ui.game_submodes.${game_submode_key}`), style);
    txt.x = Math.round(DT_CANVAS_GLOBALS.spacing/2);
    txt.y = Math.round(DT_CANVAS_GLOBALS.spacing/2);
    btn.addChild(txt);

    btn._width = btn.width + DT_CANVAS_GLOBALS.spacing;
    btn._height = btn.height + DT_CANVAS_GLOBALS.spacing;
    CanvasTools.addBackFill(btn, is_selected ? 0xffffff : 0x000000);
    CanvasTools.addBorder(
      btn,
      'chat_border_horiz.png',
      'chat_border_vert.png',
      'chat_border_horiz.png',
      'chat_border_vert.png',
      0
    );
    btn.addChild(txt); // ensure on top

    if (submode_locked) {
      const lock_icon = new PIXI.Sprite();
      lock_icon.texture = PIXI.utils.TextureCache['armory/lock.png'];
      lock_icon.height = btn.height * 1.4;
      lock_icon.scale.x = lock_icon.scale.y;
      lock_icon.x = btn.width / 2 - lock_icon.width / 2;
      lock_icon.y = btn.height / 2 - lock_icon.height * 0.45;
      btn.addChild(lock_icon);

      // tooltips
      btn.interactive = true;
      btn.mouseover = btn.pointerover = ((btn) => {
        _tooltip?.dispose();
        _tooltip = new Tooltip(
          'ui',
          {
            body_text: text(`ui.game_submodes.${btn.gameSubmode}`),
          },
          btn,
        );
      }).bind(null, btn);
      btn.mouseout = btn.pointerout = () => _tooltip?.dispose();
    } else {
      btn.interactive = btn.buttonMode = true;
      btn.click = btn.tap = onBtnClick;
    }

    return btn;
  };

  const makeButtons = () => {
    destroyButtons();

    _buttons = UIStore.getAll().current_game_mode === GAME_MODES.GAME_MODE_caverns
      ? [
        makeButton(GAME_SUBMODES.GAME_SUBMODE_caverns1man),
        makeButton(GAME_SUBMODES.GAME_SUBMODE_caverns2man),
        makeButton(GAME_SUBMODES.GAME_SUBMODE_caverns3man),
        makeButton(GAME_SUBMODES.GAME_SUBMODE_caverns5man),
        makeButton(GAME_SUBMODES.GAME_SUBMODE_caverns7man),
        // makeButton(GAME_SUBMODES.GAME_SUBMODE_caverns11man), // TODO
      ]
      : [
        makeButton(GAME_SUBMODES.GAME_SUBMODE_pvp1v1),
        makeButton(GAME_SUBMODES.GAME_SUBMODE_pvp2v2),
        makeButton(GAME_SUBMODES.GAME_SUBMODE_pvp3v3),
        makeButton(GAME_SUBMODES.GAME_SUBMODE_pvp5v5),
        makeButton(GAME_SUBMODES.GAME_SUBMODE_pvp7v7),
      ];

    for (let i = 0; i < _buttons.length; ++i) {
      const btn = _buttons[i];
      btn.x = Math.round(btn.width * 1.1 * i);
      btn.y = Math.round(DT_CANVAS_GLOBALS.spacing * 2);
      this.addChild(btn);
    }
  };
  makeButtons();

  function onBtnClick(event) {
    UIActions.selectGameSubmode(event.target.gameSubmode);
  }

  UIStore.on(UIStore.GAME_MODE_SELECTION, makeButtons);
  UIStore.on(UIStore.GAME_SUBMODE_SELECTION, makeButtons);
  GameStateStore.on(GameStateStore.HERO_UNLOCKED, makeButtons);
};
GameSubmodeSelector.prototype = Object.create(PIXI.Container.prototype);
GameSubmodeSelector.prototype.constructor = GameSubmodeSelector;
export default GameSubmodeSelector;
