<template>
  <div
    id="inn_tourney_progress_view_root"
    class="relative bg-white/10"
  >
    <canvas
      id="peanuts_canvas"
      class="absolute z-0 w-full h-full"
    ></canvas>

    <div
      id="progress_bar"
      class="absolute bottom-0 w-full bg-white/30"
      :style="`height: ${state.progress_bar_height}px;`"
    ></div>

    <hr
      ref="upgrade_threshold_marker"
      class="absolute w-full border-2 border-green-700/70 hover:border-green-500/70"
      :style="`bottom: ${state.upgrade_tier_line_height}px;`"
    />
    <hr
      ref="maintain_threshold_marker"
      class="absolute w-full border-2 border-yellow-400/70 hover:border-yellow-300/70"
      :style="`bottom: ${state.maintain_tier_line_height}px;`"
    />
    <hr
      ref="downgrade_threshold_marker"
      class="absolute bottom-0 w-full border-2 border-gray-700/70 hover:border-red-500/70"
    />
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, reactive } from 'vue';
import Balance from 'dt-common/constants/Balance';
import CanvasTools from '~/view/CanvasTools';

const MAX_TIER_PEANUTS = Balance.getInnUpgradeConditionByTier(
  Balance.MAX_INN_TOURNEY_TIER
);

let _current_num_peanuts;
let _ms_elapsed_since_last_rank_change;
let _now;
let _update_interval;

const props = defineProps({
  submode_state: {
    type: Object,
    required: true,
  },
});

const state = reactive({
  progress_bar_height: 0,
  maintain_tier_line_height: 1,
  upgrade_tier_line_height: 2,
});

onMounted(() => {
  _update_interval = setInterval(update, 1000);
  update();
});

onBeforeUnmount(() => {
  clearInterval(_update_interval);
});

function update() {
  const { current_rank, current_tier, last_num_peanuts, last_rank_change_at } =
    props.submode_state;

  // calc & render thresholds
  _now = Date.now();
  _ms_elapsed_since_last_rank_change = _now - last_rank_change_at;
  _current_num_peanuts =
    last_num_peanuts +
    _ms_elapsed_since_last_rank_change *
      Balance.getInnPeanutsPerMSByRank(current_rank);

  const { height } = CanvasTools.getDomAnchor('inn_tourney_progress_view_root');
  state.progress_bar_height = Math.min(
    height,
    height * (_current_num_peanuts / MAX_TIER_PEANUTS) // state.upgrade_condition)
  );

  state.maintain_tier_line_height =
    height *
    (Balance.getInnMaintainConditionByTier(current_tier) / MAX_TIER_PEANUTS); // state.upgrade_condition);

  state.upgrade_tier_line_height =
    height *
    (Balance.getInnUpgradeConditionByTier(current_tier) / MAX_TIER_PEANUTS);
}
</script>
