import { EventEmitter } from 'events'
import axios from 'axios'
import PlayerDispatcher from '~/flux/dispatchers/PlayerDispatcher'
import LeaderboardDispatcher from '~/flux/dispatchers/LeaderboardDispatcher'
import { awaitSocket, registerDispatchHandlers } from '~/Tools'

let playerId
let _socket

// the stuff we serve:
let entries
let matchType
let playerLocation = null

const LeaderboardStore = Object.assign({}, EventEmitter.prototype, {
  GOT_LEADERBOARD: 'GOT_LEADERBOARD',
  LOCATION_REGISTERED: 'LOCATION_REGISTERED',

  getAll() {
    return {
      entries,
      matchType,
      playerLocation
    }
  }
})
export default LeaderboardStore

PlayerDispatcher.register(registerDispatchHandlers({
  [PlayerDispatcher.PLAYER_LOGGED_IN]: onPlayerLoggedIn
}))
LeaderboardDispatcher.register(registerDispatchHandlers({
  [LeaderboardDispatcher.GET_LEADERBOARD]: getLeaderboard,
  [LeaderboardDispatcher.USE_LOCATION]: useLocation
}))

awaitSocket(onSocketConnected);
// awaitSocket().then(onSocketConnected);
function onSocketConnected(socket) {
  try {
    _socket = socket
    if (!_socket.has_LeaderboardStore_listeners) {
      _socket.on('got_leaderboard', onLeaderboard)
      _socket.on('locationRegistered', onLocationRegistered)
    }
  } catch (err) {
    logError(err, {
      module: 'LeaderboardStore',
      func: 'onSocketConnected'
    })
  }
}

function onPlayerLoggedIn(action) {
  try {
    const { player } = action

    playerId = player._id
    playerLocation = player.privateData.location
  } catch (err) {
    logError(err, {
      module: 'LeaderboardStore',
      func: 'onPlayerLoggedIn',
      action
    })
  }
}

function getLeaderboard(action) {
  _socket.emit('get_leaderboard', {
    playerId,
    ...action,
  })
}

function onLeaderboard(data) {
  LeaderboardStore.emit(LeaderboardStore.GOT_LEADERBOARD, data);
}

function useLocation() {
  try {
    navigator.geolocation.getCurrentPosition(async (position) => {
      // IP geolocation
      const { data: { ip } } = await axios.get('https://jsonip.com/')
      _socket.emit('registerLocation', {
        playerId,
        ip
      })
    })
  } catch (err) {
    logError(err, {
      module: 'LeaderboardStore',
      func: 'useLocation'
    })
  }
}

function onLocationRegistered(data) {
  try {
    playerLocation = data.location || null
    LeaderboardStore.emit(LeaderboardStore.LOCATION_REGISTERED)
  } catch (err) {
    logError(err, {
      module: 'LeaderboardStore',
      func: 'onLocationRegistered'
    })
  }
}
