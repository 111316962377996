import { ArenaLobbyDispatcher } from '~/flux/dispatchers';
import { UIStore } from '~/flux/stores';

const ArenaLobbyActions = {
  enterStandardMatchmakingQueue: () => {
    ArenaLobbyDispatcher.handleArenaLobbyAction({
      actionType: ArenaLobbyDispatcher.ENTER_STANDARD_MATCHMAKING_QUEUE,
      game_submode: UIStore.getAll().current_game_submode,
    });
  },

  selectHeroForSlot: function(matchType, heroHandle, teamIndex) {
    ArenaLobbyDispatcher.handleArenaLobbyAction({
      actionType: ArenaLobbyDispatcher.SELECT_HERO_FOR_SLOT,
      matchType,
      heroHandle,
      teamIndex,
    });
  },

  getQuickMatchup: function(matchType, onlineOnly) {
    ArenaLobbyDispatcher.handleArenaLobbyAction({
      actionType: ArenaLobbyDispatcher.GET_QUICK_MATCHUP,
      matchType,
      onlineOnly,
    });
  },

  showChallengeByNameDialog: function() {
    ArenaLobbyDispatcher.handleArenaLobbyAction({
      actionType: ArenaLobbyDispatcher.SHOW_CHALLENGE_BY_NAME_DIALOG,
    });
  },

  challengePlayerByName: function(playerName, matchType) {
    ArenaLobbyDispatcher.handleArenaLobbyAction({
      actionType: ArenaLobbyDispatcher.CHALLENGE_PLAYER_BY_NAME,
      playerName,
      matchType,
    });
  },

  setMatchType: function(matchType) {
    ArenaLobbyDispatcher.handleArenaLobbyAction({
      actionType: ArenaLobbyDispatcher.SET_MATCH_TYPE,
      matchType,
    });
  },

  initChallenge: function(matchType, opponent, fightLive, myDisplayName) {
    ArenaLobbyDispatcher.handleArenaLobbyAction({
      actionType: ArenaLobbyDispatcher.INIT_CHALLENGE,
      matchType,
      opponent,
      fightLive,
      myDisplayName,
    });
  },

  acceptLiveChallenge: function() {
    ArenaLobbyDispatcher.handleArenaLobbyAction({
      actionType: ArenaLobbyDispatcher.ACCEPT_LIVE_CHALLENGE,
    });
  },

  fetchLeaderboardRank: (options = {}) => {
    const { current_game_mode, current_game_submode } = UIStore.getAll();
    ArenaLobbyDispatcher.handleArenaLobbyAction({
      actionType: ArenaLobbyDispatcher.FETCH_LEADERBOARD_RANK,
      options,
      game_mode: current_game_mode,
      game_submode: current_game_submode,
    });
  },
};
export default ArenaLobbyActions;
