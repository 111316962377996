import * as PIXI from 'pixi.js';
import Tooltip from '~/components/tooltips/Tooltip';
import { Colors, Screens } from '~/constants';
import { TutorialActions, UIActions } from '~/flux/actions';
import {
  BattleStore,
  GameStateStore,
  HeroBuildStore,
  PlayerStore,
  TutorialStore,
  UIStore,
} from '~/flux/stores';
import text from '~/text';
import CanvasTools from '~/view/CanvasTools';
import { HeroLevelView } from '~/view/components/common/canvas';
import BattleNotifications from './BattleNotifications';
import InnNotifications from './InnNotifications';
import CurrenciesView from './CurrenciesView';

const SCREEN_LABEL_STYLE = {
  fontFamily: 'Courier New',
  fontStyle: 'bold',
  fontSize: '22px',
  fill: 0x000000,
  dropShadow: true,
  dropShadowColor: 0xaaaaaa,
  dropShadowDistance: 1,
};

const Header = function () {
  PIXI.Container.call(this);

  let _right_arrow;
  let _left_arrow;
  let _tooltip;

  this.transitionIn = () => {
    if (_screenLabel?.txt?.text?.trim()) {
      _screenLabel.visible = true;
      TweenMax.from(_screenLabel, 0.25, { y: -_screenLabel.height });
    }
  };

  this.transitionOut = (onComplete) => {
    _screenLabel &&
      TweenMax.to(_screenLabel, 0.1, {
        y: -_screenLabel.height * 2,
        onComplete,
      });
  };

  this.changeScreenLabel = () => {
    TweenMax.killTweensOf(_screenLabel);
    this.transitionOut(() => {
      if (!_nameDisplay || !_screenLabel) {
        return;
      }

      const { current_ui_screen } = UIStore.getAll();
      if (current_ui_screen === Screens.BATTLE) {
        _nameDisplay.visible = false;
        return;
      } else {
        _nameDisplay.visible = true;
      }
      _screenLabel.visible = true;
      if (_screenLabel?.bg) {
        _screenLabel.bg.tint = 0xffffff;
      }
      _screenLabel?.hero_level_view?.dispose();

      if (_right_arrow) {
        _right_arrow.visible = false;
        _left_arrow.visible = false;
      }

      if (current_ui_screen === Screens.BATTLE_DEBRIEF) {
        // render "Victory" or "Defeat" title
        const debrief = BattleStore.getAll().debrief;
        if (debrief.winnerId === PlayerStore.getAll().loggedInPlayerId) {
          _screenLabel.txt.text = text('ui.victory');
          _screenLabel.txt.style.fill = 0x00ff00;
          _screenLabel.txt.style.dropShadowColor = 0x000000;
        } else {
          _screenLabel.txt.text = text('ui.defeat');
          _screenLabel.txt.style.fill = 0xff0000;
          _screenLabel.txt.style.dropShadowColor = 0x000000;
        }
        _screenLabel.bg.tint = 0xffffff;
      } else if (current_ui_screen === Screens.EDIT_HERO) {
        // render hero's name as screen label, tint it the hero's color, and add a HeroLevelView
        const { focused_hero_handle } = UIStore.getAll();
        const roster_hero =
          GameStateStore.getAll().gameState.hero_roster[focused_hero_handle];

        _screenLabel.txt.text = text(
          `heroes.${focused_hero_handle}.name`
        ).toUpperCase();
        _screenLabel.txt.style.fill = 0xffffff;
        _screenLabel.bg.tint = Colors[focused_hero_handle];
        _screenLabel.txt.style.dropShadowColor = 0x000000;
        _screenLabel.txt.x = Math.round(
          _screenLabel.bg.width / 2 - _screenLabel.txt.width / 2
        );
        _screenLabel.txt.y = Math.round(
          _screenLabel.bg.height * 0.65 - _screenLabel.txt.height / 2
        );

        _screenLabel.hero_level_view = new HeroLevelView({
          roster_hero,
          scale_mod: 1.6,
        });
        _screenLabel.hero_level_view.height = DT_CANVAS_GLOBALS.spacing * 4.5;
        _screenLabel.hero_level_view.scale.x =
          _screenLabel.hero_level_view.scale.y;
        _screenLabel.hero_level_view.x = Math.round(
          _screenLabel.bg.width / 2 - _screenLabel.hero_level_view.width / 2
        );
        _screenLabel.hero_level_view.y = Math.round(
          _screenLabel.bg.height - DT_CANVAS_GLOBALS.spacing * 0.75
        );
        _screenLabel.hero_level_view = _screenLabel.hero_level_view;
        _screenLabel.bg.addChild(_screenLabel.hero_level_view);

        // make arrow buttons
        if (_right_arrow) {
          _right_arrow.visible = true;
          _left_arrow.visible = true;
        } else {
          _right_arrow = new PIXI.Sprite();
          _right_arrow.texture =
            PIXI.utils.TextureCache['altar/right_arrow.png'];
          _right_arrow.x =
            _screenLabel.width / _screenLabel.scale.x -
            _right_arrow.width / 2 +
            DT_CANVAS_GLOBALS.spacing * 2;
          _right_arrow.y = _screenLabel.height / _screenLabel.scale.y / 2;
          _screenLabel.addChild(_right_arrow);
          _right_arrow.interactive = _right_arrow.buttonMode = true;
          _right_arrow.hitArea = new PIXI.Circle(0, 0, 32);
          _right_arrow.tap = _right_arrow.click = () => {
            const unlocked_hero_handles = Object.entries(
              GameStateStore.getAll().gameState.hero_roster
            ).reduce((result, [handle, hero]) => {
              hero.level > 0 && result.push(handle);
              return result;
            }, []);
            const this_hero_index = unlocked_hero_handles.findIndex(
              (handle) => handle === UIStore.getAll().focused_hero_handle
            );
            const next_hero_index =
              this_hero_index === unlocked_hero_handles.length - 1
                ? 0
                : this_hero_index + 1;
            const next_hero_handle = unlocked_hero_handles[next_hero_index];
            UIActions.focusHeroBuild({
              hero_handle: next_hero_handle,
              build_id:
                HeroBuildStore.getAll().hero_builds[next_hero_handle][0]._id,
            });
          };
          // now left
          _left_arrow = new PIXI.Sprite();
          _left_arrow.texture = PIXI.utils.TextureCache['altar/left_arrow.png'];
          _left_arrow.x =
            -_left_arrow.width / 2 + DT_CANVAS_GLOBALS.spacing * -2;
          _left_arrow.y = _right_arrow.y;
          _screenLabel.addChild(_left_arrow);
          _left_arrow.interactive = _left_arrow.buttonMode = true;
          _left_arrow.hitArea = new PIXI.Circle(0, 0, 32);
          _left_arrow.tap = _left_arrow.click = () => {
            const unlocked_hero_handles = Object.entries(
              GameStateStore.getAll().gameState.hero_roster
            ).reduce((result, [handle, hero]) => {
              hero.level > 0 && result.push(handle);
              return result;
            }, []);
            const this_hero_index = unlocked_hero_handles.findIndex(
              (handle) => handle === UIStore.getAll().focused_hero_handle
            );
            const next_hero_index =
              this_hero_index === 0
                ? unlocked_hero_handles.length - 1
                : this_hero_index - 1;
            const next_hero_handle = unlocked_hero_handles[next_hero_index];
            UIActions.focusHeroBuild({
              hero_handle: next_hero_handle,
              build_id:
                HeroBuildStore.getAll().hero_builds[next_hero_handle][0]._id,
            });
          };
        }
      } else {
        // render this screen's title ("Armory", "Inn", et al.)
        _screenLabel.txt?.destroy();
        _screenLabel.txt = new PIXI.Text(
          text(`ui.${UIStore.getAll().current_ui_screen}`),
          SCREEN_LABEL_STYLE
        );
        _screenLabel.txt.x = Math.round(
          _screenLabel.bg.width / 2 - _screenLabel.txt.width / 2
        );
        _screenLabel.txt.y = Math.round(
          _screenLabel.bg.height * 0.65 - _screenLabel.txt.height / 2
        );
        _screenLabel.bg.addChild(_screenLabel.txt);
      }
      TweenMax.killTweensOf(_screenLabel);
      TweenMax.to(_screenLabel, 0.25, {
        y: Math.round(DT_CANVAS_GLOBALS.spacing * 2.1),
      });
    });

    _screenLabel.txt.x = Math.round(
      _screenLabel.bg._width / 2 - _screenLabel.txt._width / 2
    );
    _screenLabel.txt.y = Math.round(
      _screenLabel.bg.height * 0.65 - _screenLabel.txt.height / 2
    );
  };

  const destroyNameDisplay = () => {
    if (_nameDisplay) {
      this.removeChild(_nameDisplay);
      _nameDisplay.destroy();
      _nameDisplay.tap =
        _nameDisplay.click =
        _nameDisplay.mouseover =
        _nameDisplay.pointerover =
        _nameDisplay.mouseout =
        _nameDisplay.pointerout =
          null;
      _nameDisplay = null;
    }
  };

  this.dispose = () => {
    _screenLabel.txt?.destroy();
    TweenMax.killTweensOf(_screenLabel);
    _screenLabel = null;

    if (_infoBtn) {
      _infoBtn.parent?.removeChild(_infoBtn);
      _infoBtn.tap = _infoBtn.click = null;
    }

    destroyNameDisplay();
    _battleNotifications?.dispose();

    _inn_notifs?.dispose();
    _inn_notifs = null;

    _tooltip?.dispose();
    _tooltip = null;

    _currenciesView?.dispose();
    _currenciesView = null;

    if (_left_arrow) {
      _left_arrow.tap = _left_arrow.click = null;
      _left_arrow = null;
    }

    if (_right_arrow) {
      _right_arrow.tap = _right_arrow.click = null;
      _right_arrow = null;
    }

    _settingsBtn.tap =
      _settingsBtn.click =
      _settingsBtn.mouseover =
      _settingsBtn.pointerover =
      _settingsBtn.mouseout =
      _settingsBtn.pointerout =
        null;
    _settingsBtn = null;

    _leaderboardBtn.tap =
      _leaderboardBtn.click =
      _leaderboardBtn.mouseover =
      _leaderboardBtn.pointerover =
      _leaderboardBtn.mouseout =
      _leaderboardBtn.pointerout =
        null;
    _leaderboardBtn = null;

    _battleLogBtn.tap =
      _battleLogBtn.click =
      _battleLogBtn.mouseover =
      _battleLogBtn.pointerover =
      _battleLogBtn.mouseout =
      _battleLogBtn.pointerout =
        null;
    _battleLogBtn = null;

    _inn_logs_btn.tap =
      _inn_logs_btn.click =
      _inn_logs_btn.mouseover =
      _inn_logs_btn.pointerover =
      _inn_logs_btn.mouseout =
      _inn_logs_btn.pointerout =
        null;
    _inn_logs_btn = null;

    _battleNotifications.tap =
      _battleNotifications.click =
      _battleNotifications.mouseover =
      _battleNotifications.pointerover =
      _battleNotifications.mouseout =
      _battleNotifications.pointerout =
        null;
    _battleNotifications = null;

    GameStateStore.removeListener(
      GameStateStore.XP_BOOST_USED,
      this.changeScreenLabel
    );
    PlayerStore.removeListener(PlayerStore.PLAYER_LOGGED_IN, makeNameDisplay);
    PlayerStore.removeListener(PlayerStore.NEW_PLAYER_NAME, makeNameDisplay);
    UIStore.removeListener(UIStore.GAME_MODE_SELECTION, this.changeScreenLabel);
    UIStore.removeListener(UIStore.HERO_FOCUSED, onHeroFocused);

    this.removeChildren();
    this.parent?.removeChild(this);
  };

  // make the title for this UI Screen
  let _screenLabel = new PIXI.Container();
  _screenLabel.height = Math.min(
    _screenLabel.height,
    window.innerHeight * 0.045
  );
  _screenLabel.scale.x = _screenLabel.scale.y;
  _screenLabel.bg = new PIXI.Sprite();
  _screenLabel.bg.texture =
    PIXI.utils.TextureCache['current_section_label.png'];
  _screenLabel.addChild(_screenLabel.bg);
  _screenLabel.txt = new PIXI.Text(
    text(`ui.${UIStore.getAll().current_ui_screen}`),
    SCREEN_LABEL_STYLE
  );
  _screenLabel.txt.x = Math.round(
    _screenLabel.bg.width / 2 - _screenLabel.txt.width / 2
  );
  _screenLabel.txt.y = Math.round(
    _screenLabel.bg.height * 0.65 - _screenLabel.txt.height / 2
  );
  _screenLabel.bg.addChild(_screenLabel.txt);
  _screenLabel.x = Math.round(window.innerWidth / 2 - _screenLabel.width / 2);
  _screenLabel.y = Math.round(DT_CANVAS_GLOBALS.spacing * 2.1);
  _screenLabel.visible = false;
  this.addChild(_screenLabel);

  // info button attached to screen label
  const _infoBtn = new PIXI.Sprite(
    PIXI.utils.TextureCache['header/info_icon_128x128.png']
  );
  _infoBtn.width = 32;
  _infoBtn.height = 32;
  _infoBtn.tint = Colors.CAVERNS_PORTAL;
  this.addChild(_infoBtn);
  _infoBtn.x = _screenLabel.width * 0.07;
  _infoBtn.y = _screenLabel.height * 0.15;
  _screenLabel.addChild(_infoBtn);
  _infoBtn.interactive = _infoBtn.buttonMode = true;
  _infoBtn.tap = _infoBtn.click = () => {
    UIActions.showModal({ modal_key: 'ContextualInfoModal' });

    TutorialActions.hideTutorialBox();
    const { opened_context_info } = TutorialStore.getCompletedTutorialSteps();
    if (!opened_context_info) {
      TutorialActions.logTutorialStepCompleted('opened_context_info');
    }
  };

  // make the currencies view
  var _currenciesView = new CurrenciesView();
  this.addChild(_currenciesView);

  // make settings button
  var _settingsBtn = new PIXI.Sprite();
  _settingsBtn.texture = PIXI.utils.TextureCache['header/settings_btn.png'];
  _settingsBtn.scale.x = _settingsBtn.scale.y = Math.min(
    1,
    window.innerWidth / 1000
  );
  _settingsBtn.x = Math.round(DT_CANVAS_GLOBALS.spacing);
  _settingsBtn.y = Math.round(DT_CANVAS_GLOBALS.spacing);
  _settingsBtn.interactive = _settingsBtn.buttonMode = true;
  _settingsBtn.tap = _settingsBtn.click = () => {
    UIActions.showModal({ modal_key: 'SettingsModal' });
  };
  _settingsBtn.mouseover = _settingsBtn.pointerover = () => {
    _tooltip?.dispose();
    _tooltip = new Tooltip(
      'ui',
      {
        body_text: text('ui.settings'),
      },
      _settingsBtn
    );
  };
  _settingsBtn.mouseout = _settingsBtn.pointerout = () => _tooltip?.dispose();
  this.addChild(_settingsBtn);

  // make battle log button
  var _battleLogBtn = new PIXI.Sprite();
  _battleLogBtn.texture = PIXI.utils.TextureCache['header/battle_log_btn.png'];
  _battleLogBtn.scale = _settingsBtn.scale;
  _battleLogBtn.x = Math.round(
    _settingsBtn.x + _settingsBtn.width + DT_CANVAS_GLOBALS.spacing
  );
  _battleLogBtn.y = Math.round(_settingsBtn.y);
  _battleLogBtn.interactive = _battleLogBtn.buttonMode = true;
  _battleLogBtn.tap = _battleLogBtn.click = () => {
    UIActions.showModal({ modal_key: 'BattleLogModal' });
  };
  _battleLogBtn.mouseover = _battleLogBtn.pointerover = () => {
    _tooltip?.dispose();
    _tooltip = new Tooltip(
      'ui',
      {
        body_text: text('ui.battle_log'),
      },
      _battleLogBtn
    );
  };
  _battleLogBtn.mouseout = _battleLogBtn.pointerout = () => _tooltip?.dispose();
  this.addChild(_battleLogBtn);

  // make inn log button
  var _inn_logs_btn = new PIXI.Sprite();
  _inn_logs_btn.texture = PIXI.utils.TextureCache['header/inn_logs_btn.png'];
  _inn_logs_btn.scale = _settingsBtn.scale;
  _inn_logs_btn.x = Math.round(
    _battleLogBtn.x + _battleLogBtn.width + DT_CANVAS_GLOBALS.spacing
  );
  _inn_logs_btn.y = Math.round(_battleLogBtn.y);
  _inn_logs_btn.interactive = _inn_logs_btn.buttonMode = true;
  _inn_logs_btn.tap = _inn_logs_btn.click = () => {
    UIActions.showModal({ modal_key: 'InnLogsModal' });
  };
  _inn_logs_btn.mouseover = _inn_logs_btn.pointerover = () => {
    _tooltip?.dispose();
    _tooltip = new Tooltip(
      'ui',
      {
        body_text: text('ui.inn_tourney_log'),
      },
      _inn_logs_btn
    );
  };
  _inn_logs_btn.mouseout = _inn_logs_btn.pointerout = () => _tooltip?.dispose();
  this.addChild(_inn_logs_btn);

  // make leaderboard button
  var _leaderboardBtn = new PIXI.Sprite();
  _leaderboardBtn.texture =
    PIXI.utils.TextureCache['header/leaderboards_btn.png'];
  _leaderboardBtn.scale = _settingsBtn.scale;
  _leaderboardBtn.x = Math.round(
    _inn_logs_btn.x + _inn_logs_btn.width + DT_CANVAS_GLOBALS.spacing
  );
  _leaderboardBtn.y = Math.round(_inn_logs_btn.y);
  _leaderboardBtn.interactive = _leaderboardBtn.buttonMode = true;
  _leaderboardBtn.tap = _leaderboardBtn.click = () => {
    UIActions.showModal({ modal_key: 'LeaderboardsModal' });
  };
  _leaderboardBtn.mouseover = _leaderboardBtn.pointerover = () => {
    _tooltip?.dispose();
    _tooltip = new Tooltip(
      'ui',
      {
        body_text: text('ui.leaderboards'),
      },
      _leaderboardBtn
    );
  };
  _leaderboardBtn.mouseout = _leaderboardBtn.pointerout = () =>
    _tooltip?.dispose();
  this.addChild(_leaderboardBtn);

  // make social button
  var _socialBtn = new PIXI.Sprite();
  _socialBtn.texture = PIXI.utils.TextureCache['header/social_btn.png'];
  _socialBtn.scale = _settingsBtn.scale;
  _socialBtn.x = Math.round(
    _leaderboardBtn.x + _leaderboardBtn.width + DT_CANVAS_GLOBALS.spacing
  );
  _socialBtn.y = Math.round(_settingsBtn.y);
  _socialBtn.interactive = _socialBtn.buttonMode = true;
  _socialBtn.tap = _socialBtn.click = () => {
    UIActions.showModal({ modal_key: 'SocialModal' });
  };
  _socialBtn.mouseover = _socialBtn.pointerover = () => {
    _tooltip?.dispose();
    _tooltip = new Tooltip(
      'ui',
      {
        body_text: text('ui.get_connected'),
      },
      _socialBtn
    );
  };
  _socialBtn.mouseout = _socialBtn.pointerout = () => _tooltip?.dispose();
  this.addChild(_socialBtn);

  // make battle notifications overlay for battle log button
  var _battleNotifications;
  var bn = (_battleNotifications = new BattleNotifications());
  bn.x = Math.round(_battleLogBtn.width / 2 - bn._width * 0.667);
  bn.y = Math.round(_battleLogBtn.height - bn._height * 0.667);
  _battleLogBtn.addChild(bn);
  // make inn notifs overlay for inn logs button
  let _inn_notifs = new InnNotifications();
  _inn_notifs.x = Math.round(
    _inn_logs_btn.width / 2 - _inn_notifs._width * 0.667
  );
  _inn_notifs.y = Math.round(
    _inn_logs_btn.height - _inn_notifs._height * 0.667
  );
  _inn_logs_btn.addChild(_inn_notifs);

  var _nameDisplay;
  const makeNameDisplay = () => {
    destroyNameDisplay();

    _nameDisplay = new PIXI.Text(
      PlayerStore.getAll().displayName || 'New Player',
      {
        fontFamily: 'Courier New',
        fontSize: CanvasTools.dynamicFontSizeString(14),
        fill: Colors.GREEN,
      }
    );
    _nameDisplay.width = Math.min(
      _nameDisplay.width,
      window.innerWidth * 0.175
    );
    _nameDisplay.scale.y = _nameDisplay.scale.x;
    _nameDisplay.x = Math.round(
      window.innerWidth - DT_CANVAS_GLOBALS.spacing * 26 - _nameDisplay.width
    );
    _nameDisplay.y = Math.round(
      _socialBtn.y + _socialBtn.height / 2 - _nameDisplay.height / 2
    );
    this.addChild(_nameDisplay);

    _nameDisplay.interactive = _nameDisplay.buttonMode = true;
    _nameDisplay.tap = _nameDisplay.click = () => {
      UIActions.showModal({ modal_key: 'NameChangeModal' });
    };
    _nameDisplay.mouseover = _nameDisplay.pointerover = () => {
      _tooltip?.dispose();
      _tooltip = new Tooltip(
        'ui',
        {
          body_text: text('ui.change_display_name'),
        },
        _nameDisplay
      );
    };
    _nameDisplay.mouseout = _nameDisplay.pointerout = () => _tooltip?.dispose();
  };
  makeNameDisplay();

  const onHeroFocused = () => {
    if (UIStore.getAll().current_ui_screen === Screens.EDIT_HERO) {
      this.changeScreenLabel();
    }
  };

  GameStateStore.on(GameStateStore.XP_BOOST_USED, this.changeScreenLabel);
  PlayerStore.on(PlayerStore.PLAYER_LOGGED_IN, makeNameDisplay);
  PlayerStore.on(PlayerStore.NEW_PLAYER_NAME, makeNameDisplay);
  UIStore.on(UIStore.GAME_MODE_SELECTION, this.changeScreenLabel);
  UIStore.on(UIStore.HERO_FOCUSED, onHeroFocused);
};
Header.prototype = Object.create(PIXI.Container.prototype);
Header.prototype.constructor = Header;
export default Header;
