import { Dispatcher } from 'flux';

const PlayerDispatcher = Object.assign(new Dispatcher(), {
  NAME: 'PLAYER_DISPATCHER',

  PLAYER_LOGGED_IN: 'PLAYER_LOGGED_IN',
  ENTER_NEW_PLAYER_NAME: 'ENTER_NEW_PLAYER_NAME',
  MAKE_GOLD: 'MAKE_GOLD',
  SUBMIT_NEWSLETTER_SIGNUP: 'SUBMIT_NEWSLETTER_SIGNUP',
  SOCIAL_CLICK: 'SOCIAL_CLICK',

  handlePlayerAction: function(action) {
    this.dispatch({
      source: PlayerDispatcher.NAME,
      action
    });
  }
});
export default PlayerDispatcher;
