import PuppetMovement from './PuppetMovement';

const DefensiveStanceMovement = (puppet) => {
  const poses = [
    {pose:Pose1},
    {pose:Pose2}
  ];
     
  return PuppetMovement({
    	poses,
      puppet,
    	setPoseDurations: (actorCooldown) => {
	    	poses[0].duration = poses[1].duration = actorCooldown/2;
	    }
  });
};
export default DefensiveStanceMovement;

var Pose1 =  {
  head: {
    	x: 0,
    y: -139,
    z: -22
  },
  shoulders: {
    x: 0,
    y: -121,
    z: -16
  },
  torsoe: {
    x: 0,
    y: -91,
    z: -8
  },    
  leftHand: {
    x: 10,
    y: -100,
    z: 43
  },      
  bowPitch: 0,
  bowYaw: 0,
  rightHand: {
    x: -10,
    y: -109,
    z: 37
  },
  weaponPitch: 0,
  weaponYaw: 0,
  leftFoot: {
    x: -21,
    y: 0,
    z: 58
  },
  rightFoot: {
    x: 26,
    y: 0,
    z: -36
  }
};	
	
var Pose2 = {
  head: {
    x: 0,
    y: -132,
    z: -11
  },
  shoulders: {
    x: 0,
    y: -114,
    z: -8
  },
  torsoe: {
    x: 0,
    y: -84,
    z: 0
  },
  leftHand: {
    x: 10,
    y: -96,
    z: 48
  },
  bowPitch: 0,
  bowYaw: 0,
  rightHand: {
    x: -10,
    y: -105,
    z: 42
  },
  weaponPitch: 0,
  weaponYaw: 0,
  leftFoot: {
    x: -21,
    y: 0,
    z: 58
  },
  rightFoot: {
    x: 26,
    y: 0,
    z: -36	
  }
};