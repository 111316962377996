export default {
	assassin: "Assassin",
	battle_mage: "Battle Mage",
	dreamsmith: "Dreamsmith",
	invoker: "Invoker",
	knight: "Knight",
	mercenary: "Mercenary",
	monk: "Monk",
	paladin: "Paladin",
	ranger: "Ranger",
	shaman: "Shaman",
	warlock: "Warlock",

	dwarf: "Dwarf",
	elf: "Elf",
	faerie: "Faerie",

	giant_rat: "Giant Rat",
	kobold: "Kobold",
	skeleton: "Skeleton",
	skeletal_archer: "Skeletal Archer",
	cave_lizard: "Cave Lizard",
	ratling_warrior: "Ratling Warrior",
	slime: "Slime",
	ratling_priest: "Ratling Priest",
	huge_bat: "Huge Bat",
	bandit: "Bandit",
	goblin: "Goblin",
	kobold_cleric: "Kobold Cleric",
	goblin_elementalist: "Goblin Elementalist",
	snake: "Snake"
};