import { EventEmitter } from 'events';
import Economy from 'dt-common/constants/Economy';
import { AltarDispatcher, PlayerDispatcher }from '~/flux/dispatchers';
import { awaitSocket, registerDispatchHandlers } from '~/Tools';

let playerId;
let _socket;

// the stuff we serve:
let prayerSlots = [];
let alignment; // 0 = chaos, 0.5 = balance, 1.0 = order
let altarLevel;
let chaosAffinity;
let balanceAffinity;
let orderAffinity;
let activeCandles = [];
let newPrayerData = { goldValue: Economy.MIN_GOLD_SACRIFICE };

const AltarStore = Object.assign({}, EventEmitter.prototype, {
  PRAYER_DATA: 'PRAYER_DATA',
  GOT_ALTAR_STATE: 'GOT_ALTAR_STATE',
  GOT_PRAYER_RESULT_ITEM: 'GOT_PRAYER_RESULT_ITEM',
  SHOW_UNLOCK_PRAYER_SLOT_DIALOG: 'SHOW_UNLOCK_PRAYER_SLOT_DIALOG',
  SHOW_CANDLE_TARGET_DIALOG: 'SHOW_CANDLE_TARGET_DIALOG',
  WAS_PRAYER_CANDLE_TARGET: 'WAS_PRAYER_CANDLE_TARGET',

  getAll: function() {
    return {
      prayerSlots,
      alignment,
      altarLevel,
      chaosAffinity,
      balanceAffinity,
      orderAffinity,
      newPrayerData,
      activeCandles
    };
  }
});
export default AltarStore;

PlayerDispatcher.register(registerDispatchHandlers({
  [PlayerDispatcher.PLAYER_LOGGED_IN]: onPlayerLoggedIn
}));
AltarDispatcher.register(registerDispatchHandlers({
  [AltarDispatcher.GET_ALTAR_STATE]: getAltarState,
  [AltarDispatcher.START_NEW_PRAYER]: startNewPrayer,
  [AltarDispatcher.UPDATE_PRAYER_GOLD_VALUE]: updatePrayerGoldValue,
  [AltarDispatcher.UPDATE_PRAYER_ITEM_DATA]: updatePrayerItemData,
  [AltarDispatcher.UPDATE_PRAYER_GOD]: updatePrayerGod,
  [AltarDispatcher.SET_NEW_PRAYER_PARAMETER]: setNewPrayerParameter,
  [AltarDispatcher.SUBMIT_PRAYER]: submitPrayer,
  [AltarDispatcher.COMPLETE_PRAYER]: completePrayer,
  [AltarDispatcher.SHOW_UNLOCK_PRAYER_SLOT_DIALOG]: showUnlockPrayerSlotDialog,
  [AltarDispatcher.UNLOCK_PRAYER_SLOT]: unlockPrayerSlot,
  [AltarDispatcher.USE_PRAYER_CANDLE]: requestUsePrayerCandle
}));

awaitSocket(onSocketConnected);
// awaitSocket().then(onSocketConnected);
function onSocketConnected(socket) {
  try {
    _socket = socket;

    if (!_socket.has_AltarStore_listeners) {
      _socket.on('altar_state', onAltarState);
      _socket.on('equipment_change', onEquipmentChange);
      _socket.on('prayer_candle_target', onPrayerCandleTarget);
      _socket.on('challengeResolved', onChallengeResolved);
      _socket.on('returnedToSurface', onReturnedToSurface);

      _socket.has_AltarStore_listeners = true;
    }
  } catch (err) {
    logError(err, {
      module: 'AltarStore',
      func: 'onSocketConnected'
    });
  }
}

function onPlayerLoggedIn(action) {
  try {
    const { player } = action;

    playerId = player._id;
    onAltarState(player.gameState.altarState);
  } catch (err) {
    logError(err, {
      module: 'AltarStore',
      func: 'onPlayerLoggedIn'
    });
  }
}

function getAltarState() {
  _socket.emit('get_altar_state', { playerId });
}
function onChallengeResolved() {
  _socket.emit('get_altar_state', { playerId });
}
function onReturnedToSurface() {
  _socket.emit('get_altar_state', { playerId });
}

function onAltarState(altar) {
  try {
    prayerSlots = altar.prayerSlots;
    alignment = altar.alignment;
    altarLevel = altar.altarLevel;
    chaosAffinity = altar.chaosAffinity;
    balanceAffinity = altar.balanceAffinity;
    orderAffinity = altar.orderAffinity;
    activeCandles = altar.activeCandles || [];

    AltarStore.emit(AltarStore.GOT_ALTAR_STATE);
  } catch (err) {
    logError(err, {
      module: 'AltarStore',
      func: 'onAltarState',
      altar
    });
  }
}

function startNewPrayer(action) {
  newPrayerData = {
    slotIndex: action.slotIndex,
    goldValue: Economy.MIN_GOLD_SACRIFICE,
    godHandle: 'balance',
    item: { slot:'weapon', type:'dagger' },
    goldValueIsSet: false,
    godHandleIsSet: false,
    itemDataIsSet: false
  };
  AltarStore.emit(AltarStore.PRAYER_DATA, newPrayerData);
}

function updatePrayerGoldValue(action) {
  newPrayerData.goldValue = action.goldValue;
  AltarStore.emit(AltarStore.PRAYER_DATA, newPrayerData);
}

function updatePrayerItemData(action) {
  newPrayerData.item = action.itemData;
  AltarStore.emit(AltarStore.PRAYER_DATA, newPrayerData);
}

function updatePrayerGod(action) {
  newPrayerData.godHandle = action.godHandle;
  AltarStore.emit(AltarStore.PRAYER_DATA, newPrayerData);
}

function setNewPrayerParameter() {
  try {
    if (!newPrayerData.goldValueIsSet) {
      newPrayerData.goldValueIsSet = true;
    } else if (!newPrayerData.godHandleIsSet) {
      newPrayerData.godHandleIsSet = true;
    } else if (!newPrayerData.itemDataIsSet) {
      newPrayerData.itemDataIsSet = true;
      submitPrayer();
    }

    AltarStore.emit(AltarStore.PRAYER_DATA, newPrayerData);
  } catch (err) {
    logError(err, {
      module: 'AltarStore',
      func: 'setNewPrayerParameter'
    });
  }
}

function submitPrayer() {
  _socket.emit('submit_prayer', {
    playerId,
    prayerData: {
      prayerSlotIndex: newPrayerData.slotIndex,
      godTag: newPrayerData.godHandle,
      itemSlot: newPrayerData.item.slot,
      itemType: newPrayerData.item.type,
      sacAmount: newPrayerData.goldValue
    }
  });
}

function completePrayer(action) {
  _socket.emit('complete_prayer', {
    playerId,
    prayerSlotIndex: action.prayerSlotIndex
  });
}

function showUnlockPrayerSlotDialog() {
  AltarStore.emit(AltarStore.SHOW_UNLOCK_PRAYER_SLOT_DIALOG);
}

function unlockPrayerSlot() {
  _socket.emit('unlock_prayer_slot', {
    playerId
  });
}

function onEquipmentChange(data) {
  if (data.prayerResultItem) {
    AltarStore.emit(AltarStore.GOT_PRAYER_RESULT_ITEM, {
      resultItem: data.prayerResultItem,
      godTag: data.godTag
    });
  }
}

function requestUsePrayerCandle(action) {
  _socket.emit('use_prayer_candle', {
    playerId,
    candleId: action.candleId,
    candleIndex: action.candleIndex,
    targetPlayerName: action.targetPlayerName
  });
}

function onPrayerCandleTarget(data) {
  try {
    onAltarState(data.altarState);

    AltarStore.emit(AltarStore.WAS_PRAYER_CANDLE_TARGET, {
      alignment: data.alignment,
      sender: data.sender
    });
  } catch (err) {
    logError(err, {
      module: 'AltarStore',
      func: 'onPrayerCandleTarget',
      data
    });
  }
}
