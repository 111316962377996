import * as PIXI from 'pixi.js';
import { ObjectPool } from '~/Tools';

const style = {
	fontFamily: 'Courier New',
	fontWeight: 'bold',
	dropShadow: true,
	dropShadowAngle: 7.5 * Math.PI / 6,
	dropShadowColor: 0xffffff,
	dropShadowDistance: 0.8
};

var _txtPool = new ObjectPool(

	// create func
	() => {
		const txt = new PIXI.Text( '', style );
		txt.anchor = { x: 0.5, y: 0.1 }
		return txt;
	},

	// reset func
	(txt) => {
    gsap.killTweensOf(txt);
		txt.parent?.removeChild(txt);
	},

	// starting num
	50
);

const DURATION_SECS = 0.82;

const FloatingTextImage = function(str, txtSize, txtColor) {	
	PIXI.Container.call( this );
		
	this.dispose = (txtObject) => {
    gsap.killTweensOf(this);
		_txtPool.put( txtObject );
    this.removeChildren();
    this.parent?.removeChild(this);
	};

	this.anchor = { x: 0.5, y: 1 };

	this.init = () => {
		const txt = _txtPool.get();
		txt.text = str;
		txt.style.fontSize = txtSize;
		txt.style.fill = txtColor;	
		this.addChild(txt);

		TweenMax.to(this, DURATION_SECS, {
			x: this.x - 12 + Math.random() * 15,
			y: this.y - 21 - Math.random() * 8,
			ease: Quad.easeOut
		});
		this.parent && TweenMax.to(this, 0.1, {
			angle: -30 + Math.random() * 60,
			onComplete: () => {
				this.parent && TweenMax.to(this, DURATION_SECS - 0.4, {
					angle: -12 + Math.random() * 24,
					ease: Quad.easeOut,
				});
			},
		})
		TweenMax.to(this, DURATION_SECS * 1.01, { // avoid race condition with dispose() & other tweens
			alpha: 0,
			ease: Expo.easeIn,
			onComplete: this.dispose,
			onCompleteParams: [txt]
		});

		this.pivot = { x: 0.5, y: 1 }

		if (str.endsWith('!')) {
			TweenMax.from(this.scale, 0.65, { x: 0, y: 0, ease: Bounce.easeOut })
		} else {
			TweenMax.from(this.scale, 0.1, { x: 0, y: 0 })
		}
	};
};
FloatingTextImage.prototype = Object.create( PIXI.Container.prototype );
FloatingTextImage.prototype.constructor = FloatingTextImage;
export default FloatingTextImage;
