<template>
  <Dialog
    :open="true"
    @close="props.onClose"
  >
    <div class="fixed inset-0 flex items-center justify-center p-4 z-20 bg-zinc-700/90">
      <DialogPanel class="relative border border-white w-[700px] max-h-[90vh] flex flex-col bg-black">
        <canvas
          id="modal_canvas"
          ref="modal_canvas"
          class="absolute z-0 w-[700px] h-[550px]"
        />

        <DialogTitle class="border-b border-white p-3 text-yellow-300">
          {{ text('ui.hero_unlock_title') }}
        </DialogTitle>

        <div
          :class="`w-full mt-4 text-center text-xl font-bold underline`"
          :style="`color: ${Colors.string[hero.handle]}`"
        >
          {{ text(`heroes.${hero.handle}.name`) }}
        </div>

        <div class="w-full h-80 py-6 pr-12 pl-0 flex">
          <div class="grow h-full" /> <!-- space for puppet -->
          <div class="w-[50%] flex flex-col justify-center items-center">
            <div class="text-xl font-bold text-yellow-300">
              {{ text('ability_trees.ability_trees') }}
            </div>

            <ul class="mt-3 list-inside list-disc">
              <li
                v-for="n in 3"
                :key="n"
              >
                {{ text(`ability_trees.${heroBase[hero.handle].getAbilityTrees()[n - 1].handle}.name`) }}
              </li>
            </ul>
          </div>
        </div>

        <div class="w-full text-center">
          {{ question_text }}
        </div>

        <div class="w-full h-24 px-6 flex justify-center items-center z-10">
          <DTButton
            type="success"
            class="py-1 px-5"
            @click="onYesClick"
          >
            {{ text('ui.yes') }}
          </DTButton>
          <DTButton
            type="error"
            class="ml-3 py-1 px-5"
            @click="props.onClose"
          >
            {{ text('ui.no') }}
          </DTButton>
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup>
import { onBeforeUnmount, ref, watch } from 'vue';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
} from '@headlessui/vue';
import generateStarterEquipmentAndBags from 'dt-common/isomorphic-helpers/generateStarterEquipmentAndBags';
import heroBase from 'dt-common/models/game_state/heroes';
import UnitPuppet from '~/components/common/unit_puppets/UnitPuppet';
import { Colors } from '~/constants';
import { EditHeroActions } from '~/flux/actions';
import { GameStateStore } from '~/flux/stores';
import text from '~/text';
import CanvasTools from '~/view/CanvasTools';
import { DTButton } from '~/view/components/common/DOM';

let _modal_pixi_app;
let _puppet;

const props = defineProps({
  hero_handle: {
    type: String,
    required: true,
  },
  onClose: {
    type: Function,
    required: true,
  },
});
const modal_canvas = ref(null);

const { gameState: { hero_roster }, num_unlocked_heroes, heroUnlockCost } = GameStateStore.getAll();
const hero = hero_roster[props.hero_handle];
const question_text = num_unlocked_heroes > 0
  ? text('ui.hero_unlock_question.p1')
  + text('heroes.' + hero.handle + '.name')
  + text('ui.hero_unlock_question.p2')
  + heroUnlockCost
  + text('ui.hero_unlock_question.p3')
  : text('ui.hero_unlock_question.p1')
  + text('heroes.' + hero.handle + '.name') + '?';

watch(modal_canvas, val => {
  if (val && !_modal_pixi_app) {
    _modal_pixi_app = CanvasTools.makePixiRenderer({
      canvas_id: 'modal_canvas',
      canvas_ele: val,
    });
    makeHeroPuppet();
  }
});

onBeforeUnmount(() => {
  _puppet?.dispose();
  _puppet?.parent?.removeChild(_puppet);
  _modal_pixi_app?.destroy();
});

function makeHeroPuppet() {
  // spoof some starter equipment so he's not nekkid
  const { starter_equipment, starter_bags } = generateStarterEquipmentAndBags(hero.handle);
  const equipment_assignment = {};
  for (const [slot, item] of Object.entries(starter_equipment)) {
    equipment_assignment[slot] = item.uid;
  }

  _puppet = new UnitPuppet({
    body_scale: DT_CANVAS_GLOBALS.spacing * 0.35,
    roster_hero: { bags: starter_bags },
    unit_build: {
      ...hero,
      equipment_assignment,
    },
  });
  const { height, width } = modal_canvas.value.getBoundingClientRect();
  _puppet.x = width * 0.3;
  _puppet.y = height * 0.6;
  _puppet.setTint(0x555555, false, false);
  _modal_pixi_app.stage.addChild(_puppet);
}

function onYesClick() {
  EditHeroActions.heroUnlockConfirm(hero);
  props.onClose();
}
</script>
