import Colors from '~/constants/Colors';
import unit_names from './unit_names';

export default {
  // abilities
  melee_attack: (actorName, victimName, data) => {
    return actorName + ' атакует ' + victimName + '.';
  },

  // alchemy
  burning_hands: (actorName, victimName, data) => {
    return (
      actorName +
      ' использует Пылающие руки. ' +
      victimName +
      ' охвачен(а) пламенем.'
    );
  },

  fumigate: (actorName, victimName, data) => {
    return actorName + ' выпускает облако ядовитого тумана!';
  },

  ice_nine: (actorName, victimName, data) => {
    return actorName + ' швыряет флакон со Льдом-девять...';
  },

  lightning: (actorName, victimName, data) => {
    return actorName + ' призывает Молнию!';
  },

  molotov_cocktail: (actorName, victimName, data) => {
    return actorName + ' поджигает и швыряет Коктейль Молотова!';
  },

  poison_weapon: (actorName, victimName, data) => {
    return (
      actorName + ' использует Отравленное оружие, цель: ' + victimName + '.'
    );
  },

  road_to_nowhere: (actorName, victimName, data) => {
    return actorName + ' швыряет флакон со странной жидкостью...';
  },

  // armor use
  bash: (actorName, victimName, data) => {
    return actorName + ' со всей силы толкает плечом ' + victimName + '!';
  },

  shield_block: (actorName, victimName, data) => {
    return actorName + ' закрывается щитом.';
  },

  suture: (actorName, victimName, data) => {
    return actorName + ' накладывает швы на рану ' + victimName + '.';
  },

  // bow use
  arrow_shot: (actorName, victimName, data) => {
    return actorName + ' стреляет из лука, цель: ' + victimName + '.';
  },

  blunt_arrow: (actorName, victimName, data) => {
    return (
      actorName + ' выпускает тупоконечную стрелу, цель: ' + victimName + '.'
    );
  },

  chain_pull: (actorName, victimName, data) => {
    return actorName + ' выпускает стрелу с цепью, цель: ' + victimName + '.';
  },

  leg_shot: (actorName, victimName, data) => {
    return actorName + ' делает выстрел в ногу, цель: ' + victimName + '.';
  },

  multi_shot: (actorName, victimName, data) => {
    return actorName + ' выпускает несколько стрел за раз!';
  },

  razor_shot: (actorName, victimName, data) => {
    return actorName + ' выпускает режущую стрелу, цель: ' + victimName + '!';
  },

  // conjuration
  summon_dwarf: (actorName, victimName, data) => {
    return actorName + ' призывает дворфа-защитника!';
  },

  summon_elf: (actorName, victimName, data) => {
    return actorName + ' призывает эльфа-лучника!';
  },

  summon_faerie: (actorName, victimName, data) => {
    return actorName + ' призывает фею-целительницу!';
  },

  summon_option: (actorName, victimName, data) => {
    return actorName + ' призывает опциона, цель: ' + victimName + '.';
  },

  teleport: (actorName, victimName, data) => {
    return actorName + ' телепортируется!';
  },

  void_spike: (actorName, victimName, data) => {
    return actorName + ' призывает Шипы бездны.';
  },

  // illusion
  devil_mask: (actorName, victimName, data) => {
    return actorName + ' теперь выглядит как злой демон!';
  },

  faux_self: (actorName, victimName, data) => {
    return actorName + ' создает двойников!';
  },

  infect_mind: (actorName, victimName, data) => {
    return actorName + ' заражает разум ' + victimName + '...';
  },

  psyche_out: (actorName, victimName, data) => {
    return actorName + ' насылает Нервный срыв.';
  },

  sleight_of_hand: (actorName, victimName, data) => {
    return actorName + ' проделывает странный магический фокус.';
  },

  // melee combat
  armor_break: (actorName, victimName, data) => {
    return actorName + ' пробивает броню ' + victimName + '.';
  },

  mortal_strike: (actorName, victimName, data) => {
    return actorName + ' пытается добить ' + victimName + '.';
  },

  roundhouse: (actorName, victimName, data) => {
    return actorName + ' наносит удар наотмашь!';
  },

  sky_hammer: (actorName, victimName, data) => {
    return actorName + ' возносится ввысь и обрушивается на землю!';
  },

  slam: (actorName, victimName, data) => {
    return actorName + ' наносит мощный удар, цель: ' + victimName + '.';
  },

  slash: (actorName, victimName, data) => {
    return actorName + ' наносит режущий удар, цель: ' + victimName + '.';
  },

  taunt: (actorName, victimName, data) => {
    return (
      actorName +
      ' насмехается над ' +
      victimName +
      ' и показывает неприличный жест.'
    );
  },

  // movement
  adrenaline: (actorName, victimName, data) => {
    return 'В крови ' + actorName + ' кипит адреналин.';
  },

  leap: (actorName, victimName, data) => {
    return actorName + 'прыгает!';
  },

  stance_dancing: (actorName, victimName, data) => {
    return actorName + ' меняет стойку.';
  },

  tussle: (actorName, victimName, data) => {
    return actorName + ' швыряет ' + victimName + ' через плечо!';
  },

  // spirit
  bless: (actorName, victimName, data) => {
    return actorName + ' благословляет ' + victimName + '.';
  },

  healing_prayer: (actorName, victimName, data) => {
    return actorName + ' возносит молитву, цель: ' + victimName + '.';
  },

  holy_force: (actorName, victimName, data) => {
    return 'Боги приняли' + actorName + ' под свою защиту!';
  },

  mystic_focus: (actorName, victimName, data) => {
    return actorName + ' медитирует.';
  },

  sanctuary: (actorName, victimName, data) => {
    return actorName + ' превращает область в убежище!';
  },

  send_mana: (actorName, victimName, data) => {
    return actorName + ' делится силой с ' + victimName + '.';
  },

  // conditions
  armor_broken: (instigatorName, ownerName, data) => {
    return 'Класс брони ' + ownerName + ' снижается на ' + data.potency + '.';
  },

  bleeding: (instigatorName, ownerName, data) => {
    return (
      ownerName +
      ' истекает кровью и теряет ' +
      Math.floor(data.potency) +
      ' ОЗ раз в 3 секунды.'
    );
  },

  blessed: (instigatorName, ownerName, data) => {
    return ownerName + ' получает благословение!';
  },

  defensive_stance: (instigatorName, ownerName, data) => {
    return ownerName + ' принимает защитную стойку.';
  },

  enraged: (instigatorName, ownerName, data) => {
    return ownerName + ' в ярости набрасывается на ' + instigatorName + '.';
  },

  inspired: (instigatorName, ownerName, data) => {
    return ownerName + ' получает вдохновение от ' + instigatorName + '!';
  },

  offensive_stance: (instigatorName, ownerName, data) => {
    return ownerName + ' принимает атакующую стойку.';
  },

  poisoned: (instigatorName, ownerName, data) => {
    return ownerName + ' получает отравление с эффектом ' + data.potency + '.';
  },

  poisoner: (instigatorName, ownerName, data) => {
    return ownerName + "'s weapon получает " + data.charges + ' доз(ы) яда.';
  },

  scared: (instigatorName, ownerName, data) => {
    return ownerName + ' внезапно пугается ' + instigatorName + '.';
  },

  slowed: (instigatorName, ownerName, data) => {
    return 'Движения ' + ownerName + ' замедляются.';
  },

  stunned: (instigatorName, ownerName, data) => {
    return ownerName + ' оглушен(а)!';
  },

  // events
  block: (actorName, victimName, data) => {
    return {
      text: actorName + ' блокирует атаку!',
      color: 0xffffff,
    };
  },

  damage: (actorName, victimName, data) => {
    return {
      text:
        victimName +
        ' получает ' +
        data.event.damage +
        ' урона' +
        getMod() +
        '!',
      color: Colors.HP_RED,
    };

    function getMod() {
      try {
        switch (data.event.cause.handle) {
          case 'отравление':
            return ' ядом';
          case 'кровотечение':
            return ' от кровотечения';
        }
      } catch (error) {
        console.warn('Ошибка: ' + error);
      }

      return '';
    }
  },

  death: (actorName, victimName, data) => {
    return {
      text: victimName + ' терпит поражение!',
      color: 0xffffff,
    };
  },

  dodge: (actorName, victimName, data) => {
    return {
      text: actorName + ' уклоняется от атаки!',
      color: 0xffffff,
    };
  },

  gain_ap: (actorName, victimName, data) => {
    return {
      text: victimName + ' получает ' + data.event.potency + ' ОД!',
      color: Colors.AP_ORANGE,
    };
  },

  gain_mp: (actorName, victimName, data) => {
    return {
      text: victimName + ' получает ' + data.event.potency + ' МС!',
      color: Colors.MP_PURPLE,
    };
  },

  heal: (actorName, victimName, data) => {
    return {
      text: victimName + ' исцеляется на ' + data.event.potency + ' ОЗ!',
      color: 0x00ff00,
    };
  },

  ice_proc: (actorName, victimName, data) => {
    return {
      text: victimName + ' заморожен(а)!',
      color: 0xffffff,
    };
  },

  knockback: (actorName, victimName, data) => {
    return {
      text: actorName + ' сбит(а) с ног!',
      color: 0xffffff,
    };
  },

  lose_mp: (actorName, victimName, data) => {
    return {
      text: victimName + ' теряет ' + data.event.potency + ' МС!',
      color: Colors.MP_PURPLE,
    };
  },

  new_unit_turn: ({ ai_unit }) => {
    const handle = ai_unit.hero_handle || ai_unit.handle;
    // const candidates = [
    //   `The ${unit_names[handle]} совершает действие.`,
    //   `The ${unit_names[handle]} сверкает глазами в гневе!`,
    //   `The ${unit_names[handle]} в полной готовности.`,
    //   `The ${unit_names[handle]} делает шаг вперед.`,
    //   `The ${unit_names[handle]} делает ход!`,
    //   `It's the ${unit_names[handle]} ловит момент и действует.`,
    // ];
    return {
      // text: candidates[Math.floor(Math.random() * candidates.length)],
      text: `*** The ${unit_names[handle]} совершает действие. ***`,
      color: Colors[handle],
    };
  },

  option_attack: (actorName, victimName, data) => {
    return {
      text: 'Опцион ' + actorName + ' атакует ' + victimName + '.',
      color: 0xffffff,
    };
  },

  resist: (actorName, victimName, data) => {
    return {
      text: actorName + ' оказывает сопротивление!',
      color: 0xffffff,
    };
  },

  unit_spawned: (actorName, victimName, data) => {
    var unit = data.event.unit;

    if (unit.hero) {
      return null;
    } else
      return {
        text:
          'Новый уровень ' +
          unit.level +
          ' ' +
          unit_names[unit.hero_handle || unit.handle] +
          '!',
        color: Colors[unit.handle],
      };
  },
};
