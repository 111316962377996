import { Dispatcher } from 'flux';

const AccountDispatcher = Object.assign(new Dispatcher(), {
  NAME: 'ACCOUNT_DISPATCHER',

  AUTHENTICATE:  'AUTHENTICATE',
  END_SESSION:   'END_SESSION',
  LOG_OUT:       'LOG_OUT',

  handleAccountAction: function(action) {
    this.dispatch({
      source: AccountDispatcher.NAME,
      action
    })
  }
})
export default AccountDispatcher
