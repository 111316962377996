import EventEmitter from 'events';
import * as PIXI from 'pixi.js';
import CanvasTools from '~/view/CanvasTools';

/**
 * @prop selection_data <Array> [
    { slot: 'weapon', type: 'dagger', name: text('ui.weapon_dagger',icon: ex. <PuppetWithNameplate>, ) },
  ]
  @prop add_name_label Boolean
 **/
const DTCarousel = function ({
  selection_data,
  add_name_labels,
  parallax_effect_mod = 1,
  initial_selected_element_index,
  getElementHitArea,
  skip_initial_update,
}) {
  PIXI.Container.call(this);

  const ELEMENT_SPACING = DT_CANVAS_GLOBALS.spacing * 10; // 128;

  this.event_emitter = new EventEmitter();

  this.dispose = () => {
    for (const ele of _elements) {
      TweenMax.killTweensOf(ele);
      ele?.destroy();
    }
    _elements = null;
    _visibleElements = null;
    _left_arrow = null;
    _right_arrow = null;

    this.event_emitter.removeAllListeners();
    this.event_emitter = null;

    this.destroy();
  };

  const TOTAL_NUM_ITEMS = selection_data.length;
  let _elements = [];
  let _visibleElements = [];
  let _num_visible_elements = 0;
  let _selected_item_index = 0;
  let _left_arrow;
  let _right_arrow;
  let _is_updating = false;

  const getRealElementIndex = (index) => {
    if (index < 0) {
      return TOTAL_NUM_ITEMS + index;
    } else if (index >= TOTAL_NUM_ITEMS) {
      return index - TOTAL_NUM_ITEMS;
    }
    return index;
  };

  const updateVisibleElements = (
    newSelectedDataIndex,
    emitUpdate = true,
    ff_tweens = false
  ) => {
    try {
      if (_is_updating) return;

      for (const ve of _visibleElements) {
        if (ve) {
          TweenMax.killTweensOf(ve);
          this.removeChild(ve);
        }
      }
      _visibleElements = [];

      const num_to_show = (() => {
        switch (TOTAL_NUM_ITEMS) {
          case 1:
            return 1;
          case 2:
            return 1;
          default:
            return 3;
        }
      })();

      let lowIndex = Math.ceil(
        newSelectedDataIndex - Math.floor(num_to_show / 2)
      );
      let highIndex = Math.floor(
        newSelectedDataIndex + Math.floor(num_to_show / 2)
      );
      for (let i = lowIndex; i <= highIndex; ++i) {
        _visibleElements.push(_elements[getRealElementIndex(i)]);
      }

      _num_visible_elements = _visibleElements.length;
      for (let i = 0; i < _num_visible_elements; ++i) {
        const element = _visibleElements[i];
        if (!element) {
          return;
        }

        const distFromCenter = Math.abs((highIndex - lowIndex) / 2 - i);
        if (distFromCenter > 2) {
          element.interactive = false;
        } else {
          element.interactive = true;
        }

        const { name } = selection_data[element.selection_index];
        element.text =
          element.selection_index === newSelectedDataIndex ? `[${name}]` : name;

        element.y = DT_CANVAS_GLOBALS.spacing * 3 * distFromCenter;

        const TRAVEL_SECS = 0.5;
        const alpha_target = Math.max(
          0.2,
          1 - distFromCenter / (TOTAL_NUM_ITEMS / 1.5)
        );
        element.alpha = 0;
        _is_updating = true;
        const tween_a = TweenMax.to(element, TRAVEL_SECS, {
          alpha: alpha_target,
          x: Math.round(
            (-_visibleElements.length * ELEMENT_SPACING) / 2 +
              i * ELEMENT_SPACING +
              ELEMENT_SPACING / 2
          ),
          onComplete: () => {
            _is_updating = false;
          },
        });
        const SCALE_MOD = 1 / (1 + distFromCenter * 0.6 * parallax_effect_mod);
        const tween_b = TweenMax.to(element.scale, TRAVEL_SECS, {
          x: SCALE_MOD,
          y: SCALE_MOD,
        });

        if (ff_tweens) {
          tween_a.progress(1, false);
          tween_b.progress(1, false);
        }

        this.addChild(element);
      }

      _selected_item_index = newSelectedDataIndex;

      if (emitUpdate) {
        this.event_emitter.emit(
          'new_selected_item',
          selection_data[_selected_item_index]
        );
      }
    } catch (err) {
      logError(err, {
        module: 'view/components/common/canvas/DTCarousel',
        func: 'updateVisibleElements',
        newSelectedDataIndex,
        emitUpdate,
      });
    }
  };

  // initialize elements & visible elements
  for (let i = 0; i < TOTAL_NUM_ITEMS; ++i) {
    const item = selection_data[i];

    const element = new PIXI.Container();
    if (item.icon) {
      element.addChild(item.icon);
    }

    if (add_name_labels) {
      const name_label = new PIXI.Text(`[${item.name}]`, {
        fontFamily: 'Courier New',
        fontSize: CanvasTools.dynamicFontSizeString(16),
        fontStyle: 'bold',
        fill: 0xffffff,
      });
      element.addChild(name_label);
    }
    element.alpha = 0;
    this.addChild(element);
    _elements.push(element);

    element.selection_data = item;
    element.selection_index = i;

    element.interactive = element.buttonMode = true;
    element.tap = element.click = updateVisibleElements.bind(null, i);

    element.interactiveChildren = false;
    element.hitArea = getElementHitArea();
  }
  updateVisibleElements(
    initial_selected_element_index,
    false,
    skip_initial_update
  );

  if (TOTAL_NUM_ITEMS > 1) {
    // make arrow buttons
    _right_arrow = new PIXI.Sprite();
    _right_arrow.texture = PIXI.utils.TextureCache['altar/right_arrow.png'];
    _right_arrow.x =
      TOTAL_NUM_ITEMS === 2
        ? (ELEMENT_SPACING * Math.sqrt(5)) / 2
        : (_num_visible_elements / 2) * ELEMENT_SPACING - _right_arrow.width;
    _right_arrow.y = -_right_arrow.height / 2;
    this.addChild(_right_arrow);
    _right_arrow.interactive = _right_arrow.buttonMode = true;
    _right_arrow.tap = _right_arrow.click = () => {
      updateVisibleElements(getRealElementIndex(_selected_item_index + 1));
    };
    _right_arrow.hitArea = new PIXI.Circle(0, 0, 32);
    // now left
    _left_arrow = new PIXI.Sprite();
    _left_arrow.texture = PIXI.utils.TextureCache['altar/left_arrow.png'];
    _left_arrow.x = -_right_arrow.x;
    _left_arrow.y = _right_arrow.y;
    this.addChild(_left_arrow);
    _left_arrow.interactive = _left_arrow.buttonMode = true;
    _left_arrow.tap = _left_arrow.click = () => {
      updateVisibleElements(getRealElementIndex(_selected_item_index - 1));
    };
    _left_arrow.hitArea = new PIXI.Circle(0, 0, 32);
  }
};
DTCarousel.prototype = Object.create(PIXI.Container.prototype);
DTCarousel.prototype.constructor = DTCarousel;
export default DTCarousel;
