export default {
  tip_for_beginners: 'СОВЕТЫ НОВИЧКАМ',
  coming_soon: 'СКОРО В ИГРЕ',
  ARENA_LOBBY: {
    title: 'Арена для боев между пользователями в режиме реального времени',
    p1: 'Пришло время испытать свою храбрость: вступайте в сражения с другими игроками и оттачивайте боевую тактику в режиме реального времени.',
    p2: 'Нажмите кнопку "Бой", чтобы встать в очередь и сразиться с близким по рейтингу соперником.',
    coming_soon_1: 'Анализ отряда и снаряжения противника.',
    coming_soon_2: 'Поиск соперников по имени',
    coming_soon_3:
      'Получение наград за убийство членов вражеского отряда даже в случае проигрыша.',
  },
  CAVERNS: {
    title: 'Пещеры ХаОСа1',
    p1: 'Идеальное место для прокачки. Попробуйте спуститься как можно глубже — и вернуться живыми.',
    p2: 'Качество зелий исцеления зависит от максимальной глубины спуска. Продолжительность их действия зависит от того, насколько давно вы были на этой глубине в последний раз.',
    p3: 'Даже не пытайтесь проходить Пещеры одним персонажем на ранних уровнях. Будет очень больно.',
    legend: {
      title: 'ЛЕГЕНДА',
      '>': 'Вниз',
      '<': 'Вверх',
      '/': 'Дверь',
      Ω: 'Портал - сохраниться на текущей глубине, чтобы вернуться в другой раз',
      equipment_drops: 'Оборудование',
      pile_of_loot: 'Куча добычи',
    },
    loot_is_auto_gathered:
      'Добыча автоматически собирается, когда вы нажимаете на дверь, лестницу или возвращаетесь на поверхность.',
    game_runs_in_bg:
      'СОВЕТ: Можно переключать вкладки. Игра продолжит работу в фоновом режиме.',
  },
  EDIT_HERO: {
    title: 'Советы по оснащению персонажей ...',
    equipment: {
      title: 'СНАРЯЖЕНИЕ',
      p1: 'Билды можно экипировать только через инвентарь соответствующего персонажа.',
      p2: 'Чтобы использовать зелье исцеления, перетащите его в ячейку в верхней центральной части экрана.',
    },
    ai_settings: {
      title: 'НАСТРОЙКИ ИИ',
      dps_close:
        'Нанесение урона атаками ближнего боя, например Рубящий удар, Удар наотмашь, Пылающие руки, Молния и, конечно же, обычные атаки ближнего боя.',
      dps_ranged:
        'Нанесение урона атаками дальнего боя, например Выстрел из лука, Режущая стрела, Шипы бездны и Заражение разума. В бою с несколькими противниками задействуются атаки по площади, например Коктейль Молотова и Мультивыстрел.',
      interference:
        'Задействуются способы контроля групп противников, например состояния замедления, испуга и ярости.',
      tank: 'Задействуются способы отвлечь противников от ослабевших союзников. Активное использование Насмешки.',
      support: 'Исцеление, повышение характеристик и вызов подмоги.',
    },
    beginner_tip: 'Не забывайте, что способности нужно сначала экипировать :)',
  },
  INN: {
    title: 'Добро пожаловать в Таверну!',
    p1: 'Здесь можно сражаться в режиме асинхронной многопользовательской игры под контролем ИИ. Вы уже записаны на участие в 24-часовом турнире, где за почетные места в топе рейтинга соревнуются до 99 игроков!',
    p2: 'В зависимости от рейтинга каждую секунду вам будет начисляться мелочь (МВС).',
    p3: 'Одержите победу над другим отрядом, чтобы обменяться рейтингом и украсть у противника 10% запаса мелочи.',
    p4: 'Если вы наберете достаточно мелочи за 24 часа, вам будет присвоен новый ранг. А если нет, ранг останется прежним — а может и снизиться на 1 пункт. Ох, беда!',
    p5: 'Откройте сразу 3 персонажей, чтобы соревноваться в нескольких подрежимах Таверны одновременно.',
  },
  HERO_BUILDS: {
    title: 'Об Арсенале',
    p1: 'Здесь можно открывать новые классы персонажей и управлять их билдами.',
    p2: 'Для каждого класса доступны до 7 билдов, в каждом режиме/подрежиме игры можно использовать разные билды.',
    p3: 'Например, для Арены в режиме 3v3 можно задействовать Следопыта со специализацией на исцелении, а для Таверны в режиме 5v5 — Следопыта со специализацией на стрельбе из лука. Ваши возможности безграничны!',
    p4: 'Откройте сразу 3 персонажей, чтобы отправить их в Пещеры и немного прокачать уровень. Отряды из одного персонажа на ранних уровнях игры — опыт не для слабонервных...',
    unlock_costs: 'Стоимость разблокировки героя составляет:',
  },
  SHOP: {
    title: 'О Магазине',
    p1: 'Купля-продажа, ничего сложного.',
    p2: 'Ассортимент пополняется каждые 30 минут.',
  },
  TEMPLE: {
    title: 'Ш-ш-ш... ~',
  },
};
