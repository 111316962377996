import * as PIXI from 'pixi.js';
import { ObjectPool } from '~/Tools';

var _effectPool = new ObjectPool(

	// create func
	() => {
		return new PIXI.Text( '+', {
			fontFamily: 'Courier New',
			fontSize: '16px',
			fontWeight: 'bold',
			fill: 0x00ff00
		});		
	},

	// reset func
	( effect ) => {
    gsap.killTweensOf(effect);
		effect.alpha = 1;
	},

	// starting num
	30
);

const instances = {};
	
const RecuperatingRendering = {
	render: function( condition, gameBoard ) {
    // disabled (commented out) for GPU performance

		// if( instances[condition.uid] ) {
		// 	return;
		// }

		// instances[ condition.uid ] = {};
		// instances[ condition.uid ].ownerSprite = gameBoard.getAllPieceSprites()[condition.ownerId];
		// instances[ condition.uid ].interval = TweenMax.delayedCall( 0.3+Math.random()*1.0, RecuperatingRendering.makeEffect, [condition.uid] );
	},

	makeEffect: ( conditionId ) => {
		if( !instances[conditionId] ) {
			return;
		}

		const ownerSprite = instances[conditionId].ownerSprite;
		if( !ownerSprite ) {
			return;
		}
	
		var effect = _effectPool.get();	
		effect.x = -ownerSprite.tileImg.width/2 + Math.random()*ownerSprite.tileImg.width;
		effect.y = -Math.random()*ownerSprite.tileImg.height/3;
		ownerSprite.addChildAt( effect, Math.floor(Math.random()*ownerSprite.children.length) );

		TweenMax.to( effect, 0.876, {
			y: effect.y - 45 + Math.random()*25,
			alpha: 0,
			ease: Linear.easeNone,
			onComplete: RecuperatingRendering.disposeEffect,
			onCompleteParams: [ effect ]
		});

		instances[ conditionId ].interval = TweenMax.delayedCall( 0.3+Math.random()*1.0, RecuperatingRendering.makeEffect, [conditionId] );
	},

	disposeEffect: ( effect ) => { 
		if(effect.parent) {
			effect.parent.removeChild(effect);
		} 
		_effectPool.put( effect );
	},

	stopRendering: function( conditionId ) {
		if( instances[conditionId] ) {
			if( instances[conditionId].interval ) {
				instances[conditionId].interval.kill();
			}
			delete instances[ conditionId ];
		}
	},

	dispose: function() {
		for( var prop in instances ) {
			RecuperatingRendering.stopRendering( prop );
		}
	}
};
export default RecuperatingRendering;