<template>
  <div>
    <div v-if="props.entry_data.new_turn">
      <br />
      <br />
    </div>
    <div :style="getStyle()">{{ props.entry_data.text }}</div>
  </div>
</template>

<script setup>
const props = defineProps({
  entry_data: {
    type: Object,
    required: true,
  }
})

function getStyle() {
  const { color, text } = props.entry_data;
  if (!color) {
    return undefined;
  }

  let color_string = color.toString(16);
  // weird edge cases...
  if (color_string === 'b300') { // kobold
    color_string = '00b300';
  } else if (color_string === 'b42e6') { // invoker
    color_string = '0b42e6';
  } else if (color_string === 'ff00') { // healing
    color_string = '00ff00';
  }
  return `color: #${color_string};`
}
</script>
