<template>
  <Dialog
    :open="true"
    @close="props.onClose"
  >
    <div class="fixed inset-0 flex items-center justify-center p-4 z-20 bg-zinc-700/90">
      <DialogPanel class="border border-white w-[800px] max-h-[90vh] flex flex-col bg-black">
        <DialogTitle class="border-b border-white p-3 text-yellow-300">
          {{ text('ui.warnings.warning') }}
        </DialogTitle>

        <div class="py-6 pl-6 pr-2 flex flex-col">
          <div>
            {{ warning_message }}
          </div>
          <ul class="mt-4 ml-8 list-inside list-disc text-xs">
            <li
              v-for="loadout_name, i of props.affected_loadout_names"
              :key="i"
            >
              {{ loadout_name }}
            </li>
          </ul>
        </div>

        <div class="w-full text-center">
          {{ text('ui.question_proceed') }}
        </div>

        <div class="w-full h-24 px-6 flex justify-center items-center z-10">
          <DTButton
            type="success"
            class="py-1 px-5"
            @click="onYesClick"
          >
            {{ text('ui.yes') }}
          </DTButton>
          <DTButton
            type="error"
            class="ml-3 py-1 px-5"
            @click="props.onClose"
          >
            {{ text('ui.no') }}
          </DTButton>
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup>
import { computed } from 'vue';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
} from '@headlessui/vue';
import EquipmentDisplay from '~/components/common/EquipmentDisplay';
import text from '~/text';
import { DTButton } from '~/view/components/common/DOM';


const props = defineProps({
  affected_loadout_names: {
    type: Array,
    required: true,
  },
  onConfirmed: {
    type: Function,
    required: true,
  },
  game_item: {
    type: Object,
    required: true,
  },
  hero_handle: {
    type: String,
    required: true,
  },
  onClose: {
    type: Function,
    required: true,
  },
});

const warning_message = computed(() => text('ui.warnings.hero_equipment_unassignment_warning')({
  item_name: EquipmentDisplay.getName(props.game_item),
  hero_name: text(`heroes.${props.hero_handle}.name`),
}));

function onYesClick() {
  props.onConfirmed();
  props.onClose();
}
</script>
