import * as PIXI from 'pixi.js';

const LightningEffect = function( duration=700, flash=true ) {
	PIXI.Sprite.call( this );

	switch( Math.round(Math.random()*4) )
	{
		case 0: this.texture = PIXI.utils.TextureCache['effects/lightning1.png']; break;
		case 1: this.texture = PIXI.utils.TextureCache['effects/lightning2.png']; break;
		case 2: this.texture = PIXI.utils.TextureCache['effects/lightning3.png']; break;
		case 3: 
		default: this.texture = PIXI.utils.TextureCache['effects/lightning4.png']; break;
	}

	this.anchor.x = this.anchor.y = 0.5;
	
	this.alpha = 0.7;

	if (flash) {
		var timeline = new TimelineMax();
		timeline.call( ()=>{ this.alpha=0;  }, [], 0.07 );
		timeline.call( ()=>{ this.alpha=0.7;}, [], 0.15 );
		timeline.call( ()=>{ this.alpha=0;  }, [], 0.24 );
		timeline.call( ()=>{ this.alpha=0.7;}, [], 0.4 );
	}

	if( duration ) {
		TweenMax.delayedCall( duration/1000, ()=>{
			if( this.parent ) {
				this.parent.removeChild( this );
			}
		})
	}	
}
LightningEffect.prototype = Object.create( PIXI.Sprite.prototype );
LightningEffect.prototype.constructor = LightningEffect;
export default LightningEffect;