import PuppetMovement, { MELEE_SKILL } from './PuppetMovement';

const MeleeAttackMovement = (puppet) => {     
  const poses = [ 
    { pose:Pose1() },
    { pose:Pose2() },
    { pose:Pose3() },
    { pose:Pose4() }
  ];

  return PuppetMovement({
    poses,
    puppet,
    setPoseDurations: (actor_cooldown) => {
      for (const pose of poses) {
        pose.duration = actor_cooldown / 6;
      }
    },
    skill_type: MELEE_SKILL,
  });
};
export default MeleeAttackMovement;

const Pose1 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -160,
      z: 0,
    },        
    shoulders: {
      x: 0,
      y: -144,
      z: 0,
    },        
    torsoe: {
      x: 0,
      y: -105,
      z: 0,
    },        
    leftHand: {
      x: 0,
      y: -105,
      z: 42
    },        
    rightHand: {
      x: 21,
      y: -72,
      z: -24
    },        
    weaponPitch: -45,
    weaponYaw: 15,        
    leftFoot: {
      x: -6,
      y: 0,
      z: 36
    },        
    rightFoot: {
      x: 6,
      y: 0,
      z: -15
    }
  };
  return pose;       
};
    
const Pose2 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -160,
      z: 0
    },        
    shoulders: {
      x: 0,
      y: -144,
      z: 0,
    },        
    torsoe: {
      x: 0,
      y: -105,
      z: 0
    },        
    leftHand: {
      x: 0,
      y: -105,
      z: 42
    },        
    rightHand: {
      x: 36,
      y: -160,
      z: 0
    },        
    weaponPitch: -45,
    weaponYaw: 15,        
    leftFoot: {
      x: -6,
      y: 0,
      z: 12
    },       
    rightFoot: {
      x: 6,
      y: -12,
      z: -14
    }
  };        
  //forward travel
  for(var part in pose) {
    if(pose[part].z) {
      pose[part].z += 28;
    }       
  }

  return pose;
};
    
const Pose3 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -160,
      z: 0
    },
    shoulders: {
      x: 0,
      y: -144,
      z: 0
    },        
    torsoe: {
      x: 0,
      y: -105,
      z: 0
    },        
    leftHand: {
      x: -18,
      y: -78,
      z: 6
    },      
    rightHand: {
      x: 15,
      y: -165,
      z: 30
    },        
    weaponPitch: -45,
    weaponYaw: -15,
    leftFoot: {
      x: -6,
      y: 0,
      z: 0
    },        
    rightFoot: {
      x: 6,
      y: -0,
      z: 33
    }
  };
  //forward travel
  for(var part in pose) {
    if(pose[part].z) {
      pose[part].z += 36;
    }
  }

  return pose;     
};
    
const Pose4 = function() {
  const pose =  {
    head: {
      x: -10,
      y: -150,
      z: 33
    },        
    shoulders: {
      x: -4,
      y: -138,
      z: 20
    },        
    torsoe: {
      x: 0,
      y: -105,
      z: 0
    },        
    leftHand: {
      x: -30,
      y: -114,
      z: -36
    },        
    rightHand: {
      x: -18,
      y: -66,
      z: 48
    },        
    weaponPitch: 160,
    weaponYaw: -140,        
    leftFoot: {
      x: -6,
      y: 0,
      z: 0
    },        
    rightFoot: {
      x: 6,
      y: -0,
      z: 33
    }
  };
        
  //forward travel
  for(var part in pose) {
    if(pose[part].z) {
      pose[part].z += 36;
    }
  }

  return pose;
};