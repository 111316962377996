import FloatingTextEffect from '../../effects/FloatingTextEffect';
import Colors from '~/constants/Colors';
import text from '~/text';

const ResistRendering = {
  render: function(data, gameBoard, isCaverns) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    if(!actorSprite) {
      return;
    }
			
    var effect = new FloatingTextEffect(text('battle.resist'), 15, 0x999999);
			
    if(actorSprite.textQueue) {
      actorSprite.textQueue.push(effect);
    }
  }
};
export default ResistRendering;