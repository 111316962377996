import PuppetMovement from './PuppetMovement.js';

const RunMovement = (puppet) => {
  const poses = [
    { pose: Pose1 },
    { pose: Pose2 },
    { pose: Pose3 },
    { pose: Pose4 },
    { pose: Pose5 },
    { pose: Pose6 },
    { pose: Pose7 },
    { pose: Pose8 },
    { pose: Pose9 },
    { pose: Pose10 },
    { pose: Pose11 },
    { pose: Pose12 }
  ];
  
  return PuppetMovement({
    poses,
    puppet,
    setPoseDurations: (actor_cooldown) => {
      for (const pose of poses) {
        pose.duration = actor_cooldown / 12;
      }
    }
  });
};
export default RunMovement;

const Pose1 =  {
  head: {
    x: 0,
    y: -145,
    z: 0,
  },
  shoulders: {
    x: 0,
    y: -134,
    z: 0,
  },
  torsoe: {
    x: 0,
    y: -95,
    z: 0,
  },
  leftHand: {
    x: -23,
    y: -105,
    z: 32
  },
  bowPitch: -20,
  bowYaw: 15,
  rightHand: {
    x: 29,
    y: -85,
    z: -26
  },
  weaponPitch: 35,
  weaponYaw: 10,
  leftFoot: {
    x: -17,
    y: -5,
    z: -32
  },
  rightFoot: {
    x: 17,
    y: -48,
    z: 0
  }
};

const Pose2 =  {
  head: {
    x: 0,
    y: -142,
    z: 0,
  },
  shoulders: {
    x: 0,
    y: -132,
    z: 0,
  },
  torsoe: {
    x: 0,
    y: -93,
    z: 0,
  },
  leftHand: {
    x: -20,
    y: -121,
    z: 32
  },
  bowPitch: -10,
  bowYaw: 11,
  rightHand: {
    x: 32,
    y: -105,
    z: -32
  },
  weaponPitch: 29,
  weaponYaw: 6,
  leftFoot: {
    x: -17,
    y: -16,
    z: -60
  },
  rightFoot: {
    x: 17,
    y: -32,
    z: 40
  }
};

const Pose3 =  {
  head: {
    x: 0,
    y: -145,
    z: 0,
  },
  shoulders: {
    x: 0,
    y: -134,
    z: 0,
  },
  torsoe: {
    x: 0,
    y: -95,
    z: 0,
  },
  leftHand: {
    x: -17,
    y: -113,
    z: 26
  },
  bowPitch: 0,
  bowYaw: 7,
  rightHand: {
    x: 35,
    y: -97,
    z: -32
  },
  weaponPitch: 24,
  weaponYaw: 2,
  leftFoot: {
    x: -17,
    y: -32,
    z: -72
  },
  rightFoot: {
    x: 17,
    y: -8,
    z: 50
  }
};

const Pose4 =  {
  head: {
    x: 0,
    y: -148,
    z: 0,
  },
  shoulders: {
    x: 0,
    y: -136,
    z: 0,
  },
  torsoe: {
    x: 0,
    y: -97,
    z: 0,
  },
  leftHand: {
    x: -20,
    y: -113,
    z: 22
  },
  bowPitch: 10,
  bowYaw: 3,
  rightHand: {
    x: 32,
    y: -97,
    z: -16
  },
  weaponPitch: 20,
  weaponYaw: -2,
  leftFoot: {
    x: -17,
    y: -64,
    z: -64
  },
  rightFoot: {
    x: 17,
    y: -2,
    z: 46
  }
};

const Pose5 =  {
  head: {
    x: 0,
    y: -151,
    z: 0,
  },
  shoulders: {
    x: 0,
    y: -138,
    z: 0,
  },
  torsoe: {
    x: 0,
    y: -99,
    z: 0,
  },
  leftHand: {
    x: -23,
    y: -97,
    z: 16
  },
  bowPitch: 20,
  bowYaw: -1,
  rightHand: {
    x: 29,
    y: -93,
    z: -0
  },
  weaponPitch: 0,
  weaponYaw: -6,
  leftFoot: {
    x: -17,
    y: -80,
    z: -32
  },
  rightFoot: {
    x: 17,
    y: 0,
    z: 32
  }
};

const Pose6 =  {
  head: {
    x: 0,
    y: -154,
    z: 0,
  },
  shoulders: {
    x: 0,
    y: -140,
    z: 0,
  },
  torsoe: {
    x: 0,
    y: -101,
    z: 0,
  },
  leftHand: {
    x: -26,
    y: -81,
    z: 0
  },
  bowPitch: 30,
  bowYaw: -5,
  rightHand: {
    x: 26,
    y: -97,
    z: 30
  },
  weaponPitch: -15,
  weaponYaw: -10,
  leftFoot: {
    x: -17,
    y: -64,
    z: -20
  },
  rightFoot: {
    x: 17,
    y: 0,
    z: -10
  }
};

const Pose7 =  {
  head: {
    x: 0,
    y: -157,
    z: 0,
  },
  shoulders: {
    x: 0,
    y: -142,
    z: 0,
  },
  torsoe: {
    x: 0,
    y: -103,
    z: 0,
  },
  leftHand: {
    x: -29,
    y: -100,
    z: -10
  },
  bowPitch: 30,
  bowYaw: -9,
  rightHand: {
    x: 23,
    y: -113,
    z: 36
  },
  weaponPitch: -22,
  weaponYaw: -14,
  leftFoot: {
    x: -17,
    y: -48,
    z: 0
  },
  rightFoot: {
    x: 17,
    y: -10,
    z: -48
  }
};

const Pose8 =  {
  head: {
    x: 0,
    y: -160,
    z: 0,
  },
  shoulders: {
    x: 0,
    y: -144,
    z: 0,
  },
  torsoe: {
    x: 0,
    y: -105,
    z: 0,
  },
  leftHand: {
    x: -32,
    y: -113,
    z: -18
  },
  bowPitch: 25,
  bowYaw: -13,
  rightHand: {
    x: 20,
    y: -129,
    z: 30
  },
  weaponPitch: -8,
  weaponYaw: -15,
  leftFoot: {
    x: -17,
    y: -28,
    z: 32
  },
  rightFoot: {
    x: 17,
    y: -20,
    z: -64
  }
};

const Pose9 =  {
  head: {
    x: 0,
    y: -157,
    z: 0,
  },
  shoulders: {
    x: 0,
    y: -142,
    z: 0,
  },
  torsoe: {
    x: 0,
    y: -103,
    z: 0,
  },
  leftHand: {
    x: -35,
    y: -113,
    z: -16
  },
  bowPitch: 22,
  bowYaw: -12,
  rightHand: {
    x: 17,
    y: -121,
    z: 32
  },
  weaponPitch: 2,
  weaponYaw: -11,
  leftFoot: {
    x: -17,
    y: -12,
    z: 48
  },
  rightFoot: {
    x: 17,
    y: -28,
    z: -60
  }
};

const Pose10 =  {
  head: {
    x: 0,
    y: -154,
    z: 0,
  },
  shoulders: {
    x: 0,
    y: -140,
    z: 0,
  },
  torsoe: {
    x: 0,
    y: -101,
    z: 0,
  },
  leftHand: {
    x: -32,
    y: -90,
    z: 0
  },
  bowPitch: 12,
  bowYaw: -8,
  rightHand: {
    x: 29,
    y: -113,
    z: 32
  },
  weaponPitch: 12,
  weaponYaw: -7,
  leftFoot: {
    x: -17,
    y: -6,
    z: 48
  },
  rightFoot: {
    x: 17,
    y: -64,
    z: -64
  }
};

const Pose11 =  {
  head: {
    x: 0,
    y: -151,
    z: 0,
  },
  shoulders: {
    x: 0,
    y: -138,
    z: 0,
  },
  torsoe: {
    x: 0,
    y: -99,
    z: 0,
  },
  leftHand: {
    x: -29,
    y: -90,
    z: 6
  },
  bowPitch: 0,
  bowYaw: -4,
  rightHand: {
    x: 23,
    y: -90,
    z: 26
  },
  weaponPitch: 24,
  weaponYaw: -3,
  leftFoot: {
    x: -17,
    y: 0,
    z: 28
  },
  rightFoot: {
    x: 17,
    y: -67,
    z: -48
  }
};

const Pose12 =  {
  head: {
    x: 0,
    y: -148,
    z: 0,
  },
  shoulders: {
    x: 0,
    y: -136,
    z: 0,
  },
  torsoe: {
    x: 0,
    y: -97,
    z: 0,
  },
  leftHand: {
    x: -26,
    y: -89,
    z: 28
  },
  bowPitch: -20,
  bowYaw: 0,
  rightHand: {
    x: 26,
    y: -81,
    z: 0
  },
  weaponPitch: 37,
  weaponYaw: 1,
  leftFoot: {
    x: -17,
    y: 0,
    z: 0
  },
  rightFoot: {
    x: 17,
    y: -64,
    z: -32
  }
};
