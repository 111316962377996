import BattleDispatcher from '~/flux/dispatchers/BattleDispatcher';

const BattleActions = {
  selectAbilities: function(ability) {
    BattleDispatcher.handleBattleAction({
      actionType: BattleDispatcher.SELECT_ABILITIES,
    });
  },

  selectAbility: function(ability) {
    BattleDispatcher.handleBattleAction({
      actionType: BattleDispatcher.SELECT_ABILITY,
      ability,
    });
  },

  selectGamePiece: function(gamePiece) {
    BattleDispatcher.handleBattleAction({
      actionType: BattleDispatcher.SELECT_GAME_PIECE,
      gamePiece,
    });
  },

  heroWait: function() {
    BattleDispatcher.handleBattleAction({
      actionType: BattleDispatcher.HERO_WAIT,
    });
  },

  moveBtnTouch: function(dirIndex) {
    BattleDispatcher.handleBattleAction({
      actionType: BattleDispatcher.MOVE_BTN_TOUCH,
      dirIndex,
    });
  },

  executeBtnTouch: function() {
    BattleDispatcher.handleBattleAction({
      actionType: BattleDispatcher.EXECUTE_BTN_TOUCH,
    });
  },

  killBattle: function() {
    BattleDispatcher.handleBattleAction({
      actionType: BattleDispatcher.KILL_BATTLE,
    });
  },
};
export default BattleActions;