<template>
  <div
    id="Footer_DOM"
    class="w-full flex pointer-events-none"
  >
    <Chat class="pointer-events-auto" />
    <CavernsBattleMenu v-if="state.show_caverns_battle_menu" />
    <div
      v-else-if="state.show_spinner"
      class="ml-[50vw] w-[calc(100vw-20px)] h-full flex justify-center items-center"
    >
      <DTSpinner />
    </div>
  </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, reactive } from 'vue';
import { Screens } from '~/constants';
import { BattleStore, CavernsStore, UIStore } from '~/flux/stores';
import { DTSpinner } from '~/view/components/common/DOM';
import Chat from './chat/Chat.vue';
import CavernsBattleMenu from './CavernsBattleMenu.vue';

const state = reactive({
  show_caverns_battle_menu:
    UIStore.getAll().current_ui_screen === Screens.CAVERNS,
});

onMounted(() => {
  BattleStore.on(BattleStore.BATTLE_KILLED, onBattleKilled);
  BattleStore.on(BattleStore.CHALLENGE_RESOLVED, onChallengeResolved);
  CavernsStore.on(CavernsStore.ENTERED_CAVERNS, onEnteredCaverns);
  CavernsStore.on(CavernsStore.RETURNED_TO_SURFACE, onReturnedToSurface);
});

onBeforeUnmount(() => {
  BattleStore.removeListener(BattleStore.BATTLE_KILLED, onBattleKilled);
  BattleStore.removeListener(
    BattleStore.CHALLENGE_RESOLVED,
    onChallengeResolved
  );
  CavernsStore.removeListener(CavernsStore.ENTERED_CAVERNS, onEnteredCaverns);
  CavernsStore.removeListener(
    CavernsStore.RETURNED_TO_SURFACE,
    onReturnedToSurface
  );
});

function onEnteredCaverns() {
  state.show_caverns_battle_menu = true;
}

function onReturnedToSurface() {
  state.show_caverns_battle_menu = false;
}

function onBattleKilled() {
  state.show_spinner = true;
}

function onChallengeResolved() {
  state.show_spinner = true;

  // in ~/view/game-screens/battle/canvas/BattleScreen.js,
  // 2.6 seconds is the delay we give to allow the last unit
  // death animation to run
  setTimeout(() => {
    state.show_spinner = false;
  }, 2600);
}
</script>
