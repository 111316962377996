import { Dispatcher } from 'flux';

const GameItemDispatcher = Object.assign(new Dispatcher(), {
  NAME: 'GAME_ITEM_DISPATCHER',

  ACTIVATE_MIRACLE_DYE: 'ACTIVATE_MIRACLE_DYE',
  DYE_ITEM: 'DYE_ITEM',
  EQUIP_BAG_TO_GLOBAL_BAG_SLOT: 'EQUIP_BAG_TO_GLOBAL_BAG_SLOT',
  EQUIP_BAG_TO_HERO_BAG_SLOT: 'EQUIP_BAG_TO_HERO_BAG_SLOT',
  EQUIP_ITEM: 'EQUIP_ITEM',
  GLOBAL_BAG_SLOT_UNLOCK_CONFIRM: 'GLOBAL_BAG_SLOT_UNLOCK_CONFIRM',
  HERO_BAG_SLOT_UNLOCK_CONFIRM: 'HERO_BAG_SLOT_UNLOCK_CONFIRM',
  PLACE_ITEM_IN_GLOBAL_INVENTORY: 'PLACE_ITEM_IN_GLOBAL_INVENTORY',
  PLACE_ITEM_IN_HERO_INVENTORY: 'PLACE_ITEM_IN_HERO_INVENTORY',
  UNEQUIP_ITEM: 'UNEQUIP_ITEM',

  handleGameItemAction: function(action) {
    this.dispatch({
      source: GameItemDispatcher.NAME,
      action,
    });
  },
});
export default GameItemDispatcher;
