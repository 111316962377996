<template>
  <div>
    <div class="p-2 border border-zinc-700 rounded-lg bg-blue-400/20">
      <div>{{ props.lets_deal_message }}</div>
      <div
        id="items_to_sell_container"
        class="max-w-[450px] pt-2 flex flex-wrap"
      >
        <div
          v-for="item_uid in props.modelValue"
          :id="`items_to_sell_${item_uid}`"
          :style="`width: ${state.game_item_icon_size_px}px; height: ${state.game_item_icon_size_px}px;`"
        ></div>
      </div>
    </div>
    <div class="mt-2 flex">
      <DTButton
        type="success"
        class="ml-2 py-0 px-2"
        @click="onYesClick"
      >
        {{ text('ui.OK') }}
      </DTButton>
      <DTButton
        type="error"
        class="ml-5 py-0 px-2"
        @click="$emit('cancel')"
      >
        {{ text('ui.no') }}
      </DTButton>
      <DTButton
        type="danger"
        class="ml-5 py-0 px-2"
        @click="onHaggleClick"
      >
        {{ text('ui.haggle') }}
      </DTButton>
      <div class="ml-3 border border-zinc-800/80 rounded-md flex items-center">
        <img :src="`${Config.DOM_IMG_PATH}/gold.png`" />
        <div class="ml-3">
          {{ Tools.formatGameCurrency(state.total_sale_amount) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  nextTick,
  onBeforeUnmount,
  onMounted,
  onUpdated,
  reactive,
  watch,
} from 'vue';
import describeInventoryItemByUID from 'dt-common/isomorphic-helpers/describeInventoryItemByUID';
import Audio from '~/Audio';
import GameItemIcon from '~/components/common/GameItemIcon';
import { Config } from '~/constants';
import { ShopActions } from '~/flux/actions';
import { GameStateStore, ShopStore } from '~/flux/stores';
import text from '~/text';
import { DTButton } from '~/view/components/common/DOM';
import Tools from '~/Tools';
import CanvasTools from '~/view/CanvasTools';

const props = defineProps({
  canvas_root: {
    type: Object,
    required: true,
  },
  lets_deal_message: {
    type: String,
    required: true,
  },
  modelValue: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue', 'item-clicked', 'cancel']);

let _items_to_sell_icons;
let _last_item_uids_to_sell = [];

const state = reactive({
  total_sale_amount: '',
  game_item_icon_size_px: 48,
});

// watch(
//   () => props.modelValue,
//   (newModelValue) => {
//     state.game_item_icon_size_px = calcGameItemSize(newModelValue.length);
//   },
//   { deep: true }
// );

function calcGameItemSize(num_items) {
  return Math.min(48, 15 + 650 / num_items);
}

onMounted(() => {
  state.game_item_icon_size_px = calcGameItemSize(props.modelValue.length);

  nextTick(makeItemsToSellIcons);

  ShopStore.on(ShopStore.GOT_TOTAL_SALE_AMOUNT, onGotTotalSaleAmount);
  ShopActions.fetchShopkeeperOffer({ item_uids_to_sell: props.modelValue });
});

onBeforeUnmount(() => {
  destroyItemsToSellIcons();
  ShopStore.removeListener(
    ShopStore.GOT_TOTAL_SALE_AMOUNT,
    onGotTotalSaleAmount
  );
});

onUpdated(() => {
  const item_uids_to_sell = props.modelValue;
  if (
    JSON.stringify(item_uids_to_sell) !==
    JSON.stringify(_last_item_uids_to_sell)
  ) {
    ShopActions.fetchShopkeeperOffer({ item_uids_to_sell });
    _last_item_uids_to_sell = [...item_uids_to_sell];
  }
  state.game_item_icon_size_px = calcGameItemSize(item_uids_to_sell.length);
  nextTick(makeItemsToSellIcons);
});

function makeItemsToSellIcons() {
  destroyItemsToSellIcons();
  _items_to_sell_icons = [];

  for (let i = 0; i < props.modelValue.length; ++i) {
    const item_to_sell_uid = props.modelValue[i];
    const item_to_sell = describeInventoryItemByUID(
      GameStateStore.getAll().gameState.inventory,
      item_to_sell_uid
    ).item;

    // make game item icon & add to stage
    const item_to_sell_icon = new GameItemIcon(item_to_sell);
    item_to_sell_icon.setScale(state.game_item_icon_size_px);
    const { x, y, width, height } = CanvasTools.getDomAnchor(
      `items_to_sell_${item_to_sell.uid}`
    );
    item_to_sell_icon.x = x + width / 2;
    item_to_sell_icon.y = y + height / 2;
    props.canvas_root.addChildAt(item_to_sell_icon, 1); // add at 1 so as not to mask faerie spinners on equipment change
    _items_to_sell_icons.push(item_to_sell_icon);

    // add interaction listener
    item_to_sell_icon.tap = item_to_sell_icon.click = ((item_uid) => {
      emit('item-clicked', item_uid);
    }).bind(null, item_to_sell.uid);
  }
}

function destroyItemsToSellIcons() {
  for (const game_item_icon of _items_to_sell_icons || []) {
    game_item_icon?.dispose();
  }
  _items_to_sell_icons = null;
}

function onGotTotalSaleAmount({ gold_value }) {
  state.total_sale_amount = gold_value;
  Audio.play('coin_rattle');
}

function onYesClick() {
  ShopActions.confirmItemSale();
  emit('cancel');
}

function onHaggleClick() {
  ShopActions.fetchShopkeeperOffer({ item_uids_to_sell: props.modelValue });
}
</script>
