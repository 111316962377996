import { EventEmitter } from 'events'
import PlayerDispatcher from '~/flux/dispatchers/PlayerDispatcher'
import BattleDebriefDispatcher from '~/flux/dispatchers/BattleDebriefDispatcher'
import { awaitSocket, registerDispatchHandlers } from '~/Tools'

var _socket
var playerId

// the stuff we serve:
var battleLog

const NotificationsStore = Object.assign({}, EventEmitter.prototype, {
  GOT_BATTLE_LOG: 'GOT_BATTLE_LOG',
  
  getAll() {
    return {
      battleLog
    }
  }
})
export default NotificationsStore

PlayerDispatcher.register(registerDispatchHandlers({
  [PlayerDispatcher.PLAYER_LOGGED_IN]: onPlayerLoggedIn
}))
BattleDebriefDispatcher.register(registerDispatchHandlers({
  [BattleDebriefDispatcher.DELETE_BATTLE_LOG_ENTRY]: deleteBattleLogEntry
}))

awaitSocket(onSocketConnected);
// awaitSocket().then(onSocketConnected);
function onSocketConnected(socket) {
  try {
    _socket = socket
    if (!_socket.has_NotificationsStore_listeners) {
      _socket.on('battleResolvedNotif', onBattleResolvedNotif)
      _socket.on('battleLog', onBattleLog)

      _socket.has_NotificationsStore_listeners = true
    }
  } catch (err) {
    logError(err, {
      module: 'NotificationsStore',
      func: 'onSocketConnected'
    })
  }
}

function onPlayerLoggedIn(action) {
  try {
    playerId = action.player._id
    _socket.emit('get_battle_log', { playerId })
  } catch (err) {
    logError(err, {
      module: 'NotificationsStore',
      func: 'onPlayerLoggedIn',
      action
    })
  }
}

function onBattleLog(data) {
  try {
    if (data._id !== playerId) {
      throw new Error('got battle log for wrong player')
    }

    battleLog = data.battleLog
    NotificationsStore.emit(NotificationsStore.GOT_BATTLE_LOG)
  } catch (err) {
    logError(err, {
      module: 'NotificationsStore',
      func: 'onBattleLog',
      playerId,
      data
    })
  }
}


function onBattleResolvedNotif(data) {
  // TODO(@rob-wfs): not necessary to fetch the whole battle log
  _socket.emit('get_battle_log', { playerId })
}

function deleteBattleLogEntry(action) {
  _socket.emit('delete_battle_log_entry', {
    playerId,
    battle_id: action.battle_id
  })
}
