import PuppetMovement, { BOW_SKILL } from './PuppetMovement';

var FireBowMovement = (puppet) => {
  const poses = [
    { pose: Pose1() },
    { pose: Pose2() },
    { pose: Pose3() },
    { pose: Pose3() },
    { pose: Pose2() },
  ];

  return PuppetMovement({
    poses,
    puppet,
    setPoseDurations: (actorCooldown) => {
      poses[0].duration = (actorCooldown/8);
      poses[1].duration = (actorCooldown/8);
      poses[2].duration = (actorCooldown/8);
      poses[3].duration = (actorCooldown/8)*2;
      poses[4].duration = (actorCooldown/8)*3;
    },
    skill_type: BOW_SKILL,
  });
};
export default FireBowMovement;

const Pose1 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -160,
      z: 0,
    },
    shoulders: {
      x: 0,
      y: -144,
      z: 0,
    },
    torsoe: {
      x: 0,
      y: -105,
      z: 0,
    },
    leftHand: {
      x: -12,
      y: -102,
      z: 12,
    },
    bowPitch: 30,
    bowYaw: 45,
    rightHand: {
      x: 6,
      y: -186,
      z: -28,
    },
    weaponPitch: 0,
    weaponYaw: 0,
    leftFoot: {
      x: 0,
      y: 0,
      z: 42,
    },
    rightFoot: {
      x: 0,
      y: 0,
      z: -24,
    },
  };

  return pose;
};

const Pose2 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -160,
      z: 0,
    },
    shoulders: {
      x: 0,
      y: -144,
      z: 0,
    },
    torsoe: {
      x: 0,
      y: -105,
      z: 0,
    },
    leftHand: {
      x: 20,
      y: -84,
      z: 6,
    },
    bowPitch: 55,
    bowYaw: 85,
    rightHand: {
      x: 20,
      y: -84,
      z: -6,
    },
    weaponPitch: 0,
    weaponYaw: 0,
    leftFoot: {
      x: 0,
      y: 0,
      z: 42,
    },
    rightFoot: {
      x: 0,
      y: 0,
      z: -24,
    },
  };

  return pose;
};

const Pose3 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -160,
      z: 0,
    },
    shoulders: {
      x: 0,
      y: -144,
      z: 0,
    },
    torsoe: {
      x: 0,
      y: -105,
      z: 0,
    },
    leftHand: {
      x: 0,
      y: -144,
      z: 84,
    },
    bowPitch: 0,
    bowYaw: 20,
    rightHand: {
      x: 0,
      y: -144,
      z: -5,
    },
    weaponPitch: 0,
    weaponYaw: 0,
    leftFoot: {
      x: 0,
      y: 0,
      z: 42,
    },
    rightFoot: {
      x: 0,
      y: 0,
      z: -24,
    },
  };

  return pose;
};