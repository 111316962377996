import ApplicationDispatcher from '~/flux/dispatchers/ApplicationDispatcher';
import AccountStore from '~/flux/stores/AccountStore';
import ApplicationStore from '~/flux/stores/ApplicationStore';

var ApplicationActions = {
  onAssetsLoaded() {
    ApplicationDispatcher.handleAppAction({
      actionType: ApplicationDispatcher.ASSETS_LOADED
    });
  },

  connectApiSocket(token_data) {
    ApplicationDispatcher.handleAppAction({
      actionType: ApplicationDispatcher.CONNECT_API_SOCKET,
      ...token_data,
    });
  },

  uiNav(screen_id, force=false) {
    ApplicationDispatcher.handleAppAction({
      actionType: ApplicationDispatcher.UI_NAV,
      screen_id,
      force
    });
  },

  showPixieDustStore() {
    ApplicationDispatcher.handleAppAction({
      actionType: ApplicationDispatcher.SHOW_PD_STORE
    });
  },

  goldBtnSelected() {
    ApplicationDispatcher.handleAppAction({
      actionType: ApplicationDispatcher.GOLD_BTN_SELECTED
    });
  },

  battleLogSelected() {
    ApplicationDispatcher.handleAppAction({
      actionType: ApplicationDispatcher.BATTLE_LOG_SELECTED
    });
  },

  leaderboardSelected() {
    ApplicationDispatcher.handleAppAction({
      actionType: ApplicationDispatcher.LEADERBOARD_SELECTED
    });
  },
};
export default ApplicationActions;

AccountStore.on(AccountStore.AUTHENTICATED, token_data => {
  ApplicationActions.connectApiSocket(token_data);
});

ApplicationStore.on(ApplicationStore.SOCKET_CONNECTED, socket => {
  ApplicationDispatcher.handleAppAction({
    actionType: ApplicationDispatcher.SOCKET_CONNECTED,
    socket
  });
});
