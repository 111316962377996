import * as PIXI from 'pixi.js';
import Audio from '~/Audio';

const RoundhouseRendering = {
	render: function( data, gameBoard, isCaverns ) {
		var actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
		const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

		var _timeline = new TimelineMax();
		
		TweenMax.delayedCall( transitTime*0.5, ()=> {
			if (render_movement) {
				_timeline.add( TweenMax.to( actorSprite.puppet, 0.35, {alpha:0, yoyo:true, repeat:1} ) );
			}
				
			var image = new PIXI.Sprite();
      image.texture = PIXI.utils.TextureCache['effects/roundhouse.png'];
			image.anchor.x = image.anchor.y = 0.5;
			image.rotation = Math.random() * 361 * (Math.PI/180);
			image.alpha = 0.0;
			actorSprite.addChild( image );
			
			_timeline.add(
				TweenMax.to( image, 0.4, {
					alpha: 1.0,
					ease: Quad.easeOut,
					onComplete: fadeOut,
					onCompleteParams: [image]
				})
			);
			
			spin( image );
		
			Audio.play( 'roundhouse' );
		});
		
		function fadeOut( image ) {
			_timeline.add( 
				TweenMax.to( image, 0.4, {
					alpha: 0,
					ease:Quad.easeIn,
					onComplete: (img) => { if(img.parent) img.parent.removeChild(img); },
					onCompleteParams: [image]
				}), 
				_timeline._time
			);
		}
		
		function spin( image ) {
			if( !image.parent ) {
				return;
			}

			_timeline.add( 
				TweenMax.to( image, 0.3, {
					rotation: image.rotation+360*(Math.PI/180),
					ease: Linear.easeNone,
					onComplete: spin,
					onCompleteParams: [image]
				}),
				_timeline._time
			);
		}
	}
};
export default RoundhouseRendering;
