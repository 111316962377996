import * as PIXI from 'pixi.js';

const GravitySprite = function (texture) {
  PIXI.Sprite.call(this);

  this.texture = texture;

  this.affectedByGravity = true;
  this.floorY = 0;
  this.yVelocity = 0;

  var _gravityCoefficient = 0.026; // 0.000008333; // 0.008333; // 0.5 for 60fps

  var _runTime;
  var _lastUpdate = Date.now();
  var _deltaTime;

  var _frameId;

  const onEnterFrame = () => {
    _frameId = requestAnimationFrame(onEnterFrame);

    if (this.affectedByGravity) {
      _runTime = Date.now();
      _deltaTime = _runTime - _lastUpdate;
      _lastUpdate = _runTime;

      this.yVelocity += _gravityCoefficient * _deltaTime;

      this.y += this.yVelocity; //  * (30.0/stage.frameRate);

      // check for bounce
      if (this.y >= this.floorY) {
        this.y = this.floorY + 1;
        this.yVelocity *= -0.52 + Math.random() * 0.32;

        dispatchEvent(new Event('HIT_FLOOR'));
      }

      // check for resting on floor
      if (
        Math.abs(this.y - this.floorY) < 2 &&
        this.yVelocity < 0.3 &&
        this.yVelocity > -0.3
      ) {
        this.affectedByGravity = false;
      }
    }
  };

  this.init = () => {
    this.affectedByGravity = true;
    _lastUpdate = Date.now();
    cancelAnimationFrame(_frameId);
    _frameId = requestAnimationFrame(onEnterFrame);
  };

  this.dispose = function () {
    cancelAnimationFrame(_frameId);
  };
};
GravitySprite.prototype = Object.create(PIXI.Sprite.prototype);
GravitySprite.prototype.constructor = GravitySprite;
export default GravitySprite;
