import AltarDispatcher from '~/flux/dispatchers/AltarDispatcher';

const AltarActions = {

  getAltarState: function() {
    AltarDispatcher.handleAltarAction({
      actionType: AltarDispatcher.GET_ALTAR_STATE,
    });
  },

  startNewPrayer: function(slotIndex) {
    AltarDispatcher.handleAltarAction({
      actionType: AltarDispatcher.START_NEW_PRAYER,
      slotIndex,
    });
  },

  updatePrayerGoldValue: function(goldValue) {
    AltarDispatcher.handleAltarAction({
      actionType: AltarDispatcher.UPDATE_PRAYER_GOLD_VALUE,
      goldValue,
    });
  },

  updatePrayerItemData: function(itemData) {
    AltarDispatcher.handleAltarAction({
      actionType: AltarDispatcher.UPDATE_PRAYER_ITEM_DATA,
      itemData,
    });
  },

  updatePrayerGod: function(godHandle) {
    AltarDispatcher.handleAltarAction({
      actionType: AltarDispatcher.UPDATE_PRAYER_GOD,
      godHandle,
    });
  },

  setNewPrayerParameter: function() {
    AltarDispatcher.handleAltarAction({
      actionType: AltarDispatcher.SET_NEW_PRAYER_PARAMETER,
    });
  },

  submitPrayer: function(prayerData) {
    AltarDispatcher.handleAltarAction({
      actionType: AltarDispatcher.SUBMIT_PRAYER,
      prayerData,
    });
  },

  completePrayer(prayerSlotIndex) {
    AltarDispatcher.handleAltarAction({
      actionType: AltarDispatcher.COMPLETE_PRAYER,
      prayerSlotIndex,
    });
  },

  showUnlockPrayerSlotDialog: function() {
    AltarDispatcher.handleAltarAction({
      actionType: AltarDispatcher.SHOW_UNLOCK_PRAYER_SLOT_DIALOG,
    });
  },

  prayerSlotUnlockConfirm: function() {
    AltarDispatcher.handleAltarAction({
      actionType: AltarDispatcher.UNLOCK_PRAYER_SLOT,
    });
  },

  usePrayerCandle: function(candleId, candleIndex, targetPlayerName) {
    AltarDispatcher.handleAltarAction({
      actionType: AltarDispatcher.USE_PRAYER_CANDLE,
      candleId,
      candleIndex,
      targetPlayerName,
    });
  },
};
export default AltarActions;