import PuppetMovement from './PuppetMovement';

var MagicProjectileMovement = (puppet) => {
  const poses = [ 
    { pose:Pose1() },
    { pose:Pose2() },
    { pose:Pose3() },
    { pose:Pose4() },
    { pose:Pose4() },
    { pose:Pose3() }
  ];
     
  return PuppetMovement({
		poses,
    puppet,
    setPoseDurations: (actorCooldown) => {
      const transTime = actorCooldown/8;
      poses[0].duration = transTime;
      poses[1].duration = transTime;
      poses[2].duration = transTime;
      poses[3].duration = transTime;
      poses[4].duration = transTime;
      poses[5].duration = transTime*3;
    }
  });
};
export default MagicProjectileMovement;

const Pose1 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -160,
      z: 0
    },        
    shoulders: {
      x: 0,
      y: -144,
      z: 0
    },        
    torsoe: {
      x: 0,
      y: -105,
      z: 0
    },        
    leftHand: {
      x: 24,
      y: -184,
      z: 36
    },    
    bowPitch: -50,
    bowYaw: 70,    
    rightHand: {
      x: 45,
      y: -187,
      z: 39
    },        
    weaponPitch: -50,
    weaponYaw: 70,        
    leftFoot: {
      x: -12,
      y: 0,
      z: 30
    },        
    rightFoot: {
      x: 12,
      y: 0,
      z: -12
    }
  };

  return pose;       
};

const Pose2 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -160,
      z: -14
    },        
    shoulders: {
      x: 0,
      y: -144,
      z: -10
    },        
    torsoe: {
      x: 0,
      y: -105,
      z: 0
    },        
    leftHand: {
      x: 24,
      y: -180,
      z: 0
    },    
    bowPitch: -90,
    bowYaw: 30,    
    rightHand: {
      x: 30,
      y: -180,
      z: -30
    },        
    weaponPitch: -90,
    weaponYaw: 30,        
    leftFoot: {
      x: -12,
      y: 0,
      z: 30
    },        
    rightFoot: {
      x: 12,
      y: 0,
      z: 12
    }
  };

  return pose;       
};

const Pose3 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -160,
      z: -14
    },        
    shoulders: {
      x: 0,
      y: -144,
      z: -10
    },        
    torsoe: {
      x: 0,
      y: -105,
      z: 0
    },        
    leftHand: {
      x: 16,
      y: -120,
      z: 10
    },    
    bowPitch: -40,
    bowYaw: 40,    
    rightHand: {
      x: 24,
      y: -110,
      z: -10
    },        
    weaponPitch: 0,
    weaponYaw: 0,        
    leftFoot: {
      x: -12,
      y: 0,
      z: 30
    },        
    rightFoot: {
      x: 12,
      y: 0,
      z: 12
    }
  };

  return pose;       
};

const Pose4 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -132,
      z: 33
    },        
    shoulders: {
      x: 0,
      y: -119,
      z: 24
    },        
    torsoe: {
      x: 0,
      y: -88,
      z: 0
    },        
    leftHand: {
      x: -6,
      y: -99,
      z: 83
    },    
    bowPitch: 0,
    bowYaw: 90,    
    rightHand: {
      x: 6,
      y: -99,
      z: 83
    },        
    weaponPitch: 0,
    weaponYaw: 72,        
    leftFoot: {
      x: -12,
      y: 0,
      z: 36
    },        
    rightFoot: {
      x: 12,
      y: 0,
      z: -32
    }
  };

  return pose;       
};