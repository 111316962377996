import * as PIXI from 'pixi.js';

const AbilityIcon = function( ability ) {
	PIXI.Sprite.call( this );

	this.getAbility = function() { return ability; };

	if( !ability ) {
		this.texture = PIXI.utils.TextureCache['armory/ability_slot_empty.png'];
	} else {
		this.texture = PIXI.utils.TextureCache[ability.handle+'.png'];
	}		

	this.dispose = function() {
		this.removeChildren();
	}
}
AbilityIcon.prototype = Object.create( PIXI.Sprite.prototype );
AbilityIcon.prototype.constructor = AbilityIcon;
export default AbilityIcon;