import Audio from '~/Audio';

const SlamRendering = {
	render: function( data, gameBoard, isCaverns ) {
		const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
		const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

		render_movement && actorSprite.puppet.getMovements().slam.movePuppet({ transitTime });
	},

	renderResult: function( data, gameBoard ) {
		if( data.success ) {
			Audio.play( 'thump_02' );
		}
	}
};
export default SlamRendering;