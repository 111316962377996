import { Dispatcher } from 'flux';

const ChatDispatcher = Object.assign(new Dispatcher(), {
  ENTER_MESSAGE: 'ENTER_MESSAGE',
  SET_CHAT_TEXT_COLOR: 'SET_CHAT_TEXT_COLOR',
  GAME_NOTIFICATION: 'GAME_NOTIFICATION',
    
  handleChatAction: function(action) {
    this.dispatch({
      source: 'CHAT_ACTION',
      action: action
    });
  }
});
export default ChatDispatcher;
