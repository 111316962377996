/**
 * ...
 * @author Robert Lombardo, Wakefield Studios ( rob@wakefield-studios.com )
 */	
import StripAnimation from './StripAnimation';

const VoidSpikeEffect = function( duration ) {
	return new StripAnimation( 
		'effects/void_spike',
		duration,
		10,
		false
	);	
}
export default VoidSpikeEffect;