import * as PIXI from 'pixi.js';
import { EventEmitter } from 'events';
import BattleCalc from 'dt-common/battle_engine/BattleCalc';
import PinwheelPanel from '~/components/common/PinwheelPanel';
import CanvasTools from '~/view/CanvasTools';
import BattleStore from '~/flux/stores/BattleStore';

const ActionSelectPanel = function(hero) {
  PIXI.Container.call(this);

  DT_CANVAS_GLOBALS.actionSelectPanel = this;

  this.eventEmitter = new EventEmitter();

  var pinwheel = new PinwheelPanel();

  // make move button
  var _moveBtn = this.moveBtn = new PIXI.Sprite();
  _moveBtn.texture = PIXI.utils.TextureCache['icon_move_inactive.png'];
  _moveBtn.anchor.x = _moveBtn.anchor.y = 0.5;
  _moveBtn.interactive = true;
  _moveBtn.buttonMode = true;
  _moveBtn.mousedown = _moveBtn.touchstart = (event) => {
    if (_moveBtn.interactive) {
      this.eventEmitter.emit('MOVE', BattleCalc.getEquippedAbility(hero,'move'));
    }
  };

  // make melee attack button
  var _meleeAttackBtn = this.meleeAttackBtn = new PIXI.Sprite();
  _meleeAttackBtn.texture = PIXI.utils.TextureCache['icon_attack_inactive.png'];
  const BUTTON_WIDTH = this.buttonWidth = _meleeAttackBtn.width;
  _meleeAttackBtn.anchor.x = _meleeAttackBtn.anchor.y = 0.5;
  _meleeAttackBtn.interactive = true;
  _meleeAttackBtn.buttonMode = true;
  _meleeAttackBtn.mousedown = _meleeAttackBtn.touchstart = (event) => {
    if (_meleeAttackBtn.interactive) {
      this.eventEmitter.emit('MELEE_ATTACK', BattleCalc.getEquippedAbility(hero,'melee_attack'));
    }
  };

  // make ability button
  var _abilityBtn = new PIXI.Sprite();
  _abilityBtn.texture = PIXI.utils.TextureCache['icon_ability.png'];
  _abilityBtn.anchor.x = _abilityBtn.anchor.y = 0.5;
  _abilityBtn.interactive = true;
  _abilityBtn.buttonMode = true;
  _abilityBtn.mousedown = _abilityBtn.touchstart = (event) => {
    if (_abilityBtn.interactive) {
      this.eventEmitter.emit('ABILITIES');
    }
  };

  // (only if some abilities are equipped)
  if (typeof hero.equipped_abilities === 'object') {
    hero.equipped_abilities = Object.values(hero.equipped_abilities);
  }
  let numEquippedAbilities = 0;
  for (let i = 0; i < hero.equipped_abilities.length; ++i) {
    let abilityTag = hero.equipped_abilities[ i ];
    let ability = BattleCalc.getEquippedAbility(hero, abilityTag);
    if (abilityTag && abilityTag!=='move' && abilityTag!=='melee_attack' && ability && !ability.passive) {
      ++numEquippedAbilities;
    }
  }
  if (numEquippedAbilities === 0) {
    _abilityBtn.alpha = 0.2;
    _abilityBtn.interactive = _abilityBtn.buttonMode = false;
  }

  // if( !BattleStore.getAll().battleState.tutorial || hero.ap > 0 ) {
  this.addChild(_moveBtn);
  pinwheel.pinwheelObjects.push(_moveBtn);

  this.addChild(_meleeAttackBtn);
  pinwheel.pinwheelObjects.push(_meleeAttackBtn);

  this.addChild(_abilityBtn);
  pinwheel.pinwheelObjects.push(_abilityBtn);
  // }

  // make wait button
  var _waitBtn = this.waitBtn = new PIXI.Sprite();
  _waitBtn.texture = PIXI.utils.TextureCache['icon_wait_inactive.png'];
  _waitBtn.anchor.x = _waitBtn.anchor.y = 0.5;
  _waitBtn.interactive = true;
  _waitBtn.buttonMode = true;
  _waitBtn.mousedown = _waitBtn.touchstart = (event) => {
    if (_waitBtn.interactive) {
      this.eventEmitter.emit('WAIT');
    }
  };
  this.addChild(_waitBtn);
  pinwheel.pinwheelObjects.push(_waitBtn);
  if (hero.ap >= hero.max_ap) {
    _waitBtn.alpha = 0.2;
    _waitBtn.interactive = _waitBtn.buttonMode = false;
  }

  const hotkeyStyle = {
    fontFamily: 'Courier New',
    fontSize: CanvasTools.dynamicFontSizeString(10),
    fontStyle: 'bold',
    fill: 0xffffff,
  };

  if (!BattleStore.getAll().battleState.tutorial) {
    for (var j = 0; j < pinwheel.pinwheelObjects.length; ++j) {
      var icon = pinwheel.pinwheelObjects[ j ];

      // size all the buttons
      icon.height = Math.min(icon.height, window.innerHeight*0.085);
      icon.scale.x = icon.scale.y;

      // store the index
      icon.index = j+1;

      // add hotkey text
      var hotkeyText = new PIXI.Text(icon.index.toString(), hotkeyStyle);
      hotkeyText.x = -this.buttonWidth/2 - hotkeyText.width - 5;
      hotkeyText.y = -hotkeyText.height/2;
      icon.addChild(hotkeyText);
    }
  }

  pinwheel.pinwheelDelay = 0.1;
  pinwheel.pinwheelDuration = 0.4;
  pinwheel.radius = window.innerHeight * 0.075;
  pinwheel.init();

  const onKeydown = (event) => {
    for (var i = 0; i < pinwheel.pinwheelObjects.length; ++i) {
      var btn = pinwheel.pinwheelObjects[i];
      if (btn.index && event.key === btn.index.toString()) {
        btn.mousedown({ target: btn });
      }
    }
  };
  document.addEventListener('keydown', onKeydown);

  this.disable = () => {
    document.removeEventListener('keydown', onKeydown);
    this.eventEmitter.removeAllListeners();
  };

  this.dispose = () => {
    this.disable();

    this.eventEmitter = null;

    if (_moveBtn) {
      _moveBtn.mousedown = _moveBtn.touchstart = null;
      _moveBtn = null;
    }

    if (_meleeAttackBtn) {
      _meleeAttackBtn.mousedown = _meleeAttackBtn.touchstart = null;
      _meleeAttackBtn = null;
    }

    if (_abilityBtn) {
      _abilityBtn.mousedown = _abilityBtn.touchstart = null;
      _abilityBtn = null;
    }

    if (_waitBtn) {
      _waitBtn.mousedown = _waitBtn.touchstart = null;
      _waitBtn = null;
    }

    this.removeChildren();
  };
};
ActionSelectPanel.prototype = Object.create(PIXI.Container.prototype);
ActionSelectPanel.prototype.constructor = ActionSelectPanel;
export default ActionSelectPanel;
