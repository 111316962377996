import { EventEmitter } from 'events';
import { io } from 'socket.io-client';
import Colors from '~/constants/Colors';
import { ApplicationDispatcher } from '~/flux/dispatchers';
import { registerDispatchHandlers } from '~/Tools';
import Config from '~/constants/Config';

if (Config.PLATFORM === 'yandex' && window.YaGames) {
  window.YaGames.init().then((ysdk) => {
    console.info('Yandex SDK initialized');
    window.ysdk = ysdk;
    !window.ysdk_ready_sent && ysdk?.features?.LoadingAPI?.ready();

    window.addEventListener('visibilitychange', onVisibilityChange);
    function onVisibilityChange() {
      if (document.hidden || document.webkitHidden) {
        ysdk?.features?.GameplayAPI?.stop();
      } else {
        ysdk?.features?.GameplayAPI?.start();
      }
    }
  });
}

// the stuff we serve:
let socket = null;
let playersOnline = 0;
let window_has_focus = true;

const ApplicationStore = Object.assign({}, EventEmitter.prototype, {
  API_ERROR: 'API_ERROR',
  ASSETS_LOADED: 'ASSETS_LOADED',
  CONNECT_ERROR_INVALID_TOKEN: 'CONNECT_ERROR_INVALID_TOKEN',
  GAMESPARKS_ERROR: 'GAMESPARKS_ERROR',
  NUM_PLAYERS_ONLINE: 'NUM_PLAYERS_ONLINE',
  SOCKET_CONNECTED: 'SOCKET_CONNECTED',
  SOCKET_DISCONNECTED: 'SOCKET_DISCONNECTED',
  WINDOW_LOST_FOCUS: 'WINDOW_LOST_FOCUS',
  WINDOW_GOT_FOCUS: 'WINDOW_GOT_FOCUS',

  getAll() {
    return {
      socket,
      playersOnline,
      window_has_focus,
    };
  },
});
export default ApplicationStore;

ApplicationDispatcher.register(
  registerDispatchHandlers({
    [ApplicationDispatcher.CONNECT_API_SOCKET]: connectApiSocket,
    [ApplicationDispatcher.ASSETS_LOADED]: onAssetsLoaded,
  })
);

function connectApiSocket({
  browser_id,
  firebase_token,
  kong_token,
  kong_userId,
  steam_accountId,
  steamId64,
  steam_game_language,
  yandex_accountId,
  is_token_refresh,
}) {
  try {
    socket?.close();
    socket = io(process.env.DT_API_ADDRESS || 'http://localhost:3001', {
      auth: {
        browser_id,
        firebase_token,
        kong_token,
        kong_userId,
        steam_accountId,
        steamId64: steamId64?.toString(),
        steam_game_language,
        yandex_accountId,
      },
      secure: true,
      timeout: 3000,
    });

    socket.on('connected', onSocketConnected);
    socket.on('connect_error', (err) => {
      console.error(`connect_error due to ${err.message}`);
    });
    socket.io.on('reconnect_error', (err) => {
      console.error(`reconnect_error due to ${err.message}`);
    });
    socket.io.on('reconnect_failed', () => {
      console.error('reconnect_failed');
    });
    socket.on('disconnect', (reason) => {
      console.warn('socket disconnect. reason: ', reason);
      if (reason === 'io server disconnect') {
        // the disconnection was initiated by the server, you need to reconnect manually
        socket.connect();
      }
      // else the socket will automatically try to reconnect
    });
    socket.on('error', onSocketError);
    socket.on('api_server_error', onApiServerError);
    socket.on('noRedlock', onNoRedlock);
    socket.on('numPlayersOnline', onNumPlayersOnline);
    socket.on('battleEngineError', onBattleEngineError);

    if (is_token_refresh) {
      socket.do_reconnect_player = true;
    }
  } catch (err) {
    logError(err, {
      module: 'ApplicationStore',
      func: 'connectApiSocket',
    });
  }
}

function onSocketError(err) {
  logError(err);
}

function onApiServerError(data) {
  const { requestName, reqData, message, stack } = data;

  // log error data to the console
  console.group('************************\nAPI Server Error');
  console.error(stack);
  console.error('requestName', requestName);
  console.error('reqData', reqData);
  console.groupEnd();

  // surface to the player
  $addMessageLogMessage(
    `API server error [${requestName}]: ${message}`,
    Colors.RED
  );

  ApplicationStore.emit(ApplicationStore.API_ERROR, data);
}

function onNoRedlock(lock_name) {
  console.warn('no redlock for request:', lock_name);
}

async function onAssetsLoaded() {
  ApplicationStore.emit(ApplicationStore.ASSETS_LOADED);

  if (Config.PLATFORM === 'yandex') {
    // wait for the Yandex SDK sript to download
    await new Promise((resolve) => {
      const interval = setInterval(() => {
        if (window.ysdk) {
          clearInterval(interval);
          resolve();
        }
      }, 200);
    });
    window.ysdk?.features?.LoadingAPI?.ready();
  }
}

function onSocketConnected() {
  console.info('socket connected');
  ApplicationStore.emit(ApplicationStore.SOCKET_CONNECTED, socket);
}

function onNumPlayersOnline(data) {
  playersOnline = data.num;
  ApplicationStore.emit(ApplicationStore.NUM_PLAYERS_ONLINE, playersOnline);
}

function onBattleEngineError(err) {
  logError(err, {
    module: 'ApplicationStore',
    func: 'onBattleEngineError',
  });
}

window.addEventListener('focus', onWindowActive);
window.addEventListener('blur', onWindowInactive);
function onWindowInactive() {
  window_has_focus = false;
  ApplicationStore.emit(ApplicationStore.WINDOW_LOST_FOCUS);
}
function onWindowActive() {
  window_has_focus = true;
  ApplicationStore.emit(ApplicationStore.WINDOW_GOT_FOCUS);
}
