<template>
  <Dialog
    :open="true"
    @close="props.onClose"
  >
    <div class="fixed inset-0 flex items-center justify-center p-4 z-20 bg-zinc-700/90">
      <DialogPanel class="border border-white w-[500px] max-h-[90vh] flex flex-col bg-black">
        <canvas
          id="MiracleDyeModal_canvas"
          ref="modal_canvas"
          class="absolute w-full h-full z-0 pointer-events-none"
        />

        <DialogTitle class="border-b border-white p-3 text-yellow-300">
          {{ text('ui.miracle_dye_dialog_title') }}
        </DialogTitle>

        <div
          id="MiracleDyeDialog_body"
          class="p-6 w-full text-center"
        >
          <p>
            {{ text('ui.what_color_dye') }}
          </p>
          <div class="w-full flex justify-center">
            <Chrome v-model="state.colors" class="np-mb" />
            <div id="MiracleDyeModal_item_icon_anchor" class="w-[50%]"></div>
          </div>

          <div class="w-full h-24 px-6 flex justify-center items-center z-10">
            <DTButton
              type="success"
              class="py-1 px-5"
              @click="onYesClick"
            >
              {{ text('ui.OK') }}
            </DTButton>
            <DTButton
              type="error"
              class="ml-3 py-1 px-5"
              :style="`background-color: ${state.colors.hex} !important;`"
              @click="props.onClose"
            >
              {{ text('ui.cancel') }}
            </DTButton>
          </div>
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup>
import { nextTick, onBeforeUnmount, reactive, ref, watch } from 'vue';
import { Chrome } from "@lk77/vue3-color";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
} from '@headlessui/vue';
import { ChatStore } from '~/flux/stores';
import text from '~/text';
import CanvasTools from '~/view/CanvasTools';
import GameItemIcon from '~/components/common/GameItemIcon';
import { DTButton } from '~/view/components/common/DOM';

let _item_to_dye_icon;
let _modal_pixi_app;

const props = defineProps({
  item_to_dye: {
    type: Object,
    required: true,
  },
  onClose: {
    type: Function,
    required: true,
  },
  onColorSelected: {
    type: Function,
    required: true,
  },
});

const state = reactive({
  colors: { hex: ChatStore.getAll().userObject?.chatTextColor ?? '#ffffff' },
});

const modal_canvas = ref(null);

watch(modal_canvas, val => {
  if (val && !_modal_pixi_app) {
    nextTick(() => {
      _modal_pixi_app = CanvasTools.makePixiRenderer({
        canvas_id: 'MiracleDyeModal_canvas',
        canvas_ele: val,
      });
      makeGameItemIcon();
    });
  }
});
watch(state, makeGameItemIcon);

onBeforeUnmount(() => {
  destroyGameItemIcon();
  state.modal_pixi_app?.destroy();
});

function destroyGameItemIcon() {
  _item_to_dye_icon?.destroy();
  _item_to_dye_icon = null;
}

function onYesClick() {
  props.onClose();
  const color_int = state.colors.hex.substr(1, 6);
  const base_16_color = parseInt(color_int, 16);
  props.onColorSelected({ base_16_color })
}

function makeGameItemIcon() {
  destroyGameItemIcon();

  const color_int = state.colors.hex.substr(1, 6);
  _item_to_dye_icon = new GameItemIcon({
    ...props.item_to_dye,
    tint: parseInt(color_int, 16),
  });
  _item_to_dye_icon.setScale(64);
  const dom_anchor = CanvasTools.getDomAnchor(`MiracleDyeDialog_body`);
  const { width, height } = dom_anchor;
  _item_to_dye_icon.x = width * 0.75;
  _item_to_dye_icon.y = height * 0.52;
  _modal_pixi_app.stage.addChild(_item_to_dye_icon)
}
</script>
