import StripAnimation from './StripAnimation';

const PortalEffect = function( duration ) {
	return new StripAnimation( 
		'effects/portal',
		duration,
		4,
		false
	);	
}
export default PortalEffect;