import * as PIXI from 'pixi.js';
import getAvailableAttributePoints from 'dt-common/isomorphic-helpers/getAvailableAttributePoints';
import { EditHeroActions, UIActions } from '~/flux/actions';
import { FluxGetters } from '~/flux/stores';
import text from '~/text';
import CanvasTools from '~/view/CanvasTools';

const AttPointsPanel = function() {
  PIXI.Container.call(this);

  this.transitionIn = () => {};

  this.transitionOut = (onComplete) => {
    onComplete();
  };

  this.dispose = () => {
    resetBtn.tap = resetBtn.click = null;
    resetBtn.mouseover = resetBtn.pointerover = null;
    resetBtn.mouseout = resetBtn.pointerout = null;

    this.removeChildren();
  };

  const { built_hero, hero_handle, roster_hero } = FluxGetters.getFocusedHeroBuild();
  const available_att_points = getAvailableAttributePoints({ built_hero, roster_hero });

  const resetBtn = new PIXI.Sprite();
  resetBtn.texture = PIXI.utils.TextureCache['armory/reset_button.png'];
  resetBtn.hitArea = new PIXI.Circle(0, 0, 32);
  resetBtn.interactive = resetBtn.buttonMode = true;
  resetBtn.tap = resetBtn.click = () => {
    UIActions.showModal({
      modal_key: 'ResetAttributesModal',
      modal_props: {
        hero_handle,
        onConfirmed: () => {
          EditHeroActions.resetAttributesConfirm({
            hero_build_id: built_hero._id,
            hero_handle,
          });
        },
      },
    });
  };
  resetBtn.anchor.x = resetBtn.anchor.y = 0.5;
  this.addChild(resetBtn);

  // reset button mouseover highlight
  const highlight = new PIXI.Graphics();
  highlight.beginFill(0xffffff, 0.05);
  highlight.drawCircle(0, 0, 14);
  highlight.endFill();
  highlight.visible = false;
  highlight.pivot.x = highlight.pivot.y = 0.5;
  this.addChildAt(highlight, 0);

  resetBtn.mouseover = resetBtn.pointerover = () => {
    highlight.visible = true;
  };
  resetBtn.mouseout = resetBtn.pointerout = () => {
    highlight.visible = false;
  };

  var label = new PIXI.Text(text('game.att_points.full')+': '+available_att_points, {
    fontFamily: 'Courier New',
    fontSize: CanvasTools.dynamicFontSizeString(14),
    fontStyle: 'bold',
    fill: 0xffffff,
  });
  label.x = resetBtn.x + resetBtn.width / 2 + DT_CANVAS_GLOBALS.spacing;
  label.y = resetBtn.y - label.height / 2;
  this.addChild(label);

};
AttPointsPanel.prototype = Object.create(PIXI.Container.prototype);
AttPointsPanel.prototype.constructor = AttPointsPanel;
export default AttPointsPanel;
