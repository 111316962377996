export default {
  ALMOST_BLACK: 0x000000, // 0x0b0b0b,
  DARK_RED: 0x720b0b,
  DARK_AQUA: 0x336a52,
  HP_RED: 0xc41515,
  AP_GREEN: 0x108813,
  AP_ORANGE: 0xff810a,
  AP_ORANGE_DARK: 0x774600,
  MP_BLUE: 0x0f16d9,
  BRIGHT_YELLOW: 0xd7d00e,
  TILE_TINT: 0x555555,
  RED: 0xff0000,
  GREEN: 0x00ff00,
  BLUE: 0x0000ff,
  BRIGHT_BLUE: 0x00b4ff,
  CAVERNS_PORTAL: 0xd761ff,

  GOLD: 0xfbd700,
  PIXIE_DUST: 0x20bcff,

  WHITE_TEAM: 0x00c475,
  BLACK_TEAM: 0xff3b0a,
  MP_PURPLE: 0x8e58ff,
  WHITE_TEAM_DARK: 0x114a33,
  BLACK_TEAM_DARK: 0x521d11,
  MP_PURPLE_DARK: 0x321969,

  chaos: 0xff0000,
  balance: 0x00ff00,
  order: 0x0000ff,
  peanut: 0xdbb589, // 0x854e0e,

  // hero colors
  assassin: 0x474747,
  battle_mage: 0xff0000,
  dreamsmith: 0x23dcc0,
  invoker: 0x0b42e6,
  knight: 0xc8c8c8,
  mercenary: 0xc6e300,
  monk: 0x9b7358,
  paladin: 0xff00f0,
  ranger: 0x2b9620,
  shaman: 0xe16500,
  warlock: 0x4912ae,
  string: {
    assassin: '#474747',
    battle_mage: '#ff0000',
    dreamsmith: '#23dcc0',
    invoker: '#0b42e6',
    knight: '#c8c8c8',
    mercenary: '#c6e300',
    monk: '#9b7358',
    paladin: '#ff00f0',
    ranger: '#2b9620',
    shaman: '#e16500',
    warlock: '#4912ae',
  },

  dwarf: 0x743333,
  elf: 0x337433,
  faerie: 0x3bffaa,

  // caverns enemy colors
  giant_rat: 0x7e4b02,
  kobold: 0x00b300,
  skeleton: 0xffffff,
  skeletal_archer: 0xded08e,
  cave_lizard: 0x969696,
  ratling_warrior: 0x822525,
  slime: 0x69ae0f,
  ratling_priest: 0x3568bc,
  huge_bat: 0x4e481f,
  bandit: 0x4b0000,
  goblin: 0x28574b,
  kobold_cleric: 0x38fd87,
  goblin_elementalist: 0xcd7e0c,
  snake: 0x0d4709,

  // INN_TIER_COLORS {
  //   1:
  //   2:
  //   3:
  //   4:
  //   5:
  //   6:
  //   7:
  //   8:
  //   9:
  //   10:
  // }
};
