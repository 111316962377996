import * as PIXI from 'pixi.js';
import Game from 'dt-common/constants/Game';
import Colors from '~/constants/Colors';

const TileSprite = function(gamePiece) {	
  PIXI.Container.call(this);

  this.interactive = this.buttonMode = true;

  this.gamePiece = gamePiece;

  // const SCALE_X = window.innerWidth * 0.0015;
  // const SCALE_Y = window.innerHeight * 0.0018;
  // const tileWidth = SCALE_X * 38;
  // const tileHeight = SCALE_Y * 38;			

  this.tileImg = new PIXI.Sprite();
  this.tileImg.texture = PIXI.utils.TextureCache['tile.png'];
  // this.tileImg.tint = Colors.TILE_TINT;
  // this.tileImg.alpha = 0.5;
  this.tileImg.width = 52;
  this.tileImg.scale.y = this.tileImg.scale.x;
  this.tileImg.anchor.x = this.tileImg.anchor.y = 0.5;
  this.addChild(this.tileImg);

  this.TILE_WIDTH = this.tileImg.width;
  this.TILE_HEIGHT = this.tileImg.height;

  this.rangeHighlight = new PIXI.Sprite();
  this.rangeHighlight.texture = PIXI.utils.TextureCache['tile_fill.png'];
  this.rangeHighlight.width = 52;
  this.rangeHighlight.scale.y = this.rangeHighlight.scale.x;
  this.rangeHighlight.anchor.x = this.rangeHighlight.anchor.y = 0.5;
  this.addChild(this.rangeHighlight);
  this.rangeHighlight.visible = false;

  /*var coordsDisplay = new PIXI.Text( '('+gamePiece.x+','+gamePiece.y+')', {
		fill: 0xffffff,
		fontSize: 11
	});
	coordsDisplay.anchor.x = coordsDisplay.anchor.y = 0.5;
	this.tileImg.addChild( coordsDisplay );*/

  /*this.updateBaseScale = function( instant ) {
		if( instant ) {
			this.scale.x = SCALE_X * ( 0.5 + 0.5 * this.gamePiece.y / Game.TILE_ROWS );
			this.scale.y = SCALE_Y * ( 0.5 + 0.5 * this.gamePiece.y / Game.TILE_ROWS );
		} else {
			TweenMax.to( this.scale, 0.5, {
				x: SCALE_X * ( 0.5 + 0.5 * this.gamePiece.y / Game.TILE_ROWS ),
				y: SCALE_Y * ( 0.5 + 0.5 * this.gamePiece.y / Game.TILE_ROWS )
			});
		}

		this.hitArea = new PIXI.Circle( 0, 0, this.tileImg.height*0.4 );
	}
	this.updateBaseScale( true );*/
  this.hitArea = new PIXI.Circle(0, 0, this.tileImg.height*0.42);

  const getStageX = (board_x, board_y) => {
    // const distFromCenter = Game.TILE_COLUMNS/2 - board_x;
    // return board_x*tileWidth + (Game.TILE_ROWS - board_y)*(distFromCenter)*(tileWidth/45);
    return /*window.innerWidth*0.165 + */board_x * this.TILE_WIDTH * 0.757;
  };
	
  const getStageY = (board_y) => {
    // return board_y*tileHeight/4 + board_y*board_y*tileHeight / (5.5*Game.TILE_ROWS);
    return /*-window.innerHeight*0.1 + */board_y * this.TILE_HEIGHT * 0.49;
  };

  this.snapToBoardPosition = (board_x, board_y) => {
    this.x = getStageX(board_x, board_y);
    this.y = getStageY(board_y);
  };	

  this.tweenToBoardPosition = (board_x, board_y, duration) => {			
    TweenMax.to(this, duration, {
      x: getStageX(board_x, board_y), 
      y: getStageY(board_y),
      ease: Linear.easeNone
    });
  };
	
  this.dispose = () => {
    gamePiece = null;

    this.removeChildren();
  };
	
};
TileSprite.prototype = Object.create(PIXI.Container.prototype);
TileSprite.prototype.constructor = TileSprite;
export default TileSprite;
