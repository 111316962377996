<template>
  <Dialog
    :open="true"
    @close="props.onClose"
  >
    <div class="fixed inset-0 flex items-center justify-center p-4 z-20 bg-zinc-700/90">
      <DialogPanel class="border border-white w-[800px] max-h-[90vh] flex flex-col bg-black">
        <DialogTitle class="border-b border-white p-3 text-yellow-300">
          {{ text('ui.make_gold') }}
        </DialogTitle>

        <div
          id="MakeGoldDialog_body"
          class="p-6 w-full text-center"
        >
          <div class="p-8 flex justify-between items-center">
            <div>{{ Tools.formatGameCurrency(state.pd_amount) }}</div>
            <img class='w-[64px] h-[64px]' :src="`${DOM_IMG_PATH}/pixie_dust.png`" />
            <VueSimpleRangeSlider
              class="-mt-8 w-[350px]"
              :min="0"
              :max="100"
              v-model="state.pd_amount"
            />
            <div class="flex">
              <div class='mr-2'>---></div>                    
              <img className='gold-img' :src="`${DOM_IMG_PATH}/gold.png`" />
            </div>
            <div className="gold-value-wrapper">
              <div className='gold-value'>
                {{ Tools.formatGameCurrency(state.pd_amount * Economy.GOLD_PER_PD) }}
              </div>
            </div>
          </div>
          <div class="w-full flex justify-center">
            <!-- <Button type='submit' bsStyle="success" className="abracadabra-btn" onClick={this.onYesClick}>{text('ui.abracadabra')}</Button> -->
            <DTButton
              type="success"
              class="py-1 px-5 w-[300px]"
              @click="onYesClick"
            >
              {{ text('ui.abracadabra') }}
            </DTButton>
          </div>
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup>
import { reactive } from 'vue';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
} from '@headlessui/vue';
import text from '~/text';
import { DTButton } from '~/view/components/common/DOM';
import VueSimpleRangeSlider from 'vue-simple-range-slider';
import Economy from 'dt-common/constants/Economy';
import Tools from '~/Tools';
import { PlayerActions } from '~/flux/actions'

const { ASSETS_PATH='assets' } = process.env
const DOM_IMG_PATH = `${ASSETS_PATH}/img/DOM`;


const props = defineProps({
  onClose: {
    type: Function,
    required: true,
  },
});

const state = reactive({
  pd_amount: 0,
});

function onYesClick() {
  if (state.pd_amount > 0) {
    PlayerActions.makeGold(state.pd_amount );
  }
  
  props.onClose();
}
</script>
