import * as PIXI from 'pixi.js';
import CanvasTools from '~/view/CanvasTools';
import GameItemIcon from './GameItemIcon';

var EquipmentFigure = function (equipment) {
  try {
    PIXI.Container.call(this);

    var _icons = [];
    this.getIcons = function () {
      return _icons;
    };

    const makeHelmetIcon = () => {
      var helmetIcon = new GameItemIcon(equipment.helmet);
      helmetIcon.slot = 'helmet';
      helmetIcon.x = 0;
      helmetIcon.y = -63;
      this.addChild(helmetIcon);

      _icons.push(helmetIcon);
    };

    const makeTorsoeIcon = () => {
      var torsoeIcon = new GameItemIcon(equipment.torsoe);
      torsoeIcon.slot = 'torsoe';
      torsoeIcon.x = 0;
      torsoeIcon.y = 5;
      this.addChild(torsoeIcon);

      _icons.push(torsoeIcon);
    };

    const makeLeftHandIcon = () => {
      var leftHandIcon = new GameItemIcon(equipment.off_hand);
      leftHandIcon.slot = 'off_hand';
      leftHandIcon.x = 70;
      leftHandIcon.y = -30;
      this.addChild(leftHandIcon);

      _icons.push(leftHandIcon);
    };

    const makeRightHandIcon = () => {
      var rightHandIcon = new GameItemIcon(equipment.weapon);
      rightHandIcon.slot = 'weapon';
      rightHandIcon.x = -70;
      rightHandIcon.y = -30;
      this.addChild(rightHandIcon);

      _icons.push(rightHandIcon);
    };

    let leftRingIcon;
    const makeLeftRingIcon = () => {
      leftRingIcon = new GameItemIcon(equipment.left_ring);
      leftRingIcon.slot = 'ring';
      leftRingIcon.slot_hand = 'left_ring';
      leftRingIcon.x = 70;
      leftRingIcon.y = 40;
      this.addChild(leftRingIcon);

      _icons.push(leftRingIcon);
    };

    let rightRingIcon;
    const makeRightRingIcon = () => {
      rightRingIcon = new GameItemIcon(equipment.right_ring);
      rightRingIcon.slot = 'ring';
      rightRingIcon.slot_hand = 'right_ring';
      rightRingIcon.x = -70;
      rightRingIcon.y = 40;
      this.addChild(rightRingIcon);

      _icons.push(rightRingIcon);
    };

    const makeBootsIcon = () => {
      var bootsIcon = new GameItemIcon(equipment.boots);
      bootsIcon.slot = 'boots';
      bootsIcon.x = 0;
      bootsIcon.y = 71;
      this.addChild(bootsIcon);

      _icons.push(bootsIcon);
    };

    this.init = () => {
      makeHelmetIcon();
      makeTorsoeIcon();
      makeLeftHandIcon();
      makeRightHandIcon();
      makeLeftRingIcon();
      makeRightRingIcon();
      makeBootsIcon();

      for (const icon of _icons) {
        icon.setScale(56);
      }
    };

    this.renderSpinner = (item) => {
      const icon = _icons.find(
        (i) => i && i.getItem() && i.getItem().uid === item.uid
      );
      if (icon) {
        CanvasTools.makeFaerieSpinners(icon, 7);
      }
    };

    this.renderSpinnerForItemToEquip = (item) => {
      const icon =
        item.slot === 'ring'
          ? equipment.leftRing
            ? rightRingIcon
            : leftRingIcon
          : _icons.find((i) => i.slot === item.slot);

      if (icon) {
        CanvasTools.makeFaerieSpinners(icon, 7);
      }
    };

    this.dispose = () => {
      if (_icons) {
        for (const icon of _icons) {
          if (icon) {
            icon.dispose();
          }
        }
        _icons = null;
      }

      this.destroy();
    };
  } catch (err) {
    logError(
      err,
      Object.assign(
        {
          module: 'components/common/EquipmentFigure',
          func: 'constructor',
        },
        equipment
      )
    );
  }
};
EquipmentFigure.prototype = Object.create(PIXI.Container.prototype);
EquipmentFigure.prototype.constructor = EquipmentFigure;
export default EquipmentFigure;
