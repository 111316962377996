import * as PIXI from 'pixi.js';
import Game from 'dt-common/constants/Game';
import Colors from '~/constants/Colors';
import CanvasTools from '~/view/CanvasTools';
import Screens from '~/constants/Screens';
import { TutorialActions, UIActions } from '~/flux/actions';
import {
  BattleStore,
  CurrencyStore,
  GameStateStore,
  InnStore,
  UIStore,
  TutorialStore,
} from '~/flux/stores';
import text from '~/text';

const NavMenu = function (myWidth) {
  PIXI.Container.call(this);

  this.width = myWidth;
  var myHeight = (this.height = window.innerHeight * 0.26);
  const BTN_WIDTH = myWidth / 9;

  CanvasTools.addBackFill(this, Colors.ALMOST_BLACK);

  CanvasTools.addBorder(
    this,
    'menu_top_border.png',
    'window_border_vert.png',
    null,
    'window_border_vert.png',
    0
  );

  const onNavBtnSelected = (event) => {
    const { screen_id } = event.target;

    // tutorial step 1 - guide the player the caverns screen
    const { navigated_to_caverns, skipped_all } =
      TutorialStore.getCompletedTutorialSteps();
    if (
      screen_id === Screens.CAVERNS &&
      !navigated_to_caverns &&
      !skipped_all
    ) {
      TutorialActions.logTutorialStepCompleted('navigated_to_caverns');
    }

    // change screens
    UIActions.uiNav({ screen_id });
  };

  const makeBtn = (
    texPath,
    xPosIndex,
    { screen_id },
    labelString,
    highlightColor
  ) => {
    const btn = new PIXI.Sprite();
    btn.texture = PIXI.utils.TextureCache[texPath];
    btn.highlightColor = highlightColor;

    var label = (btn.label = new PIXI.Sprite());
    label.texture = PIXI.utils.TextureCache['footer/nav_menu/menu_banner.png'];
    var labelText = (btn.label.labelText = new PIXI.Text(labelString, {
      fontFamily: 'Courier New',
      fontStyle: 'bold',
      fontSize: '52px',
      fill: 0x000000,
      dropShadow: true,
      dropShadoowColor: 0x454545,
      dropShadowDistance: 4,
    }));

    labelText.x = Math.round(label.width / 2 - labelText.width / 2);
    labelText.y = Math.round(label.height * 0.61 - labelText.height / 2);
    label.addChild(labelText);

    label.width = btn.width;
    label.scale.y = label.scale.x;
    label.x = Math.round(btn.width / 2 - label.width / 2);
    label.y = Math.round(
      btn.height * (screen_id === Screens.SHOP ? 1.24 : 1.07)
    );
    btn.addChild(label);

    btn.interactive = btn.buttonMode = true;
    btn.tap = btn.click = onNavBtnSelected;
    btn.width = BTN_WIDTH;
    btn.height = Math.min(myHeight * 0.6, btn.height * btn.scale.x);
    btn.scale.x = btn.scale.y;
    btn.x = Math.round(myWidth * (xPosIndex / 6) - btn.width / 2);
    btn.y =
      (xPosIndex - 0.5) % 2 === 1
        ? Math.round(myHeight * 0.4 - btn.height / 2)
        : Math.round(myHeight * 0.55 - btn.height / 2);
    this.addChild(btn);

    btn.mouseover = () => {
      btn.tint_buf = btn.tint;
      btn.label.tint_buf = btn.label.tint;
      btn.tint = btn.label.tint = Colors.RED;
    };
    btn.mouseout = () => {
      btn.tint = btn.tint_buf;
      btn.label.tint = btn.label.tint_buf;
    };

    btn.screen_id = screen_id;
    return btn;
  };

  let _heroBuildsBtn;
  let _pvpAutoBtn;
  let _pvpLiveBtn;
  let _cavernsBtn;
  let _shopBtn;
  let _altarBtn;

  const destroyButtons = () => {
    if (_heroBuildsBtn) {
      this.removeChild(_heroBuildsBtn);
      _heroBuildsBtn.tap = _heroBuildsBtn.click = null;
    }
    if (_pvpAutoBtn) {
      this.removeChild(_pvpAutoBtn);
      _pvpAutoBtn.tap = _pvpAutoBtn.click = null;
    }
    if (_pvpLiveBtn) {
      this.removeChild(_pvpLiveBtn);
      _pvpLiveBtn.tap = _pvpLiveBtn.click = null;
    }
    if (_cavernsBtn) {
      this.removeChild(_cavernsBtn);
      _cavernsBtn.tap = _cavernsBtn.click = null;
    }
    if (_altarBtn) {
      this.removeChild(_altarBtn);
      _altarBtn.tap = _altarBtn.click = null;
    }
    if (_shopBtn) {
      this.removeChild(_shopBtn);
      _shopBtn.tap = _shopBtn.click = null;
    }
    _heroBuildsBtn = null;
    _pvpAutoBtn = null;
    _pvpLiveBtn = null;
    _cavernsBtn = null;
    _shopBtn = null;
    _altarBtn = null;
  };

  const makeButtons = () => {
    destroyButtons();

    _heroBuildsBtn = makeBtn(
      'footer/nav_menu/caverns_icon.png',
      0.5,
      { screen_id: Screens.HERO_BUILDS },
      text('ui.HERO_BUILDS'),
      0x6b5516
    );
    _pvpAutoBtn = makeBtn(
      'footer/nav_menu/armory_icon.png',
      1.5,
      { screen_id: Screens.INN },
      text('ui.INN'),
      0x0b9c1c
    );
    _pvpLiveBtn = makeBtn(
      'footer/nav_menu/arena_icon.png',
      2.5,
      { screen_id: Screens.ARENA_LOBBY },
      text('ui.ARENA'),
      0xff4a4a
    );
    _cavernsBtn = makeBtn(
      'footer/nav_menu/shop_icon.png',
      3.5,
      { screen_id: Screens.CAVERNS },
      text('ui.CAVERNS'),
      0xff0000
    );
    _altarBtn = makeBtn(
      'footer/nav_menu/altar_icon.png',
      4.5,
      { screen_id: Screens.TEMPLE },
      text('ui.TEMPLE'),
      0xb23aff
    );
    _shopBtn = makeBtn(
      'shop/shopkeeper_portrait.png',
      5.5,
      { screen_id: Screens.SHOP },
      text('ui.SHOP'),
      0x3fcd00
    );

    const lockedTextStyle = {
      fontFamily: 'Courier New',
      fontSize: 52,
      fontStyle: 'bold',
      fill: 0xffffff,
      dropShadow: true,
      dropShadowColor: 0x000000,
      dropShadowDistance: 4,
    };
    const lockedTint = 0x444444;

    // check Inn access
    if (GameStateStore.getAll().num_unlocked_heroes < 3) {
      _pvpAutoBtn.buttonMode = _pvpAutoBtn.interactive = false;
      _pvpAutoBtn.tint = _pvpAutoBtn.label.tint = lockedTint;

      let need3heroes = new PIXI.Text(
        text('ui.need_3_heroes'),
        lockedTextStyle
      );
      need3heroes.anchor.x = need3heroes.anchor.y = 0.5;
      need3heroes.rotation = -Math.PI / 5;
      need3heroes.x = _pvpAutoBtn.width / 2 / _pvpAutoBtn.scale.x;
      need3heroes.y = _pvpAutoBtn.height / 2 / _pvpAutoBtn.scale.y;
      _pvpAutoBtn.addChild(need3heroes);
    }

    // check Arena access
    if (false) {
      // InnStore.getAll().max_inn_tier_reached < 2) {
      _pvpLiveBtn.buttonMode = _pvpLiveBtn.interactive = false;
      _pvpLiveBtn.tint = _pvpLiveBtn.label.tint = lockedTint;

      const needInnTier2 = new PIXI.Text(
        text('ui.need_inn_tier_2'),
        lockedTextStyle
      );
      needInnTier2.anchor.x = needInnTier2.anchor.y = 0.5;
      needInnTier2.rotation = -Math.PI / 5;
      needInnTier2.x = _pvpLiveBtn.width / 2 / _pvpLiveBtn.scale.x;
      needInnTier2.y = _pvpLiveBtn.height / 2 / _pvpLiveBtn.scale.y;
      _pvpLiveBtn.addChild(needInnTier2);
    }

    // check altar access
    if (!CurrencyStore.getAll().altarAccess) {
      // TODO!!! should be have i EVER accumulated 5k
      _altarBtn.buttonMode = _altarBtn.interactive = false;
      _altarBtn.tint = _altarBtn.label.tint = lockedTint;

      const need5kgold = new PIXI.Text(
        text('ui.need_5k_gold'),
        lockedTextStyle
      );
      need5kgold.anchor.x = need5kgold.anchor.y = 0.5;
      need5kgold.rotation = -Math.PI / 5;
      need5kgold.x = _altarBtn.width / 2 / _altarBtn.scale.x;
      need5kgold.y = _altarBtn.height / 2 / _altarBtn.scale.y;
      _altarBtn.addChild(need5kgold);
    }
  };
  makeButtons();

  // tutorial step 1 - guide the player the caverns screen
  const { navigated_to_caverns, skipped_all } =
    TutorialStore.getCompletedTutorialSteps();
  if (!navigated_to_caverns && !skipped_all) {
    setTimeout(() => {
      TutorialActions.makeTutorialSpotlight({
        radius: 128,
        x: window.innerWidth * 0.793,
        y: window.innerHeight * 0.86,
        onTransitionInComplete: () => {
          TutorialActions.makeTutorialBox({
            arrow: 'down',
            text: text('tutorial.nav_to_caverns_tip'),
            x: window.innerWidth * 0.76 - 145,
            y: window.innerHeight * 0.7 - 65,
          });
        },
      });
    }, 2500);
  }

  let _highlightedBtn = null;
  const highlightBtn = (btn) => {
    if (btn) {
      btn.label.tint = btn.highlightColor;
      btn.label.labelText.style.fill = 0xffffff;
      btn.label.labelText.style.dropShadoowColor = 0x000000;
      _highlightedBtn = btn;
    }
  };

  const removeButtonHighlight = () => {
    if (_highlightedBtn) {
      _highlightedBtn.label.tint = 0xffffff;
      _highlightedBtn.label.labelText.style.fill = 0x000000;
      _highlightedBtn.label.labelText.style.dropShadoowColor = 0x454545;
    }
  };

  const onUINav = () => {
    removeButtonHighlight();

    const { current_ui_screen, current_game_mode } = UIStore.getAll();
    switch (current_ui_screen) {
      case Screens.TEMPLE:
        highlightBtn(_altarBtn);
        break;
      case Screens.ARENA_LOBBY:
        highlightBtn(_pvpLiveBtn);
        break;
      case Screens.CAVERNS:
        highlightBtn(_cavernsBtn);
        break;
      case Screens.INN:
        highlightBtn(_pvpAutoBtn);
        break;
      case Screens.HERO_BUILDS:
      case Screens.EDIT_HERO:
        highlightBtn(_heroBuildsBtn);
        break;
      case Screens.SHOP:
        highlightBtn(_shopBtn);
        break;
    }

    TutorialActions.destroyTutorialBox();
    TutorialActions.destroyTutorialSpotlight();
  };
  onUINav();

  GameStateStore.on(GameStateStore.HERO_LEVEL_UP, makeButtons);
  GameStateStore.on(GameStateStore.HERO_UNLOCKED, makeButtons);
  CurrencyStore.on(CurrencyStore.CURRENCY_CHANGE, makeButtons);
  InnStore.on(InnStore.PASSIVE_TOURNEY_RESOLVED, makeButtons);
  UIStore.on(UIStore.UI_NAV, onUINav);
  TutorialStore.on(
    TutorialStore.TUTORIAL_SPOTLIGHT_VISIBLE,
    onTutorialSpotlightVisible
  );
  TutorialStore.on(
    TutorialStore.TUTORIAL_SPOTLIGHT_HIDDEN,
    onTutorialSpotlightHidden
  );

  function onTutorialSpotlightVisible() {
    _heroBuildsBtn.interactive = false;
    _shopBtn.interactive = false;
  }

  function onTutorialSpotlightHidden() {
    _heroBuildsBtn.interactive = true;
    _shopBtn.interactive = true;
  }

  this.dispose = () => {
    this.destroy();
    destroyButtons();
    removeListeners();
    TutorialActions.destroyTutorialBox();
    TutorialActions.destroyTutorialSpotlight();
  };
  function removeListeners() {
    CurrencyStore.removeListener(CurrencyStore.CURRENCY_CHANGE, makeButtons);
    GameStateStore.removeListener(GameStateStore.HERO_UNLOCKED, makeButtons);
    GameStateStore.removeListener(GameStateStore.HERO_LEVEL_UP, makeButtons);
    InnStore.removeListener(InnStore.PASSIVE_TOURNEY_RESOLVED, makeButtons);
    UIStore.removeListener(UIStore.UI_NAV, onUINav);
    TutorialStore.removeListener(
      TutorialStore.TUTORIAL_SPOTLIGHT_VISIBLE,
      onTutorialSpotlightVisible
    );
    TutorialStore.removeListener(
      TutorialStore.TUTORIAL_SPOTLIGHT_HIDDEN,
      onTutorialSpotlightHidden
    );
  }
};
NavMenu.prototype = Object.create(PIXI.Container.prototype);
NavMenu.prototype.constructor = NavMenu;
export default NavMenu;
