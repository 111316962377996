import StripAnimation from './StripAnimation';

const MysticFocusEffect = function( duration ) {
	return new StripAnimation( 
		'effects/mystic_focus',
		duration,
		9,
		false
	);	
}
export default MysticFocusEffect;