<template>
  <Dialog
    :open="true"
    @close="props.onClose"
  >
    <div class="fixed inset-0 flex items-center justify-center p-4 z-20 bg-zinc-700/90">
      <DialogPanel class="border border-white w-[800px] max-h-[90vh] flex flex-col bg-black">
        <DialogTitle class="border-b border-white p-3 text-yellow-300">
          {{ text(`context_info.${UIStore.getAll().current_ui_screen}.title`) }}
        </DialogTitle>

        <div
          class="px-6 py-3 h-[70vh] overflow-y-auto text-sm"
        >
          <component
            :is="info_panels[UIStore.getAll().current_ui_screen] || 'div'"
          />
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup>
import {
  Dialog,
  DialogPanel,
  DialogTitle,
} from '@headlessui/vue';
import Screens from '~/constants/Screens';
import { UIStore } from '~/flux/stores';
import text from '~/text';
import ArenaInfoPanel from './ArenaInfoPanel.vue';
import ArmoryInfoPanel from './ArmoryInfoPanel.vue';
import CavernsInfoPanel from './CavernsInfoPanel.vue';
import EditHeroInfoPanel from './EditHeroInfoPanel.vue';
import InnInfoPanel from './InnInfoPanel.vue';
import ShopInfoPanel from './ShopInfoPanel.vue';
import TempleInfoPanel from './TempleInfoPanel.vue';

const info_panels = {
  [Screens.ARENA_LOBBY]: ArenaInfoPanel,
  [Screens.CAVERNS]: CavernsInfoPanel,
  [Screens.EDIT_HERO]: EditHeroInfoPanel,
  [Screens.HERO_BUILDS]: ArmoryInfoPanel,
  [Screens.INN]: InnInfoPanel,
  [Screens.SHOP]: ShopInfoPanel,
  [Screens.TEMPLE]: TempleInfoPanel,
};

const props = defineProps({
  onClose: {
    type: Function,
    required: true,
  },
});
</script>
