import Colors from '~/constants/Colors';
import onLootImgMouseover from './onLootImgMouseover';

const PixieDustSpawnRendering = {
  render: function (data, gameBoard, isCaverns) {
    const tileSprite = gameBoard.getAllPieceSprites()[data.tileId];
    if (!tileSprite) {
      return;
    }

    if (tileSprite.lootImg && tileSprite.lootImg.text !== '*') {
      tileSprite.lootImg.text = '&';
    } else if (!tileSprite.lootImg) {
      tileSprite.lootImg = tileSprite.txtPool.get();
      tileSprite.lootImg.text = '*';
      tileSprite.lootImg.scale = { x: 0.5, y: 0.5 };
      tileSprite.lootImg.tint = Colors.PIXIE_DUST;

      tileSprite.addChild(tileSprite.lootImg);

      tileSprite.lootImg.interactive = true;
      tileSprite.lootImg.interactiveChildren = true;
      tileSprite.lootImg.mouseover = tileSprite.lootImg.touch =
        onLootImgMouseover;

      tileSprite.tileImg.visible = false;
    }
  },
};
export default PixieDustSpawnRendering;
