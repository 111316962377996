import GameStateStore from './GameStateStore';
import HeroBuildStore from './HeroBuildStore';
import UIStore from './UIStore';

const getFocusedHeroBuild = () => {
  const { hero_handle, build_id } = UIStore.getAll().focused_hero_build;
  const { hero_builds } = HeroBuildStore.getAll();
  return {
    built_hero: hero_builds[hero_handle].find(({ _id }) => _id === build_id),
    hero_handle,
    roster_hero: GameStateStore.getAll().gameState.hero_roster[hero_handle],
  }
}

const getFocusedHero = () => {
  return getHeroByHandle(
    UIStore.getAll().focused_hero_handle,
  );
};

const getHeroByHandle = (hero_handle) => {
  const { current_game_mode, current_game_submode } = UIStore.getAll();
  const { hero_roster, loadouts } = GameStateStore.getAll().gameState;
  return {
    built_hero: (getFocusedHeroBuild()).built_hero,
    loadout_hero: loadouts[current_game_mode][current_game_submode].find(({ handle }) => handle === hero_handle),
    roster_hero: hero_roster[hero_handle],
  };
};

export default {
  getFocusedHeroBuild,
  getFocusedHero,
  getHeroByHandle,
};
