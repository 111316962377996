import * as PIXI from 'pixi.js';
import Economy from 'dt-common/constants/Economy';
import CurrencyStore from '~/flux/stores/CurrencyStore';
import AltarActions from '~/flux/actions/AltarActions';

const GoldAmountSlider = function( myParent, myWidth ) {
    PIXI.Container.call( this );

    this.dispose = () => {
        
        leftArrow.tap = leftArrow.click = null;
        leftArrow.mouseup = leftArrow.touchend = null;
        leftArrow = null;

        rightArrow.tap = rightArrow.click = null;
        rightArrow.mouseup = rightArrow.touchend = null;
        rightArrow = null;

        knob.mousedown = knob.touchstart = null;
        knob.mouseup = knob.touchend = null;
        knob = null;

        window.removeEventListener('mouseup', dropKnob);
        window.removeEventListener('touchend', dropKnob);    
        window.removeEventListener('mousemove', onCursorMove );    
        window.removeEventListener('touchmove', onCursorMove );

        this.destroy();
    }

    const BOTTOM_LIMIT = Economy.MIN_GOLD_SACRIFICE;
    const TOP_LIMIT = CurrencyStore.getAll().gold;

    // make line groove
    var lineGroove = new PIXI.Graphics();
    lineGroove.beginFill( 0xffffff );
    lineGroove.drawRect( -myWidth/2, -0.5, myWidth, 1 );
    lineGroove.endFill();
    this.addChild( lineGroove );

    // make arrow buttons
    var leftArrow = new PIXI.Sprite();
    leftArrow.texture = PIXI.utils.TextureCache['altar/left_arrow.png'];
    leftArrow.x = -myWidth/2  - DT_CANVAS_GLOBALS.spacing*4 - leftArrow.width/2;
    leftArrow.y = -leftArrow.height/2;
    this.addChild( leftArrow ); 
    leftArrow.interactive = leftArrow.buttonMode = true;
    leftArrow.tap = leftArrow.click = () => {
        knob.x = Math.max( -myWidth/2, knob.x - myWidth/20 );
        emitValue();
    }
    leftArrow.hitArea = new PIXI.Circle( 0, 0, 32 );

    // make arrow buttons
    var rightArrow = new PIXI.Sprite();
    rightArrow.texture = PIXI.utils.TextureCache['altar/right_arrow.png'];
    rightArrow.x = myWidth/2 + DT_CANVAS_GLOBALS.spacing*4 - rightArrow.width/2;
    rightArrow.y = -rightArrow.height/2;
    this.addChild( rightArrow ); 
    rightArrow.interactive = rightArrow.buttonMode = true;
    rightArrow.tap = rightArrow.click = () => {
        knob.x = Math.min( myWidth/2, knob.x + myWidth/20 );
        emitValue();
    }
    rightArrow.hitArea = new PIXI.Circle( 0, 0, 32 );

    // make slider knob
    var knob = new PIXI.Sprite();
    knob.texture = PIXI.utils.TextureCache['shop/gold_icon.png'];
    knob.anchor.x = knob.anchor.y = 0.5;
    knob.x = -myWidth/2;
    this.addChild( knob );

    knob.interactive = knob.buttonMode = true;
    knob.mousedown = knob.touchstart = () => {
        knob.inHand = true;
    }

    const onCursorMove = ( event ) => {
      if (knob.inHand) {
        var stage_x = this.toLocal( {x:0,y:0}, DT_CANVAS_GLOBALS.stage ).x;
        // knob.x = Math.max( -myWidth/2, Math.min(myWidth/2,stage_x+event.data.global.x) );
        knob.x = Math.max( -myWidth/2, Math.min(myWidth/2,stage_x+event.x) );
        emitValue();
      }       
    }

    const emitValue = () => {
        var sliderRatio = ( knob.x + myWidth/2 ) / myWidth;
        var goldValue = BOTTOM_LIMIT + (TOP_LIMIT-BOTTOM_LIMIT)*sliderRatio;
        AltarActions.updatePrayerGoldValue( Math.round(goldValue) );
    }

    window.addEventListener('mouseup', dropKnob);
    window.addEventListener('touchend', dropKnob);    
    window.addEventListener('mousemove', onCursorMove );    
    window.addEventListener('touchmove', onCursorMove );

    function dropKnob() {
      knob.inHand = false;
    }
}
GoldAmountSlider.prototype = Object.create( PIXI.Container.prototype );
GoldAmountSlider.prototype.constructor = GoldAmountSlider;
export default GoldAmountSlider;