import PuppetMovement from './PuppetMovement.js';

const TinkerMovement = (puppet) => {
  const poses = [ 
    { pose:Pose1() },
    { pose:Pose2() },
    { pose:Pose1() },
    { pose:Pose2() }
  ];
  
  return PuppetMovement({	
    poses,
    puppet,
    setPoseDurations: (actor_cooldown) => {
      for (const pose of poses) {
        poses.duration = actor_cooldown / 4;
      }
    }

  });
};
export default TinkerMovement;

const Pose1 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -143,
      z: 12
    },        
    shoulders: {
      x: 0,
      y: -144,
      z: 5
    },        
    torsoe: {
      x: 0,
      y: -105,
      z: 0
    },        
    leftHand: {
      x: -12,
      y: -108,
      z: 48
    },    
    bowPitch: 0,
    bowYaw: 0,    
    rightHand: {
      x: 12,
      y: -108,
      z: 48
    },        
    weaponPitch: 0,
    weaponYaw: 0,        
    leftFoot: {
      x: -22,
      y: 0,
      z: 10
    },        
    rightFoot: {
      x: 22,
      y: 0,
      z: -5
    }
  };
  return pose;       
};

const Pose2 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -143,
      z: 12
    },        
    shoulders: {
      x: 0,
      y: -139,
      z: 5
    },        
    torsoe: {
      x: 0,
      y: -105,
      z: 0
    },        
    leftHand: {
      x: 0,
      y: -96,
      z: 48
    },    
    bowPitch: 0,
    bowYaw: -40,    
    rightHand: {
      x: 0,
      y: -120,
      z: 48
    },        
    weaponPitch: 0,
    weaponYaw: -40,        
    leftFoot: {
      x: -22,
      y: 0,
      z: 10
    },        
    rightFoot: {
      x: 22,
      y: 0,
      z: -5
    }
  };
  return pose;       
};