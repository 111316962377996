export default {
	ability_trees: "Деревья способностей",
	alchemy: {
		name: "Алхимия"
	},
	armor_use: {
		name: "Мастерство брони"
	},
	bow_use: { 
		name: "Стрельба из лука"
	},
	conjuration: { 
		 name: "Заклинания"
	},
	illusion: { 
		 name: "Фокусы"
	},
	melee_combat: { 
		 name: "Ближний бой"
	},
	movement: { 
		 name: "Движение"
	},
	spirit: { 
		 name: "Духовность"
	}
};