import FlyingArrow from '../../FlyingArrow';
import BattleConfig from 'dt-common/constants/BattleConfig';
import BattleCalc from 'dt-common/battle_engine/BattleCalc';
import Audio from '~/Audio';

const ArrowShotRendering = {
  render: function (data, gameBoard, isCaverns) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const victimSprite = gameBoard.getAllPieceSprites()[data.victimId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
    const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

    render_movement &&
      actorSprite.puppet.getMovements().fireBow.movePuppet({ transitTime });

    setTimeout(
      () => {
        FlyingArrow(
          actorSprite,
          victimSprite,
          BattleCalc.projectileTime(
            BattleConfig.ARROW_SPEED,
            actorSprite.gamePiece,
            victimSprite.gamePiece
          )
        );
      },
      transitTime * 1000 * (5 / 8)
    );
  },

  renderResult: function (data, gameBoard) {
    if (data.success) {
      switch (Math.floor(Math.random() * 3)) {
        case 0:
          Audio.play('arrow_hit_01');
          break;
        case 1:
          Audio.play('arrow_hit_02');
          break;
        case 2:
          Audio.play('arrow_hit_03');
          break;
      }
    }
  },
};
export default ArrowShotRendering;
