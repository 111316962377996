import HeroBuildDispatcher from '~/flux/dispatchers/HeroBuildDispatcher';

const HeroBuildActions = {
  createNewHeroBuild({
    hero_handle,
    new_build_name,
  }) {
    HeroBuildDispatcher.handleHeroBuildAction({
      actionType: HeroBuildDispatcher.CREATE_NEW_HERO_BUILD,
      hero_handle,
      new_build_name,
    });
  },

  deleteHeroBuild({
    hero_handle,
    build_id,
  }) {
    HeroBuildDispatcher.handleHeroBuildAction({
      actionType: HeroBuildDispatcher.DELETE_HERO_BUILD,
      hero_handle,
      build_id,
    });
  },

  engageHeroBuild({
    hero_handle,
    hero_build_id,
    game_mode,
    game_submode,
    attack,
    defense,
    target_slot_index,
  }) {
    HeroBuildDispatcher.handleHeroBuildAction({
      actionType: HeroBuildDispatcher.ENGAGE_HERO_BULID,
      hero_handle,
      hero_build_id,
      game_mode,
      game_submode,
      attack,
      defense,
      target_slot_index,
    });
  },

  renameHeroBuild({ hero_handle, hero_build_id, new_build_name }) {
    HeroBuildDispatcher.handleHeroBuildAction({
      actionType: HeroBuildDispatcher.RENAME_HERO_BULID,
      hero_handle,
      hero_build_id,
      new_build_name,
    });
  }
};
export default HeroBuildActions;
