import { EventEmitter } from 'events'

import PlayerDispatcher from '~/flux/dispatchers/PlayerDispatcher'
import ShopDispatcher from '~/flux/dispatchers/ShopDispatcher'
import { awaitSocket, registerDispatchHandlers } from '~/Tools'
import Config from '~/constants/Config'

let _socket
let playerId

// the stuff we serve:
let totalSaleAmount = 0
let premiumItemList = []
let shopInventory = []

const ShopStore = Object.assign({}, EventEmitter.prototype, {
  GOT_TOTAL_SALE_AMOUNT: 'GOT_TOTAL_SALE_AMOUNT',
  SHOP_INVENTORY: 'SHOP_INVENTORY',

  getAll() {
    return {
      totalSaleAmount,
      premiumItemList,
      shopInventory
    }
  }
})
export default ShopStore

PlayerDispatcher.register(registerDispatchHandlers({
  [PlayerDispatcher.PLAYER_LOGGED_IN]: onPlayerLoggedIn
}))
ShopDispatcher.register(registerDispatchHandlers({
  [ShopDispatcher.FETCH_SHOPKEEPER_OFFER]: fetchShopkeeperOffer,
  [ShopDispatcher.CONFIRM_ITEM_SALE]: confirmItemSale,
  [ShopDispatcher.CONFIRM_ITEM_PURCHASE]: confirmItemPurchase,
  [ShopDispatcher.CLAIM_PREMIUM_PURCHASES]: claimKongPremiumPurchases
}))

awaitSocket(onSocketConnected);
// awaitSocket().then(onSocketConnected);
function onSocketConnected(socket) {
  try {
    _socket = socket

    if (!_socket.has_ShopStore_listeners) {
      _socket.on('totalSaleAmount', onTotalSaleAmount)
      _socket.on('shopInventory', onShopInventory)
      _socket.on('challengeResolved', onChallengeResolved)
      _socket.on('returnedToSurface', onReturnedToSurface)

      _socket.has_ShopStore_listeners = true
    }
  } catch (err) {
    logError(err, {
      module: 'ShopStore',
      func: 'onSocketConnected'
    })
  }
}

function onPlayerLoggedIn(action) {
  try {
    playerId = action.player._id
    _socket.emit('get_shop_inventory', { playerId })

    if (Config.PLATFORM === 'kongregate') {
      claimKongPremiumPurchases()

      kongregate.mtx.requestItemList([], (result) => {
        if (result.success) {
          premiumItemList = result.data
          premiumItemList.sort((a, b) => {
            return a.price - b.price
          })
        }
      })
    }
  } catch (err) {
    logError(err, {
      module: 'ShopStore',
      func: 'onPlayerLoggedIn'
    })
  }
}

function onChallengeResolved() {
  _socket.emit('get_shop_inventory', { playerId })
}
function onReturnedToSurface() {
  _socket.emit('get_shop_inventory', { playerId })
}

function onShopInventory(data) {
  try {
    if (data._id !== playerId) {
      throw new Error('shopInventory ID mismatch')
    }

    shopInventory = data.shopInventory
    ShopStore.emit(ShopStore.SHOP_INVENTORY)
  } catch (err) {
    logError(err, {
      module: 'ShopStore',
      func: 'onShopInventory'
    })
  }
}

function fetchShopkeeperOffer(action) {
  _socket.emit('fetch_shopkeeper_offer', {
    playerId,
    item_uids: action.item_uids_to_sell
  })
}

function onTotalSaleAmount( data ) {
  totalSaleAmount = data
  ShopStore.emit(ShopStore.GOT_TOTAL_SALE_AMOUNT, totalSaleAmount)
}

function confirmItemSale() {
  _socket.emit('confirm_item_sale_to_shop', { playerId })
}

function claimKongPremiumPurchases() {
  _socket.emit('claim_kong_premium_purchases', {
    playerId,
    kong_auth_token: kongregate.services.getGameAuthToken()
  })
}

function confirmItemPurchase(action) {
  _socket.emit('confirm_purchase_shop_item', {
    playerId,
    itemId: action.itemToBuy.uid
  })
}
