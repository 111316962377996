import * as PIXI from 'pixi.js';
import Balance from 'dt-common/constants/Balance';
import getAbilityLevel from 'dt-common/isomorphic-helpers/getAbilityLevel';
import getAvailableAbilityPoints from 'dt-common/isomorphic-helpers/getAvailableAbilityPoints';
import AbilityIcon from '~/components/common/AbilityIcon';
import Tooltip from '~/components/tooltips/Tooltip';
import Colors from '~/constants/Colors';
import { EditHeroActions } from '~/flux/actions';
import { FluxGetters, GameStateStore, HeroBuildStore, UIStore } from '~/flux/stores';
import CanvasTools from '~/view/CanvasTools';

const AbilityElement = function({ ability, hero_handle }) {
  PIXI.Container.call(this);

  const { built_hero, roster_hero } = FluxGetters.getHeroByHandle(hero_handle);
  const current_ability_level = getAbilityLevel({
    ability_handle: ability.handle,
    unit_build: built_hero,
  });

  this.getAbility = () => ability;

  const icon = new AbilityIcon(ability);
  this.getIcon = () => icon;

  icon.anchor.x = icon.anchor.y = 0.5;
  this.addChild(icon);

  let _tooltip;

  let ub;
  this.getUpgradeBtn = () => ub;
  let upgradeButtonTween;
  let upgradeButtonInterval;

  if (roster_hero.level >= ability.unlockLevel) {
    const lvlField = new PIXI.Text(`L${current_ability_level}`, {
      fontFamily: 'Courier New',
      fontWeight: 'bold',
      fontSize: CanvasTools.dynamicFontSizeString(26),
      fill: Colors.BRIGHT_YELLOW,
      align: 'center',
      dropShadow: true,
      dropShadowDistance: 2,
    });
    lvlField.x = -lvlField.width / 2;
    lvlField.y = 15;
    lvlField.hitArea = new PIXI.Rectangle(0, 0, 0, 0);
    this.addChild(lvlField);

    const available_ability_points = getAvailableAbilityPoints({
      built_hero,
      roster_hero: GameStateStore.getAll().gameState.hero_roster[built_hero.hero_handle],
    });
    if (available_ability_points > 0 && current_ability_level < Balance.MAX_ABILITY_LEVEL) {
      if (HeroBuildStore.getAll().pending_ability_upgrade_handle === ability.handle) {
        return;
      }

      ub = new PIXI.Sprite();
      ub.texture = PIXI.utils.TextureCache['plus.png'];
      ub.anchor.x = ub.anchor.y = 0.5;
      ub.width = 27;
      ub.height = 27;
      ub.x = this.width / 3;
      ub.y = -this.height / 3;

      this.addChild(ub);

      // upgrade button interactions
      ub.interactive = true;
      ub.buttonMode = true;
      ub.tap = ub.click = () => {
        const { current_game_mode, current_game_submode } = UIStore.getAll();
        EditHeroActions.upgradeAbility({
          hero_handle: built_hero.hero_handle,
          ability_handle: ability.handle,
          hero_build_id: built_hero._id
        });
      };
      // mouseover - tint selected built_hero's color
      ub.mouseover = ub.pointerover = () => {
        ub.tint = Colors.BRIGHT_YELLOW;
      };
      // mouseout - tint back to white
      ub.mouseout = ub.pointerout = () => {
        ub.tint = 0xffffff;

        ub.mouseup();

        CanvasTools.hideFaerieSpinners(_spinners);
      };
      // mousedown - scale down
      ub.mousedown = ub.touchstart = () => {
        CanvasTools.hideFaerieSpinners(_spinners);
        _spinners = CanvasTools.makeFaerieSpinners(this);

        ub.scale_buffer = { x: ub.scale.x, y: ub.scale.y };
        ub.scale = { x: ub.scale.x * 0.8, y: ub.scale.y * 0.8 };
      };
      // mouseup - scale up
      ub.mouseup = ub.touchend = () => {
        if (ub.scale_buffer) {
          ub.scale = ub.scale_buffer;
        }
      };
    }
  } else { // this ability is still locked
    icon.tint = 0x444444;

    const unlockIcon = new PIXI.Sprite();
    unlockIcon.texture = PIXI.utils.TextureCache['armory/lock.png'];
    unlockIcon.anchor.x = unlockIcon.anchor.y = 0.5;
    icon.addChild(unlockIcon);

    const text = new PIXI.Text(`(L${ability.unlockLevel})`, {
      fontFamily: 'Courier New',
      fontWeight: 'bold',
      fontSize: CanvasTools.dynamicFontSizeString(14),
      fill: 0xffffff,
      align: 'center',
      dropShadow: true,
      dropShadowDistance: 2,
    });
    text.anchor.x = text.anchor.y = 0.5;
    text.y = icon.height*0.42;
    text.interactive = true;
    text.buttonMode = true;
    icon.addChild(text);
  }

  // tooltip interactions
  this.buttonMode = this.interactive = true;
  this.mouseover = this.pointerover = () => {
    if (!_tooltip) {
      _tooltip = new Tooltip(
        'ability',
        {
          ability_handle: ability.handle,
          locked: roster_hero.level < ability.unlockLevel,
        },
        this,
      );
    }
  };
  this.mouseout = this.pointerout = () => {
    if (_tooltip) {
      _tooltip.dispose();
      _tooltip = null;
    }
  };

  let _spinners = [];
  // const makeSpinners = () => {
  //   if (!this.parent || HeroBuildStore.getAll().pending_ability_upgrade_handle) {
  //     return;
  //   }

  //   CanvasTools.hideFaerieSpinners(_spinners);
  //   _spinners = CanvasTools.makeFaerieSpinners(this);
  // };

  this.dispose = () => {
    this.mouseover = this.pointerover = null;
    this.mouseout = this.pointerout = null;

    if (icon) {
      icon.dispose();
    }

    if (_tooltip) {
      _tooltip.dispose();
    }

    if (ub) {
      ub.mouseover = ub.pointerover = null;
      ub.mouseout = ub.pointerout = null;
      ub.mousedown = ub.touchstart = null;
      ub.mouseup = ub.touchend = null;
    }

    if (upgradeButtonTween) {
      upgradeButtonTween.kill();
      upgradeButtonTween = null;
    }

    CanvasTools.hideFaerieSpinners(_spinners);
    _spinners = null;

    clearInterval(upgradeButtonInterval);

    this.destroy();
  };
};
AbilityElement.prototype = Object.create(PIXI.Container.prototype);
AbilityElement.prototype.constructor = AbilityElement;
export default AbilityElement;
