import onLootImgMouseover from './onLootImgMouseover';

const getSymbol = (item) => {
  switch (item.slot) {
    case 'boots':
      return '7';
    case 'helmet':
      return '^';
    case 'off_hand':
      switch (item.type) {
        case 'book':
          return '"';
        case 'bow':
          return '}';
        case 'shield':
          return '0';
        case 'trinket':
          return '%';
      }
      break;
    case 'torsoe':
      return ']';
    case 'weapon':
      switch (item.type) {
        case 'dagger':
          return '`';
        case 'hammer':
          return '?';
        case 'staff':
          return '/';
        case 'sword':
          return '(';
      }
      break;
    case 'ring':
      return '~';
  }

  return ';';
};

const EquipmentSpawnRendering = {
  render: function (data, gameBoard, isCaverns) {
    const tileSprite = gameBoard.getAllPieceSprites()[data.tileId];
    if (!tileSprite) {
      return;
    }

    if (tileSprite.lootImg) {
      tileSprite.lootImg.text = '&';
      tileSprite.lootImg.tint = 0xffffff;
    } else if (!tileSprite.lootImg) {
      tileSprite.lootImg = tileSprite.txtPool.get();
      tileSprite.lootImg.text = getSymbol(data.item);
      tileSprite.lootImg.scale = { x: 0.5, y: 0.5 };
      tileSprite.lootImg.tint = data.item.tint;

      tileSprite.addChild(tileSprite.lootImg);

      tileSprite.lootImg.interactive = true;
      tileSprite.lootImg.interactiveChildren = true;
      tileSprite.lootImg.mouseover = tileSprite.lootImg.touch =
        onLootImgMouseover;

      tileSprite.tileImg.visible = false;
    }
  },
};
export default EquipmentSpawnRendering;
