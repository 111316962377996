import BattleCalc from 'dt-common/battle_engine/BattleCalc';
import BattleConfig from 'dt-common/constants/BattleConfig';
import { BattleStore, CavernsStore } from '~/flux/stores';
import Audio from '~/Audio';

const TussleRendering = {
  render: function(data, gameBoard, isCaverns) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const actorPuppet = actorSprite.puppet;
    const actor = actorSprite.gamePiece;
    const render_movement = !isCaverns || actor.hero;
		const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;
    const victimSprite = gameBoard.getAllPieceSprites()[data.victimId];
    const victim = victimSprite.gamePiece;

    if (!actorSprite || !victimSprite || !actor || !victim) {
      return;
    }

    const battleState = isCaverns? CavernsStore.getAll().battleState : BattleStore.getAll().battleState;
    const actorTile = battleState.getTileAtCoords(actor.x, actor.y);
    const victimTile = battleState.getTileAtCoords(victim.x, victim.y);


    if (!render_movement) {
      actorSprite.snapToBoardPosition(victimTile.x, victimTile.y);
      victimSprite.snapToBoardPosition(actorTile.x, actorTile.y);
    } else {
      var walkSecs = transitTime*0.4;
      actorSprite.tweenToBoardPosition(victimTile.x, victimTile.y, walkSecs);
      actorPuppet.getMovements().run.movePuppet({ transitTime });

      TweenMax.delayedCall(walkSecs, onDelay);

      TweenMax.delayedCall(walkSecs*0.25, Audio.play, ['sandstone_step_6']);
      TweenMax.delayedCall(walkSecs*0.75, Audio.play, ['sandstone_step_46']);

      function onDelay() {
        actorPuppet.faceReverse();
        actorPuppet.getMovements().throw.movePuppet({ transitTime });

        TweenMax.delayedCall(0.3, onFurtherDelay);
      }

      function onFurtherDelay() {
        var projectileTimeSecs = BattleCalc.projectileTime(BattleConfig.THROWN_SPEED, actor, victim) / 1000;

        victimSprite.puppet.facePiece(actorTile);
        victimSprite.puppet.faceReverse();
        victimSprite.tweenToBoardPosition(actorTile.x, actorTile.y, projectileTimeSecs);
        victimSprite.puppet.getMovements().knockback.movePuppet({ transitTime: BattleCalc.getCooldown(victim), timeVar: projectileTimeSecs });

        Audio.play('leap');
        TweenMax.delayedCall(projectileTimeSecs, Audio.play, ['body_fall_01']);
      }
    }

  },
};
export default TussleRendering;
