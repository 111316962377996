import * as PIXI from 'pixi.js';
import { EditHeroActions } from '~/flux/actions';
import { FluxGetters, HeroBuildStore, UIStore } from '~/flux/stores';
import text from '~/text';
import CanvasTools from '~/view/CanvasTools';
import RolePrioritiesElement from './RolePrioritiesElement';

const RolePrioritiesPanel = function () {
  PIXI.Container.call(this);

  this.dispose = () => {
    HeroBuildStore.removeListener(
      HeroBuildStore.AI_PRIORITIES_CHANGE,
      onAIPrioritiesChange
    );
    UIStore.removeListener(
      UIStore.GAME_SUBMODE_SELECTION,
      onAIPrioritiesChange
    );

    killElementTweens();
    destroyTitle();
    destroyFrames();
    destroyElements();

    this.destroy();
  };

  const destroyTitle = () => {
    if (_title) {
      this.removeChild(_title);
      _title = null;
    }
  };

  const destroyFrames = () => {
    if (_frames) {
      for (var i = 0; i < _frames.length; ++i) {
        this.removeChild(_frames[i]);
      }
      _frames = null;
    }
  };

  const destroyElements = () => {
    if (_elements) {
      for (var i = 0; i < _elements.length; ++i) {
        _elements[i].tap = _elements[i].click = null;
        this.removeChild(_elements[i]);
      }
      _elements = null;
    }
  };

  const killElementTweens = () => {
    if (_elementTweens) {
      for (var i = 0; i < _elementTweens.length; ++i) {
        _elementTweens[i].kill();
      }
    }
  };

  const makeTitle = () => {
    destroyTitle();

    _title = new PIXI.Text(text('ui.role_priorities'), {
      fontFamily: 'Courier New',
      fontSize: CanvasTools.dynamicFontSizeString(36),
      fill: 0x888888,
    });
    _title.x = Math.round(window.innerWidth * 0.375);
    _title.y = Math.round(window.innerHeight * 0.1);
    this.addChild(_title);
  };

  const makeFrames = () => {
    destroyFrames();
    _frames = [];

    for (var i = 0; i < _hero.ai.priorities.length; ++i) {
      var frame = new PIXI.Sprite();
      frame.texture = PIXI.utils.TextureCache['armory/inventory_corner.png'];
      frame.x = Math.round(i * window.innerWidth * 0.13);
      frame.y = Math.round(i * (frame.height + DT_CANVAS_GLOBALS.spacing));
      this.addChild(frame);

      var rankText = new PIXI.Text((i + 1).toString() + '.', {
        fontFamily: 'Courier New',
        fontStyle: 'bold',
        fontSize: CanvasTools.dynamicFontSizeString(24),
        fill: 0xffffff,
      });
      rankText.x = Math.round(
        frame.x - rankText.width - DT_CANVAS_GLOBALS.spacing
      );
      rankText.y = Math.round(frame.y + frame.height / 2 - rankText.height / 2);
      this.addChild(rankText);

      _frames.push(frame);
    }
  };

  const getElementXbyIndex = (element, i) => {
    return Math.round(_frames[i].x + _frames[i].width / 2 - element.label.x);
  };
  const getElementYbyIndex = (element, i) => {
    return Math.round(_frames[i].y + _frames[i].height / 2 - element.label.y);
  };

  const onElementClick = (event) => {
    event.stopPropagation();
    var element = event.target;
    killElementTweens();

    if (_awayElement && _awayElement.aiTreeTag !== element.aiTreeTag) {
      const { focused_hero_handle } = UIStore.getAll();
      EditHeroActions.moveAIRoleToIndex({
        ai_tree_tag: _awayElement.aiTreeTag,
        hero_build_id: FluxGetters.getFocusedHeroBuild().built_hero._id,
        hero_handle: focused_hero_handle,
        target_index: element.index,
      });
    } else if (!element.away) {
      element.away = true;
      _awayElement = element;
      _elementTweens = [
        TweenMax.to(event.target, 0.23, {
          x: window.innerWidth * 0.1,
          y: DT_CANVAS_GLOBALS.stage.height * 0.3,
        }),
      ];
    } else {
      element.away = false;
      _awayElement = null;
      _elementTweens = [
        TweenMax.to(element, 0.23, {
          x: getElementXbyIndex(element, element.index),
          y: getElementYbyIndex(element, element.index),
        }),
      ];
    }
  };

  const makeElements = () => {
    destroyElements();
    _elements = [];

    for (var i = 0; i < _hero.ai.priorities.length; ++i) {
      var element = new RolePrioritiesElement(_hero.ai.priorities[i]);
      element.index = i;
      element.x = getElementXbyIndex(element, i);
      element.y = getElementYbyIndex(element, i);
      this.addChild(element);
      _elements.push(element);

      element.interactive = element.buttonMode = true;
      element.tap = element.click = onElementClick;
    }
  };

  var _hero = FluxGetters.getFocusedHeroBuild().built_hero;
  var _title;
  var _frames;
  var _elements;
  var _elementTweens;
  var _awayElement;
  makeTitle();
  makeFrames();
  makeElements();

  const onAIPrioritiesChange = () => {
    _hero = FluxGetters.getFocusedHeroBuild().built_hero;

    killElementTweens();
    _elementTweens = [];

    for (var i = 0; i < _hero.ai.priorities.length; ++i) {
      for (var j = 0; j < _elements.length; ++j) {
        _elements[j].away = false;
        if (_elements[j].aiTreeTag === _hero.ai.priorities[i]) {
          _elements[j].index = i;
          _elementTweens.push(
            TweenMax.to(_elements[j], 0.2, {
              x: getElementXbyIndex(_elements[j], i),
              y: getElementYbyIndex(_elements[j], i),
            })
          );
        }
      }
    }

    _awayElement = null;
  };
  HeroBuildStore.on(HeroBuildStore.AI_PRIORITIES_CHANGE, onAIPrioritiesChange);
  UIStore.on(UIStore.GAME_SUBMODE_SELECTION, onAIPrioritiesChange);
};
RolePrioritiesPanel.prototype = Object.create(PIXI.Container.prototype);
RolePrioritiesPanel.prototype.constructor = RolePrioritiesPanel;
export default RolePrioritiesPanel;
