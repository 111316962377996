import LeaderboardDispatcher from '~/flux/dispatchers/LeaderboardDispatcher'

const LeaderboardActions = {
  getLeaderboard: function({ game_mode, game_submode }) {
    LeaderboardDispatcher.handleLeaderboardAction({
      actionType: LeaderboardDispatcher.GET_LEADERBOARD,
      game_mode,
      game_submode
    })
  },

  useLocation: function() {
    LeaderboardDispatcher.handleLeaderboardAction({
      actionType: LeaderboardDispatcher.USE_LOCATION
    })
  }
}
export default LeaderboardActions
