import Audio from '~/Audio';

const MoveRendering = {
	render: function( data, gameBoard, isCaverns ) {
		const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
		if (!actorSprite) {
      return;
		}

    const actor = actorSprite.gamePiece;
    const render_movement = !isCaverns || actor.hero
		const victimSprite = gameBoard.getAllPieceSprites()[data.victimId];
		const victim = victimSprite.gamePiece;
		
		if (isCaverns) {
      !render_movement && actorSprite.snapToBoardPosition( victim.x, victim.y );

			const victimSprite = gameBoard.getAllPieceSprites()[data.victimId];
			victimSprite.tileImg.visible = false;
			if (victimSprite.lootImg) {
				victimSprite.lootImg.visible = false;
			}

			const tileSprites = gameBoard.getTileSprites();
			for( var prop in tileSprites ) {
				var tSprite = tileSprites[prop];
				var tile = tSprite.gamePiece;
				if( tile.x===actor.x && tile.y===actor.y ) {
					if (tSprite.lootImg) {
						tSprite.lootImg.visible = true;
					} else {
						tSprite.tileImg.visible = true;
					}
					break;
				} 
			}
			Audio.play( 'sandstone_step_46' );	
		}
    
    if (render_movement) {
      const transitTime = 0.4; // isCaverns ? 0.3 : 0.4;
			actorSprite.tweenToBoardPosition( victim.x, victim.y, transitTime );
			actorSprite.puppet.getMovements().run.movePuppet({ transitTime });
			TweenMax.delayedCall( transitTime*0.25, Audio.play, ['sandstone_step_6'] );
			TweenMax.delayedCall( transitTime*0.85, Audio.play, ['sandstone_step_46'] );			
		}
	}
};
export default MoveRendering;
