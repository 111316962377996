import PuppetMovement, { MELEE_SKILL } from './PuppetMovement';

const BashMovement = (puppet) => {
  const poses = [
    {pose:Pose1}
  ];
     
  return PuppetMovement({
    	poses,
      puppet,
    	setPoseDurations: () => {
	    	poses[0].duration = 0.2;
	    },
      skill_type: MELEE_SKILL,
  });
};
export default BashMovement;

var Pose1 =  {
  head: {
    x: -36,
    y: -132,
    z: 0
  },
  shoulders: {
    x: -42,
    y: -108,
    z: 0
  },
  torsoe: {
    x: 0,
    y: -78,
    z: 0
  },    
  leftHand: {
    x: -56,
    y: -108,
    z: -10
  },      
  bowPitch: 0,
  bowYaw: 0,
  rightHand: {
    x: -37,
    y: -92,
    z: 48
  },
  weaponPitch: 0,
  weaponYaw: 0,
  leftFoot: {
    x: -56,
    y: 0,
    z: 0
  },
  rightFoot: {
    x: 60,
    y: 0,
    z: 0
  }
};