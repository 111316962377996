import LightFromAboveEffect from '../effects/LightFromAboveEffect';

const LightFromAbove = function(victimSprite) {
  var effect = new LightFromAboveEffect(1400);
  effect.alpha = 0.4;
  effect.scale.y = 4;
  effect.y = -effect.height/2 + 20;
  victimSprite.addChild(effect);
  return effect;
};
export default LightFromAbove;
