import { Dispatcher } from 'flux';

const HeroBuildDispatcher = Object.assign(new Dispatcher(), {
  NAME: 'HERO_BUILD_DISPATCHER',

  CREATE_NEW_HERO_BUILD: 'CREATE_NEW_HERO_BUILD',
  DELETE_HERO_BUILD: 'DELETE_HERO_BUILD',
  ENGAGE_HERO_BULID: 'ENGAGE_HERO_BUILD',
  FOCUS_HERO_BUILD: 'FOCUS_HERO_BUILD',

  handleHeroBuildAction: function(action) {
    this.dispatch({
      source: HeroBuildDispatcher.NAME,
      action
    });
  }
});
export default HeroBuildDispatcher;
