import { Dispatcher } from 'flux';

const ApplicationDispatcher = Object.assign(new Dispatcher(), {
  NAME: 'APPLICATION_DISPATCHER',

  ASSETS_LOADED: 'ASSETS_LOADED',
  CONNECT_API_SOCKET: 'CONNECT_API_SOCKET',
  SOCKET_CONNECTED: 'SOCKET_CONNECTED',

  handleAppAction: function(action) {
    this.dispatch({
      source: ApplicationDispatcher.NAME,
      action
    });
  }
});
export default ApplicationDispatcher;
