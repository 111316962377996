import * as PIXI from 'pixi.js';
import CanvasTools from '~/view/CanvasTools';

const PinwheelPanel = function() {
  PIXI.Container.call(this);

  this.pinwheelDelay = 0.3;
  this.pinwheelDuration = 1.5;
  this.progressMeter = 0;
  this.radius = 95;

  this.pinwheelObjects = [];

  // this.pinwheelObjects must be populated before this function is called
  this.updatePinwheelObjects = () => {
    var progressRatio = this.progressMeter/1000;

    // returns angle in radians
    const calculateObjectAngle = (objIndex) => {
      return 2*Math.PI*objIndex / this.pinwheelObjects.length + Math.PI/2;
    };

    for (var i = 0; i < this.pinwheelObjects.length; ++i) {
      var angle = calculateObjectAngle(i) + progressRatio*Math.PI;
      var vec = CanvasTools.angleToVector(angle, 1);
      vec.x *= progressRatio*this.radius;
      vec.y *= progressRatio*this.radius;

      this.pinwheelObjects[i].x = vec.x;
      this.pinwheelObjects[i].y = vec.y;
    }
  };
};
PinwheelPanel.prototype = Object.create(PIXI.Container.prototype);
PinwheelPanel.prototype.constructor = PinwheelPanel;

PinwheelPanel.prototype.init = function() {
  if (this.pinwheelObjects.length === 1) {
    return;
  }

  this.initTween = TweenMax.to(this, this.pinwheelDuration, {
    delay: this.pinwheelDelay,
    progressMeter: 1000,
    onUpdate: this.updatePinwheelObjects,
    ease: Expo.easeOut,
  });
};

PinwheelPanel.prototype.dispose = function() {
  if (this.initTween) {
    this.initTween.kill();
    this.initTween = null;
  }

  if (this.pinwheelObjects) {
    for (var i = 0; i < this.pinwheelObjects.length; ++i) {
      this.pinwheelObjects[i]?.dispose();
    }
    this.pinwheelObjects = null;
  }
  typeof this.disposeCallback === 'function' && this.disposeCallback();
  this.destroy();
};
export default PinwheelPanel;
