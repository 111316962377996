import { GameItemDispatcher } from '~/flux/dispatchers';
import { UIStore } from '~/flux/stores';

const GameItemActions = {
  activateMiracleDye: function({
    dyeId,
    itemId,
    from,
    to,
    tint,
  }) {
    GameItemDispatcher.handleGameItemAction({
      actionType: GameItemDispatcher.ACTIVATE_MIRACLE_DYE,
      dyeId,
      tint,
      itemId,
      from,
      to,
      focused_hero_handle: UIStore.getAll().focused_hero_handle,
    });
  },

  dyeItem: function({ dyeId, itemId, from, to }) {
    GameItemDispatcher.handleGameItemAction({
      actionType: GameItemDispatcher.DYE_ITEM,
      dyeId,
      itemId,
      from,
      to,
      focused_hero_handle: UIStore.getAll().focused_hero_handle,
    });
  },

  equipBagToGlobalBagSlot({
    game_item,
    from,
    to,
  }) {
    GameItemDispatcher.handleGameItemAction({
      actionType: GameItemDispatcher.EQUIP_BAG_TO_GLOBAL_BAG_SLOT,
      game_item,
      from,
      to,
    });
  },

  equipBagToHeroBagSlot({
    game_item,
    from,
    to,
  }) {
    GameItemDispatcher.handleGameItemAction({
      actionType: GameItemDispatcher.EQUIP_BAG_TO_HERO_BAG_SLOT,
      game_item,
      from,
      to,
    });
  },

  equipItem({
    game_item,
    from,
    to,
  }) {
    GameItemDispatcher.handleGameItemAction({
      actionType: GameItemDispatcher.EQUIP_ITEM,
      game_item,
      from,
      to,
    });
  },

  globalBagSlotUnlockConfirm() {
    GameItemDispatcher.handleGameItemAction({
      actionType: GameItemDispatcher.GLOBAL_BAG_SLOT_UNLOCK_CONFIRM,
    });
  },

  heroBagSlotUnlockConfirm() {
    GameItemDispatcher.handleGameItemAction({
      actionType: GameItemDispatcher.HERO_BAG_SLOT_UNLOCK_CONFIRM,
      hero_handle: UIStore.getAll().focused_hero_handle,
    });
  },

  placeItemInGlobalInventory({
    game_item,
    from,
    to,
  }) {
    GameItemDispatcher.handleGameItemAction({
      actionType: GameItemDispatcher.PLACE_ITEM_IN_GLOBAL_INVENTORY,
      game_item,
      from,
      to,
    });
  },

  placeItemInHeroInventory({
    game_item,
    from,
    to,
  }) {
    GameItemDispatcher.handleGameItemAction({
      actionType: GameItemDispatcher.PLACE_ITEM_IN_HERO_INVENTORY,
      game_item,
      from,
      to,
    });
  },

  unequipItem({
    game_item,
    from,
  }) {
    GameItemDispatcher.handleGameItemAction({
      actionType: GameItemDispatcher.UNEQUIP_ITEM,
      game_item,
      from,
    });
  },
};
export default GameItemActions;
