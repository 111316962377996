<template>
  <div class="w-full flex justify-center">
    <div
      v-for="yield_item of state.yield_items"
      :id="`caverns_yield_item_icon_${yield_item.uid}`"
      :key="yield_item.uid"
      :style="`width: ${GAME_ITEM_ICON_SIZE_PX}px; height: ${GAME_ITEM_ICON_SIZE_PX}px;`"
      class="m-2"
    ></div>
  </div>
</template>

<script setup>
import { nextTick, onBeforeUnmount, onMounted, reactive } from 'vue';
import Balance from 'dt-common/constants/Balance';
import GameItemIcon from '~/components/common/GameItemIcon';
import { CavernsStore, UIStore } from '~/flux/stores';
import Tools from '~/Tools';
import CanvasTools from '~/view/CanvasTools';

const GAME_ITEM_ICON_SIZE_PX = 32;
let _yield_icons;

const state = reactive({
  yield_items: [],
})

onMounted(() => {
  CavernsStore.on(CavernsStore.PASSIVE_YIELDS_CLAIMED, onYieldsClaimed);
  UIStore.on(UIStore.GAME_SUBMODE_SELECTION, onGameSubmodeSelection);

  window.addEventListener('resize', handleResize);
  window.addEventListener('orientationchange', handleResize);

  state.yield_items = [
    Balance.getCavernsPassiveYieldXPPot({
      caverns_data: CavernsStore.getAll().caverns_data,
      game_submode: UIStore.getAll().current_game_submode,
    }),
  ];
  nextTick(makeYieldIcons);
});

onBeforeUnmount(() => {
  destroyYieldIcons();
  CavernsStore.removeListener(CavernsStore.PASSIVE_YIELDS_CLAIMED, onYieldsClaimed);
  UIStore.removeListener(UIStore.GAME_SUBMODE_SELECTION, onGameSubmodeSelection);
  window.removeEventListener('resize', handleResize);
  window.removeEventListener('orientationchange', handleResize);
});

const handleResize = Tools.debounce(makeYieldIcons, 300);

function destroyYieldIcons() {
  for (const icon of _yield_icons || []) {
    icon.dispose();
  }
  _yield_icons = null;
}

function makeYieldIcons() {
  destroyYieldIcons();
  _yield_icons = [];

  for (const yield_item of state.yield_items) {
    // make game item icon & add to stage
    const game_item_icon = new GameItemIcon(yield_item);
    game_item_icon.setScale(GAME_ITEM_ICON_SIZE_PX);
    const { x, y, width, height } = CanvasTools.getDomAnchor(`caverns_yield_item_icon_${yield_item.uid}`);
    game_item_icon.x = x + width / 2;
    game_item_icon.y = y + height / 2;
    DT_CANVAS_GLOBALS.stage.addChildAt(game_item_icon, 0); // add at 0 so as not to mask faerie spinners on equipment change

    // store icon data locally
    _yield_icons.push(game_item_icon);
  }
}

function onGameSubmodeSelection({ gameSubmodeKey }) {
  const { caverns_data } = CavernsStore.getAll();
  if (!caverns_data[gameSubmodeKey]) {
    return;
  }

  state.yield_items = [
    Balance.getCavernsPassiveYieldXPPot({
      caverns_data,
      game_submode: gameSubmodeKey,
    }),
  ];
  nextTick(makeYieldIcons);
}

function onYieldsClaimed() {
  state.yield_items = [
    Balance.getCavernsPassiveYieldXPPot({
      caverns_data: CavernsStore.getAll().caverns_data,
      game_submode: UIStore.getAll().current_game_submode,
    }),
  ];
  nextTick(makeYieldIcons);
}
</script>

