import IceProcEffect from '../../effects/IceProcEffect';
import Audio from '~/Audio';

const IceProcRendering = {
  render: function(data, gameBoard, isCaverns) {
    const victimSprite = gameBoard.getAllPieceSprites()[data.victimId];
    if (!victimSprite) {
      return;
    }

    var effect = new IceProcEffect(500);
    victimSprite.addChild(effect);

    Audio.play('ice_nine');
  },
};
export default IceProcRendering;