import FloatingTextEffect from '../../effects/FloatingTextEffect';
import Audio from '~/Audio';
import Colors from '~/constants/Colors';

const DamageRendering = {
  render: (data, gameBoard, isCaverns) => {
    if (!data.damage) {
      return;
    }

    const victimSprite = gameBoard.getAllPieceSprites()[data.victimId];
    if (!victimSprite) {
      return;
    }

    const { gamePiece, puppet: victimPuppet, tileImg } = victimSprite;

    if (isCaverns && !gamePiece.hero) {
      tileImg.tint = Colors.BRIGHT_YELLOW;
      TweenMax.delayedCall(
        0.15,
        (victimTileImg, victim) => {
          victimTileImg.tint = Colors[victim.hero_handle || victim.handle];
        },
        [tileImg, gamePiece]
      );
    } else {
      victimPuppet.setTint(Colors.BRIGHT_YELLOW, true, true);
      TweenMax.delayedCall(0.15, victimPuppet.doNormalTint);
      victimSprite.statBar?.update(gamePiece);
    }

    if ((!isCaverns || gamePiece.hero) && !victimPuppet.inTransit) {
      victimPuppet
        .getMovements()
        .getHurt.movePuppet(victimPuppet, victimPuppet.getCooldown());
    }

    const effect = data.crit
      ? new FloatingTextEffect(
          `*${data.damage.toString()}*`,
          18,
          Colors.AP_ORANGE
        )
      : new FloatingTextEffect(data.damage.toString(), 15, Colors.HP_RED);

    if (data.crit) {
      Audio.play('airy_magic_whoosh');
    }

    if (victimSprite.textQueue) {
      victimSprite.textQueue.push(effect);
    }
  },
};
export default DamageRendering;
