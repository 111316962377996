import * as PIXI from 'pixi.js';

const CancelButton = function(clickCallback) {
  PIXI.Container.call(this)

  // buffer for contracting/expanding on mousedown/up
  let _scale_buffer

  // add the mouseover highlight
  const highlight_ring = new PIXI.Graphics()
  highlight_ring.beginFill(0xffffff, 0.2)
  highlight_ring.drawCircle(0, 0, 16)
  highlight_ring.endFill()
  highlight_ring.visible = false
  this.addChild(highlight_ring)

  // add the red (X) icon
  const icon = new PIXI.Sprite();
  icon.texture = PIXI.utils.TextureCache['icon_cancel.png'];
  icon.anchor.set(0.5, 0.5)
  this.addChild(icon)

  // add interactions ...
  this.interactive = this.buttonMode = true

  // click - invoke callbackaarg
  this.tap = this.click = clickCallback

  // mouseover - highlight
  this.mouseover = this.pointerover = () => {
    highlight_ring.visible = true
  }

  // mouseout - hide highlight
  this.mouseout = this.pointerout = () => {
    highlight_ring.visible = false
    this.mouseup()
  }

  // mousedown - scale down
  this.mousedown = this.touchstart = () => {
    _scale_buffer = { x: this.scale.x, y: this.scale.y }
    this.scale = { x: this.scale.x * 0.92, y: this.scale.y * 0.92 }
  }

  // mouseup - scale up
  this.mouseup = this.touchend = () => {
    if (_scale_buffer) {
      this.scale = _scale_buffer
    }
    highlight_ring.visible = false
  }

  this.dispose = () => {
    this.tap = this.click  = null
    this.mouseover = this.pointerover = null
    this.mouseout = this.pointerout = null
    this.mousedown = this.touchstart = null
    this.mouseup = this.touchend = null

    this.removeChildren()
  }
}
CancelButton.prototype = Object.create(PIXI.Container.prototype)
CancelButton.prototype.constructor = CancelButton
export default CancelButton
