import * as PIXI from 'pixi.js';
import RolePrioritiesPanel from './RolePrioritiesPanel';

const EditAISettings = function () {
  PIXI.Container.call(this);

  this.transitionIn = (duration) => {
    TweenMax.from(this, duration, {
      x: window.innerWidth,
    });
  };

  this.transitionOut = (duration, onComplete) => {
    TweenMax.to(this, duration, {
      x: -window.innerWidth,
      onComplete,
    });
  };

  this.hidePopups = () => {};

  this.dispose = () => {
    destroyRolePrioritiesPanel();

    this.removeChildren();
  };

  const destroyRolePrioritiesPanel = () => {
    if (_rolePrioritiesPanel) {
      _rolePrioritiesPanel.dispose();
      this.removeChild(_rolePrioritiesPanel);
      _rolePrioritiesPanel = null;
    }
  };

  const makeRolePrioritiesPanel = () => {
    destroyRolePrioritiesPanel();

    _rolePrioritiesPanel = new RolePrioritiesPanel();
    _rolePrioritiesPanel.x = Math.round(
      window.innerWidth / 2 - _rolePrioritiesPanel.width / 2
    );
    _rolePrioritiesPanel.y = Math.round(
      window.innerHeight * 0.42 - _rolePrioritiesPanel.height / 2
    );
    this.addChild(_rolePrioritiesPanel);
  };

  var _rolePrioritiesPanel;
  makeRolePrioritiesPanel();
};
EditAISettings.prototype = Object.create(PIXI.Container.prototype);
EditAISettings.prototype.constructor = EditAISettings;
export default EditAISettings;
