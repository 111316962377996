import ExplosionEffect from '../../../effects/ExplosionEffect';
import FlameEffect from '../../../effects/FlameEffect';
import Audio from '~/Audio';

const BurningHandsRendering = {
  render: function (data, gameBoard, isCaverns) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
    const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

    if (!data.isProc && render_movement) {
      actorSprite.puppet
        .getMovements()
        .magicProjectile.movePuppet({ transitTime });
    }
  },

  renderResult: function (data, gameBoard, isCaverns) {
    const victimSprite = gameBoard.getAllPieceSprites()[data.victimId];
    if (!victimSprite) {
      return;
    }

    const timeline = new TimelineMax();

    var xRandomizer = -8 + Math.random() * 13;
    var yRandomizer = -8 + Math.random() * 13;

    var explosion = new ExplosionEffect(700);
    explosion.alpha = 0.6;
    explosion.scale.x = 0.73;
    explosion.scale.y = 0.73;
    explosion.x = xRandomizer;
    explosion.y = yRandomizer - 8;
    victimSprite.addChild(explosion);
    timeline.add(TweenMax.from(explosion.scale, 0.7, { x: 0.2, y: 0.2 }));

    var flame = new FlameEffect(1000);
    flame.x = xRandomizer;
    flame.y = yRandomizer - 20;
    flame.alpha = 0.8;
    victimSprite.addChild(flame);
    timeline.add(TweenMax.from(flame.scale, 0.13, { y: 0 }), 0);
    timeline.add(
      TweenMax.to(flame, 0.87, { alpha: 0, ease: Expo.easeIn }),
      0.13
    );

    Audio.play('burning_hands');
  },
};
export default BurningHandsRendering;
