import PuppetMovement, { MELEE_SKILL } from './PuppetMovement.js';

var SlashMovement = (puppet) => {
  const poses = [ 
    { pose:Pose1() },
    { pose:Pose2() },
    { pose:Pose2() }
  ];
     
  return PuppetMovement({
    poses,
    puppet,
    setPoseDurations: (actorCooldown) => {
      poses[0].duration = (actorCooldown/7)*3;
      poses[1].duration = (actorCooldown/7);
      poses[2].duration = (actorCooldown/7)*2;
    },

    skill_type: MELEE_SKILL,
  });
};
export default SlashMovement;

const Pose1 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -160,
      z: 18
    },        
    shoulders: {
      x: 0,
      y: -144,
      z: 9
    },        
    torsoe: {
      x: 0,
      y: -105,
      z: 0
    },        
    leftHand: {
      x: -24,
      y: -80,
      z: 0
    },    
    bowPitch: 75,
    bowYaw: 75,    
    rightHand: {
      x: -18,
      y: -155,
      z: 60
    },        
    weaponPitch: 30,
    weaponYaw: -30,        
    leftFoot: {
      x: -22,
      y: 0,
      z: 12
    },        
    rightFoot: {
      x: 22,
      y: 0,
      z: -30
    }
  };

  //forward travel
  for(var part in pose) {
    if(pose[part].z) {
      pose[part].z += 28;
    }       
  }

  return pose;       
};

const Pose2 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -160,
      z: 18
    },        
    shoulders: {
      x: 0,
      y: -144,
      z: 9
    },        
    torsoe: {
      x: 0,
      y: -105,
      z: 0
    },        
    leftHand: {
      x: 0,
      y: -105,
      z: 15
    },    
    bowPitch: 0,
    bowYaw: 0,    
    rightHand: {
      x: 70,
      y: -90,
      z: -24
    },        
    weaponPitch: 200,
    weaponYaw: -220,        
    leftFoot: {
      x: -22,
      y: 0,
      z: 12
    },        
    rightFoot: {
      x: 22,
      y: 0,
      z: -30
    }
  };

  //forward travel
  for(var part in pose) {
    if(pose[part].z) {
      pose[part].z += 18;
    }       
  }

  return pose;       
};