import CommonEffects from '../../../effects/CommonEffects';
import Audio from '~/Audio';

const SummonOptionRendering = {
  render: function (data, gameBoard, isCaverns) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const victimSprite = gameBoard.getAllPieceSprites()[data.victimId];
    const transitTime = actorSprite.puppet
      ? actorSprite.puppet.getCooldown()
      : 0;

    actorSprite.puppet &&
      actorSprite.puppet.getMovements().magicSummon.movePuppet({ transitTime });

    TweenMax.delayedCall(transitTime / 3, CommonEffects.makePortalEffect, [
      victimSprite,
    ]);

    TweenMax.delayedCall(transitTime - 0.5, () => {
      for (var i = 0; i < 7; ++i) {
        TweenMax.delayedCall(i * 0.03, CommonEffects.makeCloudPuff, [
          victimSprite,
        ]);
      }

      Audio.play('summon_04');
    });
  },
};
export default SummonOptionRendering;
