import { Dispatcher } from 'flux';

const LoadoutDispatcher = Object.assign(new Dispatcher(), {
  NAME: 'LOADOUT_DISPATCHER',

  SWAP_ENGAGED_HERO: 'SWAP_ENGAGED_HERO',

  handleLoadoutAction: function(action) {
    this.dispatch({
      source: LoadoutDispatcher.NAME,
      action
    });
  }
});
export default LoadoutDispatcher;
