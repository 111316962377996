import Audio from '~/Audio';
	
const MortalStrikeRendering = {
	render: function( data, gameBoard, isCaverns ) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
		if (render_movement) {
      const transitTime = actorSprite.puppet.getCooldown();
			switch (Math.floor(Math.random()*1)) {
				case 0: actorSprite.puppet.getMovements().mortalStrike.movePuppet({ transitTime }); break;
				// case 1: _puppetMaster.stabMovement.movePuppet( _actorSprite.puppet, _actorCooldown ); break;
			}
		}
	},
		
	renderResult: function( data, gameBoard ) {
		if( data.success ) {
			Audio.play( 'melee_attack_19' );
			TweenMax.delayedCall( 0.01, Audio.play, ['armor_break'] );
			TweenMax.delayedCall( 0.02, Audio.play, ['thump_02'] );
		}
	}
}
export default MortalStrikeRendering;