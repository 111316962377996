import Audio from '~/Audio';

const TauntRendering = {
	render: function( data, gameBoard, isCaverns ) {
		const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
		const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;
		render_movement && actorSprite.puppet.getMovements().taunt.movePuppet({ transitTime });
		TweenMax.delayedCall( transitTime*0.5, Audio.play, ['magic_wave'] );
	}
}
export default TauntRendering;