import LightFromAbove from '../../LightFromAbove';
import Audio from '~/Audio';

const HealingPrayerRendering = {
  render: function(data, gameBoard, isCaverns) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const victimSprite = gameBoard.getAllPieceSprites()[data.victimId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
		const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

    render_movement && actorSprite.puppet.getMovements().magicUpGentle.movePuppet({ transitTime });

    setTimeout(()=> {
      LightFromAbove(victimSprite);
    }, transitTime*1000*0.5);


    Audio.play('positive_magic_01');
  },
};
export default HealingPrayerRendering;