import PuppetMovement from './PuppetMovement.js';

const VampStandardMovement = (puppet) => {
  const poses = [
    { pose: StandardPose1 },
    { pose: StandardPose2 }
  ];

  return PuppetMovement({
    poses,
    puppet,
    setPoseDurations: (actor_cooldown) => {
      poses[0].duration = poses[1].duration = actor_cooldown / 2;
    }
  });
};
export default VampStandardMovement;

const StandardPose1 =  {
  head: {
    x: 0,
    y: -157,
    z: 0
  },
  shoulders: {
    x: 0,
    y: -144,
    z: 0
  },
  torsoe: {
    x: 0,
    y: -105,
    z: 0
  },
  leftHand: {
    x: -46,
    y: -103,
    z: 42
  },
  bowPitch: 0,
  bowYaw: 0,
  rightHand: {
    x: 41,
    y: -69,
    z: -24
  },
  weaponPitch: 0,
  weaponYaw: 0,
  leftFoot: {
    x: -21,
    y: 0,
    z: 36
  },
  rightFoot: {
    x: 16,
    y: 0,
    z: -15
  }
};

const StandardPose2 = {
  head: {
    x: 0,
    y: -155,
    z: 0
  },
  shoulders: {
    x: 0,
    y: -139,
    z: 0
  },
  torsoe: {
    x: 0,
    y: -102,
    z: 0
  },
  leftHand: {
    x: -46,
    y: -100,
    z: 42
  },
  bowPitch: 0,
  bowYaw: 0,
  rightHand: {
    x: 41,
    y: -67,
    z: -24
  },
  weaponPitch: 0,
  weaponYaw: 0,
  leftFoot: {
    x: -21,
    y: 0,
    z: 36
  },
  rightFoot: {
    x: 16,
    y: 0,
    z: -15
  }
};
