import PuppetMovement from './PuppetMovement.js';

const ThrowMovement = (puppet) => {
  const poses = [ 
    { pose:Pose1() },
    { pose:Pose2() },
    { pose:Pose3() },
    { pose:Pose3() }
  ];
     
  return PuppetMovement({
		poses,
    puppet,
    setPoseDurations: function(actorCooldown) {
      const transTime = actorCooldown/7;
      poses[0].duration = transTime;
      poses[1].duration = transTime*2;
      poses[2].duration = transTime;
      poses[3].duration = transTime*2;
    }
  });
};
export default ThrowMovement;

const Pose1 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -156,
      z: -10
    },        
    shoulders: {
      x: 0,
      y: -140,
      z: -5
    },        
    torsoe: {
      x: 0,
      y: -105,
      z: 0
    },        
    leftHand: {
      x: -18,
      y: -105,
      z: 66
    },       
    bowPitch: 0,
    bowYaw: 62, 
    rightHand: {
      x: 59,
      y: -111,
      z: -38
    },        
    weaponPitch: 0,
    weaponYaw: 0,        
    leftFoot: {
      x: -23,
      y: 0,
      z: 30
    },        
    rightFoot: {
      x: 15,
      y: 0,
      z: -10
    }
  };

  // backward travel
  for(var part in pose) {
    if(pose[part].z) {
      pose[part].z -= 15;
    }       
  }

  return pose;       
};

const Pose2 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -156,
      z: -10
    },        
    shoulders: {
      x: 0,
      y: -140,
      z: -5
    },        
    torsoe: {
      x: 0,
      y: -105,
      z: 0
    },        
    leftHand: {
      x: -18,
      y: -96,
      z: 36
    },       
    bowPitch: 0,
    bowYaw: 62, 
    rightHand: {
      x: 36,
      y: -156,
      z: -18
    },        
    weaponPitch: 0,
    weaponYaw: 0,        
    leftFoot: {
      x: -23,
      y: 0,
      z: 30
    },        
    rightFoot: {
      x: 15,
      y: 0,
      z: -10
    }
  };

  // backward travel
  for(var part in pose) {
    if(pose[part].z) {
      pose[part].z -= 15;
    }       
  }

  return pose;       
};

const Pose3 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -156,
      z: 24
    },        
    shoulders: {
      x: 0,
      y: -140,
      z: 12
    },        
    torsoe: {
      x: 0,
      y: -105,
      z: 0
    },        
    leftHand: {
      x: -48,
      y: -85,
      z: -24
    },       
    bowPitch: 90,
    bowYaw: 0, 
    rightHand: {
      x: 36,
      y: -140,
      z: 99
    },        
    weaponPitch: 0,
    weaponYaw: 0,        
    leftFoot: {
      x: 0,
      y: 0,
      z: 0
    },        
    rightFoot: {
      x: 12,
      y: 0,
      z: -30
    }
  };

  return pose;       
};