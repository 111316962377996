import * as PIXI from 'pixi.js';

const StripAnimation = function( 
	stripName, 
	duration,
	numFrames,
	loop,
	loopLength ) {
		
	PIXI.Sprite.call( this );

	var START_TIME = new Date().getTime();

	const frames = [];
	for( var i = 0; i < numFrames; ++i ) {
		frames.push(
      PIXI.utils.TextureCache[stripName+'_'+i.toString()+'.png']
    );
	}
	this.texture = frames[0];

	this.anchor.x = this.anchor.y = 0.5;					

	this.done = false;
	var lifeTime;
	var lifetimeRatio;
	var newFrame = 0;
	var lastFrame = 0;
	const animate = () => {
		if( this.done ) {
			return;
		}
		frameId = requestAnimationFrame( animate );	

		lifeTime = new Date().getTime() - START_TIME;
		if( loop )	{
			lifetimeRatio = ( lifeTime % loopLength ) / loopLength;
		} else {
			lifetimeRatio = lifeTime / duration;
		}
		
		newFrame = Math.min( numFrames-1, Math.floor(lifetimeRatio*numFrames) );
		if( newFrame !== lastFrame ) {
			this.texture = frames[ newFrame ];
			lastFrame = newFrame;
		}
	};
	var frameId = requestAnimationFrame( animate );
	
	this.dispose = () => {
    gsap.killTweensOf(this);
		cancelAnimationFrame( frameId );
    this.removeChildren();
    this.parent?.removeChild(this);
		this.done = true;
	};
	setTimeout( this.dispose, duration );

	this.reset = () => {
		this.done = false;
		START_TIME = new Date().getTime();
		frameId = requestAnimationFrame( animate );
		setTimeout( this.dispose, duration );
	};
};
StripAnimation.prototype = Object.create( PIXI.Sprite.prototype );
StripAnimation.prototype.constructor = StripAnimation;
export default StripAnimation;