/**
 * ...
 * @author Robert Lombardo, Wakefield Studios ( rob@wakefield-studios.com )
 */	
import StripAnimation from './StripAnimation';

const PoisonedEffect = function( duration ) {
	return new StripAnimation( 
		'effects/poisoned',
		duration,
		42,
		false
	);	
}
export default PoisonedEffect;