import * as PIXI from 'pixi.js';
// import { formatShort } from 'swarm-numberformat'
import CanvasTools from '~/view/CanvasTools'
import CancelButton from '~/components/common/CancelButton'
import text from '~/text'
import Colors from '~/constants/Colors'
import AltarStore from '~/flux/stores/AltarStore'
import AltarActions from '~/flux/actions/AltarActions'
import Tools from '~/Tools'

const PrayerSummary = function( killPrayerFunction ) {
    PIXI.Container.call( this );

    this.dispose = () => {
      AltarStore.removeListener(AltarStore.PRAYER_DATA, onNewPrayerData)

      if (checkButton) {
        checkButton.tap = checkButton.click  = null
        checkButton.mouseover = checkButton.pointerover = null
        checkButton.mouseout = checkButton.pointerout = null
        checkButton.mousedown = checkButton.touchstart = null
        checkButton.mouseup = checkButton.touchend = null
      }
      if (cancelButton) {
        cancelButton.dispose()
      }

      this.removeChildren()
    }

    // Sacrifice _____ gold to the god of _______, and ask for a _______.
    const style = {
        fontFamily: 'Courier New',
        fontSize: CanvasTools.dynamicFontSizeString(16),
        fill: 0xffffff
    };

    const TEXT_Y = -(DT_CANVAS_GLOBALS.spacing / 2)

    var pt_1 = new PIXI.Text( text('ui.altar_summary.pt_1'), style );
    pt_1.visible = false;
    pt_1.y = TEXT_Y
    this.addChild( pt_1 );

    const gold_amnt_string = AltarStore.getAll().newPrayerData.goldValue; // formatShort(AltarStore.getAll().newPrayerData.goldValue, { sigfigs: 4 })
    var gold = new PIXI.Text(`${gold_amnt_string} ${text('ui.gold')}`, style)
    gold.style.fill = Colors.BRIGHT_YELLOW;
    gold.style.fontStyle = 'bold';
    gold.visible = false;
    gold.y = TEXT_Y
    this.addChild( gold );

    var pt_2 = new PIXI.Text( text('ui.altar_summary.pt_2'), style );
    pt_2.visible = false;
    pt_2.y = TEXT_Y
    this.addChild( pt_2 );

    var god = new PIXI.Text( '_____', style );
    god.style.fontStyle = 'bold';
    god.visible = false;
    god.y = TEXT_Y
    this.addChild( god );

    var pt_3 = new PIXI.Text( text('ui.altar_summary.pt_3'), style );
    pt_3.visible = false;
    pt_3.y = TEXT_Y
    this.addChild( pt_3 );

    var item = new PIXI.Text( '_____', style );
    item.style.fontStyle = 'bold';
    item.visible = false;
    item.y = TEXT_Y
    this.addChild( item );

    var ellipses = new PIXI.Text( '...', style );
    ellipses.y = TEXT_Y
    this.addChild( ellipses );

    var checkButton = new PIXI.Sprite();
    checkButton.texture = PIXI.utils.TextureCache['icon_check.png'];
    checkButton.y = this.height + DT_CANVAS_GLOBALS.spacing/2 + checkButton.height / 2;
    checkButton.interactive = checkButton.buttonMode = true;
    checkButton.tap = checkButton.click = () => {
        AltarActions.setNewPrayerParameter();
    };

    // var cancelButton = PIXI.Sprite.from( 'icon_cancel.png' );
    // cancelButton.width = checkButton.width
    // cancelButton.scale.y = cancelButton.scale.x
    // cancelButton.y = checkButton.y;
    // cancelButton.interactive = cancelButton.buttonMode = true;
    // cancelButton.tap = cancelButton.click = () => {
    //     onNewPrayerData( null );
    //     killPrayerFunction();
    // };
    const cancelButton = new CancelButton(() => {
      onNewPrayerData(null),
      killPrayerFunction()
    })
    cancelButton.width = checkButton.width
    cancelButton.scale.y = cancelButton.scale.x
    cancelButton.y = checkButton.y

    const onNewPrayerData = ( prayerData ) => {
        var lastField = gold;

        pt_1.visible = false;
        gold.visible = false;
        pt_2.visible = false;
        god.visible = false;
        pt_3.visible = false;
        item.visible = false;
        checkButton.visible = false;
        cancelButton.visible = false;

        if( prayerData && !prayerData.itemDataIsSet ) {
            if( prayerData.goldValue ) {
                pt_1.visible = true;
                const gold_amnt_string = Tools.formatGameCurrency(
                  AltarStore.getAll().newPrayerData.goldValue
                ) // formatShort(AltarStore.getAll().newPrayerData.goldValue, { sigfigs: 4 })
                gold.text = `${gold_amnt_string} ${text('ui.gold')}`
                // gold.text = prayerData.goldValue+' '+text('ui.gold');
                gold.visible = true;
            }

            if( prayerData.goldValueIsSet ) {
                pt_2.visible = true;
                god.text = text('ui.gods.'+prayerData.godHandle);
                switch (prayerData.godHandle) {
                  case 'chaos': god.style.fill = 0xff0000; break
                  case 'balance': god.style.fill = 0x00ff00; break
                  case 'order': god.style.fill = 0x0000ff; break
                }
                god.visible = true;
                lastField = god;
            }

            if( prayerData.godHandleIsSet ) {
                pt_3.visible = true;
                item.text = prayerData.item? text('ui.'+prayerData.item.slot+'_'+prayerData.item.type) : '_____';
                item.visible = true;
                lastField = item;
            }

            gold.x = pt_1.x + pt_1.width;
            pt_2.x = gold.x + gold.width;
            god.x = pt_2.x + pt_2.width;
            pt_3.x = god.x + god.width;
            item.x = pt_3.x + pt_3.width;

            ellipses.x = lastField.x + lastField.width;

            checkButton.x = (ellipses.x + ellipses.width)/2 - checkButton.width / 2 - DT_CANVAS_GLOBALS.spacing/2;
            cancelButton.x = checkButton.x + checkButton.width + DT_CANVAS_GLOBALS.spacing;

            this.addChild( checkButton );
            this.addChild( cancelButton );
            checkButton.visible = true;
            cancelButton.visible = true;

            checkButton.anchor.set(0.5, 0.5)

            let hr = checkButton.highlight_ring = new PIXI.Graphics()
            hr.beginFill(0xffffff, 0.2);
            hr.drawCircle(0, 0, 16)
            hr.endFill()
            hr.visible = false
            checkButton.addChildAt(hr, 0)

            checkButton.mouseover = checkButton.pointerover = ((btn) => {
              btn.highlight_ring.visible = true
            }).bind(null, checkButton);
            checkButton.mouseout = checkButton.pointerout = ((btn) => {
              btn.highlight_ring.visible = false
            }).bind(null, checkButton);

            checkButton.mousedown = checkButton.touchstart = (e) => {
              e.target.scale_buffer = { x: e.target.scale.x, y: e.target.scale.y }
              e.target.scale = { x: e.target.scale.x * 0.92, y: e.target.scale.y * 0.92 }
            }
            checkButton.mouseup = checkButton.touchend = (e) => {
              if (e.target.scale_buffer) {
                e.target.scale = e.target.scale_buffer
              }
              e.target.highlight_ring.visible = false
            }
        } else {
          ellipses.x = 0
        }

        this.pivot.x = this.width / 2
    }
    AltarStore.on(AltarStore.PRAYER_DATA, onNewPrayerData)

    this.pivot.x = this.width / 2
}
PrayerSummary.prototype = Object.create(PIXI.Container.prototype)
PrayerSummary.prototype.constructor = PrayerSummary
export default PrayerSummary
