import { Dispatcher } from 'flux';

const SettingsDispatcher = Object.assign(new Dispatcher(), {
  NAME: 'SETTINGS_DISPATCHER',

  SAVE_SETTINGS: 'SAVE_SETTINGS',

  handleSettingsAction: function(action) {
    this.dispatch({
      source: SettingsDispatcher.NAME,
      action
    });
  }
});
export default SettingsDispatcher;
