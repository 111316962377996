<template>
  <div class="flex flex-col items-center">
    <form class="flex flex-col">
      <label htmlFor="email" class="pt-3">
        {{ text('ui.get_newsletter') }}
      </label>
      <input 
        id="email"
        type="text" 
        name="email" 
        :value="state.input.email"
        :onChange="handleEmailFormChange"
        :placeholder="text('ui.enter_email')"
        class="w-[240px] px-4 py-2 text-black" 
      />
      <div class="relative w-full h-60px flex items-center justify-center">
        <DTButton
          type="success"
          :disabled="state.pending_submission"
          class="mt-3 px-8 py-2 w-[138px] h-[46px]"
          @click="handleSubmitEmailForm"
        >
        <div
          v-if="state.pending_submission"
          class="w-full h-full flex justify-center items-center"
        >
          <img
            :src="`${Config.DOM_IMG_PATH}/refresh.png`"
            class="h-7 w-auto spin"
          />
        </div>
        <span v-else>
          {{ text('ui.submit_newsletter_btn') }}
        </span>
      </DTButton>
      </div>
      <div
        class="text-cyan-300 mt-3"
        :style="{
          marginLeft: '10px',
          color: 'cyan',
        }"
      >
        <span :style="{ textDecoration: state.social.signed_up_newsletter ? 'line-through':'normal'}">
          {{ text('ui.plus_100_pd') }}
        </span>
        <span v-if="state.social.signed_up_newsletter">
          Thank you!
        </span>
      </div>
    </form>
    <div
      v-if="state.success"
      :style="{ margin: '0 0 10px', textAlign: 'center', color: '#0f0', fontSize: '11px' }"
    >
      {{ text('ui.newsletter_signup_success') }}
    </div>
    <div class="h-6">
      <div
        v-if="!state.pending_submission"
        class="text-xs text-red-500"
      >
        {{ state.errors.email }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, onBeforeUnmount, reactive } from 'vue';
import { Config } from '~/constants';
import { PlayerActions } from '~/flux/actions';
import { PlayerStore } from '~/flux/stores';
import text from '~/text';
import DTButton from './DTButton.vue';

const state = reactive({
  pending_submission: false,
  input: {
    email: ''
  },
  errors: {},
  social: PlayerStore.getAll().social || {}
});

onBeforeMount(() => {
  PlayerStore.on(PlayerStore.NEWSLETTER_SIGNUP_ERROR, onSignupError)
  PlayerStore.on(PlayerStore.NEWSLETTER_SIGNUP_SUCCESS, onCompletedSignup)
});

onBeforeUnmount(() => {
  PlayerStore.removeListener(PlayerStore.NEWSLETTER_SIGNUP_ERROR, onSignupError)
  PlayerStore.removeListener(PlayerStore.NEWSLETTER_SIGNUP_SUCCESS, onCompletedSignup)
});

function handleEmailFormChange(event) {
  state.input = { email: event.target.value }
}
     
async function handleSubmitEmailForm(event) {
  event.preventDefault()

  if (validateEmailForm()) {
    PlayerActions.submitNewsletterSignup(state.input.email)

    state.pending_submission = true;
    state.input = {
       email: '',
    };
  }
}

function validateEmailForm(email_only) {
  let input = state.input
  let errors = {}
  let isValid = true

  if (!input["email"]) {
    isValid = false
    errors["email"] = "Please enter your email Address."
  }

  if (typeof input["email"] !== "undefined") {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!pattern.test(input["email"])) {
      isValid = false;
      errors.email = "Please enter a valid email address.";
    }
  }

  state.errors = errors;
  return isValid
}

function onSignupError(detail) {
  state.pending_submission = false;
  state.errors = { email: detail };
}

function onCompletedSignup() {
  state.pending_submission =false;
  state.social = PlayerStore.getAll().social || {};
  state.success = true;
}
</script>
