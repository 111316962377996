import PuppetMovement from './PuppetMovement.js';

const OffensiveStanceMovement = (puppet) => {
  const poses = [
    {pose:Pose1},
    {pose:Pose2}
  ];
  
  return PuppetMovement({
    	poses,
      puppet,
    	setPoseDurations: (actorCooldown) => {
	    	poses[0].duration = poses[1].duration = actorCooldown/2;
	    }
  });
};
export default OffensiveStanceMovement;

var Pose1 =  {
  head: {
    	x: 0,
    y: -144,
    z: 18
  },
  shoulders: {
    x: 0,
    y: -123,
    z: 9
  },
  torsoe: {
    x: 0,
    y: -96,
    z: 5
  },    
  leftHand: {
    x: 18,
    y: -84,
    z: 54
  },      
  bowPitch: -25,
  bowYaw: -25,
  rightHand: {
    x: 57,
    y: -175,
    z: -24
  },
  weaponPitch: 30,
  weaponYaw: -63,
  leftFoot: {
    x: 0,
    y: 0,
    z: 12
  },
  rightFoot: {
    x: 16,
    y: 0,
    z: -60
  }
};	
	
var Pose2 = {
  head: {
    x: 5,
    y: -144,
    z: 28
  },
  shoulders: {
    x: 5,
    y: -123,
    z: 14
  },
  torsoe: {
    x: 5,
    y: -96,
    z: 14
  },
  leftHand: {
    x: 23,
    y: -84,
    z: 64
  },
  bowPitch: -25,
  bowYaw: -25,
  rightHand: {
    x: 57,
    y: -170,
    z: -18
  },
  weaponPitch: 30,
  weaponYaw: -63,
  leftFoot: {
    x: 0,
    y: 0,
    z: 12
  },
  rightFoot: {
    x: 16,
    y: 0,
    z: -60	
  }
};