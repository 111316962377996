import StripAnimation from './StripAnimation';

const ExplosionEffect = function( duration ) {
	return new StripAnimation( 
		'effects/explosion',
		duration,
		23,
		false
	);	
}
export default ExplosionEffect;