/**
 * ...
 * @author Robert Lombardo, Wakefield Studios ( rob@wakefield-studios.com )
 */	
import FloatingTextEffect from '../../effects/FloatingTextEffect';
import Colors from '~/constants/Colors';
import text from '~/text';

const LoseMPRendering = {
  render: function(data, gameBoard, isCaverns) {
    const victimSprite = gameBoard.getAllPieceSprites()[data.victimId];
    if(!victimSprite) {
      return;
    }
			
    var effect = new FloatingTextEffect('-'+data.potency.toFixed(0), 15, Colors.MP_BLUE);
			
    if(victimSprite.textQueue) {
      victimSprite.textQueue.push(effect);
    }
  }
};
export default LoseMPRendering;