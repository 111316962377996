import Audio from '~/Audio';

const MoveEventRendering = {
	render: function( data, gameBoard, isCaverns ) {
		/*if( isCaverns ) {
			const victimSprite = gameBoard.getAllPieceSprites()[data.victimId];
			victimSprite.tileImg.visible = false;

			const oldTileSprite = gameBoard.getAllPieceSprites()[data.oldTileId];
			oldTileSprite.tileImg.visible = true;
		}*/
	}
};
export default MoveEventRendering;