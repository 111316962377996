<template>
  <span>
    {{ state.elipsis_text }}
  </span>
</template>

<script setup>
import { onBeforeUnmount, onMounted, reactive } from 'vue';

const state = reactive({
  elipsis_text: '.',
});

onMounted(() => {
  const interval = setInterval(() => {
    switch (state.elipsis_text) {
      case '.':
        state.elipsis_text = '..';
        break;
      case '..':
        state.elipsis_text = '...';
        break;
      case '...':
        state.elipsis_text = '.';
        break;
    }
  }, 200);

  onBeforeUnmount(() => {
    clearInterval(interval);
  });
});
</script>
