import PuppetMovement from './PuppetMovement';

const MagicSummonMovement = (puppet) => {     
  const poses = [ 
    { pose: Pose1() },
    { pose: Pose1() },
    { pose: Pose2() },
    { pose: Pose3() },
    { pose: Pose3() }
  ];

  return PuppetMovement({
		poses,
    puppet,
    setPoseDurations: (actor_cooldown) => {
      const transTime = actor_cooldown / 9;
      poses[0].duration = transTime * 2;
      poses[1].duration = transTime * 2;
      poses[2].duration = transTime * 2;
      poses[3].duration = transTime;
      poses[4].duration = transTime * 2;
    }
  });
};
export default MagicSummonMovement;

// gesture of offering
const Pose1 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -160,
      z: 0
    },        
    shoulders: {
      x: 0,
      y: -144,
      z: 0
    },        
    torsoe: {
      x: 0,
      y: -105,
      z: 0
    },        
    leftHand: {
      x: -58,
      y: -195,
      z: 52
    },    
    bowPitch: 15,
    bowYaw: -52,    
    rightHand: {
      x: 58,
      y: -195,
      z: 52
    },        
    weaponPitch: -15,
    weaponYaw: 52,        
    leftFoot: {
      x: -22,
      y: 0,
      z: 10
    },        
    rightFoot: {
      x: 22,
      y: 0,
      z: -5
    }
  };

  return pose;       
};

// hands together above head
const Pose2 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -160,
      z: 0
    },        
    shoulders: {
      x: 0,
      y: -144,
      z: 0
    },        
    torsoe: {
      x: 0,
      y: -105,
      z: 0
    },        
    leftHand: {
      x: 0,
      y: -190,
      z: -8
    },    
    bowPitch: -90,
    bowYaw: 0,    
    rightHand: {
      x: 0,
      y: -190,
      z: -8
    },        
    weaponPitch: 90,
    weaponYaw: -90,        
    leftFoot: {
      x: -22,
      y: 0,
      z: 10
    },        
    rightFoot: {
      x: 22,
      y: 0,
      z: -5
    }
  };

  return pose;       
};

// weapon slammed into ground
const Pose3 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -160,
      z: 0
    },        
    shoulders: {
      x: 0,
      y: -144,
      z: 0
    },        
    torsoe: {
      x: 0,
      y: -105,
      z: 0
    },        
    leftHand: {
      x: 0,
      y: -87,
      z: 47
    },    
    bowPitch: 0,
    bowYaw: 0,    
    rightHand: {
      x: 0,
      y: -87,
      z: 47
    },        
    weaponPitch: 180,
    weaponYaw: -180,        
    leftFoot: {
      x: -22,
      y: 0,
      z: 10
    },        
    rightFoot: {
      x: 22,
      y: 0,
      z: -5
    }
  };

  return pose;       
};