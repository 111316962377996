const _shopkeeperMessages = [
  'Come back to haggle with me again, have you?',
  // "They say chaotic objects can change form...",
  'Try Sleight of Hand against healers.',
  "Bleeding and Poisoned conditions stack, y'know.",
  'Send Mana is a powerful combo for teams with summoners.',
  'They say Learning and Willpower help you resist Fear & Rage.',
  'They say Strength and Dexterity help you resist Stuns & Slows.',
  'They say the power of weapon procs is based on your level.',
  "Void spike isn't blockable or dodgeable.",
  'Did you know you can zoom in on the battlefield?',
  'I get new stock every 30 minutes.',
  'The better your shield, the more often you can block.',
  "Armor won't help you against lightning attacks...",
  'Poison ticks every second; bleed every 3 seconds. Now you know.',
  "Y'know you can swap Inn attackers without affecting your defense squad.",
];

export default {
  randomShopkeeperMessage: () => {
    return _shopkeeperMessages[
      Math.floor(Math.random() * _shopkeeperMessages.length)
    ];
  },
};
