import BleedingEffect from '../../effects/BleedingEffect';

const instances = {};
	
const BleedingRendering = {
	render: function( condition, gameBoard ) {
		if( instances[condition.uid] ) {
			return;
		}

		instances[ condition.uid ] = {};
		instances[ condition.uid ].ownerSprite = gameBoard.getAllPieceSprites()[condition.ownerId];
				
		const makeBloodDrip = ( conditionId ) => {
			const ownerSprite = instances[conditionId].ownerSprite;
			if( !ownerSprite ) {
				return;
			}

			var bloodDrip = new BleedingEffect( 800 );
			bloodDrip.scale.x = 0.7 / ownerSprite.scale.x;
			bloodDrip.scale.y = 0.7 / ownerSprite.scale.y;
			ownerSprite.addChild( bloodDrip );

			TweenMax.to( bloodDrip, 0.8, {
				alpha: 0,
				ease: Quad.easeIn,
			});

			instances[ conditionId ].interval = TweenMax.delayedCall( 1.5+Math.random()*2.5, makeBloodDrip, [conditionId] );
		};

		instances[ condition.uid ].interval = TweenMax.delayedCall( Math.random()*0.1, makeBloodDrip, [condition.uid] );
	},

	stopRendering: function( conditionId ) {
		if( instances[conditionId] ) {
			instances[conditionId].interval.kill();
			delete instances[ conditionId ];
		}
	},

	dispose: function() {
		for( var prop in instances ) {
			BleedingRendering.stopRendering( prop );
		}
	}
};
export default BleedingRendering;