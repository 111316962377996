import PoisonWeaponEffect from '../../../effects/PoisonWeaponEffect';
import Audio from '~/Audio';

const PoisonWeaponRendering = {
  render: function(data, gameBoard, isCaverns) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const victimSprite = gameBoard.getAllPieceSprites()[data.victimId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
    const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

    render_movement && actorSprite.puppet.getMovements().tinker.movePuppet({ transitTime });

    setTimeout(()=>{
      const effect = new PoisonWeaponEffect(1000);
      effect.x = victimSprite.x;
      effect.y = victimSprite.y + 5;
      gameBoard.addChild(effect);
      TweenMax.to(effect, 1.0, { alpha: 0, ease: Expo.easeIn });

      Audio.play('poison_weapon');
    }, transitTime);
  },
};
export default PoisonWeaponRendering;