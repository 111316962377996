import * as PIXI from 'pixi.js';
import { ObjectPool } from '~/Tools'

const VEL_FACTOR = 0.1
const MAX_VEL = VEL_FACTOR * 5
const parentWidth = 64
const parentHeight = 64

const mote_pool = new ObjectPool(
  // create func
  () => {
    const mote = new PIXI.Graphics()
    mote.beginFill(0xffffff)
    mote.drawRect(0, 0, 1, 1)
    mote.endFill()
    return mote
  },

  // reset func
  (mote) => {
    gsap.killTweensOf(mote);
    mote.x = 0
    mote.y = 0
    mote.alpha = 1

    if (mote.parent) {
      mote.parent.removeChild(mote)
    }
  },

  // starting num
  200
)

const FaerieSprite = function() {
	PIXI.Container.call(this)

	const dot = new PIXI.Graphics()
	dot.beginFill(0xffffff)
	dot.drawRect(0, 0, 2, 2)
	dot.endFill()
	this.addChild(dot)

	let _xVel = 0
	let _yVel = 0

	const animate = () => {
		if (dot.x < -parentWidth / 5) {
			_xVel += VEL_FACTOR
		} else if (dot.x > parentWidth / 5) {
			_xVel -= Math.random() * VEL_FACTOR
		} else {
			_xVel += -VEL_FACTOR + Math.random() * 2 * VEL_FACTOR		
		}

		if (dot.y < -parentHeight / 2) {
			_yVel += VEL_FACTOR
		} else if (dot.y > -parentHeight / 4) {
			_yVel -= Math.random() * VEL_FACTOR
		} else {
			_yVel += -VEL_FACTOR + Math.random() * 2 * VEL_FACTOR		
		}

		_xVel = Math.min( Math.max(_xVel, -MAX_VEL), MAX_VEL)
		_yVel = Math.min( Math.max(_yVel, -MAX_VEL), MAX_VEL)

		dot.x += _xVel
		dot.y += _yVel

		_animation_frame_id = requestAnimationFrame(animate)
	}
	let _animation_frame_id = requestAnimationFrame(animate)

	var _dustInterval
	const makeDust = () => {
		const mote = mote_pool.get()
		mote.x = dot.x
		mote.y = dot.y
		this.addChild(mote)

		TweenMax.to(mote, 1.876, {
			y: mote.y + 25,
			alpha: 0,
			ease: Quad.easeIn,
      onComplete: mote_pool.put,
			onCompleteParams: [mote]
		})

		_dustInterval = TweenMax.delayedCall(0.01 + Math.random() * 0.2, makeDust)
	}
	makeDust()

	this.dispose = () => {
		cancelAnimationFrame(_animation_frame_id)

		if (_dustInterval) {
      _dustInterval.kill()
		  _dustInterval = null
    }

    this.removeChildren()
	}
}
FaerieSprite.prototype = Object.create(PIXI.Container.prototype)
FaerieSprite.prototype.constructor = FaerieSprite
export default FaerieSprite
