<template>
  <div>
    <div
      id="hero_roster_root"
      class="w-full h-full"
    />
  </div>
</template>

<script setup>
import * as PIXI from 'pixi.js';
import { onBeforeUnmount, onMounted } from 'vue';
import Audio from '~/Audio';
import { Colors, Screens } from '~/constants';
import { UIActions } from '~/flux/actions';
import { GameStateStore } from '~/flux/stores';
import text from '~/text';
import Tools from '~/Tools';
import CanvasTools from '~/view/CanvasTools';
import HeroRosterPuppets from '../canvas/HeroRosterPuppets';

let _hero_roster_puppets;
let _onboard_msg;

onMounted(() => {
  Audio.setBGTrack('menu_music');
  makeHeroRosterPuppets();

  window.addEventListener('resize', handleResize);
  window.addEventListener('orientationchange', handleResize);
  GameStateStore.on(GameStateStore.HERO_UNLOCKED, onHeroUnlocked);
});

onBeforeUnmount(() => {
  destroyHeroRosterPuppets();
  destroyOnboardMsg();

  window.removeEventListener('resize', handleResize);
  window.removeEventListener('orientationchange', handleResize);
  GameStateStore.removeListener(GameStateStore.HERO_UNLOCKED, onHeroUnlocked);
});

const handleResize = Tools.debounce(() => {
  makeHeroRosterPuppets();
}, 300);

function onHeroUnlocked() {
  setTimeout(() => {
    UIActions.uiNav({ screen_id: Screens.HERO_BUILDS });
  }, 2000);
}

function makeHeroRosterPuppets() {
  destroyHeroRosterPuppets();

  const { gameState, num_unlocked_heroes } = GameStateStore.getAll();

  _hero_roster_puppets = new HeroRosterPuppets(gameState.hero_roster);
  const { x, y, width, height } = CanvasTools.getDomAnchor('hero_roster_root');
  _hero_roster_puppets.x = x + width / 2 - _hero_roster_puppets.width / 2;
  _hero_roster_puppets.y = y + height / 2 - _hero_roster_puppets.height / 2;
  DT_CANVAS_GLOBALS.stage.addChild(_hero_roster_puppets);

  if (num_unlocked_heroes === 0) {
    makeOnboardMsg();
  }
}

function makeOnboardMsg() {
  destroyOnboardMsg();

  _onboard_msg = new PIXI.Text(text('tutorial.unlock_a_hero'), {
    fontFamily: 'Courier New',
    fontSize: CanvasTools.dynamicFontSizeString(18),
    fill: Colors.BRIGHT_YELLOW,
  });
  const { x, y, width, height } = CanvasTools.getDomAnchor('hero_roster_root');
  _onboard_msg.x = Math.round(x + width / 2 - _onboard_msg.width / 2);
  _onboard_msg.y = Math.round(y + height * 0.18 - _onboard_msg.height / 2);
  TweenMax.from(_onboard_msg, 3.5, { alpha: 0, ease: Expo.easeIn });
  DT_CANVAS_GLOBALS.stage.addChild(_onboard_msg);
}

function destroyHeroRosterPuppets() {
  _hero_roster_puppets?.dispose();
  _hero_roster_puppets = null;
}

function destroyOnboardMsg() {
  _onboard_msg?.destroy();
  _onboard_msg = null;
}
</script>
