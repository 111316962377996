import * as PIXI from 'pixi.js';
import { ObjectPool } from '~/Tools';
import ApplicationStore from '~/flux/stores/ApplicationStore';

var _cloud;
var _cloudPool;
const onAssetsLoaded = () => {
	_cloudPool = new ObjectPool(

		// create func
		() => {
			_cloud = new PIXI.Sprite();
      _cloud.texture = PIXI.utils.TextureCache['effects/cloud.png'];
			_cloud.anchor.x = _cloud.anchor.y = 0.5;
			return _cloud;	
		},

		// reset func
		( cloud ) => {
      gsap.killTweensOf(cloud);
			cloud.alpha = 1;
			cloud.rotation = 0;
		},

		// starting num
		200
	);
	ApplicationStore.removeListener( ApplicationStore.ASSETS_LOADED, onAssetsLoaded );
};
ApplicationStore.on( ApplicationStore.ASSETS_LOADED, onAssetsLoaded );

const instances = {};
	
const EnragedRendering = {
	render: function( condition, gameBoard, isCaverns ) {
		if( instances[condition.uid] ) {
			return;
		}

		instances[ condition.uid ] = {};
		instances[ condition.uid ].ownerSprite = gameBoard.getAllPieceSprites()[condition.ownerId];
				
		const killCloud = ( cloud ) => { 
			if( cloud.parent ) {
				cloud.parent.removeChild( cloud );
			}
			_cloudPool.put( cloud );
		};

		const makeEffect = ( conditionId ) => {
			var cloud = _cloudPool.get();
			cloud.y = isCaverns? -5 : -50;
			cloud.scale = { x: 0.3, y: 0.3 };

			const ownerSprite = instances[conditionId].ownerSprite;
			var partToAddEffectTo = isCaverns? ownerSprite.tileImg : ownerSprite.puppet.getParts().head;
			partToAddEffectTo.addChild( cloud );

			TweenMax.to( cloud.scale, 0.8, {x:(isCaverns?1:8),y:(isCaverns?1:8)} );
			cloud.rotation_tween = TweenMax.to(cloud, 0.876, {
				y: isCaverns? -25 + Math.random() * 15 : -250 + Math.random() * 150,
				rotation: (-120 + Math.random() * 240) * (Math.PI / 180),
				alpha: 0,
				ease: Quad.easeOut,
				onComplete: killCloud,
				onCompleteParams: [cloud],
				onUpdate: c => { !c?.parent && c.rotation_tween.kill() },
        onUpdateParams: [cloud],
			});

			instances[ conditionId ].interval = TweenMax.delayedCall( 0.1+Math.random()*0.3, makeEffect, [conditionId] );
		};

		instances[ condition.uid ].interval = TweenMax.delayedCall( Math.random()*0.1, makeEffect, [condition.uid] );
	},

	stopRendering: function( conditionId ) {
		if( instances[conditionId] ) {
			instances[conditionId].interval.kill();
			delete instances[ conditionId ];
		}
	},

	dispose: function() {
		for( var prop in instances ) {
			EnragedRendering.stopRendering( prop );
		}
	}
};
export default EnragedRendering;