<template>
  <div
    id="chat_root"
    ref="chat_root"
    :class="{
      [`absolute bottom-[1.75vh] left-[1.75vh] w-[calc(50%_-_1.25vh)] p-[1.5vh] bg-zinc-900/80 flex flex-col content-left`]: true,
      'h-[80%]': expanded,
      'h-[25.5%]': !expanded,
    }"
  >
    <!-- row for expandable toggle, players online info, etc -->
    <div class="flex items-end">
      <button
        class="cursor-pointer w-8"
        @click="expanded = !expanded"
      >
        <img :src="`${Config.DOM_IMG_PATH}/${expanded ? 'down' : 'up' }_arrow.png`" />
      </button>
      <PlayersOnlineInfoPanel />
    </div>

    <!-- channel content -->
    <ChatBodyScroll :expanded="expanded" />

    <!-- player text entry form -->
    <form
      class="w-full mt-2 flex"
      @submit="onTextEntered"
    >
      <DTTextInput
        id="chat_input"
        forwardRef="chat_input"
        placeholder="Say something..."
      />
      <button
        type="submit"
        class="px-4 py-1 submit-btn ml-3"
        :style="{ backgroundImage: `url('${Config.DOM_IMG_PATH}/send_btn.png')` }"
      >
        {{ text('ui.send') }}
      </button>
    </form>
  </div>
</template>

<script setup>
import { onBeforeMount, onMounted, ref, onUnmounted } from 'vue';
import { Config } from '~/constants';
import ChatActions from '~/flux/actions/ChatActions';
import text from '~/text';
import { DTTextInput } from '~/view/components/common/DOM';
import ChatBodyScroll from './ChatBodyScroll.vue';
import PlayersOnlineInfoPanel from './PlayersOnlineInfoPanel.vue';

const expanded = ref(false);
const chat_root = ref(null);

// handle ENTER key presses (holding SHIFT prevents submit)
onBeforeMount(() => document.addEventListener('keypress', onKeypress));
onUnmounted(() => document.removeEventListener('keypress', onKeypress));
function onKeypress(event) {
  if (event.code === 'Enter' && !event.shiftKey) {
    onTextEntered(event);
  }
}

onMounted(() => {
  try {
    // transition in
    TweenMax.from(chat_root.value, {
      duration: 1.15,
      y: window.innerHeight,
      ease: 'expo.out',
    });
  } catch (err) {
    logError(err, {
      module: 'components/DOM/chat/Chat',
      func: 'componentDidMount'
    });
  }
});

function onTextEntered(e) {
  try {
    // no page reload
    e.preventDefault();

    const chat_input = document.getElementById('chat_input');
    const text = chat_input?.value?.trim();
    if (!!text) {
      ChatActions.enterMessage(text);
    }
          
    // clear the field
    chat_input.value = '';
  } catch (err) {
    logError(err, {
      module: 'view/components/footer/Footer_DOM/Chat',
      func: 'onTextEntered',
    });
  }
}
</script>

<style>
.submit-btn, .submit-btn:hover, .submit-btn:focus, .submit-btn:active {
  color: black !important;
  border: 0 none !important;
  border-radius: 0 !important;
  background-position: center !important;
  background-size: 100% !important;
  background-repeat: no-repeat !important;
}
</style>
