import Audio from '~/Audio';
import CavernsActions from '~/flux/actions/CavernsActions';

export default (event) => {
  const tileSprite = event.target.parent;
  if (!tileSprite) {
    return;
  }
  const tile = tileSprite.gamePiece;
  if (!tile.loot) {
    return;
  }

  tileSprite.tileImg.visible = true;

  TweenMax.to(tileSprite.lootImg, 0.6, {
    alpha: 0,
    y: -30,
    ease: Quad.easeOut,
    onComplete: () => {
      tileSprite.lootImg && tileSprite.txtPool.put(tileSprite.lootImg)
      tileSprite.lootImg = null;
    },
  });

  if(tile.loot.gold) {
    Audio.play('coin_rattle');
  }
  if(tile.loot.pd) {
    Audio.play('crystal_twinkle');
  }
  if(tile.loot.equipment && tile.loot.equipment.length > 0) {
    Audio.play('equipment_storage');
  }

  CavernsActions.lootTile(tile.uid);
  tile.loot = null;
};