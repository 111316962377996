import Audio from '~/Audio';

const MeleeAttackRendering = {
  render: function(data, gameBoard, isCaverns) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
    if (render_movement) {
      const transitTime = actorSprite.puppet.getCooldown();
      const victim = gameBoard.getAllPieceSprites()[data.victimId].gamePiece;

      switch (Math.floor(Math.random()*1)) {
        case 0: actorSprite.puppet.getMovements().meleeAttack.movePuppet({ transitTime }); break;
				// case 1: _puppetMaster.stabMovement.movePuppet( _actorSprite.puppet, _actorCooldown ); break;
      }

      /*override protected function onPuppetMoveDelay():void
			{
				var image:SlashImage = new SlashImage( 240 );
				_victimSprite.addChildAtCoords( image, 0, -15 );
				_timeline.add( TweenMax.from( image, 0.1, {alpha:0, onComplete:TweenMax.to, onCompleteParams:[image,0.14,{alpha:0}] } ), _timeline._time );

				_effectImages.push( image );
			}*/
    }
  },

  renderResult: function(data, gameBoard) {
    if (data.success) {
      switch (Math.floor(Math.random()*6))	{
        case 0: Audio.play('melee_attack_04'); break;
        case 1: Audio.play('melee_attack_12'); break;
        case 2: Audio.play('melee_attack_14'); break;
        case 3: Audio.play('melee_attack_19'); break;
        case 4: Audio.play('dagger_clang'); break;
        default: Audio.play('sword_clang'); break;
      }
    }
  },
};
export default MeleeAttackRendering;
