import * as PIXI from 'pixi.js';
import Game from 'dt-common/constants/Game';
import Tools from '~/Tools';

const EquipmentImgFactory = {
  getItemContainer: (item) => {
    return PIXI.utils.TextureCache['item_container/bag_001.png'];
  },

  getStandard: (equipmentPiece, direction) => {
    try {
      if (!equipmentPiece) {
        return PIXI.utils.TextureCache['o_small.png'];
      }

      if (!equipmentPiece.slot || !equipmentPiece.type || isNaN(equipmentPiece.quality)) {
        throw new Error('invalid equipmentPiece');
      }

      // mirrored in the names under which the image files are saved
      var slot = equipmentPiece.slot, type = equipmentPiece.type;

      if (slot === 'ring') {
        return PIXI.utils.TextureCache['ring.png'];
      }

      var path = slot+'/'+type+'/'+slot+'_'+type+'_'+Tools.getQualityTag(equipmentPiece.quality) + (direction? '_'+direction:'') + '.png';
      return PIXI.utils.TextureCache[path];
    } catch (err) {
      logError(err, Object.assign(
        {
          module: 'components/common/EquipmentImgFactory',
          func: 'getStandard'
        },
        equipmentPiece,
        { direction }
      ));
      return PIXI.utils.TextureCache['o_small.png'];
    }
  },

  getShoulders: (torsoePiece, direction) => {
    try {
      if (!torsoePiece) {
        return [Game.NORTH, Game.SOUTH].includes(direction)
          ? PIXI.utils.TextureCache['underscore.png']
          : PIXI.utils.TextureCache['underscore_short.png'];
      }

      if (!torsoePiece.slot || !torsoePiece.type || isNaN(torsoePiece.quality)) {
        throw new Error('invalid torsoePiece');
      }

      // mirrored in the names under which the image files are saved
      var slot = torsoePiece.slot, type = torsoePiece.type;

      var path = slot+'/'+type+'/'+slot+'_'+type+'_'+Tools.getQualityTag(torsoePiece.quality) + '_shoulders' + (direction? '_'+direction:'') + '.png';
      return PIXI.utils.TextureCache[path];
    } catch (err) {
      logError(err, Object.assign(
        {
          module: 'components/common/EquipmentImgFactory',
          func: 'getShoulders'
        },
        torsoePiece,
        { direction }
      ));
      return [Game.NORTH, Game.SOUTH].includes(direction)
        ? PIXI.utils.TextureCache['underscore.png']
        : PIXI.utils.TextureCache['underscore_short.png'];
    }
  }
};
export default EquipmentImgFactory;
