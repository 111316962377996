import * as PIXI from 'pixi.js';
import LightFromAboveEffect from '~/view/game-screens/battle/canvas/game_board/effects/LightFromAboveEffect';
import GameItemIcon from '~/components/common/GameItemIcon';
import text from '~/text';
// import EditHeroActions from '~/flux/actions/EditHeroActions';

const PrayerResultDisplay = function(resultItem, godTag, itemClaimCallback) {
  PIXI.Container.call(this);

  const hideAllPopups = () => {
    // hideTooltip();
    hideClaimButton();
  };

  // const addTooltip = (event) => {
  //   EditHeroActions.selectItem(event.target.getItem());
  // };

  // const hideTooltip = () => {
  //   EditHeroActions.selectItem(null);
  // };

  const addClaimButton = (event) => {
    if (this.claimButton) {
      hideClaimButton();
    }

    this.claimButton = new PIXI.Text(text('ui.claim'), {
      fontFamily: 'Courier New',
      fontWeight: 'bold',
      fontSize: '18px',
      fill: 0x00ff00,
      dropShadow: true,
      align: 'center',
    });
    this.claimButton.interactive = this.claimButton.buttonMode = true;
    this.claimButton.mousedown = this.claimButton.touchstart = (cEvent) => {
      cEvent.stopPropagation();
      hideAllPopups();
      itemClaimCallback();
    };

    TweenMax.from(this.claimButton.scale, 0.15, { x: 0,y: 0 });

    this.claimButton.anchor.x = this.claimButton.anchor.y = 0.5;
    event.target.addChild(this.claimButton);

    // ensure the target icon is ontop
    event.target.parent.addChild(event.target);
  };

  const hideClaimButton = () => {
    if (this.claimButton) {
      TweenMax.to(this.claimButton.scale, 0.1, {
        x: 0,
        y: 0,
        onComplete: destroyClaimButton,
        onCompleteParams: [this.claimButton],
      });
    }
  };

  const destroyClaimButton = (deadButton) => {
    if (deadButton && deadButton.parent) {
      deadButton.parent.removeChild(deadButton);
    }
  };

  const onItemRewardIconTouch = (event) => {
    event.stopPropagation();

    // addTooltip(event);
    addClaimButton(event);
  };

  var _lightEffect = new LightFromAboveEffect(2000);
  _lightEffect.width = window.innerWidth*0.3;
  _lightEffect.height = window.innerHeight * 1.5;
  _lightEffect.alpha = 0.6;

  var _itemIcon = new GameItemIcon(resultItem);
  _itemIcon.y = -_itemIcon.height;
  TweenMax.to(_itemIcon, 3.0, { y: window.innerHeight*0.43, ease: Quad.easeOut });

  switch (godTag) {
    	case 'chaos':
    		_lightEffect.x = _itemIcon.x = window.innerWidth * 0.25;
    		break;

    	case 'order':
    		_lightEffect.x = _itemIcon.x = window.innerWidth * 0.75;
    		break;

    case 'balance':
    default:
      _lightEffect.x = _itemIcon.x = window.innerWidth / 2;
      break;
  }

  this.addChild(_lightEffect);
  this.addChild(_itemIcon);

  _itemIcon.mousedown = _itemIcon.touchstart = onItemRewardIconTouch;

  this.dispose = () => {
    if (_itemIcon) {
      _itemIcon.mousedown = _itemIcon.touchstart = null;
      _itemIcon.dispose();
      _itemIcon = null;
    }

  		this.removeChildren();
  	};
};
PrayerResultDisplay.prototype = Object.create(PIXI.Container.prototype);
PrayerResultDisplay.prototype.constructor = PrayerResultDisplay;
export default PrayerResultDisplay;
