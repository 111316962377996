/**
 * ...
 * @author Robert Lombardo, Wakefield Studios ( rob@wakefield-studios.com )
 */	
import FloatingTextEffect from '../../effects/FloatingTextEffect';
import Colors from '~/constants/Colors';

const HealRendering = {
  render: function(data, gameBoard, isCaverns) {
    const victimSprite = gameBoard.getAllPieceSprites()[data.victimId];
    if(!victimSprite) {
      return;
    }
			
    var effect = new FloatingTextEffect(data.potency.toString(), 15, Colors.AP_GREEN);
			
    if(victimSprite.textQueue) {
      victimSprite.textQueue.push(effect);
    }
  }
};
export default HealRendering;