import * as PIXI from 'pixi.js';
import Audio from '~/Audio';

const instances = {};

const killImage = ( deadImage ) => {
	if( deadImage && deadImage.parent ) {
		deadImage.parent.removeChild( deadImage );
	}
};
	
const ArmorBrokenRendering = {
	render: function( condition, gameBoard ) {
		/*if( instances[condition.uid] ) {
			return;
		}*/

		instances[ condition.uid ] = {};
		const ownerSprite = instances[condition.uid].ownerSprite = gameBoard.getAllPieceSprites()[condition.ownerId];

		var image = new PIXI.Sprite();
    image.texture = PIXI.utils.TextureCache['effects/armor_break.png'];
		image.scale.x = image.scale.y = 0.5;
		image.x = ownerSprite.x;
		image.y = ownerSprite.y - 16;
		gameBoard.addChild( image );
		
		var leftOrRight = Math.floor(Math.random()*2)? -1:1;
		var _timeline = new TimelineMax();
		_timeline.add( TweenMax.to( image, 0.6, {x:image.x+leftOrRight*19, ease:Linear.easeNone} ), _timeline._time );
		_timeline.add( TweenMax.to( image, 0.7, {y:image.y+29, ease:Bounce.easeOut} ), _timeline._time );
		_timeline.add( TweenMax.to( image, 1.1, {alpha:0.0, ease:Expo.easeIn,onComplete:killImage,onCompleteParams:[image]} ), _timeline._time );
		
		Audio.play( 'armor_break' );
	},

	stopRendering: function( conditionId ) {
		// instances[conditionId].interval.kill();
		delete instances[ conditionId ];
	},

	dispose: function() {
		/*for( var prop in instances ) {
			BleedingRendering.stopRendering( prop );
		}*/
	}
};
export default ArmorBrokenRendering;