<template>
    <Dialog
      :open="true"
      @close="props.onClose"
    >
      <div class="fixed inset-0 flex items-center justify-center p-4 z-20 bg-zinc-700/90">
        <DialogPanel class="border border-white w-[800px] max-h-[90vh] flex flex-col bg-black">
          <DialogTitle class="border-b border-white p-3 text-yellow-300">
            {{ props.modal_title }}
          </DialogTitle>

          <p class="mt-3 p-3 w-full text-center">
            {{ props.modal_prompt }}
          </p>
  
          <div class="py-6 pl-6 pr-2 flex flex-col items-center">
            <div class="flex items-center">
              <DTTextInput 
                id="EnterTextModal_text_input"
                :placeholder="text('ui.player_name_placeholder')"
                class="w-96 h-8"
              />
              <DTButton
                type="success"
                class="ml-3 py-1 px-5"
                @click="onSubmit"
              >
                {{ text('ui.OK') }}
              </DTButton>
            </div>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  </template>
  
  <script setup>
  import { onBeforeMount, onUnmounted } from 'vue';
  import {
    Dialog,
    DialogPanel,
    DialogTitle,
  } from '@headlessui/vue';
  import text from '~/text';
  import { DTButton, DTTextInput } from '~/view/components/common/DOM';
  
  const props = defineProps({
    modal_title: {
        type: String,
        required: true,
    },
    modal_prompt: {
        type: String,
        default: '',
    },
    onClose: Function,
    onConfirmed: Function,
  })
  
  function onSubmit() {
    const text_input = document.getElementById('EnterTextModal_text_input');
    // PlayerActions.enterNewPlayerName(build_name_input?.value?.trim());
    props.onConfirmed({ input: text_input?.value?.trim() });
    props.onClose();
  }
  
  // submit on ENTER key presses
  onBeforeMount(() => document.addEventListener('keypress', onKeypress));
  onUnmounted(() => document.removeEventListener('keypress', onKeypress));
  function onKeypress(event) {
    if (event.code === 'Enter') {
      onSubmit(event);
    }
  }
  </script>
  