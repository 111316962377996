import CanvasTools from '~/view/CanvasTools';
import LightningEffect from '../../effects/LightningEffect';
import Audio from '~/Audio';

const OptionAttackRendering = {
	render: function( data, gameBoard, isCaverns ) {
		var actorSprite = gameBoard.getAllPieceSprites()[ data.actorId ];			
		var victimSprite = gameBoard.getAllPieceSprites()[ data.victimId ];

		if( !actorSprite || !victimSprite ) {
			return;
		}

		var dist = CanvasTools.distanceFromSpriteToSprite( actorSprite, victimSprite );
		
		const makeLightningEffect = ( color ) => {
			for( var j = 16; j < dist; j +=32 ) {					
				var image = new LightningEffect();
				image.tint = color;
				image.rotation = CanvasTools.angleFromSpriteToSprite( actorSprite, victimSprite );
				image.x = actorSprite.x + (victimSprite.x-actorSprite.x)*(j/dist);
				image.y = actorSprite.y + (victimSprite.y-actorSprite.y)*(j/dist) - 13;
				gameBoard.addChild( image );
			}
		};

		makeLightningEffect( Math.random()*0xffffff );
		TweenMax.delayedCall( 0.15, makeLightningEffect, [Math.random()*0xffffff] );
		
		Audio.play( 'option_attack' );
	}
};
export default OptionAttackRendering;