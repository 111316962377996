import * as PIXI from 'pixi.js';
import { ObjectPool } from '~/Tools';
import ApplicationStore from '~/flux/stores/ApplicationStore';

let _qMark;
let _qMarkPool;

ApplicationStore.once(ApplicationStore.ASSETS_LOADED, () => {
  _qMarkPool = new ObjectPool(
    // create func
    () => {
      _qMark = new PIXI.BitmapText('?', {
        fontName: 'BitmapCourier',
        fontSize: 32,
        tint: 0xff0000,
      });
      _qMark.anchor.x = _qMark.anchor.y = 0.5;
      return _qMark;
    },

    // reset func
    (qMark) => {
      gsap.killTweensOf(qMark);
      qMark.scale = { x: 1, y: 1 };
      qMark.rotation = 0;
      qMark.alpha = 1;
    },

    // starting num
    50
  );
});

const instances = {};

const ScaredRendering = {
  render: function (condition, gameBoard, isCaverns) {
    if (instances[condition.uid]) {
      return;
    }

    instances[condition.uid] = {};
    instances[condition.uid].ownerSprite =
      gameBoard.getAllPieceSprites()[condition.ownerId];

    const killQMark = (qm) => {
      if (qm.parent) {
        qm.parent.removeChild(qm);
      }
      _qMarkPool.put(qm);
    };

    const makeEffect = (conditionId) => {
      const ownerSprite = instances[conditionId].ownerSprite;
      const { puppet, tileImg } = ownerSprite;

      var qm = _qMarkPool.get();
      qm.y = puppet ? -100 - Math.random() * 50 : -20 - Math.random() * 10;
      var xFactor = puppet ? -20 + Math.random() * 40 : -2 + Math.random() * 4;
      qm.x = xFactor * 5;

      var partToAddEffectTo = puppet ? puppet.getParts().head : tileImg;
      partToAddEffectTo.addChild(qm);

      TweenMax.to(qm.scale, 0.8, {
        x: puppet ? 30 : 2,
        y: puppet ? 30 : 2,
      });
      qm.rotation_tween = TweenMax.to(qm, 1.5, {
        y: puppet
          ? qm.y - 30 - Math.random() * 30
          : qm.y - 10 - Math.random() * 10,
        rotation: (xFactor / 10) * (1 + Math.random() * 35) * (Math.PI / 180),
        alpha: 0,
        ease: Expo.easeOut,
        onComplete: killQMark,
        onCompleteParams: [qm],
      });

      instances[conditionId].interval = TweenMax.delayedCall(
        0.5 + Math.random() * 0.5,
        makeEffect,
        [conditionId]
      );
    };

    instances[condition.uid].interval = TweenMax.delayedCall(
      Math.random() * 0.1,
      makeEffect,
      [condition.uid]
    );
  },

  stopRendering: function (conditionId) {
    if (instances[conditionId]) {
      instances[conditionId].interval.kill();
      delete instances[conditionId];
    }
  },

  dispose: function () {
    for (var prop in instances) {
      ScaredRendering.stopRendering(prop);
    }
  },
};
export default ScaredRendering;
