import PuppetMovement, { MELEE_SKILL } from './PuppetMovement.js';

const SlamMovement = (puppet) => {     
    const poses = [ 
        { pose:Pose1() },
        { pose:Pose2() },
        { pose:Pose3() },
        { pose:Pose3() }
    ];

    return PuppetMovement({
		poses,
        puppet,
        setPoseDurations: (actorCooldown ) => {
            const transTime = actorCooldown / 7;

            poses[0].duration = transTime*3;
            poses[1].duration = transTime;
            poses[2].duration = transTime;
            poses[3].duration = transTime*2;
        },
        skill_type: MELEE_SKILL,

    });
}
export default SlamMovement;

const Pose1 = function() {
    const pose =  {
        head: {
            x: 0,
            y: -160,
            z: -12
        },        
        shoulders: {
            x: 0,
            y: -144,
            z: -7
        },        
        torsoe: {
            y: -105
        },        
        leftHand: {
            x: -13,
            y: -105,
            z: 42
        },
        bowPitch: 10,
        bowYaw: 10,        
        rightHand: {
            x: 42,
            y: -105,
            z: -60
        },        
        weaponPitch: 40,
        weaponYaw: -40,        
        leftFoot: {
            x: -22,
            y: 0,
            z: 54
        },        
        rightFoot: {
            x: 22,
            y: 0,
            z: -12
        }
    }

    // backward travel
    for( var prop in pose ) {
        if( pose[prop].z ) {
            pose[prop].z -= 28
        }
    }

    return pose;       
}
    
const Pose2 = function() {
    const pose =  {
        head: {
            x: 0,
            y: -160,
            z: 0
        },        
        shoulders: {
            x: 0,
            y: -144,
            z: 0,
        },        
        torsoe: {
            x: 0,
            y: -105,
            z: 0
        },        
        leftHand: {
            x: -13,
            y: -105,
            z: 42
        },      
        bowPitch: 10,
        bowYaw: 10,  
        rightHand: {
            x: 20,
            y: -60,
            z: 10
        },        
        weaponPitch: -190,
        weaponYaw: 190,        
        leftFoot: {
            x: -22,
            y: 0,
            z: 54
        },       
        rightFoot: {
            x: 22,
            y: 0,
            z: -12
        }
    }        
    return pose;
}
    
const Pose3 = function() {
    const pose =  {
        head: {
            x: 0,
            y: -160,
            z: 18
        },
        shoulders: {
            x: 0,
            y: -144,
            z: 9
        },        
        torsoe: {
            x: 0,
            y: -105,
            z: 0
        },        
        leftHand: {
            x: -60,
            y: -150,
            z: 30
        },      
        bowPitch: -45,
        bowYaw: -45,
        rightHand: {
            x: -18,
            y: -155,
            z: 60
        },        
        weaponPitch: -360 -45,
        weaponYaw: 360+45,
        leftFoot: {
            x: -22,
            y: 0,
            z: 12
        },        
        rightFoot: {
            x: 22,
            y: 0,
            z: -30
        }
    }

    //forward travel
    for( var part in pose ) {
        if( pose[part].z ) {
            pose[part].z += 18;
        }
    }

    return pose;     
}