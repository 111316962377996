import * as PIXI from 'pixi.js';
import { ShopStore } from '~/flux/stores';
import text, { getShopkeeperMessage } from '~/text';
import CanvasTools from '~/view/CanvasTools';
import ShopInventoryShelves from './ShopInventoryShelves';

const Shop = function ({ show_tips_bubble } = {}) {
  PIXI.Container.call(this);

  let _backdrop;
  let _shopKeeper;
  let _shopInventoryShelves;

  this.transitionIn = () => {
    if (_shopInventoryShelves) {
      TweenMax.from(_shopInventoryShelves, 0.25, { alpha: 0 });
    }

    if (_shopKeeper) {
      TweenMax.from(_shopKeeper, 0.25, { alpha: 0 }).delay(0.3);
    }
  };

  this.transitionOut = (onComplete) => {
    if (_shopInventoryShelves) {
      TweenMax.to(_shopInventoryShelves, 0.25, { alpha: 0 });
    }

    if (_shopKeeper) {
      TweenMax.to(_shopKeeper, 0.25, { alpha: 0, onComplete });
    }
  };

  this.dispose = () => {
    ShopStore.removeListener(ShopStore.SHOP_INVENTORY, onShopInventory);

    destroyBackdrop();
    destroyShopKeeper();
    destroyShopInventoryShelves();

    this.destroy();
  };

  this.handleResize = () => {
    makeBackdrop();
    makeShopKeeper();
    makeShopInventoryShelves();
  };

  const destroyBackdrop = () => {
    if (_backdrop) {
      _backdrop.mousedown = _backdrop.touchstart = null;
      this.removeChild(_backdrop);
    }
  };

  const destroyShopInventoryShelves = () => {
    if (_shopInventoryShelves) {
      TweenMax.killTweensOf(_shopInventoryShelves);
      _shopInventoryShelves.dispose();
      this.removeChild(_shopInventoryShelves);
      _shopInventoryShelves = null;
    }
  };

  const destroyShopKeeper = () => {
    if (_shopKeeper) {
      TweenMax.killTweensOf(_shopKeeper);
      this.removeChild(_shopKeeper);
      _shopKeeper = null;
    }
  };

  const makeBackdrop = () => {
    destroyBackdrop();

    _backdrop = new PIXI.Container();
    _backdrop._width = window.innerWidth;
    _backdrop._height = window.innerHeight;
    _backdrop = CanvasTools.addBackFill(_backdrop, 0x000000);
    _backdrop.alpha = 0.01;
    this.addChild(_backdrop);

    _backdrop.interactive = true;
    _backdrop.mousedown = _backdrop.touchstart = () => {
      _shopInventoryShelves.hidePopups();
    };
  };

  const makeShopInventoryShelves = () => {
    destroyShopInventoryShelves();

    _shopInventoryShelves = this.shopInventoryShelves =
      new ShopInventoryShelves();
    _shopInventoryShelves.x = Math.round(
      window.innerWidth * 0.71 - _shopInventoryShelves.width / 2
    );
    _shopInventoryShelves.y = Math.round(
      50 + window.innerHeight * 0.18 - _shopInventoryShelves.height / 2
    );
    this.addChild(_shopInventoryShelves);
  };

  const makeShopKeeper = () => {
    destroyShopKeeper();

    _shopKeeper = new PIXI.Sprite();
    _shopKeeper.texture =
      PIXI.utils.TextureCache['shop/shopkeeper_portrait.png'];
    _shopKeeper.height = Math.min(_shopKeeper.height, window.innerHeight * 0.3);
    _shopKeeper.scale.x = _shopKeeper.scale.y;
    _shopKeeper.x =
      window.innerWidth - _shopKeeper.width - DT_CANVAS_GLOBALS.spacing * 3;
    _shopKeeper.y =
      window.innerHeight * 0.74 -
      _shopKeeper.height -
      DT_CANVAS_GLOBALS.spacing * 3;
    this.addChild(_shopKeeper);

    this.onItemsToSellChange({ show_tips_bubble });
  };

  let textBubble;
  let speechText;
  this.onItemsToSellChange = ({ show_tips_bubble }) => {
    textBubble?.parent && textBubble?.destroy();

    if (show_tips_bubble) {
      textBubble = new PIXI.Sprite();
      textBubble.texture =
        PIXI.utils.TextureCache['shop/shopkeeper_text_bubble.png'];
      textBubble.x = -textBubble.width * 1.2;
      textBubble.y = textBubble.height * 0.6;
      _shopKeeper.addChild(textBubble);

      speechText = _shopKeeper.speechText = new PIXI.Text(
        ShopStore.getAll().shopInventory.length
          ? getShopkeeperMessage()
          : text('ui.shop.out_of_stock_msg'),
        {
          fontFamily: 'Courier New',
          fontSize: '16px',
          fill: 0x000000,
          wordWrap: true,
          wordWrapWidth: textBubble.width * 0.85,
        }
      );
      speechText.x = textBubble.width * 0.46 - speechText.width / 2;
      speechText.y = textBubble.height / 2 - speechText.height / 2;
      textBubble.addChild(speechText);
    }
  };

  const onShopInventory = () => {
    makeShopInventoryShelves();
  };

  // init
  makeBackdrop();
  makeShopKeeper();
  makeShopInventoryShelves();
  ShopStore.on(ShopStore.SHOP_INVENTORY, onShopInventory);
};
Shop.prototype = Object.create(PIXI.Container.prototype);
Shop.prototype.constructor = Shop;
export default Shop;
