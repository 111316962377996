import StripAnimation from './StripAnimation';

const DevilMaskEffect = function(duration) {
  return new StripAnimation(
    'effects/devil_mask',
    duration,
    8,
    false,
  );
};
export default DevilMaskEffect;