import * as PIXI from 'pixi.js';
import text from '~/text';
import Colors from '~/constants/Colors';
import MessageLog from '~/view/components/MessageLog_canvas';
import Tools from '~/Tools';

const _effectPool = new Tools.ObjectPool(
  // create func
  (heroColor) => {
    var effect = new PIXI.Graphics();
    effect.beginFill(Math.random()<0.5?Colors.BRIGHT_YELLOW:heroColor, 0.7);
    effect.drawRect(-1.5, -3, 3, 6);
    effect.endFill();
    return effect;
  },

  // reset func
  (effect, heroColor) => {
    TweenMax.killTweensOf(effect.scale);
    TweenMax.killTweensOf(effect);
    effect.parent?.removeChild(effect);
    effect.clear();
    effect.beginFill(Math.random() < 0.5 ? Colors.BRIGHT_YELLOW : heroColor, 0.7);
    effect.drawRect(-1.5, -3, 3, 6);
    effect.endFill();
    effect.alpha = 1;
    effect.scale.y = 1;
  },

  // starting num
  100,
);

const HeroLevelView = function({ roster_hero, scale_mod = 1 }) {
  PIXI.Container.call(this);

  let interval;

  this.dispose = () => {
    TweenMax.killTweensOf(this);
    interval?.kill();
    interval = null;
    this.destroy();
  };

  const levelView = new PIXI.Sprite();
  levelView.texture = PIXI.utils.TextureCache['armory/level_icon_default.png'];
  levelView.tint = Colors[roster_hero.handle];
  const levelTxt = this.levelTxt = new PIXI.Text(roster_hero.level, {
    fontFamily: 'Courier New',
    fontStyle: 'bold',
    fontSize: '42px',
    fill: 0xffffff,
    align: 'center',
    dropShadow: true,
    dropShadowDistance: 4,
    dropShadowColor: 0x000000,
  });
  levelTxt.x = Math.round(levelView.width / 2 - levelTxt.width / 2);
  levelTxt.y = Math.round(levelView.height / 2 - levelTxt.height / 2);
  levelView.addChild(levelTxt);

  if (roster_hero.xp_boost) {
    const makeEffect = () => {
      var effect = _effectPool.get(Colors[roster_hero.handle]);
      effect.x = -50 * scale_mod + Math.random() * 125 * scale_mod;
      effect.y = DT_CANVAS_GLOBALS.spacing * 3.2 + Math.random() * levelView.height * 0.65;
      levelView.addChildAt(effect, 0);

      TweenMax.to(effect, 0.876, {
        y: effect.y - 35 - Math.random() * 45,
        alpha: 0,
        ease: Linear.easeNone,
        onComplete: _effectPool.put.bind(null, effect, Colors[roster_hero.handle]),
        onCompleteParams: [effect],
      });
      TweenMax.to(effect.scale, 0.876, { y: 2.0 + Math.random() * 3.5 });

      interval = TweenMax.delayedCall(0.1 + Math.random() * 0.15, makeEffect);
    };
    interval = TweenMax.delayedCall(0.1+Math.random()*0.15, makeEffect);

    this.interactive = this.buttonMode = true;
    this.tap = this.click = (event) => {
      event.stopPropagation();
      MessageLog.addMessage(text('game.xp_bonus')+': ' + (roster_hero.xp_boost.xp_bonus*100).toFixed(2)+'%', 0x00ff00);
      MessageLog.addMessage(text('game.time_remaining')+': '+ Tools.millisecondsToHHMMSS(roster_hero.xp_boost.start_time+roster_hero.xp_boost.duration-new Date().getTime()), 0xffffff);
    };
  }

  this.addChild(levelView);
};
HeroLevelView.prototype = Object.create(PIXI.Container.prototype);
HeroLevelView.prototype.constructor = HeroLevelView;
export default HeroLevelView;
