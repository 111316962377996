<template>
  <Dialog
    :open="true"
    @close="props.onClose"
  >
    <div class="fixed inset-0 flex items-center justify-center p-4 z-20 bg-transparent">
      <DialogPanel
        :class="`absolute flex flex-col bg-transparent`"
        :style="`left: ${props.left}; top: ${props.top}; width: ${props.width};  height: ${props.height};`"
        @click="props.onClose"
      >
        <canvas
          id="modal_canvas"
          ref="modal_canvas"
          class="z-0"
          :style="`width: ${props.width};  height: ${props.height};`"
        />

        <!-- <div class="w-full h-24 px-6 flex justify-center items-center z-10">
          <DTButton
            type="success"
            class="py-1 px-5"
            @click="onYesClick"
          >
            {{ text('ui.yes') }}
          </DTButton>
          <DTButton
            type="error"
            class="ml-3 py-1 px-5"
            @click="props.onClose"
          >
            {{ text('ui.no') }}
          </DTButton>
        </div> -->
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';
import {
  Dialog,
  DialogPanel,
} from '@headlessui/vue';
import text from '~/text';
import CanvasTools from '~/view/CanvasTools';
import { DTButton } from '~/view/components/common/DOM';

let _modal_pixi_app;

const props = defineProps({
  left: {
    type: String,
    default: 'calc(50% - 400px)', // half of default width
  },
  top: {
    type: String,
    default: 'calc(50% - 300px)', // half of default height
  },
  width: {
    type: String,
    default: '800px',
  },
  height: {
    type: String,
    default: '600px',
  },
  onCanvasMounted: {
    type: Function,
    default: () => {},
  },
  onClose: {
    type: Function,
    required: true,
  },
});
const modal_canvas = ref(null);

watch(modal_canvas, val => {
  if (val && !_modal_pixi_app) {
    _modal_pixi_app = CanvasTools.makePixiRenderer({
      canvas_id: 'modal_canvas',
      canvas_ele: val,
    });
    props.onCanvasMounted({ modal_pixi_app: _modal_pixi_app });
  }
});

onBeforeUnmount(() => {
  _modal_pixi_app?.destroy();
});

function onYesClick() {
  props.onClose();
}
</script>
