<template>
  <Dialog
    :open="true"
    @close="props.onClose"
  >
    <div class="fixed inset-0 flex items-center justify-center p-4 z-20 bg-zinc-700/90">
      <DialogPanel class="border border-white w-[800px] max-h-[90vh] flex flex-col bg-black">
        <DialogTitle class="border-b border-white p-3 text-yellow-300">
          {{ text('ui.chat_text_color_title') }}
        </DialogTitle>

        <div class="m-10 flex">
          <div class="w-[50%] flex flex-col justify-between text-xs">
            <div>
              <p>
                {{ text('ui.choose_chat_text_color') }}
              </p>
              <!-- emote -->
              <div class="mt-6 font-bold" :style="`color: ${state.colors.hex || '#fff'};`">
                {{ getChatCommandEmote('/dance', ChatStore.getAll().userObject, null) }}
              </div>
              <hr />
              <div class="flex" :style="`color: ${state.colors.hex};`">
                <div class="font-bold">{{ ChatStore.getAll().userObject.displayName }}: </div>
                <div class="ml-3">{{ text('ui.chat_text_color_example') }}</div>
              </div>
            </div>
            <p class="text-sm">
              {{ text('ui.text_color_change_price_warning') }}
            </p>
          </div>
          <div class="w-[50%] flex items-center justify-center">
            <Chrome v-model="state.colors" class="np-mb" />
          </div>
        </div>

        <div class="w-full h-24 px-6 flex justify-center items-center z-10">
          <DTButton
            type="success"
            class="py-1 px-5"
            @click="onYesClick"
          >
            {{ text('ui.yes') }}
          </DTButton>
          <DTButton
            type="error"
            class="ml-3 py-1 px-5"
            @click="props.onClose"
          >
            {{ text('ui.no') }}
          </DTButton>
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup>
import { reactive } from 'vue';
import { Chrome } from "@lk77/vue3-color";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
} from '@headlessui/vue';
import { ChatActions } from '~/flux/actions';
import { ChatStore } from '~/flux/stores';
import text, { getChatCommandEmote } from '~/text';
import { DTButton } from '~/view/components/common/DOM';

const state = reactive({
  colors: { hex: ChatStore.getAll().userObject?.chatTextColor ?? '#ff00ff' },
});

const props = defineProps({
  onClose: {
    type: Function,
    required: true,
  },
});

function onYesClick() {
  ChatActions.setChatTextColor(state.colors.hex);
  props.onClose();
}
</script>
