/**
 * ...
 * @author Robert Lombardo, Wakefield Studios ( rob@wakefield-studios.com )
 */	
import StripAnimation from './StripAnimation';

const LightFromAboveEffect = function( duration ) {
	return new StripAnimation( 
		'effects/light_from_above',
		duration,
		37,
		false
	);	
}
export default LightFromAboveEffect;