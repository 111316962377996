import { Dispatcher } from 'flux';

const BattleDispatcher = Object.assign(new Dispatcher(), {
  NAME: 'BATTLE_DISPATCHER',

  SELECT_ABILITIES: 'SELECT_ABILITIES',
  SELECT_ABILITY: 'SELECT_ABILITY',
  SELECT_GAME_PIECE: 'SELECT_GAME_PIECE',
  HERO_WAIT: 'HERO_WAIT',
  MOVE_BTN_TOUCH: 'MOVE_BTN_TOUCH',
  EXECUTE_BTN_TOUCH: 'EXECUTE_BTN_TOUCH',
  KILL_BATTLE: 'KILL_BATTLE',

  handleBattleAction: function(action) {
    this.dispatch({
      source: BattleDispatcher.NAME,
      action
    });
  }
});
export default BattleDispatcher;
