import * as PIXI from 'pixi.js';
import { ObjectPool } from '~/Tools';

var _trail;
var _trailPool = new ObjectPool(

	// create func
	() => {
		_trail = new PIXI.Text('.', {
			fontFamily: 'Courier New',
			fontSize: 24,
			fontStyle: 'bold',
			fill: 0x4f0d53,
		});
		_trail.alpha = 0.8;
		_trail.anchor.x = _trail.anchor.y = 0.5;
		return _trail;
	},

	// reset func
	( trail ) => {
    gsap.killTweensOf(trail);
		trail.alpha = 0.8;
	},

	// starting num
	300
);

const instances = {};
	
const HasOptionRendering = {
	render: function( condition, gameBoard, isCaverns ) {
		if( instances[condition.uid] ) {
			return;
		}

		instances[ condition.uid ] = {};
		const ownerSprite = instances[condition.uid].ownerSprite = gameBoard.getAllPieceSprites()[condition.ownerId];
		const parentToOrbit = instances[condition.uid].parentToOrbit = isCaverns? ownerSprite : ownerSprite.puppet;

		var image = instances[condition.uid].optionImage = new PIXI.Text('.', {
			fontFamily: 'Courier New',
			fontSize: 24,
			fontStyle: 'bold',
			fill: 0xffffff,
			dropShadow: true,
			dropShadowColor: 0xab04b5,
			dropShadowDistance: 1
		});
		image.anchor.x = image.anchor.y = 0.5;
		image.y = -50;

		const killTrail = ( deadTrail ) => {
			if( deadTrail.parent ) {
				deadTrail.parent.removeChild( deadTrail );	
			} 
			_trailPool.put( deadTrail );
		};

		const makeTrail = () => {
			var trail = _trailPool.get();
			trail.x = image.x;
			trail.y = image.y;
			TweenMax.to( trail, 0.3+Math.random()*0.3, {
				alpha:0,
				onComplete: killTrail,
				onCompleteParams: [trail]
			});
			image.parent.addChildAt( trail, image.parent.getChildIndex(image) );
		};

		var tween;
		var front = false;
		const makeTween = () => {
			if( instances[condition.uid].imgTween ) {
				instances[condition.uid].imgTween.kill();
			}

			const parentToOrbit = instances[condition.uid].parentToOrbit;
			if( front ) {
				parentToOrbit.addChild( image );
			} else {
				parentToOrbit.addChildAt( image, 0 );
			}
			front = !front;

			tween = instances[condition.uid].imgTween = TweenMax.to( image, 1.1+Math.random()*0.2, {
				x: image.x>0? -15-Math.random()*7 : 15+Math.random()*7,
				y: isCaverns? -20+Math.random()*20 : -45+Math.random()*35,
				ease: Quad.easeInOut,
				onUpdate: makeTrail,
				onComplete: makeTween
			});
		};
		makeTween();
	},

	stopRendering: function( conditionId, isCaverns ) {
		if( instances[conditionId] ) {
			
			if( instances[conditionId].imgTween ) {
				instances[conditionId].imgTween.kill();
			}

			if( instances[conditionId].ownerSprite ) {
				if( !isCaverns && instances[conditionId].ownerSprite.puppet ) {
					instances[conditionId].ownerSprite.puppet.removeChild( instances[conditionId].optionImage );
				} else {
					instances[conditionId].ownerSprite.removeChild( instances[conditionId].optionImage );
				}
			}
			
			delete instances[ conditionId ];
		}
	},

	dispose: function() {
		for( var prop in instances ) {
			HasOptionRendering.stopRendering( prop );
		}
	}
};
export default HasOptionRendering;