import firebase from 'firebase/compat/app';
import * as PIXI from 'pixi.js';
import Game from 'dt-common/constants/Game';
import {
  ApplicationStore,
  ArenaLobbyStore,
  BattleStore,
  UIStore,
} from '~/flux/stores';
import UIActions from '~/flux/actions/UIActions';
import {
  TutorialBox,
  TutorialSpotlight,
} from '~/view/components/common/canvas';
import CanvasTools from '~/view/CanvasTools';
import MessageLog from '~/view/components/MessageLog_canvas';
import GameBoard from './game_board/GameBoard';
import MatchOverview from './MatchOverview';
import Screens from '~/constants/Screens';
import Audio from '~/Audio';
import text from '~/text';

const {
  GAME_MODES: { GAME_MODE_pvpAuto, GAME_MODE_pvpLive },
} = Game;

let _extro_interval;

const BattleScreen = function () {
  PIXI.Container.call(this);

  Audio.setBGTrack('battle_music');

  this.transitionIn = () => {
    Audio.play('airy_magic_whoosh');
    TweenMax.from(_matchOverview, 0.8, { y: -_matchOverview.height * 2 });
    TweenMax.from(_gameBoard, 0.5, { alpha: 0 });
    TweenMax.from(_gameBoard.scale, 0.5, { x: 0.3, y: 0.3, ease: Expo.easeIn });
  };

  this.transitionOut = (onComplete) => {
    _matchOverview &&
      TweenMax.to(_matchOverview, 0.4, { y: -_matchOverview.height * 2 });
    if (_gameBoard) {
      TweenMax.to(_gameBoard, 0.5, {
        alpha: 0,
        ease: Linear.easeIn,
      });
      TweenMax.to(_gameBoard.scale, 0.5, {
        x: 0.1,
        y: 0.1,
        ease: Expo.easeIn,
        onComplete,
      });
    }
  };

  this.dispose = () => {
    clearInterval(_extro_interval);

    BattleStore.removeListener(
      BattleStore.CHALLENGE_RESOLVED,
      onChallengeResolved
    );
    BattleStore.removeListener(BattleStore.RESUMING_TIME, hideWaitBtnTip);
    BattleStore.removeListener(BattleStore.ABILITY_EXECUTED, onAbilityExecuted);
    BattleStore.removeListener(
      BattleStore.STAT_COSTS_EXACTED,
      onStatCostsExacted
    );
    BattleStore.removeListener(BattleStore.ABILITY_SELECTED, onAbilitySelected);
    ArenaLobbyStore.removeListener(
      ArenaLobbyStore.RECOVERED_OPPONENT_NAME,
      makeMatchOverview
    );

    document
      .getElementById('game_canvas')
      .addEventListener('wheel', onMouseWheel);
    document
      .getElementById('game_canvas')
      .removeEventListener('pointerup', onPointerup);

    destroyGameBoard();
    destroyMatchOverview();

    hideWaitBtnTip();
    hideAttackTip();
    hideMoveTip();

    this.parent?.removeChild(this);
    this.removeChildren();

    if (spotlight && spotlight.parent) {
      spotlight.parent.removeChild(spotlight);
    }
    if (waitBtnTip && waitBtnTip.parent) {
      waitBtnTip.parent.removeChild(waitBtnTip);
    }
    if (attackTip && attackTip.parent) {
      attackTip.parent.removeChild(attackTip);
    }
    if (moveTip && moveTip.parent) {
      moveTip.parent.removeChild(moveTip);
    }
  };

  this.handleResize = () => {
    makeGameBoard();
    makeMatchOverview();
  };

  const destroyGameBoard = () => {
    if (_gameBoard) {
      _gameBoard.dispose();
      this.removeChild(_gameBoard);
      _gameBoard = null;
    }
  };

  const destroyMatchOverview = () => {
    if (_matchOverview) {
      _matchOverview.parent.removeChild(_matchOverview);
      _matchOverview.dispose();
      _matchOverview = null;
    }
  };

  // make the game board
  var _gameBoard;
  const makeGameBoard = () => {
    destroyGameBoard();

    _gameBoard = new GameBoard(BattleStore.getAll().battleState);
    _gameBoard.pivot.x =
      _gameBoard.width / 2 - _gameBoard.getChildAt(1).width / 2;
    _gameBoard.pivot.y = _gameBoard.width * 0.45;
    _gameBoard.x = window.innerWidth / 2;
    _gameBoard.y = window.innerHeight * 0.5;
    this.addChild(_gameBoard);

    // game board panning
    _gameBoard.interactive = true;
    _gameBoard.panning = false;
    _gameBoard.pointerdown = (event) => {
      _gameBoard.panning = true;
      _gameBoard.last_pan_point = { ...event.data.global };
    };
    _gameBoard.pointermove = (event) => {
      if (_gameBoard.panning) {
        const { global } = event.data;
        const { last_pan_point, scale } = _gameBoard;
        _gameBoard.pivot.x -= (global.x - last_pan_point.x) / scale.x;
        _gameBoard.pivot.y -= (global.y - last_pan_point.y) / scale.y;
        _gameBoard.last_pan_point = { ...global };
      }
    };

    _gameBoard.on('pinchmove', function (event) {
      _gameBoard.scale.x *= event.scale;
      _gameBoard.scale.y *= event.scale;
    });
  };
  const onPointerup = () => {
    _gameBoard.panning = false;
  };
  makeGameBoard();

  // mousewheel handling
  var zoomTween;
  const onMouseWheel = function (event) {
    if (!_gameBoard) {
      return;
    }

    if (zoomTween) {
      zoomTween.kill();
    }

    const deltaNormal = event.deltaY / Math.abs(event.deltaY);
    if (isNaN(deltaNormal)) {
      return;
    }

    zoomTween = TweenMax.to(_gameBoard.scale, 0.2, {
      x: Math.max(0.15, _gameBoard.scale.x - deltaNormal * 0.3),
      y: Math.max(0.15, _gameBoard.scale.y - deltaNormal * 0.3),
      ease: Linear.easeNone,
    });
  };

  document
    .getElementById('game_canvas')
    .removeEventListener('wheel', onMouseWheel);
  document
    .getElementById('game_canvas')
    .addEventListener('wheel', onMouseWheel);
  document
    .getElementById('game_canvas')
    .removeEventListener('pointerup', onPointerup);
  document
    .getElementById('game_canvas')
    .addEventListener('pointerup', onPointerup);

  // make the match overview header piece
  var _matchOverview;
  const makeMatchOverview = () => {
    destroyMatchOverview();

    _matchOverview = new MatchOverview();
    _matchOverview.width = Math.min(
      _matchOverview.width,
      window.innerWidth * 0.4
    );
    _matchOverview.scale.y = _matchOverview.scale.x;
    _matchOverview.x = Math.round(window.innerWidth / 2);
    _matchOverview.y = Math.round(DT_CANVAS_GLOBALS.spacing);
    DT_CANVAS_GLOBALS.stage.addChild(_matchOverview);
  };
  makeMatchOverview();

  ArenaLobbyStore.on(
    ArenaLobbyStore.RECOVERED_OPPONENT_NAME,
    makeMatchOverview
  );

  let _focus_recovery = false;
  const onChallengeResolved = () => {
    ApplicationStore.removeListener(
      ApplicationStore.WINDOW_GOT_FOCUS,
      onChallengeResolved
    );
    if (!ApplicationStore.getAll().window_has_focus) {
      _focus_recovery = true;
      ApplicationStore.on(
        ApplicationStore.WINDOW_GOT_FOCUS,
        onChallengeResolved
      );
      return;
    }

    if (_gameBoard) {
      _extro_interval = setInterval(() => {
        _gameBoard.scale.x *= 0.995;
        _gameBoard.scale.y *= 0.995;
      }, 33);
    }

    setTimeout(
      // unless it's a focus recovery (i.e. the player is doing something in another tab/window when
      // the battle ends), give the battle screen 2.6 seconds to render the last unit death
      () => {
        const { current_game_mode } = UIStore.getAll();
        if (current_game_mode === GAME_MODE_pvpAuto) {
          UIActions.uiNav({ screen_id: Screens.INN });
        } else if (current_game_mode === GAME_MODE_pvpLive) {
          UIActions.uiNav({ screen_id: Screens.ARENA_LOBBY });
        }

        if (BattleStore.getAll().debrief.debrief_error) {
          MessageLog.addMessage(text('ui.battle_debrief_error'), 0xff0000);
        } else {
          UIActions.showModal({ modal_key: 'BattleDebriefModal' });
        }
      },
      _focus_recovery ? 100 : 2600
    );
    // transitionOut takes 0.5 seconds
    setTimeout(this.transitionOut, 2100);

    _focus_recovery = false;
  };
  BattleStore.on(BattleStore.CHALLENGE_RESOLVED, onChallengeResolved);

  // tutorial stuff
  var spotlight;
  var waitBtnTip;
  var attackTip;
  var moveTip;

  const clickShield = new PIXI.Container();
  clickShield._width = window.innerWidth;
  clickShield._height = window.innerHeight;
  CanvasTools.addBackFill(clickShield, 0x000000);
  clickShield.alpha = 0.01;

  if (BattleStore.getAll().battleState.tutorial) {
    $('#chat_root').css('visibility', 'hidden');

    DT_CANVAS_GLOBALS.stage.addChild(clickShield);

    var waitBtnPos;

    // wait for the actionSelectPanel to be created
    const interval = setInterval(() => {
      if (DT_CANVAS_GLOBALS.actionSelectPanel) {
        clearInterval(interval);
        kickoffTutorialSpotlight();
      }
    }, 20);

    function kickoffTutorialSpotlight() {
      firebase.analytics().logEvent('onboard_step:5', {
        name: 'spotlight_init',
      });

      TweenMax.delayedCall(2.5, () => {
        waitBtnPos = DT_CANVAS_GLOBALS.actionSelectPanel.waitBtn.toGlobal({
          x: 0,
          y: 0,
        });

        spotlight = new TutorialSpotlight(48);
        spotlight.x = waitBtnPos.x;
        spotlight.y = waitBtnPos.y;
        spotlight.transitionIn(() => {
          DT_CANVAS_GLOBALS.stage.removeChild(clickShield);
        });
        DT_CANVAS_GLOBALS.stage.addChild(spotlight);
      });

      TweenMax.delayedCall(3.0, () => {
        waitBtnTip = new TutorialBox(text('tutorial.wait_btn_tip'), 'down');
        waitBtnTip.x = Math.round(waitBtnPos.x - waitBtnTip.width / 2);
        waitBtnTip.y = Math.round(
          waitBtnPos.y - waitBtnTip.height - DT_CANVAS_GLOBALS.spacing
        );
        TweenMax.from(waitBtnTip, 0.5, { alpha: 0 });
        DT_CANVAS_GLOBALS.stage.addChild(waitBtnTip);
      });
    }
  }

  const hideWaitBtnTip = () => {
    if (waitBtnTip) {
      firebase.analytics().logEvent('onboard_step:6', {
        name: 'clicked_wait',
      });

      TweenMax.to(waitBtnTip, 0.5, {
        alpha: 0,
        onComplete: () => {
          waitBtnTip.dispose();
          DT_CANVAS_GLOBALS.stage.removeChild(waitBtnTip);
          waitBtnTip = null;
        },
      });

      spotlight &&
        spotlight.transitionOut((deadSpotlight) => {
          DT_CANVAS_GLOBALS.stage.removeChild(deadSpotlight);
        }, spotlight);

      var maBtnPos;

      DT_CANVAS_GLOBALS.stage.addChild(clickShield);

      const interval = setInterval(() => {
        if (DT_CANVAS_GLOBALS.actionSelectPanel.meleeAttackBtn) {
          clearInterval(interval);

          TweenMax.delayedCall(4.6, () => {
            maBtnPos =
              DT_CANVAS_GLOBALS.actionSelectPanel.meleeAttackBtn.toGlobal({
                x: 0,
                y: 0,
              });

            if (spotlight) {
              DT_CANVAS_GLOBALS.stage.removeChild(spotlight);
            }

            spotlight = new TutorialSpotlight(48);
            spotlight.x = maBtnPos.x;
            spotlight.y = maBtnPos.y;
            spotlight.transitionIn(() => {
              DT_CANVAS_GLOBALS.stage.removeChild(clickShield);
            });
            DT_CANVAS_GLOBALS.stage.addChild(spotlight);
          });

          TweenMax.delayedCall(5.1, () => {
            if (attackTip) {
              attackTip.dispose();
              DT_CANVAS_GLOBALS.stage.removeChild(attackTip);
            }

            attackTip = new TutorialBox(text('tutorial.attack_tip'), 'down');
            attackTip.x = Math.round(maBtnPos.x - attackTip.width / 2);
            attackTip.y = Math.round(
              maBtnPos.y - attackTip.height - DT_CANVAS_GLOBALS.spacing
            );
            TweenMax.from(attackTip, 0.5, { alpha: 0 });
            DT_CANVAS_GLOBALS.stage.addChild(attackTip);
          });
        }
      }, 20);
    }
  };

  const onMeleeAttackSelected = () => {
    if (attackTip) {
      firebase.analytics().logEvent('onboard_step:7', {
        name: 'melee_attack_selected',
      });

      spotlight &&
        spotlight.transitionOut((deadSpotlight) => {
          DT_CANVAS_GLOBALS.stage.removeChild(deadSpotlight);

          var mobSprite;
          for (var prop in _gameBoard.getAllPieceSprites()) {
            if (
              _gameBoard.getAllPieceSprites()[prop].gamePiece.team === 'black'
            ) {
              mobSprite = _gameBoard.getAllPieceSprites()[prop];
              break;
            }
          }

          DT_CANVAS_GLOBALS.stage.addChild(clickShield);

          spotlight = new TutorialSpotlight(48);
          spotlight.x = mobSprite.toGlobal({ x: 0, y: 0 }).x; // window.innerWidth*0.631;
          spotlight.y = mobSprite.toGlobal({ x: 0, y: 0 }).y; // window.innerHeight*0.926;
          spotlight.transitionIn(() => {
            DT_CANVAS_GLOBALS.stage.removeChild(clickShield);
          });
          DT_CANVAS_GLOBALS.stage.addChild(spotlight);

          const attackTipPosBuf = attackTip.position;
          DT_CANVAS_GLOBALS.stage.removeChild(attackTip);
          attackTip = new TutorialBox(text('tutorial.attack_tip'));
          attackTip.position = attackTipPosBuf;
          // attackTip.x = Math.round( window.innerWidth*0.631 - attackTip.width/2 );
          // attackTip.y = Math.round( window.innerHeight*0.9 - attackTip.height );
          DT_CANVAS_GLOBALS.stage.addChild(attackTip);
        }, spotlight);
    }
  };

  const hideAttackTip = () => {
    if (attackTip) {
      firebase.analytics().logEvent('onboard_step:8', {
        name: 'melee_attack_execute_req',
      });

      TweenMax.to(attackTip, 0.5, {
        alpha: 0,
        onComplete: () => {
          attackTip.dispose();
          DT_CANVAS_GLOBALS.stage.removeChild(attackTip);
          attackTip = null;
        },
      });

      var moveBtnPos;

      DT_CANVAS_GLOBALS.stage.addChild(clickShield);

      TweenMax.delayedCall(1.5, () => {
        moveBtnPos = DT_CANVAS_GLOBALS.actionSelectPanel.moveBtn.toGlobal({
          x: 0,
          y: 0,
        });

        spotlight = new TutorialSpotlight(48);
        spotlight.x = moveBtnPos.x; // window.innerWidth*0.592;
        spotlight.y = moveBtnPos.y; // window.innerHeight*0.85;
        spotlight.transitionIn(() => {
          DT_CANVAS_GLOBALS.stage.removeChild(clickShield);
        });
        DT_CANVAS_GLOBALS.stage.addChild(spotlight);
      });

      TweenMax.delayedCall(2.0, () => {
        moveTip = new TutorialBox(text('tutorial.move_tip'), 'down');
        moveTip.x = Math.round(moveBtnPos.x - moveTip.width / 2); // window.innerWidth*0.589 - moveTip.width/2 );
        moveTip.y = Math.round(
          moveBtnPos.y - moveTip.height - DT_CANVAS_GLOBALS.spacing
        ); // window.innerHeight*0.83 - moveTip.height );
        TweenMax.from(moveTip, 0.5, { alpha: 0 });
        DT_CANVAS_GLOBALS.stage.addChild(moveTip);
      });
    }
  };

  const onMoveSelected = () => {
    if (moveTip) {
      firebase.analytics().logEvent('onboard_step:10', {
        name: 'move_selected',
      });

      if (spotlight) {
        spotlight.transitionOut((deadSpotlight) => {
          DT_CANVAS_GLOBALS.stage.removeChild(deadSpotlight);
        }, spotlight);
      }

      const moveTipPosBuf = moveTip.position;
      DT_CANVAS_GLOBALS.stage.removeChild(moveTip);
      moveTip = new TutorialBox(text('tutorial.move_tip'));
      moveTip.position = moveTipPosBuf;
      DT_CANVAS_GLOBALS.stage.addChild(moveTip);
    }
  };

  const hideMoveTip = () => {
    if (moveTip) {
      firebase.analytics().logEvent('onboard_step:11', {
        name: 'move_selected',
      });

      TweenMax.to(moveTip, 0.5, {
        alpha: 0,
        onComplete: () => {
          moveTip.dispose();
          DT_CANVAS_GLOBALS.stage.removeChild(moveTip);
          moveTip = null;
        },
      });
    }
  };

  BattleStore.on(BattleStore.RESUMING_TIME, hideWaitBtnTip);

  const onStatCostsExacted = () => {
    if (spotlight) {
      spotlight.transitionOut((deadSpotlight) => {
        DT_CANVAS_GLOBALS.stage.removeChild(deadSpotlight);
      }, spotlight);
    }
  };
  BattleStore.on(BattleStore.STAT_COSTS_EXACTED, onStatCostsExacted);

  const onAbilitySelected = () => {
    const selectedAbility = BattleStore.getAll().selectedAbility;

    if (selectedAbility) {
      if (selectedAbility.handle === 'melee_attack') {
        onMeleeAttackSelected();
      } else if (selectedAbility.handle === 'move') {
        onMoveSelected();
      }
    }
  };
  BattleStore.on(BattleStore.ABILITY_SELECTED, onAbilitySelected);

  const onAbilityExecuted = (data) => {
    if (data.abilityHandle === 'melee_attack') {
      hideAttackTip();
    } else if (data.abilityHandle === 'move') {
      hideMoveTip();
    }
  };
  BattleStore.on(BattleStore.ABILITY_EXECUTED, onAbilityExecuted);
};
BattleScreen.prototype = Object.create(PIXI.Container.prototype);
BattleScreen.prototype.constructor = BattleScreen;
export default BattleScreen;
