import * as PIXI from 'pixi.js';
import CanvasTools from '~/view/CanvasTools';
import text from '~/text';
import Colors from '~/constants/Colors';

const RolePrioritiesElement = function( aiTreeTag ) {
    PIXI.Container.call( this );

    this.aiTreeTag = aiTreeTag;

    this.dispose = () => {
        this.removeChildren();
    }

    this._width = window.innerWidth * 0.12;
    this._height = window.innerHeight * 0.12;
    this.backdrop = CanvasTools.addBackFill( this, 0x000000 );
    this.backdrop.alpha = 0.01;

    this.label = new PIXI.Text( text('ui.ai_roles.'+aiTreeTag), {
    	fontFamily: 'Courier New',
    	// fontStyle: 'bold',
    	fontSize: CanvasTools.dynamicFontSizeString(18),
    	fill: Colors.BRIGHT_YELLOW,
    	dropShadow: true,
    	dropShadowColor: 0x000000,
    	dropShadowDistance: 2
    });
    this.label.x = Math.round( this._width/2 - this.label.width/2 );
    this.label.y = Math.round( this._height/2 - this.label.height/2 );
    this.addChild( this.label );

}
RolePrioritiesElement.prototype = Object.create( PIXI.Container.prototype );
RolePrioritiesElement.prototype.constructor = RolePrioritiesElement;
export default RolePrioritiesElement;