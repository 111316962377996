import PuppetMovement, { MELEE_SKILL } from './PuppetMovement';

const ArmorBreakMovement = (puppet) => {
  const poses = [
    { pose: Pose1() },
    { pose: Pose2() },
    { pose: Pose3() },
    { pose: Pose3() },
  ];

  return PuppetMovement({
    poses,
    puppet,
    setPoseDurations: (actorCooldown) => {
      const transTime = actorCooldown / 11;
      poses[0].duration = transTime*6;
      poses[1].duration = transTime;
      poses[2].duration = transTime;
      poses[3].duration = transTime*3;
    },
    skill_type: MELEE_SKILL
  });
};
export default ArmorBreakMovement;

const Pose1 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -155,
      z: -12,
    },
    shoulders: {
      x: 0,
      y: -144,
      z: -7,
    },
    torsoe: {
      x: 0,
      y: -105,
      z: 0,
    },
    leftHand: {
      x: -5,
      y: -165,
      z: -48,
    },
    bowPitch: -180,
    bowYaw: 70,
    rightHand: {
      x: 5,
      y: -160,
      z: -48,
    },
    weaponPitch: -180,
    weaponYaw: 70,
    leftFoot: {
      x: -22,
      y: 0,
      z: 10,
    },
    rightFoot: {
      x: 22,
      y: 0,
      z: -5,
    },
  };

  return pose;
};

const Pose2 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -160,
      z: 8,
    },
    shoulders: {
      x: 0,
      y: -144,
      z: 4,
    },
    torsoe: {
      x: 0,
      y: -105,
      z: 0,
    },
    leftHand: {
      x: -5,
      y: -160,
      z: 66,
    },
    bowPitch: 30,
    bowYaw: -40,
    rightHand: {
      x: 5,
      y: -160,
      z: 66,
    },
    weaponPitch: 30,
    weaponYaw: -40,
    leftFoot: {
      x: -22,
      y: 0,
      z: 10,
    },
    rightFoot: {
      x: 22,
      y: 0,
      z: -5,
    },
  };

  return pose;
};

const Pose3 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -130,
      z: 54,
    },
    shoulders: {
      x: 0,
      y: -117,
      z: 0,
    },
    torsoe: {
      x: 0,
      y: -91,
      z: 0,
    },
    leftHand: {
      x: -5,
      y: -78,
      z: 78,
    },
    bowPitch: 150,
    bowYaw: -125,
    rightHand: {
      x: 5,
      y: -78,
      z: 78,
    },
    weaponPitch: 150,
    weaponYaw: -125,
    leftFoot: {
      x: -22,
      y: 0,
      z: 10,
    },
    rightFoot: {
      x: 22,
      y: 0,
      z: -5,
    },
  };

  return pose;
};