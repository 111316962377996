import SettingsDispatcher from '~/flux/dispatchers/SettingsDispatcher';

const SettingsActions = {
  saveSettings: function(settings) {
    SettingsDispatcher.handleSettingsAction({
      actionType: SettingsDispatcher.SAVE_SETTINGS,
      settings,
    });
  },
};
export default SettingsActions;
