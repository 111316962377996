import { Dispatcher } from 'flux';

const UIDispatcher = Object.assign(new Dispatcher(), {
  NAME: 'UI_DISPATCHER',

  MODAL_CLOSED: 'MODAL_CLOSED',
  SELECT_GAME_MODE: 'SELECT_GAME_MODE',
  SELECT_GAME_SUBMODE: 'SELECT_GAME_SUBMODE',
  SET_FOCUSED_HERO: 'SET_FOCUSED_HERO',
  SET_FOCUSED_HERO_BUILD: 'SET_FOCUSED_HERO_BUILD',
  SHOW_MODAL: 'SHOW_MODAL',
  UI_NAV: 'UI_NAV',

  handleUIAction: function (action) {
    this.dispatch({
      source: UIDispatcher.NAME,
      action,
    });
  },
});
export default UIDispatcher;
