import PoisonedEffect from '../../effects/PoisonedEffect';

const instances = {};

const PoisonedRendering = {
	render: function( condition, gameBoard ) {
		if( instances[condition.uid] ) {
			return;
		}

		instances[ condition.uid ] = {};
		instances[ condition.uid ].ownerSprite = gameBoard.getAllPieceSprites()[condition.ownerId];
				
		const makeEffect = ( conditionId ) => {
			const ownerSprite = instances[conditionId].ownerSprite;

			var effect = new PoisonedEffect( 1000 );
			effect.x = -10 + Math.random()*20;
			effect.y = -40 + Math.random()*30;
			const scale = (0.2+Math.random()*0.3);
			effect.scale.x = scale / ownerSprite.scale.x;
			effect.scale.y = scale / ownerSprite.scale.y;

			if( Math.random() < 0.5 ) {
				effect.scale.x *= -1;
			}

			ownerSprite.addChild( effect );

			TweenMax.to( effect, 1.0, {
				y: effect.y - 20,
				alpha: 0,
				ease: Quad.easeIn,
			});

			instances[ conditionId ].interval = TweenMax.delayedCall( 0.5+Math.random()*1.0, makeEffect, [conditionId] );
		};

		instances[ condition.uid ].interval = TweenMax.delayedCall( Math.random()*0.1, makeEffect, [condition.uid] );
	},

	stopRendering: function( conditionId ) {
		if( instances[conditionId] ) {
			instances[conditionId].interval.kill();
			delete instances[ conditionId ];
		}
	},

	dispose: function() {
		for( var prop in instances ) {
			PoisonedRendering.stopRendering( prop );
		}
	}
};
export default PoisonedRendering;
