export default {
	block: "Блок!",
	dodge: "Уклонение!",
	resist: "Сопротивление!",

	resign_battle_question: "Вы правда хотите покинуть бой?",

	// message log
	not_enough_ap: "Не хватает очков действия!",
	not_enough_mp: "Не хватает магической силы!",
	insufficient_stats: "Не подходят характеристики!",
	try_wait_button: "Попробуйте нажать кнопку 'Ждать'...",
	shaped_like_hourglass: "...похоже на песочные часы...",
	illegal_action: "Запрещенный прием!",

	// battle HUD
	active_unit: "Активный объект",
	target: "Цель"
};
