import PuppetMovement from './PuppetMovement.js';

const MortalStrikeMovement = (puppet) => {
  const poses = [ 
    { pose:Pose1() },
    { pose:Pose2() },
    { pose:Pose3() },
    { pose:Pose4() },
    { pose:Pose4() },
    { pose:Pose4() }
  ];
     
  return PuppetMovement({
		poses,
    puppet,
    setPoseDurations: (actorCooldown) => {
      const transTime = actorCooldown / 11;
      poses[0].duration = transTime*2;
      poses[1].duration = transTime;
      poses[2].duration = transTime;
      poses[3].duration = transTime*2;
      poses[4].duration = transTime;
      poses[5].duration = transTime*3;
    }
  });
};
export default MortalStrikeMovement;

// crouched, about to jump
const Pose1 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -102,
      z: 48
    },        
    shoulders: {
      x: 0,
      y: -90,
      z: 18
    },        
    torsoe: {
      x: 0,
      y: -66,
      z: 0
    },        
    leftHand: {
      x: -5,
      y: -54,
      z: 66
    },       
    bowPitch: 45,
    bowYaw: -45, 
    rightHand: {
      x: 5,
      y: -54,
      z: 66
    },        
    weaponPitch: 45,
    weaponYaw: -45,        
    leftFoot: {
      x: -6,
      y: 0,
      z: -54
    },        
    rightFoot: {
      x: 10,
      y: 0,
      z: 48
    }
  };
  return pose;       
};
    
// weapon poised overhead, actor in midair
const Pose2 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -160,
      z: -30
    },        
    shoulders: {
      x: 0,
      y: -144,
      z: -15
    },        
    torsoe: {
      x: 0,
      y: -105,
      z: 0
    },        
    leftHand: {
      x: -5,
      y: -160,
      z: -60
    },       
    bowPitch: 40,
    bowYaw: -40, 
    rightHand: {
      x: 5,
      y: -160,
      z: -60
    },        
    weaponPitch: 40,
    weaponYaw: -40,        
    leftFoot: {
      x: -6,
      y: -38,
      z: -80
    },        
    rightFoot: {
      x: 10,
      y: -74,
      z: -140
    }
  };
  // upward forward travel
  for(var part in pose) {
    if(pose[part].y !== undefined) {
      pose[part].y -= 245;
    }
    if(pose[part].z !== undefined) {
      pose[part].z += 225;
    }       
  }

  return pose;
};
    
// weapon poised overhead, actor in midair, slightly lower, more forward
const Pose3 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -160,
      z: -30
    },        
    shoulders: {
      x: 0,
      y: -144,
      z: -15
    },        
    torsoe: {
      x: 0,
      y: -105,
      z: 0
    },        
    leftHand: {
      x: -5,
      y: -160,
      z: -60
    },       
    bowPitch: 60,
    bowYaw: -60, 
    rightHand: {
      x: 5,
      y: -160,
      z: -60
    },        
    weaponPitch: 60,
    weaponYaw: -60,        
    leftFoot: {
      x: -6,
      y: -38,
      z: -60
    },        
    rightFoot: {
      x: 10,
      y: -74,
      z: -107
    }
  };
  // upward forward travel
  for(var part in pose) {
    if(pose[part].y !== undefined) {
      pose[part].y -= 225;
    }
    if(pose[part].z !== undefined) {
      pose[part].z += 245;
    }       
  }

  return pose;
};

// crouched, jamming weapon into ground
const Pose4 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -102,
      z: 48
    },        
    shoulders: {
      x: 0,
      y: -90,
      z: 18
    },        
    torsoe: {
      x: 0,
      y: -66,
      z: 0
    },        
    leftHand: {
      x: -15,
      y: -66,
      z: 52
    },       
    bowPitch: 180,
    bowYaw: -180, 
    rightHand: {
      x: -5,
      y: -66,
      z: 52
    },        
    weaponPitch: 180,
    weaponYaw: -180,        
    leftFoot: {
      x: -6,
      y: 0,
      z: 0
    },        
    rightFoot: {
      x: 10,
      y: 0,
      z: 48
    }
  };
  // forward travel
  for(var part in pose) {
    if(pose[part].z !== undefined) {
      pose[part].z += 265;
    }       
  }

  return pose;
};