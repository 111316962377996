import { Dispatcher } from 'flux';

const BattleDebriefDispatcher = Object.assign(new Dispatcher(), {
  NAME: 'BATTLE_DEBRIEF_DISPATCHER',

  CLAIM_ALL_REWARDS: 'CLAIM_ALL_REWARDS',
  CLAIM_ALL_REWARDS_INN: 'CLAIM_ALL_REWARDS_INN',
  CLAIM_GOLD_REWARD: 'CLAIM_GOLD_REWARD',
  CLAIM_GOLD_REWARD_INN: 'CLAIM_GOLD_REWARD_INN',
  CLAIM_ITEM_REWARD: 'CLAIM_ITEM_REWARD',
  CLAIM_ITEM_REWARD_INN: 'CLAIM_ITEM_REWARD_INN',
  CLAIM_PD_REWARD: 'CLAIM_PD_REWARD',
  CLAIM_PD_REWARD_INN: 'CLAIM_PD_REWARD_INN',
  DELETE_BATTLE_LOG_ENTRY: 'DELETE_BATTLE_LOG_ENTRY',
  DELETE_INN_LOG_ENTRY: 'DELETE_INN_LOG_ENTRY',

  handleBattleDebriefAction: function(action) {
    this.dispatch({
      source: BattleDebriefDispatcher.NAME,
      action,
    });
  },
});
export default BattleDebriefDispatcher;
