import Audio from '~/Audio';

const AdrenalineRendering = {
	render: function( data, gameBoard, isCaverns ) {
		const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;

		render_movement && actorSprite.puppet.getMovements().theQuickening.movePuppet({ transitTime: actorSprite.puppet.getCooldown() });
		
		Audio.play( 'antidote_02' );
	}
};
export default AdrenalineRendering;