import Game from 'dt-common/constants/Game';
import UnitHandles from 'dt-common/constants/UnitHandles';
import { Config } from '~/constants';
import text from '~/text';

const { QUALITY_I, QUALITY_II, QUALITY_III, QUALITY_IV } = Game.ItemQuality;

const HEAD_TINT_DICTIONARY = {
  [UnitHandles.ASSASSIN]: 0x424242,
  [UnitHandles.BATTLE_MAGE]: 0xcf6600,
  [UnitHandles.DREAMSMITH]: 0x30dbd5,
  [UnitHandles.INVOKER]: 0x260d93,
  [UnitHandles.KNIGHT]: 0x8898ab,
  [UnitHandles.MERCENARY]: 0x982323,
  [UnitHandles.MONK]: 0x773f0e,
  [UnitHandles.PALADIN]: 0xe0f308,
  [UnitHandles.RANGER]: 0x0d610b,
  [UnitHandles.SHAMAN]: 0x9abd1f,
  [UnitHandles.WARLOCK]: 0x62076f,
};

const EQUIPMENT_DISPLAY_DICTIONARY = {
  helmet: {
    light: [
      [text('equipment.headband'), 0x85764e],
      [text('equipment.headdress'), 0x9fc3bd],
      [text('equipment.circlet'), 0x626894],
      [text('equipment.diadem'), 0xef5fba],
      [text('equipment.crown'), 0x8e17a7],
    ],
    med: [
      [text('equipment.skullcap'), 0xba955a],
      [text('equipment.leather_helm'), 0x77541e],
      [text('equipment.studded_helm'), 0x724600],
      [text('equipment.coif'), 0xb3bcbc],
      [text('equipment.dragonscale_helm'), 0x5e9457],
    ],
    heavy: [
      [text('equipment.copper_helm'), 0xa05803],
      [text('equipment.bronze_helm'), 0xcf7c05],
      [text('equipment.iron_helm'), 0x6b6b6b],
      [text('equipment.steel_helm'), 0x91a6a4],
      [text('equipment.mithril_helm'), 0x6fc6a2],
    ],
  },
  torsoe: {
    light: [
      [text('equipment.rags'), 0x85764e],
      [text('equipment.linen_cloak'), 0x9fc3bd],
      [text('equipment.brocade_cloak'), 0x626894],
      [text('equipment.spidersilk_cloak'), 0xef5fba],
      [text('equipment.mageweave_cloak'), 0x8e17a7],
    ],
    med: [
      [text('equipment.hide_vest'), 0xba955a],
      [text('equipment.leather_vest'), 0x77541e],
      [text('equipment.studded_vest'), 0x724600],
      [text('equipment.chainmail_vest'), 0xb3bcbc],
      [text('equipment.dragonscale_vest'), 0x5e9457],
    ],
    heavy: [
      [text('equipment.copper_plate'), 0xa05803],
      [text('equipment.bronze_plate'), 0xcf7c05],
      [text('equipment.iron_plate'), 0x6b6b6b],
      [text('equipment.steel_plate'), 0x91a6a4],
      [text('equipment.mithril_plate'), 0x6fc6a2],
    ],
  },
  boots: {
    light: [
      [text('equipment.sandals'), 0x85764e],
      [text('equipment.linen_shoes'), 0x9fc3bd],
      [text('equipment.brocade_slippers'), 0x626894],
      [text('equipment.spidersilk_slippers'), 0xef5fba],
      [text('equipment.mageweave_slippers'), 0x8e17a7],
    ],
    med: [
      [text('equipment.hide_boots'), 0xba955a],
      [text('equipment.leather_boots'), 0x77541e],
      [text('equipment.studded_boots'), 0x724600],
      [text('equipment.chainmail_boots'), 0xb3bcbc],
      [text('equipment.dragonscale_boots'), 0x5e9457],
    ],
    heavy: [
      [text('equipment.copper_boots'), 0xa05803],
      [text('equipment.bronze_boots'), 0xcf7c05],
      [text('equipment.iron_boots'), 0x6b6b6b],
      [text('equipment.steel_boots'), 0x91a6a4],
      [text('equipment.mithril_boots'), 0x6fc6a2],
    ],
  },
  weapon: {
    dagger: [
      [text('equipment.shiv'), 0x96ea9e],
      [text('equipment.dagger'), 0x96ea9e],
      [text('equipment.slicer'), 0xaaaaaa],
      [text('equipment.stiletto'), 0xffffff],
      [text('equipment.dragon_tooth'), 0x96ea9e],
    ],
    hammer: [
      [text('equipment.club'), 0x4d320b],
      [text('equipment.hammer'), 0x949494],
      [text('equipment.greathammer'), 0x949494],
      [text('equipment.grandhammer'), 0x949494],
      [text('equipment.thors_hammer'), 0xf3ac00],
    ],
    staff: [
      [text('equipment.walking_stick'), 0x4d320b],
      [text('equipment.quarterstaff'), 0xba8e4f],
      [text('equipment.oaken_staff'), 0xbd9018],
      [text('equipment.greatstaff'), 0x517076],
      [text('equipment.wizards_staff'), 0x641179],
    ],
    sword: [
      [text('equipment.shortsword'), 0x96ea9e],
      [text('equipment.broadsword'), 0x96ea9e],
      [text('equipment.longsword'), 0xaaaaaa],
      [text('equipment.falchion'), 0xffffff],
      [text('equipment.anduril'), 0x96ea9e],
    ],
  },
  off_hand: {
    bow: [
      [text('equipment.shortbow'), 0xba955a],
      [text('equipment.longbow'), 0x77541e],
      [text('equipment.composite_bow'), 0x724600],
      [text('equipment.mechanical_bow'), 0xb3bcbc],
      [text('equipment.elven_bow'), 0x5e9457],
    ],
    shield: [
      [text('equipment.wooden_shield'), 0xba955a],
      [text('equipment.buckler'), 0x77541e],
      [text('equipment.round_shield'), 0x724600],
      [text('equipment.kite_shield'), 0xb3bcbc],
      [text('equipment.tower_shield'), 0x5e9457],
    ],
    book: [
      [text('equipment.folio'), 0xba955a],
      [text('equipment.scroll'), 0x77541e],
      [text('equipment.book'), 0x724600],
      [text('equipment.tome'), 0xb3bcbc],
      [text('equipment.compendium'), 0x5e9457],
    ],
    trinket: [
      [text('equipment.bauble'), 0xba955a],
      [text('equipment.trinket'), 0x77541e],
      [text('equipment.figurine'), 0x724600],
      [text('equipment.totem'), 0xb3bcbc],
      [text('equipment.orb'), 0x5e9457],
    ],
  },
  ring: {
    ring: [
      [text('equipment.ring'), 0xffffff],
      [text('equipment.ring'), 0xffffff],
      [text('equipment.ring'), 0xffffff],
      [text('equipment.ring'), 0xffffff],
      [text('equipment.ring'), 0xffffff],
    ],
  },
  item_container: {
    bag: [
      [text('equipment.bag'), 0xffffff],
      [text('equipment.bag'), 0xffffff],
      [text('equipment.bag'), 0xffffff],
      [text('equipment.bag'), 0xffffff],
      [text('equipment.bag'), 0xffffff],
    ]
  },
};

const getName = (item) => {
  try {
    if (!item) {
      throw new Error('no item');
    }

    if (item.type === 'equipment_dye') {
      return text('equipment.dye');
    } else if (item.type === 'miracle_dye') {
      return text('equipment.miracle_dye');
    } else if (item.type==='hero_xp_boost') {
      return text('equipment.hero_xp_boost');
    } else if (item.type==='prayer_candle') {
      return text('equipment.prayer_candle');
    } else if (item.type === 'bag') {
      return text('equipment.bag');
    }

    switch (Config.LOCALE) {
      case 'pl':
      case 'pt':
      case 'es':
      default: {
        if (!item.slot || !item.type || isNaN(item.quality)) {
          throw new Error('invalid item');
        }

        return (item.prefix ? text('equipment.prefixes.'+item.prefix)+' ' : '')
            +   EQUIPMENT_DISPLAY_DICTIONARY[item.slot][item.type][getQualityIndex(item)][0]
            + (item.suffix ? ' '+text('equipment.suffixes.'+item.suffix) : '');
      }
    }
  } catch (err) {
    logError(err, Object.assign(
      {
        module: 'components/common/EquipmentDisplay',
        func: 'getName',
      },
      item,
    ));

    return '???';
  }
};

const PuppetTint = {
  head: function(actor) {
    return HEAD_TINT_DICTIONARY[ actor.handle ];
  },

  hand: function(actor) {
    return 0xc89e2e;
  },

  equipment: (equipmentPiece) => {
    if (!equipmentPiece) {
      return 0xffffff;
    }

    // TODO(@rob-wfs) probably we will try some sort of filter, overlay, or other effect instead of tinting
    if (equipmentPiece.prefix) {}

    if (equipmentPiece.suffix) {}

    return equipmentPiece.tint || (equipmentPiece.slot === 'item_container'
        ? 0xffffff
        : EQUIPMENT_DISPLAY_DICTIONARY[equipmentPiece.slot][equipmentPiece.type][getQualityIndex(equipmentPiece)][1]
    );
  },
};

const getQualityIndex = (item) => {
  var quality = item.quality;
  if (quality < QUALITY_I) {
    return 0;
  } else if (quality < QUALITY_II) {
    return 1;
  } else if (quality < QUALITY_III) {
    return 2;
  } else if (quality < QUALITY_IV) {
    return 3;
  } else {
    return 4;
  }
};

export default {
  getName,
  getQualityIndex,
  PuppetTint,
};
