<template>
  <div
    class="pressable"
    @mousedown="state.is_pressed = true"
    @mouseup="state.is_pressed = false"
    @mouseout="state.is_pressed = false"
  >
    <div class="pressable-child-invis">
      <!-- maintains the wrapper size -->
      <slot />
    </div>
    <div
      :class="{
        'pressable-child': true,
        'scale-90p': state.is_pressed
      }"
    >
      <slot />
    </div>
  </div>
</template>

<script setup>
import { reactive } from 'vue';
const state = reactive({ is_pressed: false });
</script>

<style>
.pressable {
  position: relative;
  cursor: pointer;
}

.pressable-child-invis {
  visibility: hidden;
}

.pressable-child {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.pressable-child > * {
  width: 100%;
  height: 100%;
}

.scale-90p {
  transform: scale(0.965);
}
</style>
