import * as PIXI from 'pixi.js';
import Colors from '~/constants/Colors';
import CanvasTools from '~/view/CanvasTools';
import text from '~/text';
import CavernsStore from '~/flux/stores/CavernsStore';

const CavernsUnitDetailsView = function (unit_state) {
  PIXI.Container.call(this);

  const unit_handle = (this.handle =
    unit_state.hero_handle || unit_state.handle);

  var _unit = (this.unit_state = unit_state);
  var _namePlate;
  var _hpField, _apField, _mpField;
  var disposed = false;

  this.dispose = () => {
    destroyNamePlate();
    destroyStatBars();

    this.destroy();

    _namePlate = null;
    _unit = null;
    _hpField = null;
    _apField = null;
    _mpField = null;

    disposed = true;
  };

  const destroyNamePlate = () => {
    if (_namePlate) {
      _namePlate.removeChildren();
      _namePlate?.parent.removeChild(_namePlate);
      _namePlate.hero_build_name.destroy();
      _namePlate.hero_build_name = null;
      _namePlate.txt.destroy();
      _namePlate.txt = null;
      _namePlate = null;
    }
  };

  const destroyStatBars = () => {
    if (_hpField) {
      _hpField.removeChildren();
      _hpField.parent?.removeChild(_hpField);
      _hpField.bgGfx.destroy();
      _hpField.fullGfx.destroy();
      _hpField.label.destroy();
      _hpField.valueText.destroy();
      _hpField = null;
    }

    if (_mpField) {
      _mpField.removeChildren();
      _mpField.parent?.removeChild(_mpField);
      _mpField.bgGfx.destroy();
      _mpField.fullGfx.destroy();
      _mpField.label.destroy();
      _mpField.valueText.destroy();
      _mpField = null;
    }

    if (_apField) {
      _apField.removeChildren();
      _apField.parent?.removeChild(_apField);
      _apField.bgGfx.destroy();
      _apField.fullGfx.destroy();
      _apField.label.destroy();
      _apField.valueText.destroy();
      _apField = null;
    }
  };

  const makeNamePlate = () => {
    destroyNamePlate();

    _namePlate = new PIXI.Sprite();
    _namePlate.texture =
      PIXI.utils.TextureCache['armory/class_label_default.png'];
    _namePlate.tint = Colors[unit_handle];
    _namePlate.txt = new PIXI.Text(
      text(`heroes.${unit_handle}.name`).toUpperCase(),
      {
        fontFamily: 'Courier New',
        fontStyle: 'bold',
        fontSize: 22,
        fill: 0xffffff,
        dropShadow: true,
        dropShadowDistance: 1,
        dropShadowColor: 0x000000,
      }
    );
    _namePlate.txt.x = Math.round(
      _namePlate.width / 2 - _namePlate.txt.width / 2
    );
    _namePlate.txt.y = Math.round(
      _namePlate.height * 0.65 - _namePlate.txt.height / 2
    );
    _namePlate.addChild(_namePlate.txt);
    _namePlate.height = Math.round(window.innerHeight * 0.04);
    _namePlate.scale.x = _namePlate.scale.y;
    this.addChild(_namePlate);

    _namePlate.hero_build_name = new PIXI.Text(
      unit_state.build_display_name || text('heroes.basic'),
      {
        fontFamily: 'Courier New',
        fontSize: 14,
        fill: Colors[unit_state.hero_handle || unit_state.handle],
        dropShadow: true,
        dropShadowDistance: 1,
        dropShadowColor: 0xffffff,
      }
    );
    _namePlate.hero_build_name.x = Math.round(
      _namePlate.width / 2 / _namePlate.scale.x -
        _namePlate.hero_build_name.width / 2
    );
    _namePlate.hero_build_name.y = Math.round(
      _namePlate.y - _namePlate.hero_build_name.height - this._height * 0.1
    );
    _namePlate.addChild(_namePlate.hero_build_name);
  };
  makeNamePlate();

  const BAR_WIDTH = window.innerWidth * 0.09;
  const BAR_HEIGHT = window.innerHeight * 0.017;
  const labelStyle = {
    fontFamily: 'Courier New',
    fontStyle: 'bold',
    fontSize: CanvasTools.dynamicFontSizeString(10),
    fill: 0xffffff,
    dropShadow: true,
    dropShadowDistance: 1,
    dropShadowColor: 0x000000,
  };

  const makeStatBars = () => {
    destroyStatBars();

    // make HP Bar
    _hpField = new PIXI.Container();
    var gfx = new PIXI.Graphics();
    gfx.beginFill(Colors.WHITE_TEAM_DARK);
    gfx.drawRect(0, 0, BAR_WIDTH, BAR_HEIGHT);
    gfx.endFill();
    _hpField.bgGfx = gfx;
    _hpField.addChild(gfx);

    var label = new PIXI.Text(text('game.HP'), labelStyle);
    label.x = Math.round(-label.width - DT_CANVAS_GLOBALS.spacing * 0.5);
    label.y = Math.round(_hpField.y + _hpField.height / 2 - label.height / 2);
    _hpField.label = label;
    _hpField.addChild(label);

    var value = _unit.hp;
    var maxValue = _unit.max_hp;
    gfx = new PIXI.Graphics();
    gfx.beginFill(Colors.WHITE_TEAM);
    gfx.drawRect(0, 0, Math.max(1, BAR_WIDTH * (value / maxValue)), BAR_HEIGHT);
    gfx.endFill();
    _hpField.fullGfx = gfx;
    _hpField.addChild(gfx);

    var valueText = new PIXI.Text(
      CanvasTools.getBriefString(value) +
        ' / ' +
        CanvasTools.getBriefString(maxValue),
      labelStyle
    );
    valueText.x = Math.round(BAR_WIDTH / 2 - valueText.width / 2);
    valueText.y = Math.round(BAR_HEIGHT / 2 - valueText.height / 2);
    _hpField.addChild(valueText);

    _hpField.x = Math.round(
      _namePlate.x + _namePlate.width + DT_CANVAS_GLOBALS.spacing * 3.5
    );
    _hpField.y = _namePlate.y - DT_CANVAS_GLOBALS.spacing * 0.9;
    _hpField.valueText = valueText;
    this.addChild(_hpField);

    // make MP Bar
    _mpField = new PIXI.Container();
    gfx = new PIXI.Graphics();
    gfx.beginFill(Colors.MP_PURPLE_DARK);
    gfx.drawRect(0, 0, BAR_WIDTH, BAR_HEIGHT);
    gfx.endFill();
    _mpField.bgGfx = gfx;
    _mpField.addChild(gfx);

    label = new PIXI.Text(text('game.MP'), labelStyle);
    label.x = Math.round(-label.width - DT_CANVAS_GLOBALS.spacing * 0.5);
    label.y = Math.round(_mpField.y + _mpField.height / 2 - label.height / 2);
    _mpField.label = label;
    _mpField.addChild(label);

    value = _unit.mp;
    maxValue = _unit.max_mp;
    gfx = new PIXI.Graphics();
    gfx.beginFill(Colors.MP_PURPLE);
    gfx.drawRect(0, 0, Math.max(1, BAR_WIDTH * (value / maxValue)), BAR_HEIGHT);
    gfx.endFill();
    _mpField.fullGfx = gfx;
    _mpField.addChild(gfx);

    valueText = new PIXI.Text(
      CanvasTools?.getBriefString(value) +
        ' / ' +
        CanvasTools?.getBriefString(maxValue),
      labelStyle
    );
    valueText.x = Math.round(BAR_WIDTH / 2 - valueText.width / 2);
    valueText.y = Math.round(BAR_HEIGHT / 2 - valueText.height / 2);
    _mpField.valueText = valueText;
    _mpField.addChild(valueText);

    _mpField.x = Math.round(_hpField.x);
    _mpField.y = Math.round(_hpField.y + _hpField.height + 1);
    this.addChild(_mpField);

    // make AP Bar
    _apField = new PIXI.Container();
    gfx = new PIXI.Graphics();
    gfx.beginFill(Colors.AP_ORANGE_DARK);
    gfx.drawRect(0, 0, BAR_WIDTH, BAR_HEIGHT);
    gfx.endFill();
    _apField.bgGfx = gfx;
    _apField.addChild(gfx);

    label = new PIXI.Text(text('game.AP'), labelStyle);
    label.x = Math.round(-label.width - DT_CANVAS_GLOBALS.spacing * 0.5);
    label.y = Math.round(_apField.y + _apField.height / 2 - label.height / 2);
    _apField.label = label;
    _apField.addChild(label);

    value = _unit.ap;
    maxValue = _unit.max_ap;
    gfx = new PIXI.Graphics();
    gfx.beginFill(Colors.AP_ORANGE);
    gfx.drawRect(0, 0, Math.max(1, BAR_WIDTH * (value / maxValue)), BAR_HEIGHT);
    gfx.endFill();
    _apField.fullGfx = gfx;
    _apField.addChild(gfx);

    valueText = new PIXI.Text(
      CanvasTools?.getBriefString(value) +
        ' / ' +
        CanvasTools?.getBriefString(maxValue),
      labelStyle
    );
    valueText.x = Math.round(BAR_WIDTH / 2 - valueText.width / 2);
    valueText.y = Math.round(BAR_HEIGHT / 2 - valueText.height / 2);
    _apField.valueText = valueText;
    _apField.addChild(valueText);

    _apField.x = Math.round(_mpField.x);
    _apField.y = Math.round(_mpField.y + _mpField.height + 1);
    this.addChild(_apField);
  };
  makeStatBars();

  this.updateStats = () => {
    if (disposed) return;
    if (!_unit) return;
    const { battleState } = CavernsStore.getAll();
    if (!battleState) return;
    _unit = battleState.allUnits[_unit.uid];
    if (!_unit) return;

    var value = Math.max(0, _unit.hp);
    var maxValue = _unit.max_hp;
    _hpField.fullGfx.width = BAR_WIDTH * (value / maxValue);
    _hpField.valueText.text =
      CanvasTools?.getBriefString(value) +
      ' / ' +
      CanvasTools?.getBriefString(maxValue);
    _hpField.valueText.x = Math.round(
      BAR_WIDTH / 2 - _hpField.valueText.width / 2
    );

    value = Math.max(0, _unit.mp);
    maxValue = _unit.max_mp;
    _mpField.fullGfx.width = BAR_WIDTH * (value / maxValue);
    _mpField.valueText.text =
      CanvasTools?.getBriefString(value) +
      ' / ' +
      CanvasTools?.getBriefString(maxValue);
    _mpField.valueText.x = Math.round(
      BAR_WIDTH / 2 - _mpField.valueText.width / 2
    );

    value = Math.max(0, _unit.ap);
    maxValue = _unit.max_ap;
    _apField.fullGfx.width = BAR_WIDTH * (value / maxValue);
    _apField.valueText.text =
      CanvasTools?.getBriefString(value) +
      ' / ' +
      CanvasTools?.getBriefString(maxValue);
    _apField.valueText.x = Math.round(
      BAR_WIDTH / 2 - _apField.valueText.width / 2
    );
  };

  this.onUnitDeath = () => {
    _hpField.fullGfx.width = 0;
    _apField.fullGfx.width = 0;
    _mpField.fullGfx.width = 0;

    _hpField.valueText.text = 'xxx';
    _mpField.valueText.text = 'xxx';
    _apField.valueText.text = 'xxx';

    _hpField.valueText.x = Math.round(
      BAR_WIDTH / 2 - _hpField.valueText.width / 2
    );
    _mpField.valueText.x = Math.round(
      BAR_WIDTH / 2 - _mpField.valueText.width / 2
    );
    _apField.valueText.x = Math.round(
      BAR_WIDTH / 2 - _apField.valueText.width / 2
    );

    _namePlate.tint = 0x999999;
    this.alpha = 0.35;
  };
};
CavernsUnitDetailsView.prototype = Object.create(PIXI.Container.prototype);
CavernsUnitDetailsView.prototype.constructor = CavernsUnitDetailsView;
export default CavernsUnitDetailsView;
