import StripAnimation from './StripAnimation';

const NukeEffect = function( duration ) {
	return new StripAnimation( 
		'effects/nuke',
		duration,
		26,
		false
	);	
}
export default NukeEffect;