import * as PIXI from 'pixi.js';
import Tooltip from '~/components/tooltips/Tooltip';
import Colors from '~/constants/Colors';
import text from '~/text';
import CanvasTools from '~/view/CanvasTools';

const EditHeroStats = function (hero_state) {
  PIXI.Container.call(this);

  let _tooltip;

  const BAR_WIDTH = window.innerWidth * 0.13;
  const BAR_HEIGHT = window.innerHeight * 0.025;
  const TEXT_STYLE = {
    fontFamily: 'Courier New',
    fontStyle: 'bold',
    fontSize: CanvasTools.dynamicFontSizeString(11),
    fill: 0xffffff,
    dropShadow: true,
    dropShadowDistance: 1,
    dropShadowColor: 0x000000,
  };

  this.dispose = () => {
    _hpBar.mouseover =
      _hpBar.mouseout =
      _hpBar.pointerover =
      _hpBar.pointerout =
        null;
    _hpBar.mouseover =
      _hpBar.mouseout =
      _hpBar.pointerover =
      _hpBar.pointerout =
        null;
    _hpBar.mouseover =
      _hpBar.mouseout =
      _hpBar.pointerover =
      _hpBar.pointerout =
        null;
    this.removeChildren();
    this.parent?.removeChild(this);
  };

  // make HP Bar
  var _hpBar = new PIXI.Container();
  let gfx = new PIXI.Graphics();
  gfx.beginFill(Colors.HP_RED);
  gfx.drawRect(0, 0, BAR_WIDTH, BAR_HEIGHT);
  gfx.endFill();
  _hpBar.addChild(gfx);

  let label = new PIXI.Text(text('game.HP') + ':', TEXT_STYLE);
  label.x = Math.round(
    BAR_WIDTH / 2 - label.width - DT_CANVAS_GLOBALS.spacing * 0.25
  );
  label.y = Math.round(BAR_HEIGHT / 2 - label.height / 2);
  _hpBar.addChild(label);

  let valueText = new PIXI.Text(hero_state.max_hp, TEXT_STYLE);
  valueText.x = Math.round(BAR_WIDTH / 2 + DT_CANVAS_GLOBALS.spacing * 0.25);
  valueText.y = Math.round(BAR_HEIGHT / 2 - valueText.height / 2);
  _hpBar.addChild(valueText);

  _hpBar.x = Math.round(-BAR_WIDTH / 2);
  _hpBar.y = DT_CANVAS_GLOBALS.spacing * 12;
  this.addChild(_hpBar);

  // make MP Bar
  var _mpBar = new PIXI.Container();
  gfx = new PIXI.Graphics();
  gfx.beginFill(Colors.MP_BLUE);
  gfx.drawRect(0, 0, BAR_WIDTH, BAR_HEIGHT);
  gfx.endFill();
  _mpBar.addChild(gfx);

  label = new PIXI.Text(text('game.MP') + ':', TEXT_STYLE);
  label.x = Math.round(
    BAR_WIDTH / 2 - label.width - DT_CANVAS_GLOBALS.spacing * 0.25
  );
  label.y = Math.round(BAR_HEIGHT / 2 - label.height / 2);
  _mpBar.addChild(label);

  valueText = new PIXI.Text(hero_state.max_mp, TEXT_STYLE);
  valueText.x = Math.round(BAR_WIDTH / 2 + DT_CANVAS_GLOBALS.spacing * 0.25);
  valueText.y = Math.round(BAR_HEIGHT / 2 - valueText.height / 2);
  _mpBar.addChild(valueText);

  _mpBar.x = _hpBar.x;
  _mpBar.y = Math.round(
    _hpBar.y + _hpBar.height + DT_CANVAS_GLOBALS.spacing * 0.5
  );
  this.addChild(_mpBar);

  // make AP Bar
  var _apBar = new PIXI.Container();
  gfx = new PIXI.Graphics();
  gfx.beginFill(Colors.AP_ORANGE);
  gfx.drawRect(0, 0, BAR_WIDTH, BAR_HEIGHT);
  gfx.endFill();
  _apBar.addChild(gfx);

  label = new PIXI.Text(text('game.AP') + ':', TEXT_STYLE);
  label.x = Math.round(
    BAR_WIDTH / 2 - label.width - DT_CANVAS_GLOBALS.spacing * 0.25
  );
  label.y = Math.round(BAR_HEIGHT / 2 - label.height / 2);
  _apBar.addChild(label);

  valueText = new PIXI.Text(hero_state.max_ap, TEXT_STYLE);
  valueText.x = Math.round(BAR_WIDTH / 2 + DT_CANVAS_GLOBALS.spacing * 0.25);
  valueText.y = Math.round(BAR_HEIGHT / 2 - valueText.height / 2);
  _apBar.addChild(valueText);

  _apBar.x = _mpBar.x;
  _apBar.y = Math.round(
    _mpBar.y + _mpBar.height + DT_CANVAS_GLOBALS.spacing * 0.5
  );
  this.addChild(_apBar);

  const onStatBarMouseover = (data, element) => {
    _tooltip?.dispose();
    _tooltip = new Tooltip('secondary_attribute', data, element);
  };
  const onStatBarMouseout = () => {
    _tooltip?.dispose();
    _tooltip = null;
  };
  _hpBar.interactive = true;
  _hpBar.mouseover = _hpBar.pointerover = onStatBarMouseover.bind(
    null,
    { handle: 'max_hp', value: hero_state.max_hp },
    _hpBar
  );
  _hpBar.mouseout = _hpBar.pointerout = onStatBarMouseout;
  _mpBar.interactive = true;
  _mpBar.mouseover = _mpBar.pointerover = onStatBarMouseover.bind(
    null,
    { handle: 'max_mp', value: hero_state.max_mp },
    _hpBar
  );
  _mpBar.mouseout = _mpBar.pointerout = onStatBarMouseout;
  _apBar.interactive = true;
  _apBar.mouseover = _apBar.pointerover = onStatBarMouseover.bind(
    null,
    { handle: 'max_ap', value: hero_state.max_ap },
    _hpBar
  );
  _apBar.mouseout = _apBar.pointerout = onStatBarMouseout;
};
EditHeroStats.prototype = Object.create(PIXI.Container.prototype);
EditHeroStats.prototype.constructor = EditHeroStats;
export default EditHeroStats;
