// standard abilities
import move from './unit_abilities/MoveRendering';
import melee_attack from './unit_abilities/MeleeAttackRendering';

// alchemy
import poison_weapon from './unit_abilities/alchemy/PoisonWeaponRendering';
import burning_hands from './unit_abilities/alchemy/BurningHandsRendering';
import molotov_cocktail from './unit_abilities/alchemy/MolotovCocktailRendering';
import ice_nine from './unit_abilities/alchemy/IceNineRendering';
import fumigate from './unit_abilities/alchemy/FumigateRendering';
import lightning from './unit_abilities/alchemy/LightningRendering';
import road_to_nowhere from './unit_abilities/alchemy/RoadToNowhereRendering';

// armor use
import bash from './unit_abilities/armor_use/BashRendering';
import suture from './unit_abilities/armor_use/SutureRendering';

// bow use
import arrow_shot from './unit_abilities/bow_use/ArrowShotRendering';
import leg_shot from './unit_abilities/bow_use/LegShotRendering';
import razor_shot from './unit_abilities/bow_use/RazorShotRendering';
import blunt_arrow from './unit_abilities/bow_use/BluntArrowRendering';
import chain_pull from './unit_abilities/bow_use/ChainPullRendering';
import multi_shot from './unit_abilities/bow_use/MultiShotRendering';

// conjuration
import void_spike from './unit_abilities/conjuration/VoidSpikeRendering';
import summon_dwarf from './unit_abilities/conjuration/SummonDwarfRendering';
import summon_elf from './unit_abilities/conjuration/SummonElfRendering';
import summon_faerie from './unit_abilities/conjuration/SummonFaerieRendering';
import summon_option from './unit_abilities/conjuration/SummonOptionRendering';
import teleport from './unit_abilities/conjuration/TeleportRendering';

// illusion
import sleight_of_hand from './unit_abilities/illusion/SleightOfHandRendering';
import psyche_out from './unit_abilities/illusion/PsycheOutRendering';
import infect_mind from './unit_abilities/illusion/InfectMindRendering';
import devil_mask from './unit_abilities/illusion/DevilMaskRendering';
import faux_self from './unit_abilities/illusion/FauxSelfRendering';

// melee combat
import slash from './unit_abilities/melee_combat/SlashRendering';
import slam from './unit_abilities/melee_combat/SlamRendering';
import taunt from './unit_abilities/melee_combat/TauntRendering';
import armor_break from './unit_abilities/melee_combat/ArmorBreakRendering';
import roundhouse from './unit_abilities/melee_combat/RoundhouseRendering';
import mortal_strike from './unit_abilities/melee_combat/MortalStrikeRendering';
import sky_hammer from './unit_abilities/melee_combat/SkyHammerRendering';

// movement
import adrenaline from './unit_abilities/movement/AdrenalineRendering';
import leap from './unit_abilities/movement/LeapRendering';
import tussle from './unit_abilities/movement/TussleRendering';

// spirit
import healing_prayer from './unit_abilities/spirit/HealingPrayerRendering';
import mystic_focus from './unit_abilities/spirit/MysticFocusRendering';
import send_mana from './unit_abilities/spirit/SendManaRendering';
import bless from './unit_abilities/spirit/BlessRendering';
import sanctuary from './unit_abilities/spirit/SanctuaryRendering';
import holy_force from './unit_abilities/spirit/HolyForceRendering';

// battle events
import move_event from './unit_actions/MoveEventRendering';
import damage from './unit_actions/DamageRendering';
import block from './unit_actions/BlockRendering';
import dodge from './unit_actions/DodgeRendering';
import resist from './unit_actions/ResistRendering';
import lose_mp from './unit_actions/LoseMPRendering';
import heal from './unit_actions/HealRendering';
import death from './unit_actions/DeathRendering';
import knockback from './unit_actions/KnockbackRendering';
import unit_spawned from './unit_actions/UnitSpawnedRendering';
import ice_proc from './unit_actions/IceProcRendering';
import option_attack from './unit_actions/OptionAttackRendering';

// caverns events
import gold_spawn from './caverns_events/GoldSpawnRendering';
import pd_spawn from './caverns_events/PixieDustSpawnRendering';
import equipment_spawn from './caverns_events/EquipmentSpawnRendering';

// conditions
import poisoner from './conditions/PoisonerRendering';
import poisoned from './conditions/PoisonedRendering';
import bleeding from './conditions/BleedingRendering';
import inspired from './conditions/InspiredRendering';
import enraged from './conditions/EnragedRendering';
import slowed from './conditions/SlowedRendering';
import armor_broken from './conditions/ArmorBrokenRendering';
import blessed from './conditions/BlessedRendering';
import scared from './conditions/ScaredRendering';
import recuperating from './conditions/RecuperatingRendering';
import has_option from './conditions/HasOptionRendering';

import Audio from '~/Audio';

const RENDERINGS = {
  // standard abilities
  move,
  melee_attack,

  // alchemy
  poison_weapon,
  burning_hands,
  molotov_cocktail,
  ice_nine,
  fumigate,
  lightning,
  road_to_nowhere,

  // armor use
  bash,
  suture,

  // bow use
  arrow_shot,
  leg_shot,
  razor_shot,
  blunt_arrow,
  chain_pull,
  multi_shot,

  // conjuration
  void_spike,
  summon_dwarf,
  summon_elf,
  summon_faerie,
  summon_option,
  teleport,

  // illusion
  sleight_of_hand,
  psyche_out,
  infect_mind,
  devil_mask,
  faux_self,

  // melee combat
  slash,
  slam,
  taunt,
  armor_break,
  roundhouse,
  mortal_strike,
  sky_hammer,

  // movement
  adrenaline,
  leap,
  tussle,

  // spirit
  healing_prayer,
  mystic_focus,
  send_mana,
  bless,
  sanctuary,
  holy_force,

  // battle events
  move_event,
  damage,
  block,
  dodge,
  resist,
  lose_mp,
  heal,
  death,
  knockback,
  unit_spawned,
  ice_proc,
  option_attack,

  // caverns events
  gold_spawn,
  pd_spawn,
  equipment_spawn,

  // conditions
  poisoner,
  poisoned,
  bleeding,
  inspired,
  enraged,
  slowed,
  armor_broken,
  blessed,
  scared,
  recuperating,
  has_option,
};

const ActionRenderer = function (gameBoard, isCaverns) {
  this.renderAbility = function (data) {
    try {
      if (document.hidden) return;

      if (RENDERINGS[data.abilityHandle]) {
        const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
        if (!isCaverns || actorSprite.gamePiece.hero) {
          // ability actors always face the target first
          const victimSprite = gameBoard.getAllPieceSprites()[data.victimId];
          if (victimSprite) {
            actorSprite.puppet.facePiece(victimSprite.gamePiece);
          }
        }

        RENDERINGS[data.abilityHandle].render(data, gameBoard, isCaverns);
      }
    } catch (err) {
      logError(err, {
        module: 'ActionRenderer',
        func: 'renderAbility',
        data,
      });
    }
  };

  this.renderAbilityResult = function (data) {
    try {
      if (document.hidden) return;

      if (RENDERINGS[data?.abilityHandle]?.renderResult) {
        RENDERINGS[data.abilityHandle].renderResult(data, gameBoard, isCaverns);
        if (data.is_reflection) {
          Audio.play('sword_clang');
        }
      }
    } catch (err) {
      logError(err, {
        module: 'ActionRenderer',
        func: 'renderAbilityResult',
        data,
      });
    }
  };

  this.renderEvent = function (event) {
    try {
      if (
        document.hidden &&
        !['death', 'move_event'].includes(event.eventHandle)
      )
        return;

      if (RENDERINGS[event.eventHandle]) {
        RENDERINGS[event.eventHandle].render(event, gameBoard, isCaverns);
      }
    } catch (err) {
      logError(err, {
        module: 'ActionRenderer',
        func: 'renderEvent',
        event,
      });
    }
  };

  this.renderCondition = function (condition) {
    if (document.hidden) return;

    try {
      if (RENDERINGS[condition.handle]) {
        RENDERINGS[condition.handle].render(condition, gameBoard, isCaverns);
      }
    } catch (err) {
      logError(err, {
        module: 'ActionRenderer',
        func: 'renderCondition',
        condition,
      });
    }
  };

  this.stopRenderingCondition = function (data) {
    try {
      if (RENDERINGS[data.conditionHandle]) {
        RENDERINGS[data.conditionHandle].stopRendering(
          data.conditionId,
          isCaverns
        );
      }
    } catch (err) {
      logError(err, {
        module: 'ActionRenderer',
        func: 'stopRenderingCondition',
        data,
      });
    }
  };

  this.dispose = () => {
    for (var prop in RENDERINGS) {
      try {
        if (RENDERINGS[prop].dispose) {
          RENDERINGS[prop].dispose();
        }
      } catch (err) {
        logError(err, {
          module: 'ActionRenderer',
          func: 'dispose',
          prop,
        });
      }
    }
  };
};
export default ActionRenderer;
