<template>
  <div class="w-full h-full">
    <div
      id="HeroRosterView_title"
      class="pt-1 px-3 text-xl font-bold text-yellow-300"
    >
      {{ text('ui.roster') }}
    </div>
    <div
      id="hero_builds_roster_body"
      class="grow w-full flex flex-col justify-center"
    >
      <div
        id="hero_builds_roster_unlocked_heroes"
        class="flex flex-wrap justify-center gap-2"
      >
        <div
          v-for="(unlocked_hero, i) of state.unlocked_heroes"
          :id="`HeroRosterView_puppet_anchor_${unlocked_hero.handle}`"
          :key="i"
          :class="{
            'basis-[21%] w-[12vh] h-[12vh] cursor-pointer hover:outline hover:outline-yellow-200/30 rounded-full': true,
            'bg-white/10': state.focused_hero_handle === unlocked_hero.handle,
            'pointer-events-auto': props.allow_pointer_events,
            'pointer-events-none': !props.allow_pointer_events,
          }"
          @click="onUnlockedHeroClick(unlocked_hero.handle)"
        ></div>
      </div>
      <div v-if="state.locked_heroes.length">
        <hr class="my-[2.5vh] border border-zinc-700" />
        <div
          id="hero_builds_roster_locked_heroes"
          class="flex flex-wrap justify-center gap-2"
        >
          <div
            v-for="(locked_hero, i) of state.locked_heroes"
            :id="`HeroRosterView_puppet_anchor_${locked_hero.handle}`"
            :key="i"
            :class="{
              'basis-[21%] w-[12vh] h-[12vh] cursor-pointer hover:outline hover:outline-yellow-200/30 rounded-full': true,
              'pointer-events-auto': props.allow_pointer_events,
              'pointer-events-none': !props.allow_pointer_events,
            }"
            @click="onLockedHeroClick(locked_hero.handle)"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import * as PIXI from 'pixi.js';
import { onBeforeUnmount, onMounted, nextTick, reactive } from 'vue';
import Audio from '~/Audio';
import { TutorialActions, UIActions } from '~/flux/actions';
import { GameStateStore, HeroBuildStore, UIStore } from '~/flux/stores';
import text from '~/text';
import Tools from '~/Tools';
import { PuppetWithNameplate } from '~/view/components/common/canvas';
import LightFromAbove from '~/view/game-screens/battle/canvas/game_board/action_renderings/LightFromAbove';

let _puppets;

const props = defineProps({
  allow_pointer_events: {
    type: Boolean,
    required: false,
    default: true,
  },
  puppet_parent: {
    type: Object,
    required: false, // defaults to DT_CANVAS_GLOBALS.stage
  },
});

const state = reactive({
  focused_hero_handle: UIStore.getAll().focused_hero_handle,
  locked_heroes: getLockedHeroes(),
  unlocked_heroes: getUnlockedHeroes(),
});

function getLockedHeroes() {
  const { hero_roster } = GameStateStore.getAll().gameState;
  return Object.values(hero_roster).filter((hero) => hero.level === 0);
}

function getUnlockedHeroes() {
  const { hero_roster } = GameStateStore.getAll().gameState;
  return Object.values(hero_roster).filter((hero) => hero.level > 0);
}

onMounted(() => {
  makePuppets();
  window.addEventListener('resize', handleResize);
  window.addEventListener('orientationchange', handleResize);
  HeroBuildStore.on(HeroBuildStore.HERO_UNLOCKED, onHeroUnlocked);
  Audio.setBGTrack('menu_music');
});

onBeforeUnmount(() => {
  destroyPuppets();
  window.removeEventListener('resize', handleResize);
  window.removeEventListener('orientationchange', handleResize);
  HeroBuildStore.removeListener(HeroBuildStore.HERO_UNLOCKED, onHeroUnlocked);
});

const handleResize = Tools.debounce(() => {
  makePuppets();
}, 300);

function makePuppets() {
  destroyPuppets();
  _puppets = [];

  const all_roster_heroes = [].concat(
    state.locked_heroes,
    state.unlocked_heroes
  );

  const parent = props.puppet_parent || DT_CANVAS_GLOBALS.stage;
  for (const roster_hero of all_roster_heroes) {
    const puppet = new PuppetWithNameplate({
      roster_hero,
      spoof_starter_equipment: state.locked_heroes.some(
        ({ handle }) => handle === roster_hero.handle
      ),
    });
    const dom_anchor = document.getElementById(
      `HeroRosterView_puppet_anchor_${roster_hero.handle}`
    );
    const { offsetTop, offsetLeft } = dom_anchor;
    const { width, height } = dom_anchor.getBoundingClientRect();
    puppet.x = offsetLeft + width / 2;
    puppet.y = offsetTop + height * 0.55;
    parent.addChild(puppet);
    _puppets.push(puppet);

    puppet.transitionIn();
  }
}

function destroyPuppets() {
  for (const puppet of _puppets || []) {
    puppet.dispose();
    puppet?.parent?.removeChild(puppet);
  }
  _puppets = null;
}

function onLockedHeroClick(hero_handle) {
  UIActions.showModal({
    modal_key: 'HeroUnlockModal',
    modal_props: { hero_handle },
  });

  TutorialActions.hideTutorialBox();
}

function onUnlockedHeroClick(hero_handle) {
  UIActions.focusHero(hero_handle);
  state.focused_hero_handle = UIStore.getAll().focused_hero_handle;
}

function onHeroUnlocked(hero_handle) {
  state.locked_heroes = getLockedHeroes();
  state.unlocked_heroes = getUnlockedHeroes();
  nextTick(() => {
    makePuppets();

    // do animation
    const unlockedPuppet = _puppets.find(
      ({ actor }) => actor.handle === hero_handle
    );
    const lightEffect = new LightFromAbove(unlockedPuppet);
    lightEffect.width = unlockedPuppet.width * 3.5;
  });
  Audio.play('level_up');
}
</script>
