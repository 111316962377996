import * as PIXI from 'pixi.js';
import BattleConfig from 'dt-common/constants/BattleConfig';
import BattleCalc from 'dt-common/battle_engine/BattleCalc';
import Audio from '~/Audio';

const IceNineRendering = {
  render: function(data, gameBoard, isCaverns) {
    if (data.isProc) {
      return;
    }

    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const victimSprite = gameBoard.getAllPieceSprites()[data.victimId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
    const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

    render_movement && actorSprite.puppet.getMovements().throw.movePuppet({ transitTime });

    const projectileTime = BattleCalc.projectileTime(BattleConfig.THROWN_SPEED, actorSprite.gamePiece, victimSprite.gamePiece);

    var _timeline = new TimelineMax();

    _timeline.call(Audio.play, ['throw_01'], transitTime * 0.4);
    _timeline.call(Audio.play, ['glass_shatter_01'], transitTime * 0.4 + projectileTime / 1000);

    _timeline.call(()=> {
      var _bottle = new PIXI.Sprite();
      _bottle.texture = PIXI.utils.TextureCache['effects/ice_nine_bottle.png'];
      _bottle.anchor.x = _bottle.anchor.y = 0.5;
      _bottle.x = actorSprite.x;
      _bottle.y = actorSprite.y;
      gameBoard.addChild(_bottle);

      _timeline.add(
        TweenMax.to(_bottle, projectileTime/1000, { rotation: (45+Math.random()*360)*(Math.PI/180), ease: Linear.easeNone }),
        _timeline._time,
      );

      _timeline.add(
        TweenMax.to(_bottle, projectileTime/1000, {
          x: victimSprite.x,
          y: victimSprite.y,
          onComplete: () => _bottle.parent?.removeChild(_bottle),
        }),
        _timeline._time,
      );

    }, [], transitTime * 0.5);
  },
};
export default IceNineRendering;
