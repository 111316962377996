import BattleCalc from 'dt-common/battle_engine/BattleCalc';
import BattleConfig from 'dt-common/constants/BattleConfig';
import Audio from '~/Audio';

const KnockbackRendering = {
	render: function( data, gameBoard, isCaverns ) {
		const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
		if( !actorSprite ) {
			return;
		}

		const victimSprite = gameBoard.getAllPieceSprites()[data.victimId];
		const victimTile = victimSprite.gamePiece;
    const actor_old_tile_sprite = gameBoard.getAllPieceSprites()[data.actorOldTileId]
		const actorOldTile = actor_old_tile_sprite.gamePiece;
		const transitTime = isCaverns? 0 : BattleCalc.projectileTime( BattleConfig.KNOCKBACK_SPEED, actorOldTile, victimTile );
		
		if (isCaverns) {
			actorSprite.snapToBoardPosition(victimTile.x, victimTile.y)

			victimSprite.tileImg.visible = false
			if (victimSprite.lootImg) {
				victimSprite.lootImg.visible = false
			}

			if (actor_old_tile_sprite.lootImg) {
				actor_old_tile_sprite.lootImg.visible = true
			} else {
        actor_old_tile_sprite.tileImg.visible = true
			}

		} else {
			actorSprite.puppet.facePiece( gameBoard.getAllPieceSprites()[data.forceTileId].gamePiece );
			actorSprite.tweenToBoardPosition( victimTile.x, victimTile.y, transitTime/1000 );
			
			actorSprite.puppet.getMovements().knockback.movePuppet({ transitTime });
		}
			
		TweenMax.delayedCall( (transitTime/1000)*0.75, Audio.play, ['body_fall_01'] );
	}
}
export default KnockbackRendering
