import PuppetMovement from './PuppetMovement.js';

const ShieldBlockMovement = (puppet) => {
  const poses = [ 
    { pose:Pose1() },
    { pose:Pose2() }
  ];

  return PuppetMovement({
		poses,
    puppet,
    setPoseDurations: (actorCooldown) => {
      for(var i = 0; i < poses.length; ++i) {
        poses[i].duration = actorCooldown / 2;
      }
    }
  });
};
export default ShieldBlockMovement;

const Pose1 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -120,
      z: 15
    },        
    shoulders: {
      x: 9,
      y: -108,
      z: 11
    },        
    torsoe: {
      x: 0,
      y: -90,
      z: 0
    },        
    leftHand: {
      x: -10,
      y: -122,
      z: 54
    },    
    bowPitch: -45,
    bowYaw: 0,    
    rightHand: {
      x: 24,
      y: -84,
      z: 24
    },        
    weaponPitch: 0,
    weaponYaw: 0,        
    leftFoot: {
      x: -43,
      y: 0,
      z: 11
    },        
    rightFoot: {
      x: 30,
      y: 0,
      z: 0
    }
  };
  return pose;       
};

const Pose2 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -122,
      z: 15
    },        
    shoulders: {
      x: 9,
      y: -115,
      z: 11
    },        
    torsoe: {
      x: 0,
      y: -92,
      z: 0
    },        
    leftHand: {
      x: -10,
      y: -126,
      z: 54
    },    
    bowPitch: -45,
    bowYaw: 0,    
    rightHand: {
      x: 24,
      y: -91,
      z: 24
    },        
    weaponPitch: 0,
    weaponYaw: 0,        
    leftFoot: {
      x: -43,
      y: 0,
      z: 11
    },        
    rightFoot: {
      x: 30,
      y: 0,
      z: 0
    }
  };
  return pose;       
};