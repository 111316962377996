import FloatingTextEffect from '../../effects/FloatingTextEffect';
import Colors from '~/constants/Colors';
import text from '~/text';
import Audio from '~/Audio';

const DodgeRendering = {
  render: function(data, gameBoard, isCaverns) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    if(!actorSprite) {
      return;
    }
			
    var effect = new FloatingTextEffect(text('battle.dodge'), 15, 0x999999);
			
    if(actorSprite.textQueue) {
      actorSprite.textQueue.push(effect);
    }

    Audio.play('dodge');
  }
};
export default DodgeRendering;