import * as PIXI from 'pixi.js';
import Audio from '~/Audio';

const SutureRendering = {
	render: function( data, gameBoard, isCaverns ) {
		const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
		const victimSprite = gameBoard.getAllPieceSprites()[data.victimId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
		const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

		render_movement && actorSprite.puppet.getMovements().tinker.movePuppet({ transitTime });

		Audio.play( 'suture' );		

		const makeEffect = () => {
			var effect = new PIXI.Text( '+', {
				fontFamily: 'Courier New',
				fontSize: '16px',
				fontWeight: 'bold',
				fill: 0x00ff00
			});
			
			effect.x = -victimSprite.tileImg.width/2 + Math.random()*victimSprite.tileImg.width;
			effect.y = -Math.random()*victimSprite.tileImg.height/2;
			victimSprite.addChildAt( effect, Math.floor(Math.random()*victimSprite.children.length) );

			TweenMax.to( effect, 0.876, {
				y: effect.y - 45 + Math.random()*25,
				alpha: 0,
				ease: Linear.easeNone,
				onComplete: e => e.parent?.removeChild(e),
				onCompleteParams: [ effect ]
			});
		};

		setTimeout( ()=>{
			for( var i = 0; i < 15; ++i ) {
				TweenMax.delayedCall( 0.1*i, makeEffect );
			}
		}, transitTime );	
	}
};
export default SutureRendering;