import StripAnimation from './StripAnimation';

const IceProcEffect = function( duration ) {
	return new StripAnimation( 
		'effects/ice_nine',
		duration,
		7,
		false
	);	
}
export default IceProcEffect;