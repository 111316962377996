import PuppetMovement from './PuppetMovement.js';

const TheQuickeningMovement = (puppet) => {
  const poses = [ 
    { pose:Pose1() },
    { pose:Pose1() }
  ];
     
  return PuppetMovement({
		poses,
    puppet,
    setPoseDurations: (actorCooldown) => {
      poses[0].duration = actorCooldown*0.5;
      poses[1].duration = actorCooldown*0.5;
    }
  });
};
export default TheQuickeningMovement;

const Pose1 = function() {
  const pose =  {
    head: {
      x: 0,
      y: -120,
      z: -55
    },        
    shoulders: {
      x: 0,
      y: -124,
      z: -20
    },        
    torsoe: {
      x: 0,
      y: -85,
      z: -5
    },        
    leftHand: {
      x: -47,
      y: -116,
      z: 42
    },    
    bowPitch: 15,
    bowYaw: -60,    
    rightHand: {
      x: 47,
      y: -116,
      z: 42
    },        
    weaponPitch: 15,
    weaponYaw: 60,        
    leftFoot: {
      x: -22,
      y: 0,
      z: 25
    },        
    rightFoot: {
      x: 22,
      y: 0,
      z: -5
    }
  };

  return pose;       
};