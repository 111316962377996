import * as PIXI from 'pixi.js';
import text from '~/text';
import CanvasTools from '~/view/CanvasTools';
import AltarActions from '~/flux/actions/AltarActions';

const ItemTypeSelector = function() {
    PIXI.Container.call( this );

    this.dispose = () => {
      for (const ele of _elements) {
        if (ele) {
          ele.tap = ele.click = null
        }
      }
      _elements = null
      _visibleElements = null

      if (leftArrow) {
        leftArrow.tap = leftArrow.click = null
        leftArrow = null
      }

      if (rightArrow) {
        rightArrow.tap = rightArrow.click = null
        rightArrow = null
      }

      this.removeChildren()
    }

    this.getSelectedItemData = () => {
      return DATA[selectedDataIndex]
    }

    const DATA = [
      { slot: 'weapon', type: 'dagger', name: text('ui.weapon_dagger') },
      { slot: 'weapon', type: 'sword', name: text('ui.weapon_sword') },
      { slot: 'weapon', type: 'hammer', name: text('ui.weapon_hammer') },
      { slot: 'weapon', type: 'staff', name: text('ui.weapon_staff') },
      { slot: 'boots', type: 'light', name: text('ui.boots_light') },
      { slot: 'torsoe', type: 'light', name: text('ui.torsoe_light') },
      { slot: 'helmet', type: 'light', name: text('ui.helmet_light') },
      { slot: 'boots', type: 'med', name: text('ui.boots_med') },
      { slot: 'torsoe', type: 'med', name: text('ui.torsoe_med') },
      { slot: 'helmet', type: 'med', name: text('ui.helmet_med') },
      { slot: 'boots', type: 'heavy', name: text('ui.boots_heavy') },
      { slot: 'torsoe', type: 'heavy', name: text('ui.torsoe_heavy') },
      { slot: 'helmet', type: 'heavy', name: text('ui.helmet_heavy') },
      { slot: 'off_hand', type: 'book', name: text('ui.off_hand_book') },
      { slot: 'off_hand', type: 'bow', name: text('ui.off_hand_bow') },
      { slot: 'off_hand', type: 'shield', name: text('ui.off_hand_shield') },
      { slot: 'off_hand', type: 'trinket', name: text('ui.off_hand_trinket') },
      { slot: 'ring', type: 'ring', name: text('ui.ring_ring') }
    ]

    var _elements = []

    for( var i = 0; i < DATA.length; ++i ) {
        var element = new PIXI.Text( '['+DATA[i].name+']', {
            fontFamily: 'Courier New',
            fontSize: CanvasTools.dynamicFontSizeString(16),
            fontStyle: 'bold',
            fill: 0xffffff
        });
        element.alpha = 0;        
        this.addChild( element );
        _elements.push( element );

        element.slot = DATA[i].slot;
        element.type = DATA[i].type;
        element.dataIndex = i;

        element.interactive = element.buttonMode = true;
        element.tap = element.click = ( event ) => {
            updateVisibleElements( event.target.dataIndex );
        };
    }

    const NUM_VISIBLE_ELEMENTS = 9;
    const ELEMENT_SPACING = 140;
    var _visibleElements = [];
    var selectedDataIndex = 0;

    const getRealElementIndex = ( index ) => {
        if( index < 0 ) {
            return _elements.length+index;
        } else if( index >= _elements.length ) {
            return index - _elements.length;
        }
            
        return index;
    };
    
    const updateVisibleElements = ( newSelectedDataIndex, emitUpdate=true ) => {
      try {
        for (const ve of _visibleElements) {
          if (ve) {
            this.removeChild(ve)
          }
        }
        _visibleElements = []

        var lowIndex = Math.ceil( newSelectedDataIndex - NUM_VISIBLE_ELEMENTS/2 );
        var highIndex = Math.floor( newSelectedDataIndex + NUM_VISIBLE_ELEMENTS/2 );
        for ( i = lowIndex; i <= highIndex; ++i ) {
            _visibleElements.push( _elements[getRealElementIndex(i)] );
        }

        for (i = 0; i < _visibleElements.length; ++i) {
          const element = _visibleElements[i]
          
          const distFromCenter = Math.abs((highIndex - lowIndex) / 2 - i)
          if (distFromCenter > 2) {
            element.interactive = false
          } else {
            element.interactive = true
          }

          const { name } = DATA[element.dataIndex]
          element.text = element.dataIndex === newSelectedDataIndex
            ? `[${name}]`
            : name

          TweenMax.to(element, 0.5, {
            alpha: Math.max(0, 1 - distFromCenter * 0.47),
            x: Math.round(-_visibleElements.length * ELEMENT_SPACING / 2 + i * ELEMENT_SPACING + ELEMENT_SPACING / 2 - element.width / 2)
          })

          this.addChild(element)
        }

        selectedDataIndex = newSelectedDataIndex;

        if (emitUpdate) { 
          AltarActions.updatePrayerItemData(DATA[selectedDataIndex])
        }
      } catch (err) {
        logError(err, {
          module: 'components/ui_screens/altar/ItemTypeSelector',
          func: 'updateVisibleElements',
          newSelectedDataIndex,
          emitUpdate
        })
      }
    }
    updateVisibleElements(0, false)

    // make arrow buttons
    var leftArrow = new PIXI.Sprite();
    leftArrow.texture = PIXI.utils.TextureCache['altar/left_arrow.png'];
    leftArrow.x = -NUM_VISIBLE_ELEMENTS/2 * ELEMENT_SPACING * 0.55 - leftArrow.width/2;
    leftArrow.y = this.height/2 - leftArrow.height/2;
    this.addChild( leftArrow ); 
    leftArrow.interactive = leftArrow.buttonMode = true;
    leftArrow.tap = leftArrow.click = () => {
        updateVisibleElements( getRealElementIndex(selectedDataIndex-1) );
    };
    leftArrow.hitArea = new PIXI.Circle( 0, 0, 32 );

    // make arrow buttons
    var rightArrow = new PIXI.Sprite();
    rightArrow.texture = PIXI.utils.TextureCache['altar/right_arrow.png'];
    rightArrow.x = NUM_VISIBLE_ELEMENTS/2 * ELEMENT_SPACING * 0.55 - rightArrow.width/2;
    rightArrow.y = this.height/2 - rightArrow.height/2;
    this.addChild( rightArrow ); 
    rightArrow.interactive = rightArrow.buttonMode = true;
    rightArrow.tap = rightArrow.click = () => {
        updateVisibleElements( getRealElementIndex(selectedDataIndex+1) );
    }; 
    rightArrow.hitArea = new PIXI.Circle( 0, 0, 32 );
}
ItemTypeSelector.prototype = Object.create(PIXI.Container.prototype)
ItemTypeSelector.prototype.constructor = ItemTypeSelector
export default ItemTypeSelector
